import React from 'react';

interface RadioProps {
  values: string[];
  label: string;
  callbackfn: (value: string, i: number) => JSX.Element;
}

export const Radio = (props: RadioProps) => {
  return (
    <div
      className={
        props?.label === 'Role' || props?.label === 'Status' ? '' : 'flex'
      }
    >
      {props.values.map(props.callbackfn)}
    </div>
  );
};
