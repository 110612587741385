import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Dropdown, Form } from 'semantic-ui-react';
import { ImageGrid } from 'components';
import { Toggle } from './forms/Toggle';
import {
  DEFAULT_FIELD_VALUE,
  FlattenModelField,
  ModelInstance,
} from 'models/Model';
import { capitalize, is, logger, useForceUpdate } from 'utils';
import { Checkbox } from './forms/Checkbox';
import { Radio } from './forms/Radio';
import { NumberInput } from './forms/Number';
import { MutatableField } from './forms/MutatableField';
import { ReadonlyField } from './forms/Readonly';
import { EmployeeRoles } from 'models/Employee';
import { Role, useAuth } from 'services';
import { ProjectStatus } from 'models';
import { ProjectStatusText } from 'models/Project';

const MSG_EMPTY_GRID = 'Nothing to display';
const BOOLEAN_DISPLAY = { true: 'Yes', false: 'No' };

/**
 * @todo we need to unify the types coming into this function
 * @param prop
 * @param values
 */
const getValues = ({
  prop,
  values,
}: {
  prop: string;
  values?: object | string | string[] | undefined;
}): string[] => {
  if (values) {
    if (Array.isArray(values)) {
      return values;
    } else if (typeof values === 'string') {
      return values.split('|');
    } else if (typeof values === 'object') {
      return Object.values(values);
    } else {
      console.error(`Invalid 'values' definition in prop '${prop}'`);
    }
  }

  return [];
};

type FieldProps<T> = {
  groupId: string;
  model: ModelInstance<T>;
  field: FlattenModelField;
  disabled?: boolean;
  readonly?: boolean;
};

export default function Field<T>({
  groupId,
  model,
  field,
  disabled = false,
  readonly = false,
}: FieldProps<T>) {
  const toggle = useRef({} as HTMLInputElement);
  const forceUpdate = useForceUpdate();
  const { user } = useAuth();

  const {
    prop,
    label,
    type,
    required,
    html,
    activated,
    activatedBy,
    filteredBy,
    fixedValues,
    defaultValue,
    nullValue,
    isNull,
    placeholder,
  } = field;

  // ensure safe valueDisplay
  let valueDisplay: (value: string) => string;
  if (field.valueDisplay) {
    valueDisplay = (value) => {
      try {
        // @ts-ignore
        return field.valueDisplay(value);
      } catch (err) {
        logger.error(`Error in valueDisplay of prop '${prop}':`, err);
        return '(Oops, this is an error)';
      }
    };
  }

  let input: JSX.Element;
  const filterFields = is.string(filteredBy)
    ? (filteredBy as string).split('|')
    : (filteredBy as string[]);
  const labelEl = html ? (
    <label dangerouslySetInnerHTML={{ __html: label }} />
  ) : (
    <label>{label}</label>
  );

  let currentValue = isNull ? nullValue : model.get(prop);

  if (!currentValue && !isNull && defaultValue) {
    model.set(prop, (currentValue = defaultValue), groupId);
  }

  // support relative prop paths
  const resolvePath = (path: string) =>
    path.includes('.') ? path : prop.replace(/[\w]+$/, path);
  const getFieldValue = (path: string) => model.get(resolvePath(path));

  /**
   * @todo fix the types on this
   * @param value
   */
  const getDisplayValue = (value: string) => {
    if (
      value === EmployeeRoles.BUYER ||
      value === EmployeeRoles.ADMIN ||
      value === EmployeeRoles.TECHNICIAN ||
      value === EmployeeRoles.DESIGN
    ) {
      return capitalize(value);
    } else if (value === EmployeeRoles.CUST_SERV) {
      return 'Customer Service';
    } else if (value === EmployeeRoles.SALES) {
      return 'Latham Sales';
    }
    if (nullValue && (!value || value === nullValue)) {
      return nullValue;
    } else if (!!valueDisplay && value) {
      return valueDisplay(value);
    } else if (is.boolean(value)) {
      return BOOLEAN_DISPLAY[value as keyof typeof BOOLEAN_DISPLAY];
    }
    return value;
  };

  const checkIfActive = useCallback(
    (initialized) => {
      // @ts-ignore
      const active =
        !activatedBy ||
        Object.entries(activatedBy).reduce((acc: boolean, [field, values]) => {
          const fieldValue = getFieldValue(field);
          /* eslint-disable indent, multiline-ternary */
          return (
            acc &&
            (is.boolean(values)
              ? values === !!fieldValue
              : is.number(values)
              ? values === fieldValue
              : // NOTE: we must split the pipe string bc all strings include the empty string (which is the empty field value)
              is.string(values)
              ? (values as string).split('|').includes(fieldValue)
              : is.array(values)
              ? (values as any[]).includes(fieldValue)
              : (console.warn(
                  `Invalid 'activatedBy' definition in prop '${prop}'`
                ),
                false))
          );
          /* eslint-enable indent, multiline-ternary */
        }, true);

      if (!active && currentValue) {
        // field became inactive, reset prop value
        model.set(prop, null, groupId);
      }

      if (initialized) {
        // set the component state
        setIsActive(active);
        // and also the field flag (for model.isValid() to pick it up)
        field.isActive = active;
      }

      return active;
    },
    [model, currentValue, forceUpdate]
  );

  const onChange = useCallback(
    (e: any, data: any) => {
      model.set(data.name, data.value, groupId);
      forceUpdate();
    },
    [model]
  );

  const toggleOn = useCallback(
    (e) => {
      const active = e.target.checked;
      if (active) {
        // UI update workaround for certain glitchy types
        if (type === 'number') {
          onChange(null, {
            name: prop,
            value: defaultValue || DEFAULT_FIELD_VALUE.number,
          });
        }
      } else {
        // reset prop value
        model.set(prop, null, groupId);
      }
    },
    [model, onChange]
  );

  const populateValues = useCallback(
    (initialized) => {
      let updatedValues = getValues(field);
      if (filterFields) {
        if (is.object(field.values)) {
          // it's a map filter
          updatedValues =
            (field.values as any)[getFieldValue(filterFields[0])] ?? [];
        } else {
          // it's a text filter
          const includesAllFilters = (value: string) =>
            filterFields.reduce(
              // NOTE: we must use an unlikely fallback prop value here because
              // all strings include the empty string (which is the empty field value)
              (acc: boolean, field) =>
                acc && value.includes(getFieldValue(field) ?? {}),
              true
            );
          updatedValues = updatedValues.filter(includesAllFilters);
        }
        if (initialized) {
          // reset prop value
          onChange(null, { name: prop, value: '' });
        }
      }
      if (fixedValues) {
        // include them
        const values = getValues({ prop, values: fixedValues });
        updatedValues = updatedValues.concat(values);
      }
      if (nullValue) {
        // add last
        updatedValues = [...updatedValues, nullValue];
      }

      if (initialized) {
        // set the component state
        setValues(updatedValues);
      }

      return updatedValues;
    },
    [model, field, filterFields, onChange]
  );

  const [isActive, setIsActive] = useState(checkIfActive(false));

  const [values, setValues] = useState(populateValues(false));

  // hook to internal isValid state
  // useEffect(() => setIsValid(!!field.isValid), [field.isValid, setIsValid])

  useEffect(() => {
    if (activatedBy) {
      // recheck upon dependencies change
      model.onChange(
        Object.keys(activatedBy).map(resolvePath),
        () => checkIfActive(true) && populateValues(true),
        'active_deps:' + prop + ':' + groupId
      );
    }
    if (filteredBy && !readonly) {
      // filter upon dependencies change
      model.onChange(
        filterFields,
        () => field.isActive && populateValues(true),
        'filter_deps:' + prop + ':' + groupId
      );
    }
  }, [model]);

  useEffect(() => {
    // remove super admin roles from radio field if user is not bright/latham_admin, sales, or cust_serv
    if (
      type === 'radio' &&
      values.includes(Role.BAI_ADMIN) &&
      user?.role !== Role.BAI_ADMIN &&
      user?.role !== Role.LATHAM_ADMIN &&
      user?.role !== Role.SALES &&
      user?.role !== Role.CUST_SERV
    ) {
      setValues(values.slice(0, -5));
    } else if (user?.role !== Role.BAI_ADMIN) {
      setValues(values.slice(0, -1));
    }

    // hide automatic statuses if user is not bright/latham_admin, sales, or cust_serv
    if (
      type === 'radio' &&
      values.includes(ProjectStatus.ACTIVE) &&
      user?.role !== Role.BAI_ADMIN &&
      user?.role !== Role.LATHAM_ADMIN &&
      user?.role !== Role.SALES &&
      user?.role !== Role.CUST_SERV
    ) {
      setValues(values.slice(0, 2).map((el) => ProjectStatusText(el)));
    } else if (values.includes(ProjectStatus.ACTIVE)) {
      setValues(values.map((el) => ProjectStatusText(el)));
    }
  }, [type]);

  if (readonly) {
    input = (
      <ReadonlyField
        labelEl={labelEl}
        type={type}
        field={field}
        currentValue={currentValue}
        displayValue={getDisplayValue(currentValue)}
      />
    );
  } else {
    switch (type) {
      case 'radio':
        input = (
          <Radio
            values={values}
            label={field.label}
            callbackfn={(value, i) => (
              <Form.Radio
                key={i}
                name={prop}
                label={getDisplayValue(value)}
                value={value}
                checked={value === currentValue}
                onChange={onChange}
              />
            )}
          />
        );
        break;

      case 'select':
        input = (
          <Dropdown
            fluid
            selection
            name={prop}
            value={currentValue}
            placeholder={placeholder || 'Choose an option'}
            options={values.map((value, i) => ({
              key: i,
              text: getDisplayValue(value),
              value,
            }))}
            onChange={onChange}
          />
        );
        break;

      case 'grid':
        const { baseUrl, showLabels } = field as FlattenModelField;
        input = (
          <ImageGrid
            value={currentValue}
            items={values.map((value) => ({
              label: getDisplayValue(value),
              url: baseUrl + value,
              value,
            }))}
            onSelect={(item) =>
              onChange(null, { name: prop, value: item.value })
            }
            placeholder={placeholder || MSG_EMPTY_GRID}
            labelProp={showLabels ? 'label' : ''}
            invalid={required && field.isValid === false}
          />
        );
        break;

      case 'check':
        input = (
          <Checkbox
            name={prop}
            checked={currentValue}
            onChange={(e) =>
              onChange(e, { name: prop, value: e.target.checked })
            }
            labelEl={labelEl}
          />
        );
        break;

      case 'toggle':
        input = (
          <Toggle
            name={prop}
            isValid={currentValue}
            checked={currentValue}
            onChange={(e) =>
              onChange(e, { name: prop, value: e.target.checked })
            }
          />
        );
        break;

      case 'number':
        input = (
          <NumberInput
            model={model}
            field={field as FlattenModelField}
            onChange={onChange}
            className="field"
          />
        );
        break;

      case 'scanner image':
        input = <img style={{ width: '100%' }} src={field.url} />;
        break;

      case 'no image':
        input = <>No image found.</>;
        break;

      case 'notes':
        input = <>{field.notes}</>;
        break;

      default:
        const Component = type === 'textarea' ? Form.TextArea : Form.Input;
        input = (
          <Component
            type={type}
            name={prop}
            value={currentValue}
            placeholder={placeholder || `Enter ${label.toLowerCase()}...`}
            onChange={onChange}
          />
        );
        break;
    }
  }

  if (isActive) {
    return readonly ? (
      input
    ) : (
      <MutatableField
        key={prop}
        required={required}
        disabled={disabled}
        valid={field.isValid}
        currentValue={currentValue}
        activated={activated}
        type={type}
        labelEl={labelEl}
        name={prop}
        onChange={toggleOn}
        input={input}
      />
    );
  }
  return <></>;
}
