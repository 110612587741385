import { _bemHelper, bemErrorHelper } from '../FieldHelpers';
import React from 'react';
import './ErrorField.scss';

export const ErrorField = (props: {
  errorMessage: string | null | undefined;
  valid: boolean;
  className?: string;
}) => {
  const namespace = 'error-field';
  return (
    <>
      {props.errorMessage && (
        <div
          className={
            bemErrorHelper(`${namespace}`, props.valid) +
            ` ${props.className ? _bemHelper(namespace, props.className) : ''}`
          }
        >
          {props.errorMessage}
        </div>
      )}
    </>
  );
};
