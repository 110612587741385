import React from 'react';
import { Button } from 'semantic-ui-react';

export interface UserModalActionsProps {
  onCancel: () => void;
  onSubmit: () => void;
  disabled?: boolean;
}

export default function UserModalActions({
  onCancel,
  onSubmit,
  disabled,
}: UserModalActionsProps): React.ReactElement {
  return (
    <div className="controls">
      <Button
        basic
        className="control-right"
        content="Cancel"
        onClick={onCancel}
      />
      <Button primary content="Save" onClick={onSubmit} disabled={disabled} />
    </div>
  );
}
