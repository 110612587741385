import React from 'react';
import '../Select/ManagedSelect.scss';
import { bemErrorHelper } from '../FieldHelpers';
import './MangedCheckbox.scss';

type ManagedFieldOnChange = React.Dispatch<React.SetStateAction<boolean>>;

interface MangedInputProps {
  isValid: boolean;
  placeholder?: string;
  label?: string;
  labelElement?: JSX.Element;
  checked: boolean;
  onChange: ManagedFieldOnChange;
  namespace?: string;
  disabled?: boolean;
}

/**
 * @param props
 * @constructor
 */
export const ManagedCheckbox = (props: MangedInputProps) => {
  // BEM css convention namespace
  const BEM_NS = props.namespace ?? 'managed-checkbox';
  const Label = props.labelElement;

  return (
    <label className={bemErrorHelper(BEM_NS, props.isValid)}>
      {!Label && (
        <span className={bemErrorHelper(`${BEM_NS}__copy`, props.isValid)}>
          {props.label}
        </span>
      )}
      {Label && (
        <span
          className={bemErrorHelper(`${BEM_NS}__copy-component`, props.isValid)}
        >
          {Label}
        </span>
      )}
      <input
        className={bemErrorHelper(`${BEM_NS}__input`, props.isValid)}
        onChange={(e) => {
          const event = e as React.ChangeEvent<HTMLInputElement>;
          props.onChange(event.target.checked);
        }}
        type="checkbox"
        placeholder={props.placeholder}
        checked={props.checked}
        disabled={props?.disabled}
      ></input>
      <span
        className={bemErrorHelper(`${BEM_NS}__input-display`, props.isValid)}
      />
    </label>
  );
};
