import React from 'react';
import '../Select/ManagedSelect.scss';
import { bemErrorHelper } from '../FieldHelpers';
import './MangedInput.scss';
import { ErrorField } from '../ErrorField/ErrorField';

type ManagedFieldOnChange =
  | React.Dispatch<React.SetStateAction<string | null | undefined>>
  | React.Dispatch<React.SetStateAction<string | undefined>>
  | React.Dispatch<React.SetStateAction<string>>;

interface MangedInputProps {
  isValid: boolean;
  placeholder: string;
  label?: string;
  value?: string | null;
  onChange: ManagedFieldOnChange & ((value: string | undefined) => void);
  namespace?: string;
  type?: 'number' | 'text' | 'email' | 'zip' | 'poNumber';
  min?: number;
  max?: number;
  errorMessage?: string | null;
  disabled?: boolean;
}

export const ManagedInput = (props: MangedInputProps) => {
  // BEM css convention namespace
  const BEM_NS = props.namespace ?? 'managed-input';

  return (
    <div className={bemErrorHelper(`${BEM_NS}__wrap`, props.isValid)}>
      <label className={bemErrorHelper(BEM_NS, props.isValid)}>
        <span className={bemErrorHelper(`${BEM_NS}__copy`, props.isValid)}>
          {props.label}
        </span>
        <input
          min={props.min}
          max={props.max}
          className={bemErrorHelper(`${BEM_NS}__input`, props.isValid)}
          onChange={(e) => {
            const event = e as React.ChangeEvent<HTMLInputElement>;
            // If type === 'zip', only allow user to enter up to 5 digits
            const isValidZipPartial = /(^\d{0,5}$)/.test(event.target.value);
            if (
              props.type !== 'zip' ||
              (props.type === 'zip' && isValidZipPartial)
            ) {
              props.onChange(
                event.target.value !== '' ? event.target.value : undefined
              );
            }
          }}
          type={props.type}
          placeholder={props.placeholder}
          value={props.value ?? ''}
          disabled={props?.disabled}
        ></input>
      </label>
      <ErrorField errorMessage={props.errorMessage} valid={props.isValid} />
    </div>
  );
};
