import { createModel, DataFromSchema } from 'models/Model';

const required = true;

export type DealerProjects = {
  dealerId: string;
  dealerName: string;
  isUserDealer: boolean;
  projectIds: string[];
  projectCount: number;
  scanCount: number;
  singleScanCount: number;
  multiScanCount: number;
  billableProjectCount: number;
  duplicateProjectCount: number;
};

const oneToManySchema = {
  dealerId: { type: 'text', label: 'Dealer Id', required },
  dealerName: { type: 'text', label: 'Dealer Name', required },
  isUserDealer: { type: 'radio', label: 'Dealer Type', required },
  projectIds: { type: 'text', label: 'Dealer Projects', required },
  projectCount: { type: 'number', label: 'Project Count', required },
  scanCount: { type: 'number', label: 'Scan Count', required },
  singleScanCount: { type: 'number', label: 'Multi Scan Count', required },
  multiScanCount: { type: 'number', label: 'Single Scan Count', required },
  billableProjectCount: {
    type: 'number',
    label: 'Billable Project Count',
    required,
  },
  duplicateProjectCount: {
    type: 'number',
    label: 'Non Billable Project Count',
    required,
  },
} as const;
// ☝️ this const assertion is needed for DataFromSchema generic to do its magic

export type OneToMany = DataFromSchema<DealerProjects>;

export const OneToManyModel = createModel<OneToMany>(oneToManySchema);
