import { FeatureType } from 'models';

export const getFeatureTypeOptions = () => {
  const options = [];
  for (const val in FeatureType) {
    if (val !== FeatureType.Calibration && val !== FeatureType.Perimeter) {
      options.push({
        label: val,
        value: val,
      });
    }
  }
  return options;
};

export const SafetyStripesOptions = [
  { label: 'Aqua', value: 'Aqua' },
  { label: 'Black', value: 'Black' },
  { label: 'Blue', value: 'Blue' },
  { label: 'Charcoal Gray', value: 'Charcoal Gray' },
  { label: 'Dove Gray', value: 'Dove Gray' },
  { label: 'Powder Blue', value: 'Powder Blue' },
  { label: 'White', value: 'White' },
];

export const SolidShallowEndOptions = [
  { label: 'Black', value: 'Black' },
  { label: 'Blue', value: 'Blue' },
  { label: 'White', value: 'White' },
];
