import React from 'react';
import { Button } from 'semantic-ui-react';
import { OrderStatus } from '../../models';

export interface OrderModalCommonActionsProps {
  nextStep: () => void;
  cancelOrder: () => void;
  submitOrder: () => void;
  previousStep: () => void;
  currentStep: number;
  initialStep: number;
  isLastStep: boolean;
  finalStep: number;
  currentOrder?: {
    status: OrderStatus;
  };
}

export interface OrderModalActionsProps {
  nextDisabled: boolean;
  archived?: boolean;
  hasAccess: boolean;
}

type modalProps = OrderModalActionsProps & OrderModalCommonActionsProps;

export const OrderModalActions = (props: modalProps) => {
  const ACTION_STRINGS = {
    SubmitOrder: 'Submit Order',
    SubmitQuote: 'Submit for Quote',
    Next: 'Next',
    Cancel: 'Cancel',
    Prev: 'Prev',
    Done: 'Done',
  };
  const isLastStep = props.finalStep === props.currentStep || props.isLastStep;
  const currentStep = props.currentStep;
  const isSubmitted = props.currentOrder?.status === OrderStatus.Ordered;
  const isInReview =
    props.currentOrder?.status === OrderStatus.QuoteInReview ||
    props.currentOrder?.status === OrderStatus.OrderInReview;
  const isQuoted =
    props.currentOrder?.status === OrderStatus.QuotedOptions ||
    props.currentOrder?.status === OrderStatus.ReadyForOrder;

  const getButtonText = () => {
    if (isLastStep) {
      return isQuoted ? ACTION_STRINGS.SubmitOrder : ACTION_STRINGS.SubmitQuote;
    }
    return ACTION_STRINGS.Next;
  };

  return (
    <div className="controls">
      <Button
        basic
        className="control-left"
        onClick={props.cancelOrder}
        content={ACTION_STRINGS.Cancel}
      />
      {currentStep > 0 && !isSubmitted ? (
        <Button
          className="control-right"
          primary
          onClick={() => props.previousStep()}
          content={ACTION_STRINGS.Prev}
        />
      ) : null}
      {isSubmitted ? (
        <Button
          className="control-right"
          primary
          onClick={props.cancelOrder}
          content={ACTION_STRINGS.Done}
        />
      ) : isInReview && isLastStep && !props.hasAccess ? null : (
        <Button
          className={currentStep <= props.initialStep ? 'control-right' : ''}
          primary
          disabled={
            props.nextDisabled ||
            (props.archived && getButtonText() !== ACTION_STRINGS.Next)
          }
          onClick={() => (isLastStep ? props.submitOrder() : props.nextStep())}
          content={getButtonText()}
        />
      )}
    </div>
  );
};
