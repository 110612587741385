import { TableType } from './SmartTableContext';

export enum SmartTableSortOrderActionType {
  UPDATE_TABLES = 'update_tables',
}

export enum SmartTableSortOrder {
  ASC = 'ascending',
  DES = 'descending',
}

export type GlobalSmartTableState = { [key in TableType]: TableState };

export type SmartTableFilterState = Map<string, string>;

export interface TableState {
  filters: SmartTableFilterState | undefined;
  sorting: SmartTableSortState;
}

export interface SmartTableSortState {
  prop: string;
  order: SmartTableSortOrder;
}

export interface SmartTableSortStateAction {
  type: SmartTableSortOrderActionType;
  payload?: GlobalSmartTableState;
}

export const updateTables = (
  payload: GlobalSmartTableState
): SmartTableSortStateAction => ({
  type: SmartTableSortOrderActionType.UPDATE_TABLES,
  payload,
});

const filters = new Map();

export const initialProjectsTableState: TableState = {
  filters: undefined,
  sorting: { prop: 'createdAt', order: SmartTableSortOrder.DES },
};

export const initialProjectsWithDealerTableState: TableState = {
  filters: filters?.set('userDealerFilter', 'One To Many Profile'),
  sorting: { prop: 'dealerName', order: SmartTableSortOrder.ASC },
};

export const initialEmployeesTableState: TableState = {
  filters: undefined,
  sorting: { prop: 'name', order: SmartTableSortOrder.ASC },
};

export const initialDealersTableState: TableState = {
  filters: undefined,
  sorting: { prop: 'name', order: SmartTableSortOrder.ASC },
};

export const initialOrdersTableState: TableState = {
  filters: undefined,
  sorting: { prop: 'createdAt', order: SmartTableSortOrder.DES },
};

export const initialGlobalTableState: GlobalSmartTableState = {
  projects: initialProjectsTableState,
  employees: initialEmployeesTableState,
  dealers: initialEmployeesTableState,
  orders: initialOrdersTableState,
  projectsWithDealer: initialProjectsWithDealerTableState,
};

const SmartTableReducer = (
  state: GlobalSmartTableState = initialGlobalTableState,
  action: SmartTableSortStateAction
): GlobalSmartTableState => {
  switch (action.type) {
    case SmartTableSortOrderActionType.UPDATE_TABLES:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default SmartTableReducer;
