import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Message } from 'semantic-ui-react';
import { Toast } from 'components';
import { USER_MSG } from 'strings';

export type UIMessageProps = {
  title: string;
  msg: string;
  icon?: string;
  kind?: MessageKind;
};

enum MessageKind {
  INFO,
  ERROR,
  SUCCESS,
}

interface UIContextType {
  devMode: boolean;
  setDevMode: (active: boolean) => void;
  showInfo: (msg: UIMessageProps) => void;
  showError: (msg?: UIMessageProps) => void;
  showSuccess: (msg?: UIMessageProps) => void;
}

const UIContext = createContext({} as UIContextType);

export function useUI() {
  return useContext(UIContext);
}

export function UIProvider({
  children,
}: {
  children?: ReactNode;
}): ReactElement {
  const [devMode, setDevMode] = useState(false);
  const [message, setMessage] = useState({} as UIMessageProps);
  const showInfo = (msg: UIMessageProps) => setMessage(msg);
  const showError = (msg = USER_MSG.ERROR_GENERIC as UIMessageProps) =>
    setMessage({ ...msg, kind: MessageKind.ERROR });
  const showSuccess = (msg = USER_MSG.SUCCESS_GENERIC as UIMessageProps) =>
    setMessage({ ...msg, kind: MessageKind.SUCCESS });

  useEffect(() => {
    if (devMode) setTimeout(() => showSuccess(USER_MSG.DEV_MODE), 0);
  }, [devMode]);

  return (
    <UIContext.Provider
      value={{ devMode, setDevMode, showInfo, showError, showSuccess }}
    >
      {children}
      <Toast trigger={message}>
        <UIMessage {...message} />
      </Toast>
    </UIContext.Provider>
  );
}

export function UIMessage({
  title,
  msg,
  icon,
  kind,
}: UIMessageProps): ReactElement {
  const isLiteralIcon = icon && icon.length < 5;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {isLiteralIcon && (
        <h1
          style={{
            fontSize: '4em',
            margin: 0,
          }}
        >
          {icon}
        </h1>
      )}
      <Message
        compact
        error={kind === MessageKind.ERROR}
        success={kind === MessageKind.SUCCESS}
        icon={isLiteralIcon ? undefined : icon}
        size="large"
        header={title}
        content={msg}
      />
    </div>
  );
}

export const MountedStyle = { animation: 'inAnimation 250ms ease-in' };

export const UnmountedStyle = {
  animation: 'outAnimation 270ms ease-out',
  animationFillMode: 'forwards',
};
