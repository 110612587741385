export const HARDWARE_NEEDED_CHOOSE =
  'Choose Hardware Options for each Deck Type';
export const HARDWARE_NEEDED_NO_ANCHORS = 'No Anchors are needed';
export const HARDWARE_NEEDED_NOTHING =
  'No hardware (springs, anchors, installation rods, etc)';

export const hardwareNeededOptions = [
  {
    label: HARDWARE_NEEDED_CHOOSE,
    value: HARDWARE_NEEDED_CHOOSE,
  },
  {
    label: HARDWARE_NEEDED_NO_ANCHORS,
    value: HARDWARE_NEEDED_NO_ANCHORS,
  },
  {
    label: HARDWARE_NEEDED_NOTHING,
    value: HARDWARE_NEEDED_NOTHING,
  },
];

export const getHardwareNeededValues = (): string[] => {
  return hardwareNeededOptions.map((el) => el.value);
};
