import { Resource } from 'models';
import React, { useRef, useState } from 'react';
import { ReactImageGalleryItem } from 'react-image-gallery';
import ImageGallery from 'react-image-gallery';
import './FeatureGallery.scss';

interface FeatureGalleryProps {
  images: Resource[];
  onImageClick: (
    ref: React.MutableRefObject<ImageGallery | null>,
    index: number
  ) => void;
  galleryVisible: boolean;
  setIsGalleryVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FeatureGallery = (
  props: FeatureGalleryProps
): React.ReactElement => {
  const { images, onImageClick, galleryVisible, setIsGalleryVisible } = props;
  const [visibility, setVisibility] = useState('hidden');
  const [style, setStyle] = useState<any>({
    boxShadow: '-1px 1px 8px rgb(0 0 0 / 41%)',
  });
  const gallery = useRef<ImageGallery | null>(null);

  const setHoverStyle = () => {
    setVisibility('visible');
    setStyle({
      cursor: 'pointer',
      background: '#fff',
      border: 'none',
      webkitBoxShadow: '0 1px 3px 0 #bcbdbd,0 0 0 1px #d4d4d5',
      boxShadow: '0 1px 3px 0 #bcbdbd,0 0 0 1px #d4d4d5',
      webkitTransform: 'translateY(-2px)',
      transform: 'translateY(-2px)',
    });
  };

  const clearHoverStyle = () => {
    setVisibility('hidden');
    setStyle({
      boxShadow: '-1px 1px 8px rgb(0 0 0 / 41%)',
    });
  };

  return (
    <>
      {images.length > 0 && (
        <>
          <div
            className="image-container-small"
            onClick={() => onImageClick(gallery, 0)}
            onMouseEnter={() => setHoverStyle()}
            onMouseLeave={() => clearHoverStyle()}
          >
            <img
              src={images[0]?.url}
              alt={`image-0`}
              height="50%"
              width="100%"
              style={style}
            />
            <div
              className="icon-container-small"
              style={{
                visibility: visibility,
              }}
            >
              <i className="zoom-in icon"></i>
            </div>
          </div>
          <ImageGallery
            lazyLoad={true}
            ref={gallery}
            items={
              images.map((img) => ({
                original: img?.url,
                thumbnail: img?.url,
              })) as ReactImageGalleryItem[]
            }
            useBrowserFullscreen={false}
            onScreenChange={setIsGalleryVisible}
            thumbnailPosition="top"
            additionalClass={'abs hidden ' + (galleryVisible ? 'show' : '')}
            showPlayButton={false}
            showFullscreenButton={true}
          />
        </>
      )}
    </>
  );
};
