import React, { useEffect, useState } from 'react';
import './SpecForm.scss';
import { ManagedInput } from 'components/orders/Fields/Input/ManagedInput';
import { OutsideStepArray } from './SpecFormSchema';
import { ManagedToggle } from 'components/orders/Fields/Toggle/ManagedToggle';

interface OutsideStepProps {
  setOutsideStep: (outsideStep: OutsideStepArray) => void;
  outsideStep: OutsideStepArray | null;
  archived?: boolean;
}

export interface outsideStepValuesType {
  id: string;
  anchorTypeOptions?: string;
  cableAssembly?: boolean;
  isRemovable?: boolean;
  isCovered?: boolean;
  isOverWater?: boolean;
  notes?: string;
}

const OutsideStep = ({
  outsideStep,
  setOutsideStep,
  archived,
}: OutsideStepProps) => {
  // Field states
  const [outsideStepBool, setOutsideStepBool] = useState<boolean>(
    outsideStep?.outsideStep ?? true
  );
  const [outsideStepFeatureId, setOutsideStepFeatureId] = useState<
    string | undefined
  >(outsideStep?.outsideStepFeatureId);
  const [outsideStepWidthSize, setOutsideStepWidthSize] = useState<
    string | undefined
  >(outsideStep?.outsideStepWidthSize?.toString());
  const [oustideStepComments, setOustideStepComments] = useState<
    string | undefined
  >(outsideStep?.oustideStepComments);

  useEffect(() => {
    setOutsideStep({
      outsideStep: outsideStepBool,
      outsideStepFeatureId: outsideStepFeatureId ?? '',
      outsideStepWidthSize: parseInt(outsideStepWidthSize ?? '0'),
      oustideStepComments: oustideStepComments ?? '',
    });
  }, [
    outsideStepBool,
    outsideStepFeatureId,
    outsideStepWidthSize,
    oustideStepComments,
  ]);

  return (
    <>
      {outsideStepFeatureId && (
        <div className="spec-form__second-title">
          {`Feature: ${outsideStepFeatureId}`}
        </div>
      )}
      <ManagedToggle
        label="Outside Step?"
        name="Outside Step?"
        isValid={true}
        onChange={setOutsideStepBool}
        checked={outsideStepBool}
        disabled={archived}
      />
      <ManagedInput
        isValid={true}
        label="Outside Step FeatureID"
        type="text"
        placeholder="Outside Step FeatureID"
        onChange={setOutsideStepFeatureId}
        value={outsideStepFeatureId}
        disabled={archived}
      />
      <ManagedInput
        isValid={true}
        label="Outside Step Width Size"
        type="text"
        placeholder="Outside Step Width Size"
        onChange={setOutsideStepWidthSize}
        value={outsideStepWidthSize}
        disabled={archived}
      />
      <ManagedInput
        isValid={true}
        label="Oustide Step Comments"
        type="text"
        placeholder="Oustide Step Comments"
        onChange={setOustideStepComments}
        value={oustideStepComments}
        disabled={archived}
      />
    </>
  );
};

export default OutsideStep;
