import React, { useEffect, useState, useCallback } from 'react';
import { Form, Header, Modal, Segment } from 'semantic-ui-react';
import Loader from 'components/layout/loader/Loader';
import { FieldGroup, TransitionGroup } from 'components';
import { Dealer, DealerModel, PurchasePartner, User } from 'models';
import { PurchasePartners, CompanyService } from '.';
import { Role, useAuth, useUI } from 'services';
import { USER_MSG } from 'strings';
import {
  PurchasePartnerWithBrands,
  TYPE_BUYING_GROUP,
  TYPE_DIRECT,
  TYPE_DISTRIBUTOR,
} from 'models/Dealer';
import { dealerDirectString } from 'components/orders/Forms/Partials/Product/ProductFormConstants';
import { DealerMsg } from 'sections/admin/Constants';

export default function DealerDetail(): React.ReactElement {
  const { user } = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingRefresh, setLoadingRefresh] = useState<boolean>(false);
  const [dealer, setDealer] = useState(DealerModel({} as Dealer));
  const [brandsError, setBrandsError] = useState(false);
  const [purchasePartners, setPurchasePartners] =
    useState<PurchasePartnerWithBrands[]>();
  const { showError, showSuccess } = useUI();
  const hasAdminPrivilege =
    user?.role === Role.BAI_ADMIN ||
    user?.role === Role.LATHAM_ADMIN ||
    user?.role === Role.CUST_SERV;

  const setDefaultPurchasePartner = (purchasePartner: PurchasePartner) => {
    CompanyService.set(
      //@ts-ignore
      { id: `mine/defaultPartner/${purchasePartner.code}` },
      'PATCH',
      false
    )
      .then(() => {
        showSuccess();
        fetchDealersAndBrands();
      })
      .catch((e) => {
        showError({ title: e.message, msg: e.message });
      });
  };

  const removePurchasePartner = (purchasePartner: PurchasePartner) => {
    // TODO blocked by LP-1844
  };

  const fetchDealerInfo = useCallback(async () => {
    try {
      const dealerInfo = await CompanyService.get(`${user?.dealerId}`);
      setDealer(DealerModel(dealerInfo));
    } catch (error: any) {
      showError({ title: error.message, msg: error.message });
    }
  }, [user]);

  const fetchBrandsInfo = useCallback(async () => {
    setBrandsError(false);
    try {
      const purchasePartners = await CompanyService.getBrands();

      const { direct, throughBuyingGroup, throughDistribution } =
        purchasePartners;
      const formattedPartners = direct
        ? [{ ...direct, name: dealerDirectString, type: TYPE_DIRECT }]
        : [];
      if (throughBuyingGroup?.name) {
        formattedPartners.push({
          ...throughBuyingGroup,
          type: TYPE_BUYING_GROUP,
        });
      }
      if (throughDistribution?.distributorCustomerAccount?.length) {
        throughDistribution.distributorCustomerAccount.forEach(
          (distributor) => {
            formattedPartners.push({ ...distributor, type: TYPE_DISTRIBUTOR });
          }
        );
      }

      setPurchasePartners(
        // Distributors without brands should not be shown
        formattedPartners.filter((partner) => partner.brands.length > 0)
      );
    } catch (error: any) {
      setBrandsError(true);
    }
  }, [user]);

  const fetchDealersAndBrands = async () => {
    await Promise.all([fetchDealerInfo(), fetchBrandsInfo()]).then(() => {
      setLoading(false);
    });
  };

  const refreshDealer = async () => {
    try {
      setLoadingRefresh(true);
      await CompanyService.addOrRefreshDealer(dealer.data.rewardsId).then(
        (dealerInfo) => {
          setLoadingRefresh(false);
          const newDealer = {
            address: {
              address1: dealerInfo.partnersInfo.shipping_address_street,
              address2: '',
              city: dealerInfo.partnersInfo.shipping_address_city,
              state: dealerInfo.partnersInfo.shipping_address_state,
              postalcode: dealerInfo.partnersInfo.shipping_address_postalcode,
              country: dealerInfo.partnersInfo.shipping_address_country,
            },
            id: dealerInfo.dealerId,
            name: dealerInfo.partnersInfo.customer_name,
            phone: dealerInfo.partnersInfo.phone_office,
            rewardsId: dealerInfo.rewardsId,
          };
          showSuccess({
            title: DealerMsg.SUCCESS_TITLE,
            msg: DealerMsg.SUCCESS_MSG_UPDATE,
          });
          setDealer(DealerModel(newDealer));
          fetchBrandsInfo();
        }
      );
    } catch (error: any) {
      setLoadingRefresh(false);
      showError({ title: DealerMsg.ERROR_MSG_UPDATE, msg: error.message });
    }
  };

  useEffect(() => {
    if (!user?.dealerId) return;
    fetchDealersAndBrands();
  }, [user, fetchDealerInfo]);

  return loading ? (
    <TransitionGroup transition="scale" isVisible={!!dealer.data.id}>
      <Loader text={USER_MSG.DEALER_NOT_FOUND} />
    </TransitionGroup>
  ) : !dealer.data.id ? (
    <Loader text={USER_MSG.DEALER_NOT_FOUND} />
  ) : (
    <TransitionGroup transition="scale" isVisible={!!dealer.data.id}>
      <Modal open={loadingRefresh}>
        <div className="ui large text loader">
          {DealerMsg.REFRESH_IN_PROGRESS}
        </div>
      </Modal>
      <Segment className="pad">
        {hasAdminPrivilege && (
          <button
            className="ui primary button main-action"
            onClick={refreshDealer}
          >
            {DealerMsg.REFRESH}
          </button>
        )}
        {!hasAdminPrivilege && (
          <a
            className="ui primary button main-action"
            href="https://www.lathampool.com/contact-us/"
            target="_blank"
            rel="noreferrer"
            onClick={refreshDealer}
          >
            {DealerMsg.CONTACT_LATHAM}
          </a>
        )}
        <Form>
          <Header size="large">{DealerMsg.DEALER_INFO_TITLE}</Header>
          <div className="row">
            <FieldGroup
              model={dealer}
              fields="name address.address1 address.address2 address.city rewardsId"
              disabled={true}
              className="col"
            />
            <FieldGroup
              model={dealer}
              fields="url email address.state address.postalcode phone"
              disabled={true}
              className="col"
            />
          </div>
        </Form>
      </Segment>
      <PurchasePartners
        error={brandsError}
        purchasePartners={purchasePartners}
        setDefaultPurchasePartner={setDefaultPurchasePartner}
        removePurchasePartner={removePurchasePartner}
      />
    </TransitionGroup>
  );
}
