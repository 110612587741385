import React, { useEffect, useState } from 'react';
import Pool from './pool.svg';
import './Loader.scss';
import TransitionGroup from 'components/TransitionGroup';

interface LoaderProps {
  text?: { title: string; msg: string };
  children?: any;
  topClass?: string;
}

export default function Loader({
  text,
  children,
  topClass,
}: LoaderProps): React.ReactElement {
  const [loading, setLoading] = useState(false);

  useEffect(() => setLoading(true));

  return (
    <TransitionGroup isVisible={loading}>
      <div className={`loader ${topClass}`}>
        <div className="loader__content" data-cy="loader">
          <div id="pool">
            <img src={Pool} alt="pool" />
          </div>
          <p className="loader-text">{text?.title}</p>
          <p className="loader-text">{text?.msg}</p>
          {children}
        </div>
      </div>
    </TransitionGroup>
  );
}
