import React from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { ProjectStatsTotal } from '../DataTabs/DataUtils';

interface PieChartDataProps {
  title: string;
  data: ProjectStatsTotal[];
  dataLength: number;
  percentage?: boolean;
}

interface LabelProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  value: number;
}

export default function PieChartData(
  props: PieChartDataProps
): React.ReactElement {
  const { title, data, dataLength, percentage } = props;
  const colors = ['#003057', '#0087f4', '#95bfdf'];

  const radian = Math.PI / 180;
  const customizedLabel = (props: LabelProps) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, value } = props;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * radian);
    const y = cy + radius * Math.sin(-midAngle * radian);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {percentage ? `${Math.round((value / dataLength) * 100)}%` : value}
      </text>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%" className="pie-chart">
      <>
        <h1 className="pie-chart-title">{title}</h1>
        <PieChart width={200} height={200}>
          <Pie
            dataKey="value"
            isAnimationActive={true}
            data={data}
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill="#8884d8"
            labelLine={false}
            label={customizedLabel}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </>
    </ResponsiveContainer>
  );
}
