import React, { useEffect, useState } from 'react';
import { ManagedSelect } from 'components/orders/Fields/Select/ManagedSelect';
import { ManagedToggle } from 'components/orders/Fields/Toggle/ManagedToggle';
import { DeckFormType } from './DeckSchema';
import './Deck.scss';
import { AnchorsDeckValidity } from '../AnchorsForm/AnchorsForm';
import {
  deckingTypeOptions,
  hardwareTypeOptions,
  paddingTypeOptions,
} from './DeckConstants';
import { FeatureMeasurements } from 'models/Project';

interface DeckProps {
  title: string;
  showHardwareDropdown: boolean;
  image?: string;
  notes?: string;
  setDeck: (deck: DeckFormType) => void;
  validFields: AnchorsDeckValidity;
  deck: DeckFormType;
  length: number;
  archived?: boolean;
  featureName: string | null | undefined;
  measurement?: FeatureMeasurements;
}

const deckTypeLabelCopy = 'Decking Type';
const hardwareLabelCopy = 'Hardware';
const brassCollarsCopy = 'Include Brass Collars';
const paddingCopy =
  'Is the coping rough / would you like additional perimeter padding';
const paddingTypeCopy = 'Padding Type';

const Deck = ({
  title,
  showHardwareDropdown,
  image,
  notes,
  setDeck,
  validFields,
  deck,
  length,
  archived,
  featureName,
  measurement,
}: DeckProps) => {
  if (typeof deck?.decking === undefined) {
    throw new Error('Decks are required for deck component to render!');
  }

  const isRDM =
    measurement &&
    measurement.deckingProperties &&
    (measurement.deckingProperties[0]?.numPoints > 0 ||
      measurement?.deckingProperties[1]?.numPoints > 0 ||
      measurement?.deckingProperties[2]?.numPoints > 0);

  // Field states
  const [deckingType, setDeckingType] = useState<string | undefined>(
    deck?.decking ?? undefined
  );
  const [hardwareOption, setHardwareOption] = useState<string | undefined>(
    deck?.hardware ?? undefined
  );
  const [includeBrassCollars, setIncludeBrassCollars] = useState(
    deck?.collars ?? undefined
  );
  const [isRough, setIsRough] = useState(deck?.padding ?? false);
  const [paddingType, setPaddingType] = useState<string | undefined>(
    deck?.paddingType ?? undefined
  );

  useEffect(() => {
    if (!showHardwareDropdown && !isRDM) {
      setHardwareOption(undefined);
      setIncludeBrassCollars(undefined);
    }
  });

  useEffect(() => {
    setDeck({
      decking: deckingType,
      hardware: hardwareOption,
      padding: isRough,
      collars: includeBrassCollars,
      paddingType: paddingType,
      length: length,
      featureName: featureName,
      notes: notes,
      rdm: isRDM,
    } as DeckFormType);
  }, [
    deckingType,
    hardwareOption,
    isRough,
    paddingType,
    includeBrassCollars,
    isRDM,
  ]);

  useEffect(() => {
    // Clear paddingType if isRough toggle switches to "off"
    if (!isRough) setPaddingType(undefined);
  }, [isRough]);

  return (
    <div className="deck">
      <div className="deck__title">{title}</div>
      {image ? (
        <img className="deck__image" src={image} />
      ) : (
        <div>No image found.</div>
      )}
      {notes ?? <div>{notes}</div>}
      {isRDM && (
        <span className="rdmContainer">
          <i className="rdmIcon exclamation triangle icon"></i>
          <div className="rdmWarning">This is an RDM deck</div>
        </span>
      )}
      <ManagedSelect
        label={deckTypeLabelCopy}
        isValid={validFields.decking}
        value={deckingType}
        onChange={setDeckingType}
        options={deckingTypeOptions}
        name="deck-type"
        disabled={archived}
      />
      {(showHardwareDropdown || isRDM) && (
        <ManagedSelect
          label={hardwareLabelCopy}
          isValid={validFields.hardware}
          value={hardwareOption}
          onChange={setHardwareOption}
          name="hardware-type"
          options={
            deckingType
              ? hardwareTypeOptions[
                  deckingType as keyof typeof hardwareTypeOptions
                ]
              : []
          }
          disabled={archived}
        />
      )}
      {!!hardwareOption && (showHardwareDropdown || isRDM) && (
        <ManagedToggle
          label={brassCollarsCopy}
          name="collars"
          isValid={validFields.collars}
          onChange={setIncludeBrassCollars}
          checked={includeBrassCollars ?? false}
          disabled={archived}
        />
      )}
      <ManagedToggle
        label={paddingCopy}
        name="padding"
        isValid={validFields.padding}
        onChange={setIsRough}
        checked={isRough}
        disabled={archived}
      />
      {isRough && (
        <ManagedSelect
          label={paddingTypeCopy}
          isValid={validFields.paddingType}
          value={paddingType}
          onChange={setPaddingType}
          options={paddingTypeOptions}
          name="is-rough"
          disabled={archived}
        />
      )}
    </div>
  );
};

export default Deck;
