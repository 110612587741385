/**
 * This just takes an input string, and appends `--error` to that class name to simplify bem error states
 *
 * For example: bemHelper('block-component') =>
 *      'block-component' || 'block-component block-component--error'
 * @param name
 * @param isValid
 */
export const bemErrorHelper = (name = '', isValid?: boolean): string => {
  if (typeof isValid !== undefined) {
    const isValidString = !isValid ? 'error' : '';
    return _bemHelper(name, isValidString);
  }

  return `${name}`;
};

export const _bemHelper = (name: string, modifierString: string) => {
  return `${name} ${modifierString ? name + '--' + modifierString : ''}`;
};
