export const chooseLaterString = 'Quote all options';
export const chooseLaterStringOld = "I'll Choose Later";
export const dealerDirectString = 'Dealer Direct';
export const lathamCanadaBrand = 'Latham CA';

// #region Covers
// Cover fabric options
export const fabric5000MStandardMesh = '5000M';
export const fabric7000MSHighShadeMesh = '7000MS';
export const fabric9000MXMaxShadeMesh = '9000MX';
export const fabric500PLiteSolid = '500P';
export const fabric1000HSHyperLiteSolid = '1000HS';
export const fabricSelectOptionsSolid = [
  { label: '1000HS HyperLite Solid', value: fabric1000HSHyperLiteSolid },
  { label: '500P Lite', value: fabric500PLiteSolid },
];
export const fabricSelectOptionsMesh = [
  { label: '5000M Standard', value: fabric5000MStandardMesh },
  { label: '7000MS High-Shade', value: fabric7000MSHighShadeMesh },
  { label: '9000MX Max-Shade', value: fabric9000MXMaxShadeMesh },
];
export const fabricColorOptions1000HS = [
  '1000HS_Full-Weight-Solid_Blue',
  '1000HS_Full-Weight-Solid_Green',
  '1000HS_Full-Weight-Solid_Grey',
  '1000HS_Full-Weight-Solid_Tan',
];
export const fabricColorOptions5000M = [
  '5000M_Standard-Mesh_Blue',
  '5000M_Standard-Mesh_Green',
  '5000M_Standard-Mesh_Grey',
  '5000M_Standard-Mesh_Tan',
];
export const fabricColorOptions500P = [
  '500P_Lite-Solid_Blue',
  '500P_Lite-Solid_Green',
];
export const fabricColorOptions7000MS = [
  '7000MS_High-Shade-Mesh_Blue',
  '7000MS_High-Shade-Mesh_Green',
  '7000MS_High-Shade-Mesh_Grey',
  '7000MS_High-Shade-Mesh_Tan',
];
export const fabricColorOptions9000MX = ['9000MX_Max-Shade-Mesh_Black'];
// Combine & deduplicate fabricSelectOptionsSolid & fabricSelectOptionsMesh
export const fabricSelectOptions = fabricSelectOptionsSolid.concat(
  fabricSelectOptionsMesh.filter((meshOption) => {
    return !fabricSelectOptionsSolid.find(
      (solidOption) =>
        solidOption.value === meshOption.value &&
        solidOption.label === meshOption.label
    );
  })
);

// Cover pump options
export const pumpString = 'Pump';
export const noPumpString = 'No, thanks';
export const pumpSelectOptions = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
];
export const drainOrPumpSelectOptions = [
  { label: 'Invis-a-Drain', value: 'Invis-a-Drain' },
  { label: 'Pump', value: 'Pump' },
  { label: 'No, thanks', value: 'No, thanks' },
];

// Cover material options
export const MaterialOptionSolid = 'Solid';
export const MaterialOptionMesh = 'Mesh';
export const coverMaterialSelectOptions = [
  { label: MaterialOptionSolid, value: MaterialOptionSolid },
  { label: MaterialOptionMesh, value: MaterialOptionMesh },
  { label: chooseLaterString, value: chooseLaterString },
];

// Cover grid options
export const threeByThreeGridOption = { label: '3x3', value: '3x3' };
export const fiveByFiveGridOption = { label: '5x5', value: '5x5' };
export const chooseLaterGridOption = {
  label: chooseLaterString,
  value: chooseLaterString,
};
export const gridSpacingSelectOptions = [
  fiveByFiveGridOption,
  threeByThreeGridOption,
  chooseLaterGridOption,
];
export const gridSpacingSelectOptionsOrder = [
  fiveByFiveGridOption,
  threeByThreeGridOption,
];
// #endregion

// #region Liners
// Liner material selections
export const MaterialOptionMatch = 'Matched';
export const MaterialOptionBottom = 'All Bottom';
export const MaterialOptionMismatched = 'Mismatched';
export const linerMaterialSelectOptions = [
  { label: MaterialOptionMatch, value: MaterialOptionMatch },
  { label: MaterialOptionBottom, value: MaterialOptionBottom },
  { label: MaterialOptionMismatched, value: MaterialOptionMismatched },
  { label: chooseLaterString, value: chooseLaterString },
];

// Liner material options
export const WhiteStrips = 'White stripe on each tread';
export const DifferentMaterialOrColor =
  'Different material/color riser for treads';
export const linerMaterialOptions = [
  { label: WhiteStrips, value: WhiteStrips },
  { label: DifferentMaterialOrColor, value: DifferentMaterialOrColor },
];

// Liner step fastener options
export const Bead = 'Bead';
export const Flap = 'Flap';
export const HookAndLoop = 'Hook & Loop';
export const RodPocket = 'Rod pocket';
export const SideBead = 'Side bead';
export const None = 'None';
// #endregion

// #region General
// General selections
export const productTypeSelectOptions = [
  { label: 'Safety Cover', value: 'Safety Cover' },
  { label: 'Vinyl Liner', value: 'Vinyl Liner' },
];
export const coverTypeSelectOptions = [
  { label: 'New Cover', value: 'No' },
  { label: 'Replacement Cover', value: 'Yes' },
];
export const linerTypeSelectOptions = [
  { label: 'New Liner', value: 'No' },
  { label: 'Replacement Liner', value: 'Yes' },
];
export const purchasePartnerSelectOptions = [
  { label: dealerDirectString, value: dealerDirectString },
];
export const brandSelectOptions = [
  { label: lathamCanadaBrand, value: lathamCanadaBrand },
  { label: 'Latham US', value: 'Latham_US' },
  { label: 'Premier', value: 'Premier' },
];
export const allCoverSelectOptions = [
  { label: MaterialOptionSolid, value: MaterialOptionSolid },
  { label: MaterialOptionMesh, value: MaterialOptionMesh },
  { label: MaterialOptionMatch, value: MaterialOptionMatch },
  { label: MaterialOptionBottom, value: MaterialOptionBottom },
  { label: MaterialOptionMismatched, value: MaterialOptionMismatched },
  { label: chooseLaterString, value: chooseLaterString },
];
// #endregion
