import React from 'react';
import { Form } from 'semantic-ui-react';
import { Toggle } from './Toggle';

interface MutatableFieldProps {
  required?: boolean;
  disabled?: boolean;
  valid?: boolean;
  currentValue: any;
  activated: undefined | boolean;
  type: FieldType;
  labelEl: JSX.Element;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  input: JSX.Element;
}

export const MutatableField = (props: MutatableFieldProps) => (
  <Form.Field
    required={props.required}
    disabled={props.disabled}
    error={!props.disabled && props.valid === false && !props.currentValue}
    className={
      props.activated ? 'activated' : props.type === 'toggle' ? 'inline' : ''
    }
  >
    {props.type !== 'check' && props.labelEl}
    {props.activated ? (
      <>
        <Toggle
          isValid={!!props.currentValue}
          name={props.name}
          checked={!!props.currentValue}
          onChange={props.onChange}
        />
      </>
    ) : (
      props.input
    )}
  </Form.Field>
);

export type FieldType =
  | 'date'
  | 'number'
  | 'select'
  | 'grid'
  | 'textarea'
  | 'range'
  | 'tel'
  | 'toggle'
  | 'text'
  | 'check'
  | 'email'
  | 'radio'
  | 'scanner image'
  | 'no image'
  | 'notes';
