import { Modal } from 'components';
import { ManagedCheckbox } from 'components/orders/Fields/Checkbox/ManagedCheckbox';
import { User, UserModel } from 'models';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { UserService } from 'sections/user';
import { Button } from 'semantic-ui-react';
import { useAuth, useUI } from 'services';
import { USER_MSG } from 'strings';

export default function TermsAndConditions(): ReactElement {
  const { user, setUser } = useAuth();
  const { showError, showSuccess } = useUI();
  const [userModel] = useState(UserModel(user || ({} as User)));
  const [open, setOpen] = useState(!user?.signedTOS);
  const [signed, setSigned] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // reset signed state when URL or user changes so access is blocked until TOS is signed
    setSigned(false);
    setOpen(!user?.signedTOS);
  }, [window.location.pathname, user]);

  const onSubmit = useCallback(async () => {
    setLoading(true);
    if (!userModel.isValid()) {
      showError(USER_MSG.ERROR_INVALID);
      return;
    }
    // @ts-ignore
    delete userModel.data.developer;

    await UserService.set({ ...userModel.data, signedTOS: signed })
      .then((user) => {
        setUser(user);
        showSuccess(USER_MSG.SUCCESS_TOS);
        setSigned(false);
        setOpen(false);
        setLoading(false);
      })
      .catch(() => {
        showError();
        setLoading(false);
      });
  }, [userModel, signed, setUser, showError, showSuccess, setSigned]);

  const renderModelControls: React.ReactElement = useMemo(() => {
    return (
      <div className="controls">
        <Button
          className="control-right"
          primary
          onClick={onSubmit}
          content="Accept"
          disabled={!signed || loading}
          loading={loading}
        />
      </div>
    );
  }, [signed, loading, setLoading, setOpen, setSigned]);

  return (
    <Modal
      isOpen={open}
      size="large"
      title="Terms of Use"
      actions={renderModelControls}
    >
      <div className="tos-container">
        <embed
          id="terms-and-conditions"
          src="https://latham-cloud-api.s3.us-west-2.amazonaws.com/docs/2023.05.12+Measure+-+Terms+of+Use+FINAL.pdf#toolbar=0&navpanes=0&scrollbar=0"
          className="tos-document"
        />
      </div>
      <ManagedCheckbox
        labelElement={<span>I agree to the Terms of Use.</span>}
        isValid={true}
        checked={signed}
        disabled={loading}
        onChange={(e) => setSigned(e)}
      />
    </Modal>
  );
}
