import React from 'react';
import {
  Bar,
  BarChart,
  LabelProps,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { ProjectStats } from '../DataTabs/DataUtils';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

interface CustomBarChartProps {
  data: ProjectStats[];
}

export default function CustomBarChart(
  props: CustomBarChartProps
): React.ReactElement {
  const { data } = props;

  const CustomizedLabel = (props: LabelProps) => {
    const { x, y, width, value } = props;
    const xInt = parseInt(x?.toString() ?? '1');
    const yInt = parseInt(y?.toString() ?? '1');
    const widthInt = parseInt(width?.toString() ?? '1');
    if (value !== 0) {
      return (
        <text
          x={xInt + widthInt / 2}
          y={yInt}
          dy={-6}
          fontSize="14"
          fontFamily="sans-serif"
          fill="#666666"
          textAnchor="middle"
        >
          {value}
        </text>
      );
    }
    return <></>;
  };

  const CustomTooltip = (props: TooltipProps<ValueType, NameType>) => {
    const { active, payload, label } = props;
    if (active && payload && payload.length) {
      return (
        <div className="bar-chart-tooltip">
          <span className="bar-chart-tooltip-title">{label}</span>
          <span>{`${payload[0].payload.projectCount} Projects`}</span>
          <span>{`${payload[0].payload.quoteCount} Projects with Quotes`}</span>
          <span>{`${payload[0].payload.orderCount} Projects with Orders`}</span>
          <span>{`${payload[0].payload.remainderProjectCount} Remaining Projects`}</span>
        </div>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey="month" />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Bar
          name="Remainder Project Count"
          dataKey="remainderProjectCount"
          stackId="a"
          fill="#003057"
        />
        <Bar
          name="Quote Count"
          dataKey="quoteCount"
          stackId="a"
          fill="#0087f4"
        />
        <Bar
          name="Order Count"
          dataKey="orderCount"
          stackId="a"
          fill="#95bfdf"
          label={<CustomizedLabel />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
