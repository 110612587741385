import { PoolShape } from 'models/Project';
import * as yup from 'yup';
import { productTypeSelectOptions } from '../Product/ProductFormConstants';

export interface ProjectFormPropTypes {
  poolArea?: number | null;
  poolShapeType: 'freeform' | 'geometric' | undefined;
  poolShape?: PoolShape;
  coverOverlap?: number | null;
  address1: string;
  address2: string;
  city: string;
  shippingState: string;
  postalCode: string;
  primaryContactName: string;
  poNumber?: string;
  type?: string;
}

export const ProjectFormBaseShape = {
  address1: yup.string().required(),
  address2: yup.string().optional(),
  city: yup.string().required(),
  shippingState: yup.string().required(),
  postalCode: yup.string().required(),
  primaryContactName: yup.string().required(),
  poNumber: yup.string(),
  type: yup
    .string()
    .oneOf(productTypeSelectOptions.map((el) => el.value))
    .required(),
};

/**
 * The yup schema of the project step in the order form
 */
export const ProjectFormSchema = yup.object().shape({
  address1: yup.string().required(),
  address2: yup.string().optional(),
  city: yup.string().required(),
  shippingState: yup.string().required(),
  postalCode: yup.string().required(),
  primaryContactName: yup.string().required(),
  poNumber: yup.string().max(20, 'Must be 20 characters or less'),
  type: yup
    .string()
    .oneOf(productTypeSelectOptions.map((el) => el.value))
    .required(),
});

/**
 * The yup schema of the project (first) step in the order form, before quote is submitted
 */
export const ProjectFormSchemaForQuote = yup.object().shape({
  ...ProjectFormBaseShape,
  poNumber: ProjectFormBaseShape.poNumber.max(
    20,
    'PO Number cannot exceed 20 characters'
  ),
  type: yup
    .string()
    .oneOf(productTypeSelectOptions.map((el) => el.value))
    .required(),
});

/**
 * The yup schema of the project (first) step in the order form, after quote is submitted
 */
export const ProjectFormSchemaForOrder = yup.object().shape({
  ...ProjectFormBaseShape,
  poNumber: ProjectFormBaseShape.poNumber
    .max(20, 'PO Number cannot exceed 20 characters')
    .required(),
  type: yup
    .string()
    .oneOf(productTypeSelectOptions.map((el) => el.value))
    .required(),
});

export const ProjectFormSchemaForOrderNoPo = yup.object().shape({
  ...ProjectFormBaseShape,
  poNumber: ProjectFormBaseShape.poNumber.max(
    20,
    'PO Number cannot exceed 20 characters'
  ),
  type: yup
    .string()
    .oneOf(productTypeSelectOptions.map((el) => el.value))
    .required(),
});

/**
 * The hand-made description of props of the possible validation states
 */
export interface ProjectFormFieldsValidationType {
  address1: boolean;
  address2: boolean;
  city: boolean;
  shippingState: boolean;
  postalCode: boolean;
  primaryContactName: boolean;
  poNumber: boolean;
  type: boolean;
}

export interface ProjectFormFieldsErrorMessageType {
  poNumber: string | null;

  // Allowing any index to instead of having to add ts-ignore to usage in OrderForm
  [index: string]: string | null;
}
