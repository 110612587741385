import { TransitionGroup } from 'components';
import { Dealer, Project, DealerProjects } from 'models';
import React, { useCallback, useEffect, useState } from 'react';
import { CompanyService } from 'sections/employee';
import { Tab } from 'semantic-ui-react';
import { useUI } from 'services';
import { DealerMsg } from './Constants';
import AdminDealerList from './AdminDealerList';
import AdminMeasureData from './AdminMeasureData';
import { ProjectService } from 'sections/projects';
import './Admin.scss';

export default function Admin(props: any): React.ReactElement {
  const [dealers, setDealers] = useState([] as Dealer[]);
  const [projects, setProjects] = useState([] as Project[]);
  const [projectsWithDealer, setProjectsWithDealer] = useState(
    [] as DealerProjects[]
  );
  const [orders, setOrders] = useState([] as any[]);
  const [loaded, setLoaded] = useState<boolean>(false);

  const { showError } = useUI();

  const fetchDealers = useCallback(async () => {
    try {
      const dealers = await CompanyService.getDealers();
      setDealers(dealers as unknown as Dealer[]);
    } catch (error: any) {
      setLoaded(false);
      showError({ title: DealerMsg.ERROR_TITLE, msg: error.message });
    }
  }, []);

  const fetchProjects = useCallback(async () => {
    try {
      const projects = await ProjectService.getAllScans();
      setProjects(
        projects.sort((a, b) => a.createdAt.localeCompare(b.createdAt))
      );
      setOrders(projects.flatMap((proj) => proj.orders));
    } catch (e) {
      setLoaded(false);
      showError();
    }
  }, []);

  const fetchProjectsWithDealers = useCallback(async () => {
    try {
      const dealers: DealerProjects[] =
        await ProjectService.getProjectDealers();
      setProjectsWithDealer(
        dealers.sort((a, b) => a.dealerName.localeCompare(b.dealerName))
      );
    } catch (e) {
      setLoaded(false);
      showError();
    }
  }, []);

  useEffect(() => {
    setLoaded(true);
    fetchDealers();
    fetchProjects();
    fetchProjectsWithDealers();
  }, []);

  return (
    <div className="admin">
      <TransitionGroup isVisible={loaded}>
        <Tab
          menu={{ pointing: true }}
          className="tab-bar"
          panes={[
            {
              menuItem: 'Dealers',
              render: () => (
                <Tab.Pane className="admin-content" attached={false}>
                  {
                    <AdminDealerList
                      dealers={dealers}
                      fetchDealers={fetchDealers}
                      loaded={loaded}
                      setLoaded={setLoaded}
                    />
                  }
                </Tab.Pane>
              ),
            },
            {
              menuItem: 'Data',
              render: () => (
                <Tab.Pane className="admin-content" attached={false}>
                  {
                    <AdminMeasureData
                      projects={projects}
                      projectsWithDealer={projectsWithDealer}
                      dealers={dealers}
                      orders={orders}
                      loaded={loaded}
                      setLoaded={setLoaded}
                    />
                  }
                </Tab.Pane>
              ),
            },
          ]}
        />
      </TransitionGroup>
    </div>
  );
}
