import React, { useEffect, useState } from 'react';
import OrderForm, { GenericFieldValidationType } from '../../OrderForm';
import {
  CoverFeatureAttributes,
  CoverMaterialAttributes,
  LinerAccessoryAttributes,
  LinerMaterialAttributes,
  ObstructionTypeArray,
  OutsideStepArray,
  PartialDecking,
  SpecFormFieldsValidationType,
  SpecFormPropTypes,
  SpecFormSchema,
  VinylCoveredSteps,
  VinylCoveredStepsInfo,
} from './SpecFormSchema';
import './SpecForm.scss';
import { ManagedInput } from 'components/orders/Fields/Input/ManagedInput';
import { useRouteMatch } from 'react-router-dom';
import { OrderContext } from '../../../../../context/OrderContext';
import { ManagedToggle } from 'components/orders/Fields/Toggle/ManagedToggle';
import Obstruction from './ObstructionFeat';
import OutsideStep from './OutsideStepFeat';
import PartialDeckingFeat from './PartialDecking';
import { Button } from 'semantic-ui-react';
import { Modal } from 'components';
import UserModalActions from 'components/user/UserModalActions';
import { ManagedSelect } from 'components/orders/Fields/Select/ManagedSelect';
import {
  SafetyStripesOptions,
  SolidShallowEndOptions,
  getFeatureTypeOptions,
} from './SpecConstants';
import { FeatureType, OrderType } from 'models';
import { Collapsible } from 'components/Collapsible';
import VinylCoveredStepInfo from './VinylCoveredStep';

interface SpecFormProps {
  data: SpecFormPropTypes;
  hasEditAccess: boolean;
}

/**
 * All fields are "valid", until we get an error!
 */
const InitialSpecFormValidationStatus = (): SpecFormFieldsValidationType => {
  return {
    spec: true,
  };
};

const SpecForm = (props: SpecFormProps) => {
  const {
    //@ts-ignore
    params: { id },
  } = useRouteMatch();

  // Global state
  const { state, dispatch } = React.useContext(OrderContext);
  const isCover = state.project.type === OrderType.Cover;
  const [featureType, setFeatureType] = useState('');
  const [featModalOpen, setFeatModalOpen] = useState(false);
  const [newFeature, setNewFeature] = useState<
    ObstructionTypeArray | OutsideStepArray
  >();

  const featureTypeOptions = getFeatureTypeOptions();

  const disabled = state?.order?.archived || !props?.hasEditAccess;

  // complete spec object
  const [spec, setSpec] = useState(props?.data);

  // #region coverFeatureAttributes
  const [coverFeatureAttributes] = useState<CoverFeatureAttributes>(
    props?.data?.coverFeatureAttributes
  );
  const [obstructionTypeArray] = useState(
    props?.data?.coverFeatureAttributes?.obstructionTypeArray
  );
  const [outsideStepArray] = useState(
    props?.data?.coverFeatureAttributes?.outsideStepArray
  );
  // #endregion

  // #region vinylCoveredSteps
  const [vinylCoveredSteps] = useState<VinylCoveredSteps>(
    props?.data?.vinylCoveredSteps
  );
  const [vinylCoveredStepsInfo] = useState(props?.data?.vinylCoveredSteps?.vos);
  // #endregion

  // #region coverMaterialAttributes
  const [coverMaterialAttributes, setCoverMaterialAttributes] =
    useState<CoverMaterialAttributes>(props.data.coverMaterialAttributes);
  const [generalSpecific, setGeneralSpecific] = useState<string | undefined>(
    state?.spec?.coverMaterialAttributes?.generalSpecific ??
      props?.data?.coverMaterialAttributes?.generalSpecific ??
      ''
  );
  const [materialColor, setMaterialColor] = useState<string | undefined>(
    state?.spec?.coverMaterialAttributes?.materialColor ??
      props?.data?.coverMaterialAttributes?.materialColor ??
      ''
  );
  const [materialDrainTypeFeatureSId, setMaterialDrainTypeFeatureSId] =
    useState<string | undefined>(
      state?.spec?.coverMaterialAttributes?.materialColor ??
        props?.data?.coverMaterialAttributes?.materialDrainTypeFeatureSId ??
        ''
    );
  const [materialFabric, setMaterialFabric] = useState<string | undefined>(
    state?.spec?.coverMaterialAttributes?.materialFabric ??
      props?.data?.coverMaterialAttributes?.materialFabric ??
      ''
  );
  const [materialGrid, setMaterialGrid] = useState<string | undefined>(
    state?.spec?.coverMaterialAttributes?.materialGrid?.toString() ??
      props?.data?.coverMaterialAttributes?.materialGrid?.toString()
  );
  const [materialSpringUpgradeFeatureSId, setMaterialSpringUpgradeFeatureSId] =
    useState<string | undefined>(
      state?.spec?.coverMaterialAttributes?.materialSpringUpgradeFeatureSId ??
        props?.data?.coverMaterialAttributes?.materialSpringUpgradeFeatureSId ??
        ''
    );
  // #endregion

  // #region linerMaterialAttributes
  const [linerMaterialAttributes, setLinerMaterialAttributes] =
    useState<LinerMaterialAttributes>(props.data.linerMaterialAttributes);

  const [materialWall, setMaterialWall] = useState<string | undefined>(
    state?.spec?.linerMaterialAttributes?.materialWall ??
      props?.data?.linerMaterialAttributes?.materialWall ??
      ''
  );
  const [materialFloor, setMaterialFloor] = useState<string | undefined>(
    state?.spec?.linerMaterialAttributes?.materialFloor ??
      props?.data?.linerMaterialAttributes?.materialFloor ??
      ''
  );
  const [materialBeadType, setMaterialBeadType] = useState<string | undefined>(
    state?.spec?.linerMaterialAttributes?.materialBeadType ??
      props?.data?.linerMaterialAttributes?.materialBeadType ??
      ''
  );
  // #endregion

  // #region linerAccessoryAttributes
  const [linerAccessoryAttributes, setlinerAccessoryAttributes] =
    useState<LinerAccessoryAttributes>(props.data.linerAccessoryAttributes);

  const [safetyStripFeatureSId, setSafetyStripFeatureSId] = useState<
    string | undefined
  >(
    state?.spec?.linerAccessoryAttributes?.safetyStripFeatureSId ??
      props.data.linerAccessoryAttributes.safetyStripFeatureSId
  );

  const [safetyStripeWidth, setSafetyStripeWidth] = useState<
    string | undefined
  >(
    state?.spec?.linerAccessoryAttributes?.safetyStripeWidth?.toString() ??
      props.data.linerAccessoryAttributes.safetyStripeWidth?.toString()
  );

  const [materialSolidShallowFeatureSId, setMaterialSolidShallowFeatureSId] =
    useState<string | undefined>(
      state?.spec?.linerAccessoryAttributes?.materialSolidShallowFeatureSId ??
        props.data.linerAccessoryAttributes.materialSolidShallowFeatureSId
    );

  const [safetyStripeColor, setSafetyStripeColor] = useState<
    string | undefined
  >(
    state?.spec?.linerAccessoryAttributes?.safetyStripeColor ??
      props.data.linerAccessoryAttributes.safetyStripeColor
  );

  const [materialOverlapFeatureId, setMaterialOverlapFeatureId] = useState<
    string | undefined
  >(
    state?.spec?.linerAccessoryAttributes?.materialOverlapFeatureId ??
      props.data.linerAccessoryAttributes.materialOverlapFeatureId
  );

  const [materialOverlapSize, setMaterialOverlapSize] = useState<
    string | undefined
  >(
    state?.spec?.linerAccessoryAttributes?.materialOverlapSize?.toString() ??
      props.data.linerAccessoryAttributes.materialOverlapSize?.toString() ??
      '0'
  );

  const [materialSolidShallowColor, setMaterialSolidShallowColor] = useState<
    string | undefined
  >(
    state?.spec?.linerAccessoryAttributes?.materialSolidShallowColor ??
      props.data.linerAccessoryAttributes.materialSolidShallowColor
  );

  const [racingLanesFeatureSId, setRacingLanesFeatureSId] = useState<
    string | undefined
  >(
    state?.spec?.linerAccessoryAttributes?.racingLanesFeatureSId ??
      props.data.linerAccessoryAttributes.racingLanesFeatureSId
  );

  const [racingLanesComments, setRacingLanesComments] = useState<
    string | undefined
  >(
    state?.spec?.linerAccessoryAttributes?.racingLanesComments ??
      props.data.linerAccessoryAttributes.racingLanesComments
  );

  const [targetFeatureSId, setTargetFeatureSId] = useState<string | undefined>(
    state?.spec?.linerAccessoryAttributes?.targetFeatureSId ??
      props.data.linerAccessoryAttributes.targetFeatureSId
  );

  const [targetComments, setTargetComments] = useState<string | undefined>(
    state?.spec?.linerAccessoryAttributes?.targetComments ??
      props.data.linerAccessoryAttributes.targetComments
  );
  // #endregion

  // #region customerAttributes
  const [customerAttributes, setCustomerAttributes] = useState(
    props?.data?.customerAttributes
  );
  const [sugarId, setSugarId] = useState<string | undefined>(
    props?.data?.customerAttributes?.SugarId
  );
  const [customerName, setCustomerName] = useState<string | undefined>(
    props?.data?.customerAttributes?.customerName
  );
  const [gl_company_new_c, setGl_company_new_c] = useState<string | undefined>(
    props?.data?.customerAttributes?.gl_company_new_c
  );
  const [indirect_customer_number_c, setIndirect_customer_number_c] = useState<
    string | undefined
  >(props?.data?.customerAttributes?.indirect_customer_number_c);
  const [purchasing_channel_c, setPurchasing_channel_c] = useState<
    string | undefined
  >(props?.data?.customerAttributes?.purchasing_channel_c);
  const [rewards_id_c, setRewards_id_c] = useState<string | undefined>(
    props?.data?.customerAttributes?.rewards_id_c
  );
  const [selectedBranchNumber, setSelectedBranchNumber] = useState<
    string | undefined
  >(props?.data?.customerAttributes?.selectedBranchNumber);
  const [selectedCustomerNumber, setSelectedCustomerNumber] = useState<
    string | undefined
  >(props?.data?.customerAttributes?.selectedCustomerNumber);
  // #endregion

  // #region deckingAttributes
  const [deckingAttributes, setDeckingAttributes] = useState(
    props?.data?.deckingAttributes
  );
  const [copingTexture, setCopingTexture] = useState<string | undefined>(
    state.spec?.deckingAttributes?.copingTexture ||
      props?.data?.deckingAttributes?.copingTexture
  );
  const [deckAnchorFeatureSId, setDeckAnchorFeatureSId] = useState<
    string | undefined
  >(
    state?.spec?.deckingAttributes?.deckAnchorFeatureSId ||
      props?.data?.deckingAttributes?.deckAnchorFeatureSId
  );
  const [deckConsistency, setDeckConsistency] = useState(
    props?.data?.deckingAttributes?.deckConsistency ?? false
  );
  const [deckSize, setDeckSize] = useState<string | undefined>(
    props?.data?.deckingAttributes?.deckSize?.toString()
  );
  const [deckType, setDeckType] = useState<string | undefined>(
    state.spec?.deckingAttributes?.deckType ||
      props?.data?.deckingAttributes?.deckType
  );
  const [extraPaddingFeatureId, setExtraPaddingFeatureId] = useState<
    string | undefined
  >(
    state.spec?.deckingAttributes?.extraPaddingFeatureId ||
      props?.data?.deckingAttributes?.extraPaddingFeatureId
  );
  const [extraPaddingLength, setExtraPaddingLength] = useState<
    string | undefined
  >(props?.data?.deckingAttributes?.extraPaddingLength?.toString());
  const [partialDeckingArray] = useState(
    props?.data?.deckingAttributes?.partialDeckingArray
  );
  // #endregion

  // #region fullPerimeterCoverAccessoryAttributes
  const [
    fullPerimeterCoverAccessoryAttributes,
    setFullPerimeterCoverAccessoryAttributes,
  ] = useState(props?.data?.fullPerimeterCoverAccessoryAttributes);
  const [brassAnchorCollarsFeatureSId, setBrassAnchorCollarsFeatureSId] =
    useState<string | undefined>(
      state?.spec?.fullPerimeterCoverAccessoryAttributes
        ?.brassAnchorCollarsFeatureSId ||
        props?.data?.fullPerimeterCoverAccessoryAttributes
          ?.brassAnchorCollarsFeatureSId
    );
  const [bucklesFeatureSId, setBucklesFeatureSId] = useState<
    string | undefined
  >(
    state?.spec?.fullPerimeterCoverAccessoryAttributes?.bucklesFeatureSId ??
      props?.data?.fullPerimeterCoverAccessoryAttributes?.bucklesFeatureSId
  );
  const [cableArea1FeatureId, setCableArea1FeatureId] = useState<
    string | undefined
  >(
    props?.data?.fullPerimeterCoverAccessoryAttributes?.cableAssembly
      ?.cableArea1?.cableArea1FeatureId
  );
  const [cableArea1Length, setCableArea1Length] = useState<string | undefined>(
    props?.data?.fullPerimeterCoverAccessoryAttributes?.cableAssembly?.cableArea1?.cableArea1Length?.toString()
  );
  const [cableArea2FeatureId, setCableArea2FeatureId] = useState<
    string | undefined
  >(
    props?.data?.fullPerimeterCoverAccessoryAttributes?.cableAssembly
      ?.cableArea2?.cableArea2FeatureId
  );
  const [cableArea2Length, setCableArea2Length] = useState<string | undefined>(
    props?.data?.fullPerimeterCoverAccessoryAttributes?.cableAssembly?.cableArea2?.cableArea2Length?.toString()
  );
  const [cableArea3FeatureId, setCableArea3FeatureId] = useState<
    string | undefined
  >(
    props?.data?.fullPerimeterCoverAccessoryAttributes?.cableAssembly
      ?.cableArea3?.cableArea3FeatureId
  );
  const [cableArea3Length, setCableArea3Length] = useState<string | undefined>(
    props?.data?.fullPerimeterCoverAccessoryAttributes?.cableAssembly?.cableArea3?.cableArea3Length?.toString()
  );
  const [cableArea4FeatureId, setCableArea4FeatureId] = useState<
    string | undefined
  >(
    props?.data?.fullPerimeterCoverAccessoryAttributes?.cableAssembly
      ?.cableArea4?.cableArea4FeatureId
  );
  const [cableArea4Length, setCableArea4Length] = useState<string | undefined>(
    props?.data?.fullPerimeterCoverAccessoryAttributes?.cableAssembly?.cableArea4?.cableArea4Length?.toString()
  );

  const [doubleDRingsFeatureSId, setDoubleDRingsFeatureSId] = useState<
    string | undefined
  >(
    state?.spec?.fullPerimeterCoverAccessoryAttributes
      ?.doubleDRingsFeatureSId ??
      props?.data?.fullPerimeterCoverAccessoryAttributes?.doubleDRingsFeatureSId
  );
  const [extraLongAnchorsFeatureSId, setExtraLongAnchorsFeatureSId] = useState<
    string | undefined
  >(
    props?.data?.fullPerimeterCoverAccessoryAttributes
      ?.extraLongAnchorsFeatureSId
  );
  const [kemKapFeatureSId, setKemKapFeatureSId] = useState<string | undefined>(
    props?.data?.fullPerimeterCoverAccessoryAttributes?.kemKapFeatureSId
  );
  const [noAnchorsFeatureSId, setNoAnchorsFeatureSId] = useState<
    string | undefined
  >(
    state?.spec?.fullPerimeterCoverAccessoryAttributes?.noAnchorsFeatureSId ||
      props?.data?.fullPerimeterCoverAccessoryAttributes?.noAnchorsFeatureSId
  );
  const [noHardwareFeatureSId, setNoHardwareFeatureSId] = useState<
    string | undefined
  >(
    state?.spec?.fullPerimeterCoverAccessoryAttributes.noHardwareFeatureSId ||
      props?.data?.fullPerimeterCoverAccessoryAttributes?.noHardwareFeatureSId
  );
  const [topBottomWebbingFeatureSId, setTopBottomWebbingFeatureSId] = useState<
    string | undefined
  >(
    state?.spec?.fullPerimeterCoverAccessoryAttributes
      ?.topBottomWebbingFeatureSId ??
      props?.data?.fullPerimeterCoverAccessoryAttributes
        ?.topBottomWebbingFeatureSId
  );
  const [yStrapFeatureId, setYStrapFeatureId] = useState<string | undefined>(
    state?.spec?.fullPerimeterCoverAccessoryAttributes?.yStrapAssembly
      ?.yStrapFeatureId ??
      props?.data?.fullPerimeterCoverAccessoryAttributes?.yStrapAssembly
        ?.yStrapFeatureId
  );
  const [yStrapQty, setYStrapQty] = useState<string | undefined>(
    state?.spec?.fullPerimeterCoverAccessoryAttributes?.yStrapAssembly?.yStrapQty?.toString() ??
      props?.data?.fullPerimeterCoverAccessoryAttributes?.yStrapAssembly?.yStrapQty?.toString()
  );
  const [singlePadFeatureId, setSinglePadFeatureId] = useState<
    string | undefined
  >(
    props?.data?.fullPerimeterCoverAccessoryAttributes?.singlePad
      ?.singlePadFeatureId
  );
  const [singlePadQty, setSinglePadQty] = useState<string | undefined>(
    props?.data?.fullPerimeterCoverAccessoryAttributes?.singlePad?.singlePadQty?.toString()
  );
  // #endregion

  //  #region geoShapeAttributes
  const [geoShapeAttributes, setGeoShapeAttributes] = useState(
    props?.data?.geoShapeAttributes
  );
  const [cornerLCCornerType, setCornerLCCornerType] = useState<
    string | undefined
  >(props?.data?.geoShapeAttributes?.cornerLC?.cornerType);
  const [cornerLCCornerSize, setCornerLCCornerSize] = useState<
    string | undefined
  >(props?.data?.geoShapeAttributes?.cornerLC?.cornerSize?.toString());
  const [cornerLDCornerType, setCornerLDCornerType] = useState<
    string | undefined
  >(props?.data?.geoShapeAttributes?.cornerLD?.cornerType);
  const [cornerLDCornerSize, setCornerLDCornerSize] = useState<
    string | undefined
  >(props?.data?.geoShapeAttributes?.cornerLD?.cornerSize?.toString());
  const [cornerLSCornerType, setCornerLSCornerType] = useState<
    string | undefined
  >(props?.data?.geoShapeAttributes?.cornerLS?.cornerType);
  const [cornerLSCornerSize, setCornerLSCornerSize] = useState<
    string | undefined
  >(props?.data?.geoShapeAttributes?.cornerLS?.cornerSize?.toString());
  const [cornerRDCornerType, setCornerRDCornerType] = useState<
    string | undefined
  >(props?.data?.geoShapeAttributes?.cornerRD?.cornerType);
  const [cornerRDCornerSize, setCornerRDCornerSize] = useState<
    string | undefined
  >(props?.data?.geoShapeAttributes?.cornerRD?.cornerSize?.toString());
  const [cornerRSCornerType, setCornerRSCornerType] = useState<
    string | undefined
  >(props?.data?.geoShapeAttributes?.cornerRS?.cornerType);
  const [cornerRSCornerSize, setCornerRSCornerSize] = useState<
    string | undefined
  >(props?.data?.geoShapeAttributes?.cornerRS?.cornerSize?.toString());
  const [cornerReverseCornerType, setCornerReverseCornerType] = useState<
    string | undefined
  >(props?.data?.geoShapeAttributes?.cornerReverse?.cornerType);
  const [cornerReverseCornerSize, setCornerReverseCornerSize] = useState<
    string | undefined
  >(props?.data?.geoShapeAttributes?.cornerReverse?.cornerSize?.toString());
  const [cutCornerQty, setCutCornerQty] = useState<string | undefined>(
    props?.data?.geoShapeAttributes?.cutCorner?.cutCornerQty?.toString()
  );
  const [cutCornerFeatureId, setCutCornerFeatureId] = useState<
    string | undefined
  >(props?.data?.geoShapeAttributes?.cutCorner?.cutCornerFeatureId);
  const [ovalEndQty, setOvalEndQty] = useState<string | undefined>(
    props?.data?.geoShapeAttributes?.ovalEnd?.ovalEndQty?.toString()
  );
  const [ovalEndFeatureId, setOvalEndFeatureId] = useState<string | undefined>(
    props?.data?.geoShapeAttributes?.ovalEnd?.ovalEndFeatureId
  );
  const [romanEndQty, setRomanEndQty] = useState<string | undefined>(
    props?.data?.geoShapeAttributes?.romanEnd?.romanEndQty?.toString()
  );
  const [romanEndFeatureId, setRomanEndFeatureId] = useState<
    string | undefined
  >(props?.data?.geoShapeAttributes?.romanEnd?.romanEndFeatureId);
  //  #endregion

  // #region measurementAttributes
  const [measurementAttributes, setMeasurementAttributes] = useState(
    props?.data?.measurementAttributes
  );
  const [coverLnFt, setCoverLnFt] = useState<string | undefined>(
    props?.data?.measurementAttributes?.coverLnFt?.toString()
  );
  const [coverSqFt, setCoverSqFt] = useState<string | undefined>(
    props?.data?.measurementAttributes?.coverSqFt?.toString()
  );
  const [dimensionA, setDimensionA] = useState<string | undefined>(
    props?.data?.measurementAttributes?.dimensionA?.toString()
  );
  const [dimensionA1, setDimensionA1] = useState<string | undefined>(
    props?.data?.measurementAttributes?.dimensionA1?.toString()
  );
  const [dimensionB, setDimensionB] = useState<string | undefined>(
    props?.data?.measurementAttributes?.dimensionB?.toString()
  );
  const [dimensionB3, setDimensionB3] = useState<string | undefined>(
    props?.data?.measurementAttributes?.dimensionB3?.toString()
  );
  const [dimensionB7, setDimensionB7] = useState<string | undefined>(
    props?.data?.measurementAttributes?.dimensionB7?.toString()
  );
  const [dimensionC, setDimensionC] = useState<string | undefined>(
    props?.data?.measurementAttributes?.dimensionC?.toString()
  );
  const [dimensionD, setDimensionD] = useState<string | undefined>(
    props?.data?.measurementAttributes?.dimensionD?.toString()
  );
  const [dimensionE, setDimensionE] = useState<string | undefined>(
    props?.data?.measurementAttributes?.dimensionE?.toString()
  );
  const [dimensionJ2, setDimensionJ2] = useState<string | undefined>(
    props?.data?.measurementAttributes?.dimensionJ2?.toString()
  );
  const [dimensionJ3, setDimensionJ3] = useState<string | undefined>(
    props?.data?.measurementAttributes?.dimensionJ3?.toString()
  );
  const [dimensionJ4, setDimensionJ4] = useState<string | undefined>(
    props?.data?.measurementAttributes?.dimensionJ4?.toString()
  );
  const [dimensionL, setDimensionL] = useState<string | undefined>(
    props?.data?.measurementAttributes?.dimensionL?.toString()
  );
  const [dimensionRd, setDimensionRd] = useState<string | undefined>(
    props?.data?.measurementAttributes?.dimensionRd?.toString()
  );
  const [dimensionS, setDimensionS] = useState<string | undefined>(
    props?.data?.measurementAttributes?.dimensionS?.toString()
  );
  const [dimensionS1, setDimensionS1] = useState<string | undefined>(
    props?.data?.measurementAttributes?.dimensionS1?.toString()
  );
  const [dimensionT, setDimensionT] = useState<string | undefined>(
    props?.data?.measurementAttributes?.dimensionT?.toString()
  );
  const [dimensionV3, setDimensionV3] = useState<string | undefined>(
    props?.data?.measurementAttributes?.dimensionV3?.toString()
  );
  const [dimensionW1, setDimensionW1] = useState<string | undefined>(
    props?.data?.measurementAttributes?.dimensionW1?.toString()
  );
  const [dimensionX1, setDimensionX1] = useState<string | undefined>(
    props?.data?.measurementAttributes?.dimensionX1?.toString()
  );
  const [linerSqFt, setLinerSqFt] = useState<string | undefined>(
    props?.data?.measurementAttributes?.linerSqFt?.toString()
  );
  const [overlapCalc, setOverlapCalc] = useState<string | undefined>(
    props?.data?.measurementAttributes?.overlapCalc?.toString()
  );
  const [overlapOverride, setOverlapOverride] = useState<string | undefined>(
    props?.data?.measurementAttributes?.overlapOverride?.toString()
  );
  // #endregion

  // #region projectAttributes
  const [projectAttributes, setProjectAttributes] = useState(
    props?.data?.projectAttributes
  );
  const [brand, setBrand] = useState<string | undefined>(
    props?.data?.projectAttributes?.brand
  );
  const [dropShip, setDropShip] = useState(
    props?.data?.projectAttributes?.dropShip
  );
  const [enteredBy, setEnteredBy] = useState<string | undefined>(
    props?.data?.projectAttributes?.enteredBy
  );
  const [enteredDate, setEnteredDate] = useState<string | undefined>(
    props?.data?.projectAttributes?.enteredDate
  );
  const [externalComments, setExternalComments] = useState<string | undefined>(
    props?.data?.projectAttributes?.externalComments
  );
  const [freightInstructions, setFreightInstructions] = useState<
    string | undefined
  >(props?.data?.projectAttributes?.freightInstructions);
  const [geolocationMatch, setGeolocationMatch] = useState(
    props?.data?.projectAttributes?.geolocationMatch
  );
  const [lathamQuoteNumber, setLathamQuoteNumber] = useState<
    string | undefined
  >(props?.data?.projectAttributes?.lathamQuoteNumber);
  const [orderChanges, setOrderChanges] = useState(
    props?.data?.projectAttributes?.orderChanges
  );
  const [pickup, setPickup] = useState(
    state?.spec?.projectAttributes?.pickup ??
      props?.data?.projectAttributes?.pickup
  );
  const [poNumber, setPoNumber] = useState<string | undefined>(
    state?.spec?.projectAttributes?.poNumber ??
      props?.data?.projectAttributes?.poNumber
  );
  const [previousJobNumber, setPreviousJobNumber] = useState<
    string | undefined
  >(props?.data?.projectAttributes?.previousJobNumber);
  const [priorityRush, setPriorityRush] = useState(
    props?.data?.projectAttributes?.priorityRush
  );
  const [productType, setProductType] = useState<string | undefined>(
    props?.data?.projectAttributes?.productType
  );
  const [projectId, setProjectId] = useState<string | undefined>(
    props?.data?.projectAttributes?.projectId
  );
  const [projectLat, setProjectLat] = useState<string | undefined>(
    props?.data?.projectAttributes?.projectLat
  );
  const [projectLong, setProjectLong] = useState<string | undefined>(
    props?.data?.projectAttributes?.projectLong
  );
  const [projectName, setProjectName] = useState<string | undefined>(
    props?.data?.projectAttributes?.projectName
  );
  const [projectSiteCity, setProjectSiteCity] = useState<string | undefined>(
    props?.data?.projectAttributes?.projectSiteCity
  );
  const [projectSiteState, setProjectSiteState] = useState<string | undefined>(
    props?.data?.projectAttributes?.projectSiteState
  );
  const [projectSiteStreet, setProjectSiteStreet] = useState<
    string | undefined
  >(props?.data?.projectAttributes?.projectSiteStreet);
  const [projectSiteStreet2, setProjectSiteStreet2] = useState<
    string | undefined
  >(props?.data?.projectAttributes?.projectSiteStreet2);
  const [projectSiteZip, setProjectSiteZip] = useState<string | undefined>(
    props?.data?.projectAttributes?.projectSiteZip
  );
  const [projectUrl, setProjectUrl] = useState<string | undefined>(
    props?.data?.projectAttributes?.projectUrl
  );
  const [quoteOrder, setQuoteOrder] = useState<string | undefined>(
    props?.data?.projectAttributes?.quoteOrder
  );
  const [quoteStatus, setQuoteStatus] = useState<string | undefined>(
    props?.data?.projectAttributes?.quoteStatus
  );
  const [reorderRemakeReason, setReorderRemakeReason] = useState<
    string | undefined
  >(props?.data?.projectAttributes?.reorderRemakeReason);
  const [reorderSerialNumber, setReorderSerialNumber] = useState<
    string | undefined
  >(props?.data?.projectAttributes?.reorderSerialNumber);
  const [shipToCity, setShipToCity] = useState<string | undefined>(
    state?.spec?.projectAttributes?.shipToCity ??
      props?.data?.projectAttributes?.shipToCity
  );
  const [shipToCountry, setShipToCountry] = useState<string | undefined>(
    state?.spec?.projectAttributes?.shipToCountry ??
      props?.data?.projectAttributes?.shipToCountry
  );
  const [shipToState, setShipToState] = useState<string | undefined>(
    state?.spec?.projectAttributes?.shipToState ??
      props?.data?.projectAttributes?.shipToState
  );
  const [shipToStreet, setShipToStreet] = useState<string | undefined>(
    state?.spec?.projectAttributes?.shipToStreet ??
      props?.data?.projectAttributes?.shipToStreet
  );
  const [shipToZip, setShipToZip] = useState<string | undefined>(
    state?.spec?.projectAttributes?.shipToZip ??
      props?.data?.projectAttributes?.shipToZip
  );
  // #endregion

  // #region shapeAttributes
  const [shapeAttributes, setShapeAttributes] = useState(
    props?.data?.shapeAttributes
  );
  const [bottomType, setBottomType] = useState<string | undefined>(
    props?.data?.shapeAttributes?.bottomType
  );
  const [freeformIdentification, setFreeformIdentification] = useState(
    props?.data?.shapeAttributes?.freeformIdentification
  );
  const [shapeId, setShapeId] = useState<string | undefined>(
    props?.data?.shapeAttributes?.shapeId
  );
  const [shapeName, setShapeName] = useState<string | undefined>(
    props?.data?.shapeAttributes?.shapeName
  );
  const [shapeOrientaion, setShapeOrientaion] = useState<string | undefined>(
    props?.data?.shapeAttributes?.shapeOrientaion
  );
  // #endregion

  // Validation state
  const [validFields, setValidFields] = useState<SpecFormFieldsValidationType>(
    InitialSpecFormValidationStatus()
  );
  const currentValues = {
    spec,
    coverFeatureAttributes,
    vinylCoveredSteps,
    coverMaterialAttributes,
    customerAttributes,
    deckingAttributes,
    fullPerimeterCoverAccessoryAttributes,
    geoShapeAttributes,
    linerMaterialAttributes,
    linerAccessoryAttributes,
    measurementAttributes,
    projectAttributes,
    shapeAttributes,
  };

  const updateOrderState = () => {
    dispatch({
      type: 'updateSpec',
      payload: spec,
    });
  };

  // setSpec
  useEffect(() => {
    setSpec({
      ...spec,
      coverMaterialAttributes: coverMaterialAttributes,
      linerMaterialAttributes: linerMaterialAttributes,
      linerAccessoryAttributes: linerAccessoryAttributes,
      customerAttributes: customerAttributes,
      deckingAttributes: deckingAttributes,
      fullPerimeterCoverAccessoryAttributes:
        fullPerimeterCoverAccessoryAttributes,
      geoShapeAttributes: geoShapeAttributes,
      measurementAttributes: measurementAttributes,
      projectAttributes: projectAttributes,
      shapeAttributes: shapeAttributes,
    });
  }, [
    coverMaterialAttributes,
    linerMaterialAttributes,
    linerAccessoryAttributes,
    customerAttributes,
    deckingAttributes,
    fullPerimeterCoverAccessoryAttributes,
    geoShapeAttributes,
    measurementAttributes,
    projectAttributes,
    shapeAttributes,
  ]);

  // setCoverMaterialAttributes
  useEffect(() => {
    if (isCover) {
      setCoverMaterialAttributes({
        ...coverMaterialAttributes,
        generalSpecific: generalSpecific ?? '',
        materialColor: materialColor ?? '',
        materialDrainTypeFeatureSId: materialDrainTypeFeatureSId ?? '',
        materialFabric: materialFabric ?? '',
        materialGrid: parseInt(materialGrid ?? '0'),
        materialSpringUpgradeFeatureSId: materialSpringUpgradeFeatureSId ?? '',
      });
    }
  }, [
    generalSpecific,
    materialColor,
    materialDrainTypeFeatureSId,
    materialFabric,
    materialGrid,
    materialSpringUpgradeFeatureSId,
  ]);

  // setLinerMaterialAttributes
  useEffect(() => {
    if (!isCover) {
      setLinerMaterialAttributes({
        ...linerMaterialAttributes,
        generalSpecific: generalSpecific ?? '',
        materialWall: materialWall ?? '',
        materialFloor: materialFloor ?? '',
        materialBeadType: materialBeadType ?? '',
      });
    }
  }, [generalSpecific, materialWall, materialFloor, materialBeadType]);

  // setlinerAccessoryAttributes
  useEffect(() => {
    if (!isCover) {
      setlinerAccessoryAttributes({
        ...linerAccessoryAttributes,
        safetyStripFeatureSId: safetyStripFeatureSId ?? '',
        safetyStripeWidth: parseInt(safetyStripeWidth ?? '0'),
        materialSolidShallowFeatureSId: materialSolidShallowFeatureSId ?? '',
        safetyStripeColor: safetyStripeColor ?? '',
        materialOverlapFeatureId: materialOverlapFeatureId ?? '',
        materialOverlapSize: parseInt(materialOverlapSize ?? '0'),
        materialSolidShallowColor: materialSolidShallowColor ?? '',
        racingLanesFeatureSId: racingLanesFeatureSId ?? '',
        racingLanesComments: racingLanesComments ?? '',
        targetFeatureSId: targetFeatureSId ?? '',
        targetComments: targetComments ?? '',
      });
    }
  }, [
    safetyStripFeatureSId,
    safetyStripeWidth,
    materialSolidShallowFeatureSId,
    safetyStripeColor,
    materialOverlapFeatureId,
    materialOverlapSize,
    materialSolidShallowColor,
    racingLanesFeatureSId,
    racingLanesComments,
    targetFeatureSId,
    targetComments,
  ]);

  // setCustomerAttributes
  useEffect(() => {
    setCustomerAttributes({
      ...customerAttributes,
      SugarId: sugarId ?? '',
      customerName: customerName ?? '',
      gl_company_new_c: gl_company_new_c ?? '',
      indirect_customer_number_c: indirect_customer_number_c ?? '',
      purchasing_channel_c: purchasing_channel_c ?? '',
      rewards_id_c: rewards_id_c ?? '',
      selectedBranchNumber: selectedBranchNumber ?? '',
      selectedCustomerNumber: selectedCustomerNumber ?? '',
    });
  }, [
    sugarId,
    customerName,
    gl_company_new_c,
    indirect_customer_number_c,
    purchasing_channel_c,
    rewards_id_c,
    selectedBranchNumber,
    selectedCustomerNumber,
  ]);

  // setDeckingAttributes
  useEffect(() => {
    if (isCover) {
      setDeckingAttributes({
        ...deckingAttributes,
        copingTexture: copingTexture ?? '',
        deckAnchorFeatureSId: deckAnchorFeatureSId ?? '',
        deckConsistency: deckConsistency,
        deckSize: parseInt(deckSize ?? '0'),
        deckType: deckType ?? '',
        extraPaddingFeatureId: extraPaddingFeatureId ?? '',
        extraPaddingLength: parseInt(extraPaddingLength ?? '0'),
        partialDeckingArray: partialDeckingArray,
      });
    }
  }, [
    copingTexture,
    deckAnchorFeatureSId,
    deckConsistency,
    deckSize,
    deckType,
    extraPaddingFeatureId,
    extraPaddingLength,
    partialDeckingArray,
  ]);

  // setFullPerimeterCoverAccessoryAttributes
  useEffect(() => {
    setFullPerimeterCoverAccessoryAttributes({
      ...fullPerimeterCoverAccessoryAttributes,
      brassAnchorCollarsFeatureSId: brassAnchorCollarsFeatureSId ?? '',
      bucklesFeatureSId: bucklesFeatureSId ?? '',
      cableAssembly: {
        cableArea1: {
          cableArea1FeatureId: cableArea1FeatureId ?? '',
          cableArea1Length: parseInt(cableArea1Length ?? '0'),
        },
        cableArea2: {
          cableArea2FeatureId: cableArea2FeatureId ?? '',
          cableArea2Length: parseInt(cableArea2Length ?? '0'),
        },
        cableArea3: {
          cableArea3FeatureId: cableArea3FeatureId ?? '',
          cableArea3Length: parseInt(cableArea3Length ?? '0'),
        },
        cableArea4: {
          cableArea4FeatureId: cableArea4FeatureId ?? '',
          cableArea4Length: parseInt(cableArea4Length ?? '0'),
        },
      },
      doubleDRingsFeatureSId: doubleDRingsFeatureSId ?? '',
      extraLongAnchorsFeatureSId: extraLongAnchorsFeatureSId ?? '',
      kemKapFeatureSId: kemKapFeatureSId ?? '',
      noAnchorsFeatureSId: noAnchorsFeatureSId ?? '',
      noHardwareFeatureSId: noHardwareFeatureSId ?? '',
      topBottomWebbingFeatureSId: topBottomWebbingFeatureSId ?? '',
      yStrapAssembly: {
        yStrapFeatureId: yStrapFeatureId ?? '',
        yStrapQty: parseInt(yStrapQty ?? '0'),
      },
      singlePad: {
        singlePadFeatureId: singlePadFeatureId ?? '',
        singlePadQty: parseInt(singlePadQty ?? '0'),
      },
    });
  }, [
    brassAnchorCollarsFeatureSId,
    bucklesFeatureSId,
    cableArea1FeatureId,
    cableArea1Length,
    cableArea2FeatureId,
    cableArea2Length,
    cableArea3FeatureId,
    cableArea3Length,
    cableArea4FeatureId,
    cableArea4Length,
    doubleDRingsFeatureSId,
    extraLongAnchorsFeatureSId,
    kemKapFeatureSId,
    noAnchorsFeatureSId,
    noHardwareFeatureSId,
    topBottomWebbingFeatureSId,
    yStrapFeatureId,
    yStrapQty,
    singlePadFeatureId,
    singlePadQty,
  ]);

  // setGeoShapeAttributes
  useEffect(() => {
    setGeoShapeAttributes({
      ...geoShapeAttributes,
      cornerLC: {
        cornerType: cornerLCCornerType ?? '',
        cornerSize: parseInt(cornerLCCornerSize ?? '0'),
      },
      cornerLD: {
        cornerType: cornerLDCornerType ?? '',
        cornerSize: parseInt(cornerLDCornerSize ?? '0'),
      },
      cornerLS: {
        cornerType: cornerLSCornerType ?? '',
        cornerSize: parseInt(cornerLSCornerSize ?? '0'),
      },
      cornerRD: {
        cornerType: cornerRDCornerType ?? '',
        cornerSize: parseInt(cornerRDCornerSize ?? '0'),
      },
      cornerRS: {
        cornerType: cornerRSCornerType ?? '',
        cornerSize: parseInt(cornerRSCornerSize ?? '0'),
      },
      cornerReverse: {
        cornerType: cornerReverseCornerType ?? '',
        cornerSize: parseInt(cornerReverseCornerSize ?? '0'),
      },
      cutCorner: {
        cutCornerQty: parseInt(cutCornerQty ?? '0'),
        cutCornerFeatureId: cutCornerFeatureId ?? '',
      },
      ovalEnd: {
        ovalEndQty: parseInt(ovalEndQty ?? '0'),
        ovalEndFeatureId: ovalEndFeatureId ?? '',
      },
      romanEnd: {
        romanEndQty: parseInt(romanEndQty ?? '0'),
        romanEndFeatureId: romanEndFeatureId ?? '',
      },
    });
  }, [
    cornerLCCornerType,
    cornerLCCornerSize,
    cornerLDCornerType,
    cornerLDCornerSize,
    cornerLSCornerType,
    cornerLSCornerSize,
    cornerRDCornerType,
    cornerRDCornerSize,
    cornerRSCornerType,
    cornerRSCornerSize,
    cornerReverseCornerType,
    cornerReverseCornerSize,
    cutCornerQty,
    cutCornerFeatureId,
    ovalEndQty,
    ovalEndFeatureId,
    romanEndQty,
    romanEndFeatureId,
  ]);

  // setMeasurementAttributes
  useEffect(() => {
    setMeasurementAttributes({
      ...measurementAttributes,
      coverLnFt: parseInt(coverLnFt ?? '0'),
      coverSqFt: parseInt(coverSqFt ?? '0'),
      dimensionA: parseInt(dimensionA ?? '0'),
      dimensionA1: parseInt(dimensionA1 ?? '0'),
      dimensionB: parseInt(dimensionB ?? '0'),
      dimensionB3: parseInt(dimensionB3 ?? '0'),
      dimensionB7: parseInt(dimensionB7 ?? '0'),
      dimensionC: parseInt(dimensionC ?? '0'),
      dimensionD: parseInt(dimensionD ?? '0'),
      dimensionE: parseInt(dimensionE ?? '0'),
      dimensionJ2: parseInt(dimensionJ2 ?? '0'),
      dimensionJ3: parseInt(dimensionJ3 ?? '0'),
      dimensionJ4: parseInt(dimensionJ4 ?? '0'),
      dimensionL: parseInt(dimensionL ?? '0'),
      dimensionRd: parseInt(dimensionRd ?? '0'),
      dimensionS: parseInt(dimensionS ?? '0'),
      dimensionS1: parseInt(dimensionS1 ?? '0'),
      dimensionT: parseInt(dimensionT ?? '0'),
      dimensionV3: parseInt(dimensionV3 ?? '0'),
      dimensionW1: parseInt(dimensionW1 ?? '0'),
      dimensionX1: parseInt(dimensionX1 ?? '0'),
      linerSqFt: parseInt(linerSqFt ?? '0'),
      overlapCalc: parseInt(overlapCalc ?? '0'),
      overlapOverride: parseInt(overlapOverride ?? '0'),
    });
  }, [
    coverLnFt,
    coverSqFt,
    dimensionA,
    dimensionA1,
    dimensionB,
    dimensionB3,
    dimensionB7,
    dimensionC,
    dimensionD,
    dimensionE,
    dimensionJ2,
    dimensionJ3,
    dimensionJ4,
    dimensionL,
    dimensionRd,
    dimensionS,
    dimensionS1,
    dimensionT,
    dimensionV3,
    dimensionW1,
    dimensionX1,
    linerSqFt,
    overlapCalc,
    overlapOverride,
  ]);

  // setProjectAttributes
  useEffect(() => {
    setProjectAttributes({
      ...projectAttributes,
      brand: brand ?? '',
      dropShip: dropShip,
      enteredBy: enteredBy ?? '',
      enteredDate: enteredDate ?? '',
      externalComments: externalComments ?? '',
      freightInstructions: freightInstructions ?? '',
      geolocationMatch: geolocationMatch,
      lathamQuoteNumber: lathamQuoteNumber ?? '',
      orderChanges: orderChanges,
      pickup: pickup,
      poNumber: poNumber ?? '',
      previousJobNumber: previousJobNumber ?? '',
      priorityRush: priorityRush,
      productType: productType ?? '',
      projectId: projectId ?? '',
      projectLat: projectLat ?? '',
      projectLong: projectLong ?? '',
      projectName: projectName ?? '',
      projectSiteCity: projectSiteCity ?? '',
      projectSiteState: projectSiteState ?? '',
      projectSiteStreet: projectSiteStreet ?? '',
      projectSiteStreet2: projectSiteStreet2 ?? '',
      projectSiteZip: projectSiteZip ?? '',
      projectUrl: projectUrl ?? '',
      quoteOrder: quoteOrder ?? '',
      quoteStatus: quoteStatus ?? '',
      reorderRemakeReason: reorderRemakeReason ?? '',
      reorderSerialNumber: reorderSerialNumber ?? '',
      shipToCity: shipToCity ?? '',
      shipToCountry: shipToCountry ?? '',
      shipToState: shipToCountry ?? '',
      shipToStreet: shipToStreet ?? '',
      shipToZip: shipToZip ?? '',
    });
  }, [
    brand,
    dropShip,
    enteredBy,
    enteredDate,
    externalComments,
    freightInstructions,
    geolocationMatch,
    lathamQuoteNumber,
    orderChanges,
    pickup,
    poNumber,
    previousJobNumber,
    priorityRush,
    productType,
    projectId,
    projectLat,
    projectLong,
    projectName,
    projectSiteCity,
    projectSiteState,
    projectSiteStreet,
    projectSiteStreet2,
    projectSiteZip,
    projectUrl,
    quoteOrder,
    quoteStatus,
    reorderRemakeReason,
    reorderSerialNumber,
    shipToCity,
    shipToCountry,
    shipToState,
    shipToStreet,
    shipToZip,
  ]);

  // setShapeAttributes
  useEffect(() => {
    setShapeAttributes({
      ...shapeAttributes,
      bottomType: bottomType ?? '',
      freeformIdentification: freeformIdentification,
      shapeId: shapeId ?? '',
      shapeName: shapeName ?? '',
      shapeOrientaion: shapeOrientaion ?? '',
    });
  }, [bottomType, freeformIdentification, shapeId, shapeName, shapeOrientaion]);

  const setObstructionTypeArray = (obs: ObstructionTypeArray, i: number) => {
    if (obstructionTypeArray) obstructionTypeArray[i] = obs;
  };

  const setOutsideStepArray = (obs: OutsideStepArray, i: number) => {
    if (outsideStepArray) outsideStepArray[i] = obs;
  };

  const setVinylCoveredStepInfo = (step: VinylCoveredStepsInfo, i: number) => {
    if (vinylCoveredStepsInfo) vinylCoveredStepsInfo[i] = step;
  };

  const setPartialDeckingArray = (deck: PartialDecking, i: number) => {
    if (partialDeckingArray) partialDeckingArray[i] = deck;
  };

  const addFeature = () => {
    if (featureType === FeatureType.Steps) {
      outsideStepArray?.push(newFeature as OutsideStepArray);
    } else {
      obstructionTypeArray?.push(newFeature as ObstructionTypeArray);
    }
    setFeatModalOpen(false);
  };

  return (
    <div className="spec-form">
      <OrderForm
        values={currentValues}
        initialValidationState={InitialSpecFormValidationStatus()}
        schema={SpecFormSchema}
        onFieldsValid={updateOrderState}
        onFieldsInvalid={updateOrderState}
        setValidFields={
          setValidFields as React.Dispatch<
            React.SetStateAction<GenericFieldValidationType>
          >
        }
      >
        <>
          {isCover && (
            <>
              <Modal
                title="Add New Feature"
                isOpen={featModalOpen}
                actions={
                  <UserModalActions
                    onCancel={() => setFeatModalOpen(false)}
                    onSubmit={addFeature}
                    disabled={featureType === ''}
                  />
                }
                size="small"
                onClose={() => setFeatModalOpen(false)}
                className="nopad"
              >
                <div className="spec-form__modal">
                  <ManagedSelect
                    label="Feature Type"
                    isValid={featureType !== ''}
                    value={featureType}
                    onChange={setFeatureType}
                    options={featureTypeOptions}
                    name="new_feature"
                  />
                  {featureType !== FeatureType.Steps && featureType !== '' && (
                    <Obstruction
                      obstruction={null}
                      setObstruction={(obs) => setNewFeature(obs)}
                    />
                  )}
                  {featureType === FeatureType.Steps && (
                    <OutsideStep
                      outsideStep={null}
                      setOutsideStep={(obs) => setNewFeature(obs)}
                    />
                  )}
                </div>
              </Modal>
              <Collapsible title="Feature Data">
                <>
                  <Button primary onClick={() => setFeatModalOpen(true)}>
                    Add Feature
                  </Button>
                  {obstructionTypeArray && obstructionTypeArray.length > 0 && (
                    <>
                      {obstructionTypeArray.map((val, i) => {
                        return (
                          <Obstruction
                            key={`obstruction-${i}`}
                            obstruction={val}
                            setObstruction={(obs) =>
                              setObstructionTypeArray(obs, i)
                            }
                          />
                        );
                      })}
                    </>
                  )}
                  {outsideStepArray && outsideStepArray.length > 0 && (
                    <>
                      {outsideStepArray.map((val, i) => {
                        return (
                          <OutsideStep
                            key={`outsideStep-${i}`}
                            outsideStep={val}
                            setOutsideStep={(obs) =>
                              setOutsideStepArray(obs, i)
                            }
                          />
                        );
                      })}
                    </>
                  )}
                </>
              </Collapsible>
            </>
          )}
          {!isCover && (
            <Collapsible title="Feature Data">
              <>
                {obstructionTypeArray && obstructionTypeArray.length > 0 && (
                  <>
                    {obstructionTypeArray.map((val, i) => {
                      return (
                        <Obstruction
                          key={`obstruction-${i}`}
                          obstruction={val}
                          setObstruction={(obs) =>
                            setObstructionTypeArray(obs, i)
                          }
                        />
                      );
                    })}
                  </>
                )}
              </>
            </Collapsible>
          )}
          {/* Vinyl Covered Step Attributes */}
          {!isCover && (
            <Collapsible title="Vinyl Covered Steps">
              <>
                {vinylCoveredStepsInfo && vinylCoveredStepsInfo.length > 0 && (
                  <>
                    {vinylCoveredStepsInfo.map((val, i) => {
                      return (
                        <VinylCoveredStepInfo
                          key={`obstruction-${i}`}
                          vinylCoveredStepInfo={val}
                          setVinylCoveredStepInfo={(step) =>
                            setVinylCoveredStepInfo(step, i)
                          }
                        />
                      );
                    })}
                  </>
                )}
              </>
            </Collapsible>
          )}
          {/* Cover Material Attributes */}
          {isCover && (
            <Collapsible title="Cover Material Attributes">
              <ManagedInput
                isValid={true}
                label="General Specific"
                type="text"
                placeholder="General Specific"
                onChange={setGeneralSpecific}
                value={generalSpecific}
                disabled={disabled}
              />
              <ManagedInput
                isValid={true}
                label="Material Color"
                type="text"
                placeholder="Material Color"
                onChange={setMaterialColor}
                value={materialColor}
                disabled={disabled}
              />
              <ManagedInput
                isValid={true}
                label="Material Drain Type FeatuerSId"
                type="text"
                placeholder="Material Drain Type FeatuerSId"
                onChange={setMaterialDrainTypeFeatureSId}
                value={materialDrainTypeFeatureSId}
                disabled={disabled}
              />
              <ManagedInput
                isValid={true}
                label="Material Fabric"
                type="text"
                placeholder="Material Fabric"
                onChange={setMaterialFabric}
                value={materialFabric}
                disabled={disabled}
              />
              <ManagedInput
                isValid={true}
                label="Material Grid"
                type="text"
                placeholder="Material Grid"
                onChange={setMaterialGrid}
                value={materialGrid}
                disabled={disabled}
              />
              <ManagedInput
                isValid={true}
                label="Material Spring Upgrade FeatureSId"
                type="text"
                placeholder="Material Spring Upgrade FeatureSId"
                onChange={setMaterialSpringUpgradeFeatureSId}
                value={materialSpringUpgradeFeatureSId}
                disabled={disabled}
              />
            </Collapsible>
          )}
          {/* Liner Material Attributes */}
          {!isCover && (
            <Collapsible title="Liner Material Attributes">
              <ManagedInput
                isValid={true}
                label="General Specific"
                type="text"
                placeholder="General Specific"
                onChange={setGeneralSpecific}
                value={generalSpecific}
                disabled={disabled}
              />
              <ManagedInput
                isValid={true}
                label="Material Wall"
                type="text"
                placeholder="Material Wall"
                onChange={setMaterialWall}
                value={materialWall}
                disabled={disabled}
              />
              <ManagedInput
                isValid={true}
                label="Material Floor"
                type="text"
                placeholder="Material Floor"
                onChange={setMaterialFloor}
                value={materialFloor}
                disabled={disabled}
              />
              <ManagedInput
                isValid={true}
                label="Material Bead Type"
                type="text"
                placeholder="Material Bead Type"
                onChange={setMaterialBeadType}
                value={materialBeadType}
                disabled={disabled}
              />
            </Collapsible>
          )}
          {/* Liner Accessory Attributes */}
          {!isCover && (
            <Collapsible title="Liner Accessory Attributes">
              <ManagedInput
                isValid={true}
                label="Safety Strip FeatureSId"
                type="text"
                placeholder="Safety Strip FeatureSId"
                onChange={setSafetyStripFeatureSId}
                value={safetyStripFeatureSId}
                disabled={true}
              />
              <>
                {state.accessories.safetyStripes &&
                  state.accessories.safetyStripeWidth && (
                    <ManagedInput
                      isValid={true}
                      label="Safety Stripe Width"
                      type="text"
                      placeholder="Safety Stripe Width"
                      onChange={setSafetyStripeWidth}
                      value={safetyStripeWidth}
                      disabled={disabled}
                    />
                  )}
              </>
              <>
                {state.accessories.safetyStripes && safetyStripeColor && (
                  <ManagedSelect
                    label="Safety Stripes Color"
                    isValid={true}
                    value={safetyStripeColor}
                    onChange={setSafetyStripeColor}
                    options={SafetyStripesOptions}
                    name="safetyStripesColor"
                    disabled={disabled}
                  />
                )}
              </>
              <ManagedInput
                isValid={true}
                label="Material Overlap FeatureId"
                type="text"
                placeholder="Material Overlap FeatureId"
                onChange={setMaterialOverlapFeatureId}
                value={materialOverlapFeatureId}
                disabled={true}
              />
              <ManagedInput
                isValid={true}
                label="Material Overlap Size"
                type="text"
                placeholder="Material Overlap Size"
                onChange={setMaterialOverlapSize}
                value={materialOverlapSize}
                disabled={disabled}
              />
              <ManagedInput
                isValid={true}
                label="Material Solid Shallow FeatureSId"
                type="text"
                placeholder="Material Solid Shallow FeatureSId"
                onChange={setMaterialSolidShallowFeatureSId}
                value={materialSolidShallowFeatureSId}
                disabled={true}
              />
              <ManagedSelect
                label="Material Solid Shallow Color"
                isValid={true}
                value={materialSolidShallowColor}
                onChange={setMaterialSolidShallowColor}
                options={SolidShallowEndOptions}
                name="materialSolidShallowColor"
                disabled={disabled}
              />
              <ManagedInput
                isValid={true}
                label="Racing Lanes FeatureSId"
                type="text"
                placeholder="Racing Lanes FeatureSId"
                onChange={setRacingLanesFeatureSId}
                value={racingLanesFeatureSId}
                disabled={true}
              />

              <ManagedInput
                label="Racing Lanes Comments"
                isValid={true}
                type="text"
                placeholder="Racing Lanes Comments"
                onChange={setRacingLanesComments}
                value={racingLanesComments}
              />

              <ManagedInput
                isValid={true}
                label="Target FeatureSId"
                type="text"
                placeholder="Target FeatureSId"
                onChange={setTargetFeatureSId}
                value={targetFeatureSId}
                disabled={true}
              />
              <ManagedInput
                label="Target Comments"
                isValid={true}
                type="text"
                placeholder="Target Comments"
                onChange={setTargetComments}
                value={targetComments}
              />
            </Collapsible>
          )}
          {/* Customer Attributes */}
          <Collapsible title="Customer Attributes">
            <ManagedInput
              isValid={true}
              label="Sugar Id"
              type="text"
              placeholder="Sugar Id"
              onChange={setSugarId}
              value={sugarId}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Customer Name"
              type="text"
              placeholder="Customer Name"
              onChange={setCustomerName}
              value={customerName}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="gl_company_new_c" // no idea what this means
              type="text"
              placeholder="gl_company_new_c" // no idea what this means
              onChange={setGl_company_new_c}
              value={gl_company_new_c}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Indirect Customer Number"
              type="text"
              placeholder="Indirect Customer Number"
              onChange={setIndirect_customer_number_c}
              value={indirect_customer_number_c}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Purchasing Channel"
              type="text"
              placeholder="Purchasing Channel"
              onChange={setPurchasing_channel_c}
              value={purchasing_channel_c}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Rewards Id"
              type="text"
              placeholder="Rewards Id"
              onChange={setRewards_id_c}
              value={rewards_id_c}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Selected Branch Number"
              type="text"
              placeholder="Selected Branch Number"
              onChange={setSelectedBranchNumber}
              value={selectedBranchNumber}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Selected Customer Number"
              type="text"
              placeholder="Selected Customer Number"
              onChange={setSelectedCustomerNumber}
              value={selectedCustomerNumber}
              disabled={disabled}
            />
          </Collapsible>
          {/* Decking Attributes */}
          <Collapsible title="Decking Attributes">
            <ManagedInput
              isValid={true}
              label="Coping Texture"
              type="text"
              placeholder="Coping Texture"
              onChange={setCopingTexture}
              value={copingTexture}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Deck Anchor FeatureSId"
              type="text"
              placeholder="Deck Anchor FeatureSId"
              onChange={setDeckAnchorFeatureSId}
              value={deckAnchorFeatureSId}
              disabled={disabled}
            />
            <ManagedToggle
              label="Deck Consistency Rough?"
              name="Deck Consistency Rough?"
              isValid={true}
              onChange={setDeckConsistency}
              checked={deckConsistency}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Deck Size"
              type="text"
              placeholder="Deck Size"
              onChange={setDeckSize}
              value={deckSize}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Deck Type"
              type="text"
              placeholder="Deck Type"
              onChange={setDeckType}
              value={deckType}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Extra Padding FeatureId"
              type="text"
              placeholder="Extra Padding FeatureId"
              onChange={setExtraPaddingFeatureId}
              value={extraPaddingFeatureId}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Extra Padding Length"
              type="text"
              placeholder="Extra Padding Length"
              onChange={setExtraPaddingLength}
              value={extraPaddingLength}
              disabled={disabled}
            />
            <>
              {partialDeckingArray && partialDeckingArray.length > 0 && (
                <>
                  {partialDeckingArray.map((val, i) => {
                    return (
                      <PartialDeckingFeat
                        key={`partial-decking-${i}`}
                        partialDecking={val}
                        setPartialDecking={(obs) =>
                          setPartialDeckingArray(obs, i)
                        }
                      />
                    );
                  })}
                </>
              )}
            </>
          </Collapsible>
          {/* Full Perimeter Cover Accessory Attributes */}
          {isCover && (
            <Collapsible title="Full Perimeter Cover Accessory Attributes">
              <ManagedInput
                isValid={true}
                label="Brass Anchor Collars FeatureSId"
                type="text"
                placeholder="Brass Anchor Collars FeatureSId"
                onChange={setBrassAnchorCollarsFeatureSId}
                value={brassAnchorCollarsFeatureSId}
                disabled={disabled}
              />
              <ManagedInput
                isValid={true}
                label="Buckles FeatureSId"
                type="text"
                placeholder="Buckles FeatureSId"
                onChange={setBucklesFeatureSId}
                value={bucklesFeatureSId}
                disabled={disabled}
              />
              <ManagedInput
                isValid={true}
                label="Cable Area 1 FeatureId"
                type="text"
                placeholder="Cable Area 1 FeatureId"
                onChange={setCableArea1FeatureId}
                value={cableArea1FeatureId}
                disabled={disabled}
              />
              <ManagedInput
                isValid={true}
                label="Cable Area 1 Length"
                type="text"
                placeholder="Cable Area 1 Lenght"
                onChange={setCableArea1Length}
                value={cableArea1Length}
                disabled={disabled}
              />
              <ManagedInput
                isValid={true}
                label="Cable Area 2 FeatureId"
                type="text"
                placeholder="Cable Area 2 FeatureId"
                onChange={setCableArea2FeatureId}
                value={cableArea2FeatureId}
                disabled={disabled}
              />
              <ManagedInput
                isValid={true}
                label="Cable Area 2 Length"
                type="text"
                placeholder="Cable Area 2 Lenght"
                onChange={setCableArea2Length}
                value={cableArea2Length}
                disabled={disabled}
              />
              <ManagedInput
                isValid={true}
                label="Cable Area 3 FeatureId"
                type="text"
                placeholder="Cable Area 3 FeatureId"
                onChange={setCableArea3FeatureId}
                value={cableArea3FeatureId}
                disabled={disabled}
              />
              <ManagedInput
                isValid={true}
                label="Cable Area 3 Length"
                type="text"
                placeholder="Cable Area 3 Lenght"
                onChange={setCableArea3Length}
                value={cableArea3Length}
                disabled={disabled}
              />
              <ManagedInput
                isValid={true}
                label="Cable Area 4 FeatureId"
                type="text"
                placeholder="Cable Area 4 FeatureId"
                onChange={setCableArea4FeatureId}
                value={cableArea4FeatureId}
                disabled={disabled}
              />
              <ManagedInput
                isValid={true}
                label="Cable Area 4 Length"
                type="text"
                placeholder="Cable Area 4 Lenght"
                onChange={setCableArea4Length}
                value={cableArea4Length}
                disabled={disabled}
              />
              <ManagedInput
                isValid={true}
                label="Double D Rings FeatureSId"
                type="text"
                placeholder="Double D Rings FeatureSId"
                onChange={setDoubleDRingsFeatureSId}
                value={doubleDRingsFeatureSId}
                disabled={disabled}
              />
              <ManagedInput
                isValid={true}
                label="Extra Long Anchors FeatureSId"
                type="text"
                placeholder="Extra Long Anchors FeatureSId"
                onChange={setExtraLongAnchorsFeatureSId}
                value={extraLongAnchorsFeatureSId}
                disabled={disabled}
              />
              <ManagedInput
                isValid={true}
                label="Kem Kap FeatureSId"
                type="text"
                placeholder="Kem Kap FeatureSId"
                onChange={setKemKapFeatureSId}
                value={kemKapFeatureSId}
                disabled={disabled}
              />
              <ManagedInput
                isValid={true}
                label="No Anchors FeatureSId"
                type="text"
                placeholder="No Anchors FeatureSId"
                onChange={setNoAnchorsFeatureSId}
                value={noAnchorsFeatureSId}
                disabled={disabled}
              />
              <ManagedInput
                isValid={true}
                label="No Hardware FeatureSId"
                type="text"
                placeholder="No Hardware FeatureSId"
                onChange={setNoHardwareFeatureSId}
                value={noHardwareFeatureSId}
                disabled={disabled}
              />
              <ManagedInput
                isValid={true}
                label="Top Bottom Webbing FeatureSId"
                type="text"
                placeholder="Top Bottom Webbing FeatureSId"
                onChange={setTopBottomWebbingFeatureSId}
                value={topBottomWebbingFeatureSId}
                disabled={disabled}
              />
              <ManagedInput
                isValid={true}
                label="Y Strap FeatureID"
                type="text"
                placeholder="Y Strap FeatureID"
                onChange={setYStrapFeatureId}
                value={yStrapFeatureId}
                disabled={disabled}
              />
              <ManagedInput
                isValid={true}
                label="Y Strap Quantity"
                type="text"
                placeholder="Y Strap Quantity"
                onChange={setYStrapQty}
                value={yStrapQty}
                disabled={disabled}
              />
              <ManagedInput
                isValid={true}
                label="Single Pad FeatureId"
                type="text"
                placeholder="Single Pad FeatureId"
                onChange={setSinglePadFeatureId}
                value={singlePadFeatureId}
                disabled={disabled}
              />
              <ManagedInput
                isValid={true}
                label="Single Pad Quantity"
                type="text"
                placeholder="Single Pad Quantity"
                onChange={setSinglePadQty}
                value={singlePadQty}
                disabled={disabled}
              />
            </Collapsible>
          )}
          {/* Geo Shape Attributes */}
          <Collapsible title="Geometric Shape Attributes">
            <ManagedInput
              isValid={true}
              label="Corner LC Corner Type"
              type="text"
              placeholder="Corner LC Corner Type"
              onChange={setCornerLCCornerType}
              value={cornerLCCornerType}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Corner LC Corner Size"
              type="text"
              placeholder="Corner LC Corner Size"
              onChange={setCornerLCCornerSize}
              value={cornerLCCornerSize}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Corner LD Corner Type"
              type="text"
              placeholder="Corner LD Corner Type"
              onChange={setCornerLDCornerType}
              value={cornerLDCornerType}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Corner LD Corner Size"
              type="text"
              placeholder="Corner LD Corner Size"
              onChange={setCornerLDCornerSize}
              value={cornerLDCornerSize}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Corner LS Corner Type"
              type="text"
              placeholder="Corner LS Corner Type"
              onChange={setCornerLSCornerType}
              value={cornerLSCornerType}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Corner LS Corner Size"
              type="text"
              placeholder="Corner LS Corner Size"
              onChange={setCornerLSCornerSize}
              value={cornerLSCornerSize}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Corner RD Corner Type"
              type="text"
              placeholder="Corner RD Corner Type"
              onChange={setCornerRDCornerType}
              value={cornerRDCornerType}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Corner RD Corner Size"
              type="text"
              placeholder="Corner RD Corner Size"
              onChange={setCornerRDCornerSize}
              value={cornerRDCornerSize}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Corner RS Corner Type"
              type="text"
              placeholder="Corner RS Corner Type"
              onChange={setCornerRSCornerType}
              value={cornerRSCornerType}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Corner RS Corner Size"
              type="text"
              placeholder="Corner RS Corner Size"
              onChange={setCornerRSCornerSize}
              value={cornerRSCornerSize}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Corner Reverse Corner Type"
              type="text"
              placeholder="Corner Reverse Corner Type"
              onChange={setCornerReverseCornerType}
              value={cornerReverseCornerType}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Corner Reverse Corner Size"
              type="text"
              placeholder="Corner Reverse Corner Size"
              onChange={setCornerReverseCornerSize}
              value={cornerReverseCornerSize}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Cut Corner Quantity"
              type="text"
              placeholder="Cut Corner Quantity"
              onChange={setCutCornerQty}
              value={cutCornerQty}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Cut Corner FeatureId"
              type="text"
              placeholder="Cut Corner FeatureId"
              onChange={setCutCornerFeatureId}
              value={cutCornerFeatureId}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Oval End Quantity"
              type="text"
              placeholder="Oval End Quantity"
              onChange={setOvalEndQty}
              value={ovalEndQty}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Oval End FeatureId"
              type="text"
              placeholder="Oval End FeatureId"
              onChange={setOvalEndFeatureId}
              value={ovalEndFeatureId}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Roman End Quantity"
              type="text"
              placeholder="Roman End Quantity"
              onChange={setRomanEndQty}
              value={romanEndQty}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Roman End FeatureId"
              type="text"
              placeholder="Roman End FeatureId"
              onChange={setRomanEndFeatureId}
              value={romanEndFeatureId}
              disabled={disabled}
            />
          </Collapsible>
          {/* Measurement Attributes */}
          <Collapsible title="Measurement Attributes">
            <>
              {isCover && (
                <>
                  <ManagedInput
                    isValid={true}
                    label="Cover Linear Feet"
                    type="text"
                    placeholder="Cover Linear Feet"
                    onChange={setCoverLnFt}
                    value={coverLnFt}
                    disabled={disabled}
                  />
                  <ManagedInput
                    isValid={true}
                    label="Cover Square Feet"
                    type="text"
                    placeholder="Cover Square Feet"
                    onChange={setCoverSqFt}
                    value={coverSqFt}
                    disabled={disabled}
                  />
                </>
              )}
            </>
            <>
              {!isCover && (
                <ManagedInput
                  isValid={true}
                  label="Liner Square Feet"
                  type="text"
                  placeholder="Liner Square Feet"
                  onChange={setLinerSqFt}
                  value={linerSqFt}
                  disabled={disabled}
                />
              )}
            </>
            <ManagedInput
              isValid={true}
              label="Dimension A"
              type="text"
              placeholder="Dimension A"
              onChange={setDimensionA}
              value={dimensionA}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Dimension A1"
              type="text"
              placeholder="Dimension A1"
              onChange={setDimensionA1}
              value={dimensionA1}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Dimension B"
              type="text"
              placeholder="Dimension B"
              onChange={setDimensionB}
              value={dimensionB}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Dimension B3"
              type="text"
              placeholder="Dimension B3"
              onChange={setDimensionB3}
              value={dimensionB3}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Dimension B7"
              type="text"
              placeholder="Dimension B7"
              onChange={setDimensionB7}
              value={dimensionB7}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Dimension C"
              type="text"
              placeholder="Dimension C"
              onChange={setDimensionC}
              value={dimensionC}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Dimension D"
              type="text"
              placeholder="Dimension D"
              onChange={setDimensionD}
              value={dimensionD}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Dimension E"
              type="text"
              placeholder="Dimension E"
              onChange={setDimensionE}
              value={dimensionE}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Dimension J2"
              type="text"
              placeholder="Dimension J2"
              onChange={setDimensionJ2}
              value={dimensionJ2}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Dimension J3"
              type="text"
              placeholder="Dimension J3"
              onChange={setDimensionJ3}
              value={dimensionJ3}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Dimension J4"
              type="text"
              placeholder="Dimension J4"
              onChange={setDimensionJ4}
              value={dimensionJ4}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Dimension L"
              type="text"
              placeholder="Dimension L"
              onChange={setDimensionL}
              value={dimensionL}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Dimension Rd"
              type="text"
              placeholder="Dimension Rd"
              onChange={setDimensionRd}
              value={dimensionRd}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Dimension S"
              type="text"
              placeholder="Dimension S"
              onChange={setDimensionS}
              value={dimensionS}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Dimension S1"
              type="text"
              placeholder="Dimension S1"
              onChange={setDimensionS1}
              value={dimensionS1}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Dimension T"
              type="text"
              placeholder="Dimension T"
              onChange={setDimensionT}
              value={dimensionT}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Dimension V3"
              type="text"
              placeholder="Dimension V3"
              onChange={setDimensionV3}
              value={dimensionV3}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Dimension W1"
              type="text"
              placeholder="Dimension W1"
              onChange={setDimensionW1}
              value={dimensionW1}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Dimension X1"
              type="text"
              placeholder="Dimension X1"
              onChange={setDimensionX1}
              value={dimensionX1}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Overlap Calculator"
              type="text"
              placeholder="Overlap Calculator"
              onChange={setOverlapCalc}
              value={overlapCalc}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Overlap Override"
              type="text"
              placeholder="Overlap Override"
              onChange={setOverlapOverride}
              value={overlapOverride}
              disabled={disabled}
            />
          </Collapsible>
          {/* Project Attributes */}
          <Collapsible title="Project Attributes">
            <ManagedInput
              isValid={true}
              label="Brand"
              type="text"
              placeholder="Brand"
              onChange={setBrand}
              value={brand}
              disabled={disabled}
            />
            <ManagedToggle
              label="Drop Ship?"
              name="Drop Ship?"
              isValid={true}
              onChange={setDropShip}
              checked={dropShip}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Entered By"
              type="text"
              placeholder="Entered By"
              onChange={setEnteredBy}
              value={enteredBy}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Entered Date"
              type="text"
              placeholder="Entered Date"
              onChange={setEnteredDate}
              value={enteredDate}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="External Comments"
              type="text"
              placeholder="External Comments"
              onChange={setExternalComments}
              value={externalComments}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Freight Instructions"
              type="text"
              placeholder="Freight Instructions"
              onChange={setFreightInstructions}
              value={freightInstructions}
              disabled={disabled}
            />
            <ManagedToggle
              label="Geolocation Match?"
              name="Geolocation Match?"
              isValid={true}
              onChange={setGeolocationMatch}
              checked={geolocationMatch}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Latham Quote Number"
              type="text"
              placeholder="Latham Quote Number"
              onChange={setLathamQuoteNumber}
              value={lathamQuoteNumber}
              disabled={disabled}
            />
            <ManagedToggle
              label="Order Changes?"
              name="Order Changes?"
              isValid={true}
              onChange={setOrderChanges}
              checked={orderChanges}
              disabled={disabled}
            />
            <ManagedToggle
              label="Pickup?"
              name="Pickup?"
              isValid={true}
              onChange={setPickup}
              checked={pickup}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="poNumber"
              type="text"
              placeholder="poNumber"
              onChange={setPoNumber}
              value={poNumber}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Previous Job Number"
              type="text"
              placeholder="Previous Job Number"
              onChange={setPreviousJobNumber}
              value={previousJobNumber}
              disabled={disabled}
            />
            <ManagedToggle
              label="Priority Rush?"
              name="Priority Rush?"
              isValid={true}
              onChange={setPriorityRush}
              checked={priorityRush}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Product Type"
              type="text"
              placeholder="Product Type"
              onChange={setProductType}
              value={productType}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="ProjectId"
              type="text"
              placeholder="ProjectId"
              onChange={setProjectId}
              value={projectId}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Project Latitude"
              type="text"
              placeholder="Project Latitude"
              onChange={setProjectLat}
              value={projectLat}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Project Longitude"
              type="text"
              placeholder="Project Longitude"
              onChange={setProjectLong}
              value={projectLong}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Project Name"
              type="text"
              placeholder="Project Name"
              onChange={setProjectName}
              value={projectName}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Project Site City"
              type="text"
              placeholder="Project Site City"
              onChange={setProjectSiteCity}
              value={projectSiteCity}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Project Site State"
              type="text"
              placeholder="Project Site State"
              onChange={setProjectSiteState}
              value={projectSiteState}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Project Site Street"
              type="text"
              placeholder="Project Site Street"
              onChange={setProjectSiteStreet}
              value={projectSiteStreet}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Project Site Street 2"
              type="text"
              placeholder="Project Site Street 2"
              onChange={setProjectSiteStreet2}
              value={projectSiteStreet2}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Project Site Zip"
              type="text"
              placeholder="Project Site Zip"
              onChange={setProjectSiteZip}
              value={projectSiteZip}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Project Url"
              type="text"
              placeholder="Project Url"
              onChange={setProjectUrl}
              value={projectUrl}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Quote Order"
              type="text"
              placeholder="Quote Order"
              onChange={setQuoteOrder}
              value={quoteOrder}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Quote Status"
              type="text"
              placeholder="Quote Status"
              onChange={setQuoteStatus}
              value={quoteStatus}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Reorder Remake Reason"
              type="text"
              placeholder="Reorder Remake Reason"
              onChange={setReorderRemakeReason}
              value={reorderRemakeReason}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Reorder Serial Number"
              type="text"
              placeholder="Reorder Serial Number"
              onChange={setReorderSerialNumber}
              value={reorderSerialNumber}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Ship To City"
              type="text"
              placeholder="Ship To City"
              onChange={setShipToCity}
              value={shipToCity}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Ship To Country"
              type="text"
              placeholder="Ship To Country"
              onChange={setShipToCountry}
              value={shipToCountry}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Ship To State"
              type="text"
              placeholder="Ship To State"
              onChange={setShipToState}
              value={shipToState}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Ship To Street"
              type="text"
              placeholder="Ship To Street"
              onChange={setShipToStreet}
              value={shipToStreet}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Ship To Zip"
              type="text"
              placeholder="Ship To Zip"
              onChange={setShipToZip}
              value={shipToZip}
              disabled={disabled}
            />
          </Collapsible>
          {/* Shape Attributes */}
          <Collapsible title="Shape Attributes">
            <ManagedInput
              isValid={true}
              label="Bottom Type"
              type="text"
              placeholder="Bottom Type"
              onChange={setBottomType}
              value={bottomType}
              disabled={disabled}
            />
            <ManagedToggle
              label="Freeform?"
              name="Freeform?"
              isValid={true}
              onChange={setFreeformIdentification}
              checked={freeformIdentification}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="ShapeId"
              type="text"
              placeholder="ShapeId"
              onChange={setShapeId}
              value={shapeId}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Shape Name"
              type="text"
              placeholder="Shape Name"
              onChange={setShapeName}
              value={shapeName}
              disabled={disabled}
            />
            <ManagedInput
              isValid={true}
              label="Shape Orientaion"
              type="text"
              placeholder="Shape Orientaion"
              onChange={setShapeOrientaion}
              value={shapeOrientaion}
              disabled={disabled}
            />
          </Collapsible>
        </>
      </OrderForm>
    </div>
  );
};

export default SpecForm;
