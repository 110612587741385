import { APIService, APIMockService, tryRes } from 'services';
import { Dealer, PurchasePartner } from 'models';
import { MOCK_SERVICE } from 'config';
import { delay } from 'utils';
import { DealerPartner, PurchasePartnerWithBrands } from 'models/Dealer';

export interface BrandsResponse {
  direct: PurchasePartnerWithBrands;
  throughBuyingGroup: PurchasePartnerWithBrands;
  throughDistribution: {
    distributorCustomerAccount: PurchasePartnerWithBrands[];
  };
  dealerId: string;
}

export interface ProductInfo {
  product: string;
  name: string;
  id: string;
}

interface Brand {
  name: string;
  code: number;
  isDefault: boolean;
  brands: ProductInfo[];
}

type DistributorInfo = Brand;

type BrandsDirect = Brand;
type BrandsBuyingGroup = Brand;
type BrandsDistribution = { distributorCustomerAccount: BrandsDistribution };

export interface BrandsInfo {
  direct: BrandsDirect;
  throughBuyingGroup: BrandsBuyingGroup;
  throughDistribution: BrandsDistribution;
}

class DealerMockService extends APIMockService<Dealer> {
  async getDealers(): Promise<Dealer[]> {
    return delay([]);
  }

  async getBrands(): Promise<BrandsResponse> {
    return delay({} as BrandsResponse);
  }
}
class DealerService extends APIService<Dealer> {
  async getDealers() {
    const response = await fetch(`${this._endpoint}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    return tryRes<Dealer[]>(response);
  }

  async getBrands(): Promise<BrandsResponse> {
    const response = await fetch(`${this._endpoint}/mine/partners/brands`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    return tryRes<BrandsResponse>(response);
  }

  async getAllBrands(): Promise<BrandsResponse[]> {
    const response = await fetch(`${this._endpoint}/partners/brands`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    return tryRes<BrandsResponse[]>(response);
  }

  async getBrandsById(rewardsId: string): Promise<BrandsResponse> {
    const response = await fetch(
      `${this._endpoint}/${rewardsId}/partners/brands`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
    );
    return tryRes<BrandsResponse>(response);
  }

  async addOrRefreshDealer(rewardsId: string): Promise<DealerPartner> {
    const response = await fetch(`${this._endpoint}/${rewardsId}`, {
      method: 'PUT',
    });
    return tryRes<DealerPartner>(response);
  }

  async addOrRefreshUserDealer(dealer: Dealer): Promise<Dealer> {
    const response = await fetch(`${this._endpoint}/user`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(dealer),
    });
    return tryRes<Dealer>(response);
  }

  async updateDealer(dealer: Dealer): Promise<any> {
    const response = await fetch(`${this._endpoint}/${dealer.id}`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(dealer),
    });
    return tryRes<Dealer>(response);
  }
}

const service = new DealerService('dealers');

export default service;
