import { createModel, DataFromSchema } from 'models/Model';

export enum EmployeeRoles {
  TECHNICIAN = 'technician',
  BUYER = 'buyer',
  ADMIN = 'admin',
  CUST_SERV = 'cust_serv',
  DESIGN = 'design',
  SALES = 'sales',
  LATHAM_ADMIN = 'latham_admin',
  BRIGHT_ADMIN = 'bright_admin',
}

export enum EmployeeStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

const employeeSchema = {
  name: { type: 'text', label: 'Name' },
  email: { type: 'email', label: 'Email' },
  role: { type: 'radio', label: 'Role', values: EmployeeRoles },
  phone: { type: 'tel', label: 'Contact Phone' },
  dealerId: { type: 'text', label: 'Company' },
  createdAt: { type: 'date', label: 'Created' },
  signedTOS: { type: 'text', label: 'TOS Signed?' },
  isActive: { type: 'toggle', label: 'Status', values: EmployeeStatus },
} as const;

type EmployeeMetaData = {
  id: string;
  createdAt?: string;
  dealerId: string;
};

export type Employee = DataFromSchema<typeof employeeSchema> & EmployeeMetaData;

export const EmployeeModel = createModel<Employee>(employeeSchema);
