import React, { createContext, useContext, useReducer } from 'react';
import SmartTableReducer, {
  initialDealersTableState,
  initialEmployeesTableState,
  initialGlobalTableState,
  initialOrdersTableState,
  initialProjectsTableState,
  initialProjectsWithDealerTableState,
  SmartTableSortState,
  updateTables,
} from './SmartTableReducer';

const TableSortOrderContext = createContext({
  projectsTableState: initialProjectsTableState,
  projectsWithDealerTableState: initialProjectsWithDealerTableState,
  employeesTableState: initialEmployeesTableState,
  dealersTableState: initialDealersTableState,
  ordersTableState: initialOrdersTableState,
  updateTableSortingState: (
    sortState: SmartTableSortState,
    table: TableType
  ) => {
    return;
  },
  updateTableFilteringState: (
    filterKey: string,
    filterValue: string,
    table: TableType
  ) => {
    return;
  },
});

export type TableType =
  | 'projects'
  | 'employees'
  | 'dealers'
  | 'orders'
  | 'projectsWithDealer';

export const SmartTableProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [globalTableState, dispatch] = useReducer(
    SmartTableReducer,
    initialGlobalTableState
  );

  const updateTableSortingState = (
    sortState: SmartTableSortState,
    table: TableType
  ) => {
    const newState = {
      [table]: { ...globalTableState[table], sorting: sortState },
    };
    const payload = { ...globalTableState, ...newState };
    dispatch(updateTables(payload));
  };

  const updateTableFilteringState = (
    filterKey: string,
    filterValue: string,
    table: TableType
  ) => {
    const filters = globalTableState[table].filters ?? new Map();
    filters?.set(filterKey, filterValue);
    if (filterValue === '') {
      filters.delete(filterKey);
    }
    const newState = { [table]: { ...globalTableState[table], filters } };
    const payload = { ...globalTableState, ...newState };
    dispatch(updateTables(payload));
  };

  const {
    projects: projectsTableState,
    projectsWithDealer: projectsWithDealerTableState,
    employees: employeesTableState,
    dealers: dealersTableState,
    orders: ordersTableState,
  } = globalTableState;
  const value = {
    projectsTableState,
    employeesTableState,
    dealersTableState,
    ordersTableState,
    projectsWithDealerTableState,
    updateTableSortingState,
    updateTableFilteringState,
  };
  return (
    <TableSortOrderContext.Provider value={value}>
      {children}
    </TableSortOrderContext.Provider>
  );
};

const useSmartTableSortOrder = () => useContext(TableSortOrderContext);

export default useSmartTableSortOrder;
