import { APIEntity, IAPIMethods } from './APIService';
import { delay, bindMethodsToSelf } from 'utils';

export interface IAPIMockService<T> extends IAPIMethods<T> {
  _data: T[];
}

export default class APIMockService<T> implements IAPIMockService<T> {
  _data: APIEntity<T>[];

  constructor(data: APIEntity<T>[]) {
    this._data = data;
    bindMethodsToSelf(this);
  }

  getAll() {
    return delay(this._data);
  }

  get(id: string) {
    return delay(() => {
      const el = this._data.find((p) => p.id === id);
      if (el) return { ...el };
      else throw new Error('Element not found');
    });
  }

  set(el: APIEntity<T>) {
    // Don't modify cache if there is no data
    // The .splice method will add the element to an emtpy array, which can cause bugs
    if (this._data.length) {
      const idx = this._data.findIndex((p) => p.id === el.id);
      this._data.splice(idx, 1, el);
    }
    return delay(el);
  }

  add(el: T) {
    const newEl = el as APIEntity<T>;
    if (!newEl.id) newEl.id = String(this._data.length);
    this._data.push(newEl);
    return delay(newEl);
  }

  del(id: string) {
    const idx = this._data.findIndex((p) => p.id === id);
    this._data.splice(idx, 1);
    return delay(void 0);
  }
}
