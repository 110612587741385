import * as yup from 'yup';
import { InferType } from 'yup';
import {
  DECKING_CONCRETE,
  DECKING_GRASS_DIRT,
  DECKING_PAVERS_CONCRETE,
  DECKING_PAVERS_SAND,
  DECKING_WOOD_COMPOSITE,
  deckingTypeOptions,
  getDeckingTypeOptions,
  getPaddingTypeOptions,
  hardwareTypeOptions,
} from './DeckConstants';
import { HARDWARE_NEEDED_CHOOSE } from '../AnchorsForm/AnchorsFormConstants';

/**
 * The schema for each deck feature in the Anchors schema
 * Keep in mind that the conditional logic in this schema depends on the hardware needed value from the parent schema
 * It's being referenced as a context variable, so when we're doing the validation: we need to pass in the values of that parent schema into the yup context, otherwise things won't work properly
 * Hint: for schema debugging, yup convert your statement to take a function and drop in your debugging in there
 * Note: Some yup features that reference (this) the yup schema are only available when using a function expression (function () {}) instead of arrow functions (...values, schema) => {}
 */
export const BaseDeckFormSchema = yup.object().shape({
  decking: yup.string().required().oneOf(getDeckingTypeOptions()),
  padding: yup.boolean().required(),
  featureName: yup.string().nullable(),
  notes: yup.string().nullable(),
  length: yup.number().nullable(),
  rdm: yup.boolean().nullable(),
  hardware: yup
    .string()
    .nullable()
    // I can't seem to find it documented anywhere, but $ is how you reference context variables, we're adding the parent field to the context so we can conditionally validate without merging those schemas
    .when('$hardwareNeeded', {
      is: (hardwareOptions: string) =>
        hardwareOptions === HARDWARE_NEEDED_CHOOSE,
      then: yup
        .string()
        .required()
        .when('decking', {
          is: (decking: string) => decking === DECKING_CONCRETE,
          then: yup
            .string()
            .oneOf(hardwareTypeOptions[DECKING_CONCRETE].map((el) => el.value)),
        })
        .when('decking', {
          is: (decking: string) => decking === DECKING_PAVERS_CONCRETE,
          then: yup
            .string()
            .oneOf(
              hardwareTypeOptions[DECKING_PAVERS_CONCRETE].map((el) => el.value)
            ),
        })
        .when('decking', {
          is: (decking: string) => decking === DECKING_PAVERS_SAND,
          then: yup
            .string()
            .oneOf(
              hardwareTypeOptions[DECKING_PAVERS_SAND].map((el) => el.value)
            ),
        })
        .when('decking', {
          is: (decking: string) => decking === DECKING_GRASS_DIRT,
          then: yup
            .string()
            .oneOf(
              hardwareTypeOptions[DECKING_GRASS_DIRT].map((el) => el.value)
            ),
        })
        .when('decking', {
          is: (decking: string) => decking === DECKING_WOOD_COMPOSITE,
          then: yup
            .string()
            .oneOf(
              hardwareTypeOptions[DECKING_WOOD_COMPOSITE].map((el) => el.value)
            ),
        }),
    })
    .when('rdm', {
      is: (rdm: boolean) => rdm === true,
      then: yup
        .string()
        .required()
        .when('decking', {
          is: (decking: string) => decking === DECKING_CONCRETE,
          then: yup
            .string()
            .oneOf(hardwareTypeOptions[DECKING_CONCRETE].map((el) => el.value)),
        })
        .when('decking', {
          is: (decking: string) => decking === DECKING_PAVERS_CONCRETE,
          then: yup
            .string()
            .oneOf(
              hardwareTypeOptions[DECKING_PAVERS_CONCRETE].map((el) => el.value)
            ),
        })
        .when('decking', {
          is: (decking: string) => decking === DECKING_PAVERS_SAND,
          then: yup
            .string()
            .oneOf(
              hardwareTypeOptions[DECKING_PAVERS_SAND].map((el) => el.value)
            ),
        })
        .when('decking', {
          is: (decking: string) => decking === DECKING_GRASS_DIRT,
          then: yup
            .string()
            .oneOf(
              hardwareTypeOptions[DECKING_GRASS_DIRT].map((el) => el.value)
            ),
        })
        .when('decking', {
          is: (decking: string) => decking === DECKING_WOOD_COMPOSITE,
          then: yup
            .string()
            .oneOf(
              hardwareTypeOptions[DECKING_WOOD_COMPOSITE].map((el) => el.value)
            ),
        }),
    }),
  collars: yup
    .boolean()
    .nullable()
    .when('hardware', {
      is: (hardware: string) =>
        deckingTypeOptions
          .map((el) => el.value)
          .find((validDeckOption) => validDeckOption === hardware),
      then: yup.string().required(),
    }),
  paddingType: yup
    .string()
    .nullable()
    .when('padding', {
      is: true,
      then: yup.string().nullable().required().oneOf(getPaddingTypeOptions()),
    }),
});

export type DeckFormType = InferType<typeof BaseDeckFormSchema>;
