import CancunBluesLeftSvg from './assets/CancunBluesLeft.svg';
import CancunBluesRightSvg from './assets/CancunBluesRight.svg';
import DefaultSvg from './assets/Default.svg';
import DiamondLeftSvg from './assets/DiamondLeft.svg';
import DiamondRightSvg from './assets/DiamondRight.svg';
import EscaleLeftSvg from './assets/EscaleLeft.svg';
import EscaleRightSvg from './assets/EscaleRight.svg';
import FiberglassFreeformSvg from './assets/FiberglassFreeform.svg';
import FiberglassGeometricSvg from './assets/FiberglassGeometric.svg';
import Figure8Svg from './assets/Figure8.svg';
import FreeformSvg from './assets/Freeform.svg';
import FullLLeftSvg from './assets/FullLLeft.svg';
import FullLRightSvg from './assets/FullLRight.svg';
import GeminiLeftSvg from './assets/GeminiLeft.svg';
import GeminiRightSvg from './assets/GeminiRight.svg';
import GrecianTrueLLeftSvg from './assets/GrecianTrueLLeft.svg';
import GrecianTrueLRightSvg from './assets/GrecianTrueLRight.svg';
import GrecianLazyLLeftSvg from './assets/GrecianLazyLLeft.svg';
import GrecianLazyLRightSvg from './assets/GrecianLazyLRight.svg';
import GrecianSvg from './assets/Grecian.svg';
import HighlanderLeftSvg from './assets/HighlanderLeft.svg';
import HighlanderRightSvg from './assets/HighlanderRight.svg';
import KeyholeSvg from './assets/Keyhole.svg';
import KidneyLeftSvg from './assets/KidneyLeft.svg';
import KidneyRightSvg from './assets/KidneyRight.svg';
import KiteSvg from './assets/Kite.svg';
import LazyLLeftSvg from './assets/LazyLLeft.svg';
import LazyLRightSvg from './assets/LazyLRight.svg';
import LibertyLeftSvg from './assets/LibertyLeft.svg';
import LibertyRightSvg from './assets/LibertyRight.svg';
import ModifiedOvalSvg from './assets/ModifiedOval.svg';
import MonteCarloJazzSvg from './assets/MonteCarloJazz.svg';
import MontereyNiagraVermontLeftSvg from './assets/MontereyNiagraVermontLeft.svg';
import MontereyNiagraVermontRightSvg from './assets/MontereyNiagraVermontRight.svg';
import MountainPondLeftSvg from './assets/MountainPondLeft.svg';
import MountainPondRightSvg from './assets/MountainPondRight.svg';
import MountainLakeCrystalLakeLeftSvg from './assets/MountainLakeCrystalLakeLeft.svg';
import MountainLakeCrystalLakeRightSvg from './assets/MountainLakeCrystalLakeRight.svg';
import NorlanderLeftSvg from './assets/NorlanderLeft.svg';
import NorlanderRightSvg from './assets/NorlanderRight.svg';
import ObliqueCelebrityLeftSvg from './assets/ObliqueCelebrityLeft.svg';
import ObliqueCelebrityRightSvg from './assets/ObliqueCelebrityRight.svg';
import OctagonSvg from './assets/Octagon.svg';
import OdysseyLeftSvg from './assets/OdysseyLeft.svg';
import OdysseyRightSvg from './assets/OdysseyRight.svg';
import OtherSvg from './assets/Other.svg';
import OvalSvg from './assets/Oval.svg';
import PacificLagoonLeftSvg from './assets/PacificLagoonLeft.svg';
import PacificLagoonRightSvg from './assets/PacificLagoonRight.svg';
import PaletteNautilusLeftSvg from './assets/PaletteNautilusLeft.svg';
import PaletteNautilusRightSvg from './assets/PaletteNautilusRight.svg';
import PatricianSvg from './assets/Patrician.svg';
import RectangleSvg from './assets/Rectangle.svg';
import RioLeftSvg from './assets/RioLeft.svg';
import RioRightSvg from './assets/RioRight.svg';
import RomanSvg from './assets/Roman.svg';
import RoundSvg from './assets/RoundLeft.svg';
import SentraMichiganLeftSvg from './assets/SentraMichiganLeft.svg';
import SentraMichiganRightSvg from './assets/SentraMichiganRight.svg';
import TaorminaLeftSvg from './assets/TaorminaLeft.svg';
import TaorminaRightSvg from './assets/TaorminaRight.svg';

export const CancunBluesLeft = CancunBluesLeftSvg;
export const CancunBluesRight = CancunBluesRightSvg;
export const Default = DefaultSvg;
export const DiamondLeft = DiamondLeftSvg;
export const DiamondRight = DiamondRightSvg;
export const EscaleLeft = EscaleLeftSvg;
export const EscaleRight = EscaleRightSvg;
export const FiberglassFreeform = FiberglassFreeformSvg;
export const FiberglassGeometric = FiberglassGeometricSvg;
export const Figure8 = Figure8Svg;
export const Freeform = FreeformSvg;
export const GeminiOmniOasisCypressLeft = GeminiLeftSvg;
export const GeminiOmniOasisCypressRight = GeminiRightSvg;
export const Grecian = GrecianSvg;
export const GrecianLazyLLeft = GrecianLazyLLeftSvg;
export const GrecianLazyLRight = GrecianLazyLRightSvg;
export const GrecianTrueLLeft = GrecianTrueLLeftSvg;
export const GrecianTrueLRight = GrecianTrueLRightSvg;
export const HighlanderLeft = HighlanderLeftSvg;
export const HighlanderRight = HighlanderRightSvg;
export const Keyhole = KeyholeSvg;
export const KidneyLeft = KidneyLeftSvg;
export const KidneyRight = KidneyRightSvg;
export const Kite = KiteSvg;
export const LazyLLeft = LazyLLeftSvg;
export const LazyLRight = LazyLRightSvg;
export const LibertyLeft = LibertyLeftSvg;
export const LibertyRight = LibertyRightSvg;
export const MichiganSentraLeft = SentraMichiganLeftSvg;
export const MichiganSentraRight = SentraMichiganRightSvg;
export const ModifiedOval = ModifiedOvalSvg;
export const MonteCarloJazz = MonteCarloJazzSvg;
export const MontereyNiagraVermontLeft = MontereyNiagraVermontLeftSvg;
export const MontereyNiagraVermontRight = MontereyNiagraVermontRightSvg;
export const MountainCrystalLakeLeft = MountainLakeCrystalLakeLeftSvg;
export const MountainCrystalLakeRight = MountainLakeCrystalLakeRightSvg;
export const MountainPondLeft = MountainPondLeftSvg;
export const MountainPondRight = MountainPondRightSvg;
export const NorlanderCapeMayLeft = NorlanderLeftSvg;
export const NorlanderCapeMayRight = NorlanderRightSvg;
export const ObliqueCelebrityLeft = ObliqueCelebrityLeftSvg;
export const ObliqueCelebrityRight = ObliqueCelebrityRightSvg;
export const Octagon = OctagonSvg;
export const OdysseyLeft = OdysseyLeftSvg;
export const OdysseyRight = OdysseyRightSvg;
export const Other = OtherSvg;
export const Oval = OvalSvg;
export const PacificLagoonLeft = PacificLagoonLeftSvg;
export const PacificLagoonRight = PacificLagoonRightSvg;
export const PaletteNautilusLeft = PaletteNautilusLeftSvg;
export const PaletteNautilusRight = PaletteNautilusRightSvg;
export const Patrician = PatricianSvg;
export const Rectangle = RectangleSvg;
export const RioLeft = RioLeftSvg;
export const RioRight = RioRightSvg;
export const RomanEnd = RomanSvg;
export const Round = RoundSvg;
export const TaorminaLeft = TaorminaLeftSvg;
export const TaorminaRight = TaorminaRightSvg;
export const TrueLLeft = FullLLeftSvg;
export const TrueLRight = FullLRightSvg;
