import React from 'react';
import './ManagedSelect.scss';
import { bemErrorHelper } from '../FieldHelpers';
import { OrderType, CoverType, ReplacementType } from '../../../../models';
import { SelectOptions } from 'models/SelectOptions';

type ManagedFieldOnChange =
  | React.Dispatch<React.SetStateAction<string | undefined>>
  | React.Dispatch<React.SetStateAction<OrderType | undefined>>
  | React.Dispatch<React.SetStateAction<CoverType | undefined>>
  | React.Dispatch<React.SetStateAction<ReplacementType | undefined>>
  | React.Dispatch<React.SetStateAction<string>>
  | ((value: string) => void);

type ManagedFieldIndex =
  | React.Dispatch<React.SetStateAction<number | undefined>>
  | ((value: number) => void);
interface ManagedSelectProps {
  index?: number;
  isValid: boolean;
  placeholder?: string;
  label?: string;
  value?: string | null;
  onChange: ManagedFieldOnChange;
  getSelectedIndex?: ManagedFieldIndex;
  options: SelectOptions;
  name: string;
  namespace?: string;
  disabled?: boolean;
}

export const ManagedSelect = (props: ManagedSelectProps) => {
  // BEM css convention namespace
  const BEM_NS = props.namespace ?? 'managed-select';

  return (
    <label className={bemErrorHelper(BEM_NS, props.isValid)}>
      <span className={bemErrorHelper(`${BEM_NS}__copy`, props.isValid)}>
        {props.label}
      </span>

      <span className={bemErrorHelper(`${BEM_NS}__select-wrap`, props.isValid)}>
        <select
          className={bemErrorHelper(`${BEM_NS}__select`, props.isValid)}
          onChange={(e) => {
            const event = e as React.ChangeEvent<HTMLSelectElement>;
            //@ts-ignore @todo clean this up
            props.onChange(event.target.value);
            if (props.getSelectedIndex && props.index) {
              props.getSelectedIndex(props.index);
            }
          }}
          placeholder={props.placeholder}
          name={props.name}
          value={props.value ?? props.placeholder}
          disabled={props.disabled}
        >
          <ManagedOptions
            value={props.value}
            options={props.options}
            placeholder={props.placeholder}
          />
        </select>
      </span>
    </label>
  );
};

const ManagedOptions = (props: {
  options: Array<{ label: string; value: string }>;
  value?: string | null;
  placeholder?: string;
}) => {
  const hasSelectedValue = props.options.find(
    (option) => option.value === props.value
  );
  return (
    <>
      {!hasSelectedValue && (
        <option>{props.placeholder ?? 'Please select'}</option>
      )}
      {props.options.map((el, index) => (
        <option key={index} value={el.value}>
          {el.label}
        </option>
      ))}
    </>
  );
};
