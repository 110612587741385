import React, { useEffect } from 'react';
import { ACTIONS, FlattenModelField, ModelInstance } from 'models/Model';
import { useForceUpdate } from 'utils';
import Field from './Field';
import { FeaturesPlaceholder } from 'sections/orders';

type FieldGroupProps<T> = {
  model: ModelInstance<T>;
  fields?: string;
  exclude?: string;
  disabled?: string | boolean;
  readonly?: string | boolean;
  className?: string;
  placeholder?: JSX.Element;
};
export default function FieldGroup<T>({
  model,
  fields: selected = '',
  exclude,
  disabled = false,
  readonly = false,
  className,
}: FieldGroupProps<T>): React.ReactElement {
  const forceUpdate = useForceUpdate();
  const id = Date.now().toString();
  const isListed = (prop: string, list: string) =>
    list.split(' ').some((f) => [prop, prop.split('.')[0]].includes(f));
  const fullfills = (prop: string, attr: string | boolean) =>
    !!attr && typeof attr === 'string' ? isListed(prop, attr) : !!attr;

  const fieldsForSection = model.fields.filter(
    ({ prop }) =>
      (!selected || isListed(prop, selected)) &&
      (!exclude || !isListed(prop, exclude))
  );
  useEffect(() => {
    // trigger UI update upon model validation (to show any error feedback)
    model.onChange(ACTIONS.VALIDATE(selected), forceUpdate, selected);
  }, [model, selected, forceUpdate]);

  if (!fieldsForSection.length) {
    switch (selected) {
      case 'features':
        return <FeaturesPlaceholder />;
      default:
        return <></>;
    }
  }

  return (
    <div className={className}>
      {fieldsForSection.map((field, i) => {
        // @ts-ignore
        if (field.isSection) {
          return (
            <section key={i}>
              <h3>{field.label}</h3>
              <FieldGroup
                {...{
                  model,
                  fields: field.prop + '.',
                  exclude,
                  disabled,
                  className,
                }}
              />
            </section>
          );
        }

        const isDisabled = fullfills(field.prop, disabled);
        const isReadonly = fullfills(field.prop, readonly);

        return (
          <Field
            key={i}
            groupId={id}
            model={model}
            field={field as FlattenModelField}
            disabled={isDisabled}
            readonly={isReadonly}
          />
        );
      })}
    </div>
  );
}
