import React from 'react';

export const Checkbox = (props: {
  name: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  labelEl: JSX.Element;
}) => (
  <div className="ui checkbox ">
    <input
      type="checkbox"
      name={props.name}
      checked={props.checked}
      onChange={props.onChange}
    />
    {props.labelEl}
  </div>
);
