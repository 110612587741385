import React, { useState } from 'react';
import '../components/orders/Forms/Partials/Spec/SpecForm.scss';

type ManagedFieldOnChange = React.Dispatch<React.SetStateAction<boolean>>;

interface CollapsibleProps {
  title: string;
  children: JSX.Element | JSX.Element[];
}

/**
 * @param props
 * @constructor
 */
export const Collapsible = (props: CollapsibleProps) => {
  const [collapse, setCollapse] = useState(true);

  const toggleCollapse = () => {
    setCollapse(!collapse);
  };

  return (
    <>
      <div
        className={
          collapse ? 'spec-form__title-collapsed' : 'spec-form__title-active'
        }
        onClick={toggleCollapse}
      >
        {collapse ? (
          <i className="caret right icon" />
        ) : (
          <i className="caret down icon" />
        )}
        {props?.title}
      </div>
      <div style={{ display: collapse ? 'none' : 'block' }}>
        {props?.children}
      </div>
    </>
  );
};
