import { API_URL, tryRes } from 'services/APIService';
import { bindMethodsToSelf, delay } from 'utils';
import { MOCK_SERVICE } from 'config';
import { User } from 'models';
import { user } from 'dummyData';

class UserMockService {
  _data: User;

  constructor(data: User) {
    this._data = data;
    bindMethodsToSelf(this);
  }

  async get(): Promise<User> {
    return delay(this._data);
  }

  async getById(): Promise<User> {
    return delay(this._data);
  }

  async set(el: Partial<User>): Promise<User> {
    return { ...this._data, ...el };
  }
}

class UserService {
  _endpoint: string;

  constructor(endpoint: string) {
    this._endpoint = API_URL + endpoint;
    bindMethodsToSelf(this);
  }

  async get(): Promise<User> {
    const res = await fetch(this._endpoint);
    return tryRes<User>(res);
  }

  async getById(id: string) {
    const res = await fetch(`${API_URL}users/${id}`);
    if (res.status === 400) {
      return undefined;
    }
    return tryRes<User>(res);
  }

  async set(el: Partial<User>) {
    const res = await fetch(this._endpoint, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(el),
    });
    return tryRes<User>(res);
  }
}

const service = MOCK_SERVICE.user
  ? new UserMockService(user)
  : new UserService('users/me');

export default service;
