import { FlattenModelField, ModelField } from '../../models/Model';
import React from 'react';
import { FieldType } from './MutatableField';

interface ReadonlyFieldProps {
  labelEl: JSX.Element;
  type: FieldType;
  field: ModelField & { prop: string };
  currentValue: string;
  displayValue: string;
}

export const ReadonlyField = (props: ReadonlyFieldProps) => (
  <div className="readonly field">
    {props.labelEl}
    {props.type === 'grid' && (
      <div
        className="swatch"
        style={{
          backgroundImage: `url(${
            (props.field as FlattenModelField).baseUrl + props.currentValue
          })`,
        }}
      />
    )}
    <div className="value">{props.displayValue}</div>
  </div>
);
