import { is, logger } from 'utils';

export const DEFAULT_FIELD_VALUE = {
  text: '',
  email: '',
  tel: '',
  textarea: '',
  date: '',
  number: 0,
  range: 0,
  toggle: false,
  check: false,
  select: '',
  radio: '',
  grid: '',
  'scanner image': '',
  'no image': '',
  notes: '',
};

const TASK_QUEUE_LIMIT = 100; // task qty
const TASK_QUEUE_DELAY = 10; // ms

type SchemaTypeMap = {
  [key in keyof typeof DEFAULT_FIELD_VALUE]: typeof DEFAULT_FIELD_VALUE[key];
};

export type ModelField = {
  type: keyof SchemaTypeMap;
  label: string;
  required?: boolean;
  activated?: boolean;
  activatedBy?: {
    [field: string]: boolean | number | string | number[] | string[];
  }; // map of field:value(s)
  filteredBy?: string | string[]; // pipe delimited string or string array
  values?: object | string | string[]; // enum object or pipe delimited string or string array
  fixedValues?: object | string | string[]; // same as above
  defaultValue?: string | number;
  nullValue?: string;
  valueDisplay?: (value: any) => string;
  min?: number;
  max?: number;
  step?: number;
  baseUrl?: string;
  showLabels?: boolean;
  placeholder?: string;
  html?: boolean;
  // for internal use (not part of schema)
  isValid?: boolean;
  isActive?: boolean;
  isNull?: boolean;
  url?: string;
  notes?: string;
};
export type ModelNamespace = object & { label?: string; isSection?: boolean };
const NS_PROPS = 'label isSection'.split(' ');
export type ModelSchema = { [prop: string]: ModelField | ModelNamespace };

export type FlattenModelField = ModelField & { prop: string };
export type FlattenModelNamespace = ModelNamespace & { prop: string };
export type FlattenModelSchema = Array<
  FlattenModelField | FlattenModelNamespace
>;

export type PlainObject = {
  [key: string]: any;
};

// ts arcane spell ✨🧙✨
export type DataFromSchema<T extends PlainObject> = {
  [K in keyof T]: T[K] extends ModelField
    ? SchemaTypeMap[T[K]['type']]
    : DataFromSchema<T[K]>;
};

type ModelHandler<T> = ((model: ModelInstance<T>) => void) & { id?: string };
type ModelTask<T> = [string, ModelHandler<T>[]];
type ModelTaskQueue<T> = ModelTask<T>[] & { interval?: any };

export type ModelInstance<T extends PlainObject> = {
  data: T;
  fields: FlattenModelSchema;
  _subscribers: Map<string, ModelHandler<T>[]>;
  _queue: ModelTaskQueue<T>;
  get: (prop: string) => any;
  set: (prop: string, value: any, id?: string) => void;

  /**
   * Checks if required fields have values.
   * Passing a path checks only that path.
   * Passing no path checks the whole model.
   */
  isValid: (path?: string) => boolean;

  /**
   * Registers handlers for changes on the model on partial paths (ie namespaces) or full paths (ie fields).
   * Paths can be delimited by spaces or can be an array.
   * Passing an empty path triggers the handler upon any change to the model (ie registers a global handler).
   * Passing an id ensures the handler is registered only once.
   * */
  onChange: (
    path: string | string[],
    handlers: ModelHandler<T>,
    id?: string
  ) => void;

  /**
   * Programatically triggers handlers registered on the model.
   * Passing a path triggers handlers from that level up to the root (ie 'foo.bar' triggers also 'foo' handlers).
   * Passing no path triggers all registered handlers.
   * Passing an id triggers only handlers matching that id.
   * */
  trigger: (path?: string, id?: string) => void;
};

export type Model<T extends PlainObject> = {
  (data: Partial<T>): ModelInstance<T>;
  fields: FlattenModelSchema;
};

export function createModel<T extends PlainObject>(
  schema: ModelSchema
): Model<T> {
  const [fields, emptyData] = flattenSchema();

  function flattenSchema(
    node = { ...schema },
    keychain = [] as string[],
    fields = [] as FlattenModelSchema,
    data = {} as any
  ): [FlattenModelSchema, T] {
    for (const [key, val] of Object.entries(node)) {
      if (!val) continue;
      // @ts-ignore
      if (val.type) {
        // sub-node is a field
        // initialize data key as value and register field with key path
        data[key] = DEFAULT_FIELD_VALUE[(val as ModelField).type];
        fields.push({
          prop: [...keychain, key].join('.'),
          ...(val as ModelField),
        });
      } else {
        // sub-node is a namespace
        // initialize data key as empty object and keep traversing
        data[key] = {};
        if ((val as ModelNamespace).isSection) {
          // add section label to data and register section
          if (val.label) data[key].label = val.label;
          fields.push({
            prop: [...keychain, key].join('.'),
            label: val.label || '',
            isSection: true,
          });
        }
        // delete any namespace-specific props before recursing to avoid loop
        NS_PROPS.map((prop) => delete (val as any)[prop]);
        flattenSchema(
          val as ModelSchema,
          [...keychain, key],
          fields,
          data[key]
        );
      }
    }
    return [fields, data];
  }

  function wrap(data: Partial<T>) {
    data = { ...emptyData, ...data };

    const model = {
      fields,
      data,
      _subscribers: new Map(),
      _queue: [] as ModelTaskQueue<T>,
    } as ModelInstance<T>;

    model.get = (path) => {
      const field = fields.find((f) => f.prop === path);
      if (!field || !(field as FlattenModelField).type) {
        logger.warn(`Tried to get field value for invalid path '${path}'`);
        return null;
      }

      const props = path.split('.');
      let node = data as any;
      let prop = props.shift();
      while (prop) {
        try {
          node = node[prop];
          prop = props.shift();
        } catch (err) {
          // previous node is missing
          return null;
        }
      }
      return node; // || DEFAULT_FIELD_VALUE[fields.find(f => f.prop === path).type]
    };

    model.set = (path, value, id) => {
      const field = fields.find((f) => f.prop === path);
      if (!field || !(field as FlattenModelField).type) {
        logger.warn(`Tried to set field value for invalid path '${path}'`);
        return;
      }

      const props = path.split('.');
      let node = data as any;
      let prop = props.shift();
      while (prop && props.length) {
        if (!node[prop]) node[prop] = {};
        node = node[prop];
        prop = props.shift();
      }
      // if value is the 'nullValue', set the node value to null
      // and flag the field so the FieldGroup shows the 'ghost' value
      // (yes, this is hacky... suggestions are welcome n_n)
      const isNull = value === (field as FlattenModelField).nullValue;
      node[prop as string] = isNull ? null : value;
      (field as FlattenModelField).isNull = isNull;
      // trigger any registered handlers
      model.trigger(path, id);
    };

    model.onChange = (paths, handler, id) => {
      const pathString = is.array(paths)
        ? (paths as string[]).join('|')
        : (paths as string);
      handler.id = id;
      const subs = model._subscribers;
      subs.set(pathString, (subs.get(pathString) || []).concat([handler]));
      return () => {
        // surgical extraction
        const handlers = subs.get(pathString);
        const idx = handlers && handlers.findIndex((h) => h.id === id);
        is.number(idx) && (idx as number) > -1
          ? // @ts-ignore
            subs.set(pathString, handlers.splice(idx, 1))
          : logger.warn('Could not unregister handler, not found');
      };
    };

    model.trigger = (triggerpath, id) => {
      const qlen = model._queue.length;
      if (qlen >= TASK_QUEUE_LIMIT) {
        logger.warn('Task queue limit reached, ignoring trigger');
        return;
      }
      const subs = model._subscribers;
      Array.from(subs).map(([paths, handlers]) => {
        if (
          !triggerpath || // global trigger
          !paths || // global handler
          paths
            .split('|')
            .some(
              (p) => p === triggerpath || triggerpath.split('.').includes(p)
            )
        ) {
          if (id) handlers = handlers.filter((h) => h.id?.includes(id));
          if (handlers.length) model._queue.push([paths, handlers]);
        }
      });

      if (!model._queue.interval && model._queue.length) {
        logger.info('Task queue starting');
        model._queue.interval = setInterval(() => {
          logger.info(
            '| Pending tasks: ',
            model._queue.map((t) => t[0].toString())
          );
          if (model._queue.length) {
            const task = model._queue.shift() as ModelTask<T>;
            task[1].map((h) => h(model));
          } else {
            logger.info('Task queue cleared');
            clearInterval(model._queue.interval);
            delete model._queue.interval;
          }
        }, TASK_QUEUE_DELAY);
      }
    };

    model.isValid = (path) => {
      const isValid = (fields as FlattenModelField[])
        // @ts-ignore
        .filter(
          ({ prop, required, activatedBy, isActive }) =>
            (!path || prop.includes(path)) &&
            (!activatedBy || isActive) &&
            required
        )
        .map((field) => {
          // @ts-ignore
          field.isValid = isFieldValid(field, model);
          return field;
        })
        // @ts-ignore
        .every((field) => field.isValid);
      // trigger UI update (ie for FieldGroup)
      model.trigger(ACTIONS.VALIDATE(path));
      return isValid;
    };

    return model;
  }

  wrap.fields = fields;
  return wrap;
}

export function genericModel<T extends PlainObject>(data = [] as T[]) {
  const fields = Object.keys(data[0] || {}).map((prop) => ({
    prop,
    label: prop,
    type: 'text',
  }));
  const wrap = (el: T) =>
    ({
      fields,
      data: el,
      get: (prop: string) => el[prop],
      set: (prop: string, value: any) => {
        (el as any)[prop] = value;
      },
      isValid: () => true,
    } as ModelInstance<T>);
  wrap.fields = fields;
  return wrap as Model<T>;
}

const isFieldValid = (
  field: FlattenModelField,
  model: ModelInstance<any>
): boolean => {
  const doesFieldAllowNull = field.isNull;
  const value = model.get(field.prop);
  const isValueTruthy = !!value;
  const isValueNotZero = value !== '0';
  return doesFieldAllowNull || (isValueTruthy && isValueNotZero);
};

export const ACTIONS = {
  VALIDATE: (path?: string) => `[MODEL:VALIDATE:${path || 'GLOBAL'}]`,
};
