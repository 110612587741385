import { APIService, tryRes } from 'services';
import { Feature, Project, Resource } from 'models';
import { ServerOrder } from '../../models/Order';
import { NEW_PROJECT_ID_STRING } from './ProjectDetail';
import { DealerProjects } from 'models/OneToManyDealer';

class ProjectService extends APIService<Project> {
  async getAllScans() {
    const response = await fetch(`${this._endpoint}/scans`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });

    const json = await tryRes<Project[]>(response);

    const formattedData = json.map((el) => {
      // Get scan time for each project with existing scans
      let scanTime = '';
      if (el?.scans && el.scans.length > 0) {
        const scans = el.scans;
        // If project is a single scan, confirm both dates exist
        if (scans.length === 1 && scans[0]?.startTime && scans[0]?.endTime) {
          const difference =
            new Date(scans[0]?.endTime).getTime() -
            new Date(scans[0]?.startTime).getTime();
          const seconds = Math.floor(difference / 1000);
          // Return time in hh:mm:ss format
          scanTime = new Date(seconds * 1000).toISOString().slice(11, 19);
        } else if (scans.length > 1) {
          let seconds = 0;
          scans.forEach((scan) => {
            // If project is multi scan, confirm both dates exist
            if (scan?.startTime && scan?.endTime) {
              const difference =
                new Date(scan.endTime).getTime() -
                new Date(scan.startTime).getTime();
              // Add time of each scan together
              seconds += Math.floor(difference / 1000);
            }
            // Return time in hh:mm:ss format
            scanTime = new Date(seconds * 1000).toISOString().slice(11, 19);
          });
        }
      }
      return { ...el, scanTime };
    });
    return formattedData;
  }

  async upload(
    projectId: string,
    fileName: string,
    data: string,
    mimeType = ''
  ) {
    // upload the base64 data for the resource
    // (the server creates and returns the resource metadata)
    const res = await fetch(`${this._endpoint}/${projectId}/media`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        mimetype: mimeType,
        fileName,
      },
      body: JSON.stringify({ data: data.split(',')[1] }),
    });

    const resource = await tryRes<Resource>(res);

    return resource.id;
  }

  async getOrders(projectId: string) {
    const response = await fetch(`${this._endpoint}/${projectId}/orders`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    return tryRes<ServerOrder[]>(response);
  }

  async getOrdersLathamSchema(projectId: string) {
    const response = await fetch(
      `${this._endpoint}/${projectId}/orders/latham_spec`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
    );
    return tryRes<ServerOrder>(response);
  }

  async getImages(projectId: string, width?: number) {
    if (projectId === NEW_PROJECT_ID_STRING) {
      return Promise.resolve([]);
    }
    const response = await fetch(
      `${this._endpoint}/${projectId}/resources/imageurls/${width ?? ''}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
    );
    return tryRes<string[]>(response);
  }

  async submitOrder(projectId: string, orderId: string) {
    const response = await fetch(
      `${this._endpoint}/${projectId}/submit/${orderId}`
    );
    return tryRes<{ url: string }>(response);
  }

  async deleteOrder(projectId: string, orderId: string) {
    await fetch(`${this._endpoint}/${projectId}/orders/${orderId}`, {
      method: 'DELETE',
    });
  }

  async cancelQuote(projectId: string, orderId: string) {
    await fetch(`${this._endpoint}/${projectId}/orders/${orderId}/quote`, {
      method: 'DELETE',
    });
  }

  async getFeatures(projectId: string) {
    const response = await fetch(`${this._endpoint}/${projectId}/features`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });

    return await tryRes<Feature[]>(response);
  }

  async generateDXF(projectId: string) {
    const response = await fetch(
      `${this._endpoint}/${projectId}/generate_dxf`,
      {
        method: 'POST',
      }
    );
    return await tryRes<any>(response);
  }

  async downloadAllResources(projectId: string) {
    const response = await fetch(
      `${this._endpoint}/${projectId}/resources/all`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/octet-stream' },
      }
    );

    return response;
  }

  async getBase64Image(resourceId: string) {
    const response = await fetch(
      `/portal/api/v1/resources/${resourceId}/base64raw`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/octet-stream' },
      }
    );

    return await response.text();
  }

  async submit(projectId: string) {
    const response = await fetch(`${this._endpoint}/${projectId}/submit`);
    return tryRes<{ url: string }>(response);
  }

  async submitOrderForQuote(projectId: string, orderId: string) {
    const response = await fetch(
      `${this._endpoint}/${projectId}/orders/${orderId}/quote`
    );
    return tryRes<unknown>(response);
  }

  async submitQuotedOrder(projectId: string, orderId: string) {
    const response = await fetch(
      `${this._endpoint}/${projectId}/orders/${orderId}/submit`
    );
    return tryRes<unknown>(response);
  }

  async submitQuoteForReview(projectId: string, orderId: string) {
    const response = await fetch(
      `${this._endpoint}/${projectId}/quote/${orderId}/review`
    );
    return tryRes<unknown>(response);
  }

  async getProjectDealers() {
    const response = await fetch(`${this._endpoint}/oneToMany`);
    return tryRes<DealerProjects[]>(response);
  }
}

const service = new ProjectService('projects');

export default service;
