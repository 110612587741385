import { projectOrderState } from 'context/OrderContext';
import { FeatureType, OrderType } from 'models';
import { coverFeatures, linerFeatures } from 'models/Feature';
import { FeatureMeasurements } from 'models/Project';

export const ANCHOR_NA = 'Not Applicable';
export const ANCHOR_SS_FLUSH = 'SS Flush Wall Anchor';
export const ANCHOR_EYE_BOLTS = 'Eye Bolts Wall Anchor';
export const ANCHOR_2_INCH_THUNDER = '2” Thunder Bolt';
export const ANCHOR_6_INCH_THUNDER = '6” Thunder Bolt';

export const YES = 'Yes';
export const NO = 'No';

export const Bead = 'Bead';
export const Flap = 'Flap';
export const HookAndLoop = 'Hook & Loop';
export const RodPocket = 'Rod pocket';
export const SideBead = 'Side bead';
export const None = 'None';

export const select = 'Please select';

export const ChooseLaterString = 'Quote all options';
export const SameAsFloor = 'Same as Floor Material';
export const SameAsBottom = 'SameAsBottom';
export const Textured = 'Textured';
export const Other = 'Other';
export const MaterialUpgrade = 'Material Upgrade to 27';

export const beadAllTread = 'Back & side walls';
export const beadBackWall = 'Back wall only';
export const beadBackAndCorners = 'Back wall & corners';
export const beadBackAndLeft = 'Back wall & left side';
export const beadBackAndRight = 'Back wall & right side';

const removableFeatures = [
  FeatureType.DivingBoard,
  FeatureType.DivingObject,
  FeatureType.Handrail,
  FeatureType.Planter,
  FeatureType.Slide,
];

const hiddenOptionFeatures = [
  FeatureType.AutoCover,
  FeatureType.DivingBoard,
  FeatureType.Handrail,
  FeatureType.Steps,
];

export const isFeatureRemovable = (type: FeatureType) => {
  return removableFeatures.some((val) => {
    return type === val;
  });
};

export const isLinerFeature = (type: FeatureType) => {
  return linerFeatures.some((val) => {
    return type === val;
  });
};

export const getFeatureType = (el: string) => {
  for (const enumValue in FeatureType) {
    const idx = Object.keys(FeatureType).indexOf(enumValue);
    const value = Object.values(FeatureType)[idx];
    if (el && el.toLowerCase().includes(value.toLowerCase())) {
      return value;
    }
  }
  return '';
};

export const checkFeatureOrderType = (
  featureType: FeatureType,
  orderType: string
) => {
  if (orderType === OrderType.Liner) {
    return linerFeatures.some((val) => {
      return val === featureType;
    });
  } else if (orderType === OrderType.Cover) {
    return coverFeatures.some((val) => {
      return val === featureType;
    });
  }
};

export const isOptionsHidden = (type: FeatureType) => {
  return hiddenOptionFeatures.some((val) => {
    return type === val;
  });
};

export const featureInOverlap = (
  isCutout: boolean,
  project: projectOrderState,
  measurement?: FeatureMeasurements
) => {
  // If feature is cutout type (right now just diving board features), check if it is in overlap
  if (measurement && isCutout) {
    switch (project?.coverOverlap) {
      case 12:
        return (
          measurement?.deckingProperties[0]?.numPoints > 0 &&
          measurement?.deckingProperties[0]?.lengthAlongPerimeterIn > 0
        );
      case 18:
        return (
          (measurement?.deckingProperties[0]?.numPoints > 0 &&
            measurement?.deckingProperties[0]?.lengthAlongPerimeterIn > 0) ||
          (measurement?.deckingProperties[1]?.numPoints > 0 &&
            measurement?.deckingProperties[1]?.lengthAlongPerimeterIn > 0)
        );
      case 24:
        return (
          (measurement?.deckingProperties[0]?.numPoints > 0 &&
            measurement?.deckingProperties[0]?.lengthAlongPerimeterIn > 0) ||
          (measurement?.deckingProperties[1]?.numPoints > 0 &&
            measurement?.deckingProperties[1]?.lengthAlongPerimeterIn > 0) ||
          (measurement?.deckingProperties[2]?.numPoints > 0 &&
            measurement?.deckingProperties[2]?.lengthAlongPerimeterIn > 0)
        );
      default:
        return false;
    }
  }
  return false;
};

export const removableTypeOptions = [
  { label: YES, value: YES },
  { label: NO, value: NO },
];

export const anchorTypeOptions = [
  {
    label: ANCHOR_NA,
    value: ANCHOR_NA,
  },
  {
    label: ANCHOR_SS_FLUSH,
    value: ANCHOR_SS_FLUSH,
  },
  {
    label: ANCHOR_2_INCH_THUNDER,
    value: ANCHOR_2_INCH_THUNDER,
  },
  {
    label: ANCHOR_6_INCH_THUNDER,
    value: ANCHOR_6_INCH_THUNDER,
  },
];

export const linerStepFastenerOptions = [
  { label: Bead, value: Bead },
  { label: Flap, value: Flap },
  { label: HookAndLoop, value: HookAndLoop },
  { label: RodPocket, value: RodPocket },
  { label: SideBead, value: SideBead },
  { label: None, value: None },
];

export const linerStepMaterialOptions = [
  { label: Textured, value: Textured },
  { label: SameAsFloor, value: SameAsFloor },
  { label: ChooseLaterString, value: ChooseLaterString },
];

export const stepStripeOptions = [
  { label: YES, value: YES },
  { label: NO, value: NO },
];

export const differentTreadOptions = [
  { label: YES, value: YES },
  { label: NO, value: NO },
];

export const beadOptions = [
  { label: beadAllTread, value: beadAllTread },
  { label: beadBackWall, value: beadBackWall },
  { label: beadBackAndCorners, value: beadBackAndCorners },
  { label: beadBackAndLeft, value: beadBackAndLeft },
  { label: beadBackAndRight, value: beadBackAndRight },
];
