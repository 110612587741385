import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { TransitionGroup } from '.';

const TOAST_DELAY = 5000; // ms

export default function Toast({
  trigger,
  delay = TOAST_DELAY,
  children,
}: {
  trigger: object & { msg?: string };
  delay?: number;
  children?: ReactNode;
}): ReactElement {
  const [active, setActive] = useState(false);

  useEffect(() => {
    trigger?.msg ? setActive(true) : setActive(false);
    const timeout = setTimeout(() => {
      setActive(false);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [trigger, delay]);

  return (
    <TransitionGroup isVisible={active}>
      <div className="toast">{children}</div>
    </TransitionGroup>
  );
}
