import APIMockService from './APIMockService';
import { bindMethodsToSelf } from 'utils';

export const API_URL = '/portal/api/v1/';

export type APIEntity<T> = T & { id: string };

export interface APIResponse<T> {
  data: T[];
  metadata: {
    count: number;
    currentPage: number;
    totalCount: number;
    totalPage: number;
  };
}

export interface IAPIMethods<T> {
  getAll(sync?: boolean): Promise<APIEntity<T>[]>;
  get(id: string, force?: boolean): Promise<APIEntity<T> | undefined>;
  set(el: APIEntity<T>): Promise<APIEntity<T>>;
  add(el: T): Promise<APIEntity<T>>;
  del(id: string): Promise<any>;
}

export interface IAPIService<T> extends IAPIMethods<T> {
  _endpoint: string;
  _mock: APIMockService<T>;
}

export async function tryRes<T>(res: Response): Promise<T> {
  try {
    if (res.ok) return res.json();
    else throw await res.json();
  } catch (err) {
    throw typeof err === 'object' ? err : new Error(await res.json());
  }
}

export default class APIService<T> implements IAPIService<T> {
  _endpoint: string;
  _mock = new APIMockService<T>([]); // used for caching data locally

  constructor(endpoint: string, baseUrl = API_URL) {
    this._endpoint = baseUrl + endpoint;
    bindMethodsToSelf(this);
  }

  async getAll(sync = false) {
    if (sync || !this._mock._data.length) {
      const res = await fetch(this._endpoint);
      const json = await tryRes<APIResponse<APIEntity<T>>>(res);
      this._mock._data = json.data;
    }
    return Promise.resolve(this._mock._data);
  }

  async get(id: string, force = false) {
    const el = this._mock._data.find((p) => p.id === id);
    if (force || !el) {
      const res = await fetch(this._endpoint + '/' + id);
      return tryRes<APIEntity<T>>(res);
    } else {
      return Promise.resolve({ ...el });
    }
  }

  async set(
    el: APIEntity<T>,
    method = 'PUT' as 'PUT' | 'PATCH',
    parseResponse = true
  ) {
    const res = await fetch(this._endpoint + '/' + el.id, {
      method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(el),
    });
    try {
      // the API does not always return the updated entity representation
      // so we can use this flag to skip trying to parse the server response
      if (parseResponse) {
        const ret = await tryRes<APIEntity<T>>(res);
        // if server action went ok, sync local cache
        this._mock.set(ret);
        return ret;
      } else if (res.ok) {
        // if server action went ok, sync local cache
        this._mock.set(el);
        return el;
      } else {
        throw await res.json();
      }
    } catch (err) {
      throw typeof err === 'object' ? err : new Error(await res.json());
    }
  }

  async add(el: T, method = 'POST' as 'POST' | 'PUT') {
    const res = await fetch(this._endpoint, {
      method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(el),
    });
    const ret = await tryRes<APIEntity<T>>(res);
    // if server action went ok, sync local cache
    this._mock.add(ret);
    return ret;
  }

  async del(id: string) {
    const res = await fetch(this._endpoint + '/' + id, { method: 'DELETE' });

    try {
      if (res.ok) {
        // if server action went ok, sync local cache
        this._mock.del(id);
      } else throw await res.json();
    } catch (err) {
      throw typeof err === 'object' ? err : new Error(await res.json());
    }
  }
}
