import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Indexable } from '../types';

export interface ImageGridItem {
  label: string;
  url: string;
  value: string;
}

export interface ImageGridProps {
  items: Indexable<ImageGridItem>[];
  label?: string;
  imgProp?: string;
  labelProp?: string;
  placeholder?: string;
  maxHeight?: string;
  className?: string;
  value?: string;
  invalid?: boolean;
  onSelect?: (item: ImageGridItem) => void;
}

export default function ImageGrid({
  items,
  label,
  imgProp = 'url',
  labelProp = 'label',
  placeholder,
  maxHeight = 'auto',
  className = '',
  value,
  invalid,
  onSelect,
}: ImageGridProps): ReactElement {
  const initialItem = items.findIndex((it) => it.value === value);
  const [selected, setSelected] = useState(initialItem);

  useEffect(() => {
    // keep selected item in sync with value
    setSelected(initialItem);
  }, [initialItem]);

  return (
    <div
      className={'image-grid ' + (invalid ? 'invalid ' : '') + className}
      data-cy="fabricColorSelector"
    >
      {label && <label>{label}</label>}
      <div className="content" style={{ maxHeight }}>
        {items.length ? (
          <div className="grid">
            {items.map((item, i) => (
              <div key={i} className="flex flex-col">
                <div
                  className={
                    'swatch' +
                    (onSelect ? ' pointer' : '') +
                    (i === selected ? ' selected' : '')
                  }
                  style={{ backgroundImage: `url(${item[imgProp]})` }}
                  onClick={() => {
                    if (onSelect) {
                      onSelect(item);
                      setSelected(i);
                    }
                  }}
                  data-cy={`colorSwatch_${item[labelProp]}`}
                  data-testid={item.value}
                />
                {labelProp && <label>{item[labelProp] as ReactNode}</label>}
              </div>
            ))}
          </div>
        ) : (
          <div className="placeholder">{placeholder}</div>
        )}
      </div>
    </div>
  );
}
