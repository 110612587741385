import { Icon, Loader } from 'semantic-ui-react';
import React from 'react';

export const UploadImageButton = (props: {
  dragging: boolean;
  onClick: () => void;
  loading: boolean;
  uploading: boolean;
  disabled: boolean | undefined;
  dragProps: {
    onDrop: (e: any) => void;
    onDragEnter: (e: any) => void;
    onDragLeave: (e: any) => void;
    onDragOver: (e: any) => void;
    onDragStart: (e: any) => void;
  };
}) => {
  return (
    <button
      style={{
        color: props.dragging ? 'red' : undefined,
        cursor: props.loading || props.disabled ? 'default' : 'pointer',
      }}
      className="drop-target"
      onClick={props.onClick}
      disabled={props.loading || props.disabled}
      {...props.dragProps}
    >
      {props.loading || props.uploading ? (
        <Loader
          active
          inline
          as="pre"
          style={{ fontFamily: 'inherit' }}
          content={`${
            props.uploading ? 'Uploading' : 'Loading'
          } resources,\n please wait...`}
        />
      ) : (
        <>
          <Icon size="big" name="cloud upload" data-cy="dropzone" />
          <div style={{ fontFamily: 'inherit', margin: '5px' }}>
            {props.disabled
              ? 'Toggle Edit Project'
              : 'Click or drop your picture here!'}
          </div>
        </>
      )}
    </button>
  );
};
