import { APIService, tryRes } from 'services';
import { Employee } from 'models';

class EmployeeService extends APIService<Employee> {
  async getDealersById(dealerId: string): Promise<Employee[]> {
    const response = await fetch(`${this._endpoint}/dealer/${dealerId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    return tryRes<Employee[]>(response);
  }

  async getEmails(): Promise<string[]> {
    const res = await fetch(`${this._endpoint}/emails`);
    return tryRes<string[]>(res);
  }
}

export default new EmployeeService('employees');
