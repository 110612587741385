import React from 'react';
import { _bemHelper, bemErrorHelper } from '../FieldHelpers';
import { ErrorField } from '../ErrorField/ErrorField';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Mousewheel } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import './ManagedCarouselImagePicker.scss';
import '../Select/ManagedSelect.scss';

interface MangedCarouselImagePickerProps<T> {
  isValid: boolean;
  label?: string;
  value?: any | null;
  displayText: (value: T) => string | undefined;
  onChange: (value: T | undefined) => void;
  namespace?: string;
  options: T[];
  imageUrl: (option: T) => string;
  errorMessage?: string | null;
  disabled?: boolean;
  isItemSelected: (value: any | undefined, option: T) => boolean;
  itemDataCy: (value: T) => string;
}

SwiperCore.use([Navigation, Pagination, Mousewheel]);

export const ManagedCarouselImagePicker = (
  props: MangedCarouselImagePickerProps<any>
) => {
  // BEM css convention namespace
  const BEM_NS = props.namespace ?? 'carousel-image-picker';

  const selectedClassName = _bemHelper(`${BEM_NS}__img`, 'selected');
  const defaultClassName = bemErrorHelper(`${BEM_NS}__img`);

  const defaultImageWrapperClassName = bemErrorHelper(
    `${BEM_NS}__img-label-wrap`
  );
  const selectedImageWrapperClassName = _bemHelper(
    `${BEM_NS}__img-label-wrap`,
    'selected'
  );

  const getInitalSlide = () => {
    if (!props.value) return 0;
    for (const { index, option } of props.options.map((option, index) => ({
      index,
      option,
    }))) {
      if (props.isItemSelected(props.value, option)) {
        return index;
      }
    }
    return 0;
  };

  return (
    <div className={bemErrorHelper(`${BEM_NS}__wrap`, props.isValid)}>
      <label className={bemErrorHelper(BEM_NS, props.isValid)}>
        <span className={bemErrorHelper(`${BEM_NS}__copy`, props.isValid)}>
          {props.label}
        </span>
        <ErrorField
          className="margin-bottom"
          errorMessage={props.errorMessage}
          valid={props.isValid}
        />
        <Swiper
          navigation={true}
          slidesPerView={3}
          spaceBetween={20}
          initialSlide={getInitalSlide()}
          allowTouchMove={false}
          mousewheel={{
            invert: false,
          }}
        >
          {props.options &&
            props.options.map((option, key) => (
              <SwiperSlide
                key={key}
                onClick={(e) => {
                  if (!props?.disabled) props.onChange(option);
                }}
              >
                <div className={bemErrorHelper(`${BEM_NS}__img-wrap`)}>
                  <div
                    className={
                      props.isItemSelected(props.value, option)
                        ? selectedImageWrapperClassName
                        : defaultImageWrapperClassName
                    }
                    data-cy={`${props.itemDataCy(option)}`}
                  >
                    <img
                      className={
                        props.isItemSelected(props.value, option)
                          ? selectedClassName
                          : defaultClassName
                      }
                      alt={props.displayText(option)}
                      src={props.imageUrl(option)}
                    />
                  </div>
                  <span className={bemErrorHelper(`${BEM_NS}__label`)}>
                    {props.displayText(option)}
                  </span>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </label>
    </div>
  );
};
