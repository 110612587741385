import { TransitionGroup } from 'components';
import React, { useEffect } from 'react';
import { Tab } from 'semantic-ui-react';
import { Dealer, Project, DealerProjects } from 'models';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import './Admin.scss';
import { Order } from 'models/Order';
import ProjectTab from './DataTabs/ProjectTab';
import OrderTab from './DataTabs/OrderTab';
import OneToManyTab from './DataTabs/OneToManyTab';
import OverviewTab from './DataTabs/OverviewTab';

interface AdminMeasureDataProps {
  projects: Project[];
  projectsWithDealer: DealerProjects[];
  dealers: Dealer[];
  orders: Order[];
  loaded: boolean;
  setLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AdminMeasureData(
  props: AdminMeasureDataProps
): React.ReactElement {
  const { projects, projectsWithDealer, dealers, orders, loaded, setLoaded } =
    props;

  // Filter out test dealer projects
  const dealersToRemove = [
    'R06c1de8',
    'R0000000',
    'R1234567',
    'R06c1de8',
    'R837bcef',
  ];
  const filteredProjects = projects.filter((proj) => {
    const rewardsId =
      dealers.find((dealer) => dealer.id === proj?.dealerId)?.rewardsId ?? '';
    return !dealersToRemove.includes(rewardsId);
  });

  const filterByCreatedDate = (createdDate: string, el: Project | Order) => {
    if (!createdDate) return true;
    const createdAt = new Date(el.createdAt);
    const [startDateStr, endDateStr] = createdDate.split('-');
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);
    endDate.setDate(endDate.getDate() + 1); // Adding one more day to include the selection in the range
    return startDate <= createdAt && createdAt <= endDate;
  };

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <div className="admin">
      <TransitionGroup isVisible={loaded}>
        <Tab
          menu={{ pointing: true }}
          className="tab-bar"
          panes={[
            {
              menuItem: 'Overview',
              render: () => (
                <Tab.Pane className="data-content" attached={false}>
                  {
                    <OverviewTab
                      projects={filteredProjects}
                      orders={orders}
                      loaded={loaded}
                      setLoaded={setLoaded}
                    />
                  }
                </Tab.Pane>
              ),
            },
            {
              menuItem: 'Projects',
              render: () => (
                <Tab.Pane className="data-content" attached={false}>
                  {
                    <ProjectTab
                      projects={filteredProjects}
                      dealers={dealers}
                      loaded={loaded}
                      filterByCreatedDate={filterByCreatedDate}
                      setLoaded={setLoaded}
                    />
                  }
                </Tab.Pane>
              ),
            },
            {
              menuItem: 'Dealer Project Stats',
              render: () => (
                <Tab.Pane className="data-content" attached={false}>
                  {
                    <OneToManyTab
                      projects={filteredProjects}
                      projectsWithDealer={projectsWithDealer}
                      dealers={dealers}
                      loaded={loaded}
                      setLoaded={setLoaded}
                    />
                  }
                </Tab.Pane>
              ),
            },
            {
              menuItem: 'Quotes/Orders',
              render: () => (
                <Tab.Pane className="data-content" attached={false}>
                  {
                    <OrderTab
                      projects={filteredProjects}
                      dealers={dealers}
                      orders={orders}
                      loaded={loaded}
                      filterByCreatedDate={filterByCreatedDate}
                      setLoaded={setLoaded}
                    />
                  }
                </Tab.Pane>
              ),
            },
          ]}
        />
      </TransitionGroup>
    </div>
  );
}
