import { Project } from 'models';
import { Order, OrderStatus } from 'models/Order';

export interface ProjectStats {
  quoteCount: number;
  orderCount: number;
  remainderProjectCount: number;
  projectCount: number;
  month: string;
}

export interface ProjectStatsTotal {
  name: string;
  value: number;
}

export interface Result {
  monthCount: ProjectStats[];
  totalCount: ProjectStatsTotal[];
}

export const getSum = (map: Map<string, number>) => {
  let sum = 0;

  map.forEach((v) => {
    sum += v;
  });

  return sum;
};

export const getMonthlyQuoteCount = (projects: Project[], orders: Order[]) => {
  // Get quotes and orders from projects
  const projQuotes = orders.filter(
    (q) =>
      q?.status === OrderStatus.QuoteInReview ||
      q?.status === OrderStatus.ReadyForOrder ||
      q?.status === OrderStatus.Ordered
  );
  const projOrders = orders.filter((o) => o?.status === OrderStatus.Ordered);
  const projRemainder = projects.filter((proj) => proj.orders?.length === 0);

  // Group quotes, orders, remainder projects, and projects by month and initialize counts to zero
  const quoteCountByMonth: Map<string, number> = new Map();
  const orderCountByMonth: Map<string, number> = new Map();
  const projectCountByMonth: Map<string, number> = new Map();
  const remainderProjectCountByMonth: Map<string, number> = new Map();
  const checkedQuoteIds: string[] = [];
  const checkedOrderIds: string[] = [];
  projQuotes.forEach((quote) => {
    if (quote) {
      if (!checkedQuoteIds.includes(quote.projectId)) {
        checkedQuoteIds.push(quote.projectId);
        const quoteDate = new Date(quote.updatedAt);
        const monthYearKey = `${
          quoteDate.getMonth() + 1
        }-${quoteDate.getFullYear()}`;
        quoteCountByMonth.set(
          monthYearKey,
          (quoteCountByMonth.get(monthYearKey) || 0) + 1
        );
      }
    }
  });
  projOrders.forEach((order) => {
    if (order) {
      if (!checkedOrderIds.includes(order.projectId)) {
        checkedOrderIds.push(order.projectId);
        const orderDate = new Date(order.updatedAt);
        const monthYearKey = `${
          orderDate.getMonth() + 1
        }-${orderDate.getFullYear()}`;
        orderCountByMonth.set(
          monthYearKey,
          (orderCountByMonth.get(monthYearKey) || 0) + 1
        );
      }
    }
  });
  projRemainder.forEach((proj) => {
    const projDate = new Date(proj?.updatedAt ?? proj.createdAt);
    const monthYearKey = `${projDate.getMonth() + 1}-${projDate.getFullYear()}`;
    remainderProjectCountByMonth.set(
      monthYearKey,
      (remainderProjectCountByMonth.get(monthYearKey) || 0) + 1
    );
  });
  projects.forEach((proj) => {
    const projDate = new Date(proj?.updatedAt ?? proj.createdAt);
    const monthYearKey = `${projDate.getMonth() + 1}-${projDate.getFullYear()}`;
    projectCountByMonth.set(
      monthYearKey,
      (projectCountByMonth.get(monthYearKey) || 0) + 1
    );
  });

  // Fill in missing months with count zero and format result
  const startDate = new Date(`01-01-${new Date().getFullYear()}`);
  const endDate = new Date();
  const result: ProjectStats[] = [];

  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const monthYearKey = `${
      currentDate.getMonth() + 1
    }-${currentDate.getFullYear()}`;
    const quoteCount = quoteCountByMonth.get(monthYearKey) || 0;
    const orderCount = orderCountByMonth.get(monthYearKey) || 0;
    const remainderProjectCount =
      remainderProjectCountByMonth.get(monthYearKey) || 0;
    const projectCount = projectCountByMonth.get(monthYearKey) || 0;
    result.push({
      quoteCount,
      orderCount,
      remainderProjectCount,
      projectCount,
      month: `${currentDate.toLocaleString('default', {
        month: 'long',
      })}`,
    });

    // Move to the next month
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  const projectBreakdown: ProjectStatsTotal[] = [
    {
      name: 'Remainder Projects',
      value: getSum(remainderProjectCountByMonth),
    },
    {
      name: 'Projects with Quotes',
      value: getSum(quoteCountByMonth),
    },
    {
      name: 'Projects with Orders',
      value: getSum(orderCountByMonth),
    },
  ];

  return {
    monthCount: result,
    totalCount: projectBreakdown,
  };
};
