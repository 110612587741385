import React, { PropsWithChildren } from 'react';
import Footer from './footer/Footer';
import Header from './Header';
import SideMenu from './SideMenu/SideMenu';

export default function Layout({
  children,
}: PropsWithChildren<{}>): React.ReactElement {
  return (
    <div className="layout">
      <header className="header">
        <Header />
      </header>
      <aside className="side">
        <SideMenu />
      </aside>
      <main className="content">{children}</main>
      <footer className="layout__footer">
        <Footer />
      </footer>
    </div>
  );
}
