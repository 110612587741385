import { Modal } from 'components';
import UserModalActions from 'components/user/UserModalActions';
import { PoolShapeOptionsType, PoolShapeType } from 'models/Project';
import React, { useEffect, useState } from 'react';
import {
  Dropdown,
  Icon,
  Image,
  Input,
  Modal as SemModal,
  Radio,
} from 'semantic-ui-react';
import './PoolShapeSelect.scss';

interface PoolShapeSelectProps {
  poolShape: string;
  options: PoolShapeOptionsType[];
  value: string | undefined;
  loading: boolean;
  isOpen: boolean;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  onCancel: () => void;
  onSubmit: () => void;
}

export const PoolShapeSelect = (props: PoolShapeSelectProps) => {
  const {
    poolShape,
    options,
    value,
    loading,
    isOpen,
    onChange,
    onCancel,
    onSubmit,
  } = props;
  const [checked, setChecked] = useState(value);
  const [disabled, setDisabled] = useState(value === poolShape);
  const [poolShapeType, setPoolShapeType] = useState('');
  const [query, setQuery] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    setDisabled(value === poolShape);
    setChecked(value);
    setQuery('');
    setPoolShapeType('');
  }, [isOpen]);

  useEffect(() => {
    setFilteredOptions(
      options.filter((el) => {
        const nameLowerCase = el.text.toLowerCase();
        const queryLowerCase = query.toLowerCase();
        return poolShapeType !== ''
          ? nameLowerCase.includes(queryLowerCase) && el.type === poolShapeType
          : nameLowerCase.includes(queryLowerCase);
      })
    );
  }, [poolShapeType, query]);

  const handleChange = (val: string) => {
    setDisabled(val === poolShape);
    onChange(val);
    setChecked(val);
  };

  const handleClear = () => {
    setQuery('');
    setPoolShapeType('');
  };

  const onSelectChange = (e: any, data: any) => {
    setPoolShapeType(data.value);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        size="small"
        title="Pool Shapes"
        actions={
          <UserModalActions
            onCancel={onCancel}
            onSubmit={onSubmit}
            disabled={disabled}
          />
        }
      >
        <>
          <Input
            icon={
              query !== '' ? (
                <Icon name="delete" link onClick={handleClear} />
              ) : (
                <Icon name="search" />
              )
            }
            placeholder="Pool Shape..."
            onChange={(_, { value }) => setQuery(value)}
            value={query}
          />
          <Dropdown
            selection
            clearable
            name="PoolShapeFilter"
            className="pool-shape-dropdown"
            size="tiny"
            value={poolShapeType}
            placeholder="Filter by type..."
            options={PoolShapeType.map((el, i) => ({
              key: i,
              text: el.label,
              value: el.value,
            }))}
            onChange={onSelectChange}
          />
          {filteredOptions.map((item, i) => (
            <div
              key={i}
              className="image-select-card"
              onClick={() => handleChange(item.text)}
            >
              <Image className="image-contianer" src={item.image.src} />
              <p className="image-title">{item.text}</p>
              <Radio
                name="radioGroup"
                className="image-select-radio"
                value={item.text}
                checked={checked === item.text}
                onChange={() => handleChange(item.text)}
              />
            </div>
          ))}
        </>
      </Modal>
      <SemModal open={loading}>
        <div className="ui large text loader">Processing...</div>
      </SemModal>
    </>
  );
};
