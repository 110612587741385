export const DECKING_CONCRETE = 'Concrete';
export const DECKING_PAVERS_CONCRETE = 'Pavers with a concrete base';
export const DECKING_PAVERS_SAND = 'Pavers with a sand base';
export const DECKING_GRASS_DIRT = 'Grass or Dirt';
export const DECKING_WOOD_COMPOSITE = 'Wood/Composite';

export const HARDWARE_STANDARD_BRASS_ANCHORS = 'Standard Brass Anchors';
export const HARDWARE_2_INCH_BRASS_ANCHORS = '2” Long Brass Anchors';
export const HARDWARE_DECK_TUBES = 'Deck Tubes';
export const HARDWARE_LAWN_TUBES = 'Lawn Tubes';
export const HARDWARE_WOOD_DECK_ANCHORS = 'Wood Deck Anchors';

export const PADDING_TYPE_SEWN_ON = 'Sewn On';
export const PADDING_TYPE_REPLACEABLE = 'Replaceable';
export const PADDING_TYPE_LOOSE = 'Loose (2” X 20”)';

export const deckingTypeOptions = [
  {
    label: DECKING_CONCRETE,
    value: DECKING_CONCRETE,
  },
  {
    label: DECKING_PAVERS_CONCRETE,
    value: DECKING_PAVERS_CONCRETE,
  },
  {
    label: DECKING_PAVERS_SAND,
    value: DECKING_PAVERS_SAND,
  },
  {
    label: DECKING_GRASS_DIRT,
    value: DECKING_GRASS_DIRT,
  },
  {
    label: DECKING_WOOD_COMPOSITE,
    value: DECKING_WOOD_COMPOSITE,
  },
];

export const getDeckingTypeOptions = (): string[] => {
  return deckingTypeOptions.map((el) => el.value);
};

export const hardwareTypeOptions = {
  [DECKING_CONCRETE]: [
    {
      label: HARDWARE_STANDARD_BRASS_ANCHORS,
      value: HARDWARE_STANDARD_BRASS_ANCHORS,
    },
    {
      label: HARDWARE_2_INCH_BRASS_ANCHORS,
      value: HARDWARE_2_INCH_BRASS_ANCHORS,
    },
  ],
  [DECKING_PAVERS_CONCRETE]: [
    {
      label: HARDWARE_DECK_TUBES,
      value: HARDWARE_DECK_TUBES,
    },
  ],
  [DECKING_PAVERS_SAND]: [
    {
      label: HARDWARE_LAWN_TUBES,
      value: HARDWARE_LAWN_TUBES,
    },
    {
      label: HARDWARE_DECK_TUBES,
      value: HARDWARE_DECK_TUBES,
    },
  ],
  [DECKING_GRASS_DIRT]: [
    {
      label: HARDWARE_LAWN_TUBES,
      value: HARDWARE_LAWN_TUBES,
    },
  ],
  [DECKING_WOOD_COMPOSITE]: [
    {
      label: HARDWARE_WOOD_DECK_ANCHORS,
      value: HARDWARE_WOOD_DECK_ANCHORS,
    },
  ],
};

export const paddingTypeOptions = [
  {
    label: PADDING_TYPE_SEWN_ON,
    value: PADDING_TYPE_SEWN_ON,
  },
  {
    label: PADDING_TYPE_REPLACEABLE,
    value: PADDING_TYPE_REPLACEABLE,
  },
  {
    label: PADDING_TYPE_LOOSE,
    value: PADDING_TYPE_LOOSE,
  },
];

export const getPaddingTypeOptions = (): string[] => {
  return paddingTypeOptions.map((el) => el.value);
};
