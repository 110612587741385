import { APIService, tryRes } from 'services';
import { Project } from 'models';

class SnowflakeService extends APIService<any> {
  async loadSnowflakeData(): Promise<Project[]> {
    const response = await fetch(`${this._endpoint}/billable`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    return tryRes<Project[]>(response);
  }
}

export default new SnowflakeService('projects');
