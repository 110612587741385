import { TransitionGroup } from 'components';
import React, { useEffect, useState } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { USER_MSG } from 'strings';
import { Project } from 'models';
import { Order } from 'models/Order';
import Loader from 'components/layout/loader/Loader';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import '../Admin.scss';
import PieChartData from '../Charts/PieChart';
import CustomBarChart from '../Charts/CustomBarChart';
import { Result, getMonthlyQuoteCount } from './DataUtils';

interface OverviewTabProps {
  projects: Project[];
  orders: Order[];
  loaded: boolean;
  setLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function OverviewTab(
  props: OverviewTabProps
): React.ReactElement {
  const { projects, orders, loaded, setLoaded } = props;
  const [results, setResults] = useState<Result>();

  useEffect(() => {
    setResults(getMonthlyQuoteCount(projects, orders));
  }, [projects, orders, loaded]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <div className="admin">
      <TransitionGroup isVisible={loaded}>
        <div className="admin-tab">
          {projects.length > 0 ? (
            <Segment className="admin-table">
              <Header className="table-header" size="large">
                Overview
              </Header>
              <div style={{ height: '50%', display: 'flex' }}>
                <CustomBarChart data={results?.monthCount ?? []} />
              </div>
              <div style={{ width: '100%', height: '45%', display: 'flex' }}>
                <div style={{ width: '50%', height: '100%' }}>
                  <PieChartData
                    title="Projects Breakdown"
                    data={results?.totalCount ?? []}
                    dataLength={projects.length}
                  />
                </div>
                <div style={{ width: '50%', height: '100%' }}>
                  <PieChartData
                    title="Projects Breakdown (%)"
                    data={results?.totalCount ?? []}
                    dataLength={projects.length}
                    percentage
                  />
                </div>
              </div>
            </Segment>
          ) : (
            <>
              {loaded && projects.length === 0 ? (
                <Loader text={USER_MSG.PROJECTS_LOADING} />
              ) : (
                <Loader text={USER_MSG.PROJECT_DATA_NOT_FOUND} />
              )}
            </>
          )}
        </div>
      </TransitionGroup>
    </div>
  );
}
