import React, { useEffect, useState } from 'react';
import './SpecForm.scss';
import { ManagedInput } from 'components/orders/Fields/Input/ManagedInput';
import { VinylCoveredStepsInfo } from './SpecFormSchema';
import { YES, NO } from '../Features/Feature/FeatureConstants';

interface VinylCoveredStepInfoProps {
  setVinylCoveredStepInfo: (
    vinylCoveredStepInfo: VinylCoveredStepsInfo
  ) => void;
  vinylCoveredStepInfo: VinylCoveredStepsInfo | null;
  archived?: boolean;
}

export interface vinylCoveredStepInfoValuesType {
  id: string;
  anchorTypeOptions?: string;
  cableAssembly?: boolean;
  isRemovable?: boolean;
  isCovered?: boolean;
  isOverWater?: boolean;
  notes?: string;
}

const VinylCoveredStepInfo = ({
  vinylCoveredStepInfo,
  setVinylCoveredStepInfo,
  archived,
}: VinylCoveredStepInfoProps) => {
  // Field states
  const [stepNumber, setStepNumber] = useState<string | undefined>(
    vinylCoveredStepInfo?.stepNumber?.toString()
  );
  const [stepType, setStepType] = useState<string | undefined>(
    vinylCoveredStepInfo?.stepType
  );
  const [texturedStepFeatureSId, setTexturedStepFeatureSId] = useState<
    string | undefined
  >(vinylCoveredStepInfo?.texturedStepFeatureSId);
  const [stepFastener, setStepFastener] = useState<string | undefined>(
    vinylCoveredStepInfo?.stepFastener
  );
  const [stepDimensionWidth, setStepDimensionWidth] = useState<
    string | undefined
  >(vinylCoveredStepInfo?.stepDimensionWidth);
  const [treadQty, setTreadQty] = useState<string | undefined>(
    vinylCoveredStepInfo?.treadQty
  );
  const [stepComment, setStepComment] = useState<string | undefined>(
    vinylCoveredStepInfo?.stepComment?.replace('. ', ' ')
  );
  const [whiteStripeTread, setWhiteStripeTread] = useState<string | undefined>(
    vinylCoveredStepInfo?.whiteStripeTread ? YES : NO
  );
  const [colorStripeWidth, setColorStripeWidth] = useState<string | undefined>(
    vinylCoveredStepInfo?.colorStripeWidth?.toString()
  );

  useEffect(() => {
    setVinylCoveredStepInfo({
      ...vinylCoveredStepInfo,
      stepNumber: parseInt(stepNumber ?? '0'),
      stepType: stepType ?? '',
      stepFastener: stepFastener ?? '',
      stepDimensionWidth: stepDimensionWidth ?? '',
      treadQty: treadQty ?? '',
      stepComment: stepComment ?? '',
      whiteStripeTread: whiteStripeTread === YES,
      colorStripeWidth: Number(colorStripeWidth),
      diffColorRiser: false,
      riserMaterial: '',
      texturedStepFeatureSId: texturedStepFeatureSId ?? '',
    });
  }, [
    stepNumber,
    stepType,
    texturedStepFeatureSId,
    stepFastener,
    stepDimensionWidth,
    treadQty,
    stepComment,
  ]);

  return (
    <>
      {stepNumber && (
        <div className="spec-form__second-title">
          {`Feature: ${stepNumber}`}
        </div>
      )}
      <ManagedInput
        isValid={true}
        label="Step Number"
        type="text"
        placeholder="Step Number"
        onChange={setStepNumber}
        value={stepNumber}
        disabled={archived}
      />
      <ManagedInput
        isValid={true}
        label="Step Type"
        type="text"
        placeholder="Step Type"
        onChange={setStepType}
        value={stepType}
        disabled={archived}
      />
      <ManagedInput
        isValid={true}
        label="Textured Step FeatureSId"
        type="text"
        placeholder="Textured Step FeatureSId"
        onChange={setTexturedStepFeatureSId}
        value={texturedStepFeatureSId}
        disabled={archived}
      />
      <ManagedInput
        isValid={true}
        label="White Stripe Tread"
        type="text"
        placeholder="White Stripe Tread"
        onChange={setWhiteStripeTread}
        value={whiteStripeTread}
        disabled={archived}
      />
      {whiteStripeTread === YES && (
        <ManagedInput
          isValid={true}
          label="Color Stripe Width"
          type="text"
          placeholder="Color Stripe Width"
          onChange={setColorStripeWidth}
          value={colorStripeWidth}
          disabled={true}
        />
      )}
      <ManagedInput
        isValid={true}
        label="Step Fastener Type"
        type="text"
        placeholder="Step Fastener"
        onChange={setStepFastener}
        value={stepFastener}
        disabled={archived}
      />
      <ManagedInput
        isValid={true}
        label="Step Dimension Width"
        type="text"
        placeholder="Step Dimension Width"
        onChange={setStepDimensionWidth}
        value={stepDimensionWidth}
        disabled={archived}
      />
      <ManagedInput
        isValid={true}
        label="Tread Quantity"
        type="text"
        placeholder="Tread Quantity"
        onChange={setTreadQty}
        value={treadQty}
        disabled={archived}
      />
      <ManagedInput
        isValid={true}
        label="Step Comment"
        type="text"
        placeholder="Step Comment"
        onChange={setStepComment}
        value={stepComment}
        disabled={archived}
      />
    </>
  );
};

export default VinylCoveredStepInfo;
