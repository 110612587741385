import React from 'react';
import { Toggle } from '../../../forms/Toggle';

export type ManagedFieldOnChange =
  | React.Dispatch<React.SetStateAction<boolean>>
  | React.Dispatch<React.SetStateAction<boolean | undefined>>;

interface ManagedToggleProps {
  onChange: ManagedFieldOnChange;
  name: string;
  checked: boolean;
  label?: string;
  isValid: boolean;
  disabled?: boolean;
}

export const ManagedToggle = (props: ManagedToggleProps) => {
  const toggleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.checked);
  };

  return (
    <span className="managed-toggle">
      <Toggle
        onChange={toggleOnChange}
        name={props.name}
        checked={props.checked}
        label={props.label}
        isValid={props.isValid}
        disabled={props?.disabled}
      />
    </span>
  );
};
