import { SmartTable, TransitionGroup } from 'components';
import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as Empty } from 'res/img/empty.svg';
import { Button, Header, Segment, Select } from 'semantic-ui-react';
import { USER_MSG } from 'strings';
import useSmartTableSortOrder, { TableType } from 'context/SmartTableContext';
import { Dealer, Project } from 'models';
import { useHistory } from 'react-router-dom';
import { Order, OrderModel, OrderStatus } from 'models/Order';
import Loader from 'components/layout/loader/Loader';
import DateRangePicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import '../Admin.scss';

const today = new Date();
const orderTableType: TableType = 'orders';

const orderStatusOptions: { [label: string]: string } = {
  Any: '',
  Order: OrderStatus.Ordered,
  Quote: OrderStatus.ReadyForOrder,
};

interface OrderTabProps {
  projects: Project[];
  dealers: Dealer[];
  orders: Order[];
  loaded: boolean;
  filterByCreatedDate: (createdDate: string, el: Project | Order) => boolean;
  setLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function OrderTab(props: OrderTabProps): React.ReactElement {
  const { projects, dealers, orders, loaded, filterByCreatedDate, setLoaded } =
    props;
  const [exportData, setExportData] = useState(false);
  const [orderCount, setOrderCount] = useState(0);
  const [quoteCount, setQuoteCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const history = useHistory();
  const { ordersTableState, updateTableFilteringState } =
    useSmartTableSortOrder();

  const orderDealerFilter = ordersTableState.filters?.get('dealerFilter') ?? '';
  const orderProjectFilter =
    ordersTableState.filters?.get('projectFilter') ?? '';
  const statusFilter = ordersTableState.filters?.get('statusFilter') ?? '';
  const orderCreatedDate = ordersTableState.filters?.get('createdDate') ?? '';

  useEffect(() => {
    setOrderProjectFilter('');
    setStatusFilter('');
    setDealerFilter('');
    setCreatedDate('');
  }, []);

  useEffect(() => {
    const qCount = orders?.filter(
      (o) =>
        o.status === OrderStatus.QuoteInReview ||
        o.status === OrderStatus.ReadyForOrder
    );
    const oCount = orders?.filter((o) => o.status === OrderStatus.Ordered);

    setQuoteCount(qCount.length);
    setTotalCount(qCount.length + oCount.length);
    setOrderCount(oCount.length);
  }, [projects, orders, loaded]);

  const companyMappings = useCallback(
    () =>
      dealers
        .map((dealer: Dealer) => ({
          key: dealer.id,
          value: dealer.id,
          text: `${dealer.name} (${dealer.rewardsId})`,
        }))
        .sort((a, b) =>
          a.text.toLocaleLowerCase().localeCompare(b.text.toLocaleLowerCase())
        ),
    [dealers]
  );

  const setDealerFilter = (value: string) => {
    updateTableFilteringState('dealerFilter', value, orderTableType);
  };

  const projectMappings = useCallback(
    () =>
      projects
        .filter((project: Project) =>
          orders.find((order: Order) => order.projectId === project.id)
        )
        .map((project: Project) => ({
          key: project.id,
          value: project.id,
          text: `${project.name}`,
        }))
        .sort((a, b) =>
          a.text.toLocaleLowerCase().localeCompare(b.text.toLocaleLowerCase())
        ),
    [projects]
  );

  const setOrderProjectFilter = (value: string) => {
    updateTableFilteringState('projectFilter', value, orderTableType);
  };

  const setStatusFilter = (value: string) => {
    updateTableFilteringState('statusFilter', value, orderTableType);
  };

  const setCreatedDate = (value: string) => {
    updateTableFilteringState('createdDate', value, orderTableType);
  };

  const filterOrdersByDealers = (el: Order) =>
    orderDealerFilter === el.dealerId || orderDealerFilter === '';

  const filterOrdersByProjects = (el: Order) =>
    orderProjectFilter === el.projectId || orderProjectFilter === '';

  const filterOrdersByStatus = (el: Order) => {
    return statusFilter === OrderStatus.Ordered
      ? el.status === OrderStatus.Ordered
      : statusFilter === OrderStatus.ReadyForOrder
      ? el.status === OrderStatus.ReadyForOrder ||
        el.status === OrderStatus.QuoteInReview
      : statusFilter === '';
  };

  const orderFilter = useCallback(
    (order: Order) => {
      return (
        filterByCreatedDate(orderCreatedDate, order) &&
        filterOrdersByDealers(order) &&
        filterOrdersByProjects(order) &&
        filterOrdersByStatus(order)
      );
    },
    [orderCreatedDate, orderDealerFilter, orderProjectFilter, statusFilter]
  );

  const onOrderRowClick = (order: Order) => {
    history.push(`/projects/${order.projectId}`);
  };

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <div className="admin">
      <TransitionGroup isVisible={loaded}>
        <div className="admin-tab">
          {projects.length > 0 ? (
            <Segment className="admin-table">
              <Header className="table-header" size="large">
                Quotes and Orders
              </Header>
              <div className="order-icon">
                <div className="tooltiptext order-tooltiptext">
                  {`Quotes: ${totalCount}`}
                  <br />
                  {`Quotes Without Order: ${quoteCount}`}
                  <br />
                  {`Orders: ${orderCount}`}
                </div>
                <i className="order-info info circle icon" />
              </div>
              <Button
                className="export-button"
                primary
                onClick={() => setExportData(true)}
                content={<i className="download icon" style={{ margin: 0 }} />}
              />
              <div className="controls mbottom">
                <DateRangePicker
                  type="range"
                  maxDate={today}
                  onChange={(_, data) => {
                    const values = data.value as Date[];
                    if (!values) setCreatedDate('');
                    if (values?.length == 2) {
                      const formatter = new Intl.DateTimeFormat();
                      const [startDate, endDate] = values;
                      setCreatedDate(
                        `${formatter.format(startDate)}-${formatter.format(
                          endDate
                        )}`
                      );
                    }
                  }}
                />
                <Select
                  data-cy="order-dropdown-status"
                  placeholder="Status"
                  options={Object.entries(orderStatusOptions).map(
                    ([text, value]) => ({
                      text,
                      value,
                    })
                  )}
                  onChange={(_, { value }) =>
                    setStatusFilter(value?.toString() ?? '')
                  }
                  value={statusFilter}
                  clearable
                  search
                />
                <Select
                  data-cy="order-dropdown-dealer"
                  placeholder="Dealer"
                  options={companyMappings()}
                  onChange={(_, { value }) =>
                    setDealerFilter(value?.toString() ?? '')
                  }
                  value={orderDealerFilter}
                  clearable
                  search
                />
                <Select
                  data-cy="order-dropdown-project"
                  placeholder="Project Name"
                  options={projectMappings()}
                  onChange={(_, { value }) =>
                    setOrderProjectFilter(value?.toString() ?? '')
                  }
                  value={orderProjectFilter}
                  clearable
                  search
                />
              </div>
              <SmartTable
                className="orders-table data-scroll"
                tableType="orders"
                data={orders}
                model={OrderModel}
                excludeProps=" updatedAt "
                initialSortProp="createdAt"
                transformProps={{
                  createdAt: (val) => val.split('T')[0],
                  dealerId: (val, el) => {
                    const project = projects.filter(
                      (proj) => proj.id === el.projectId
                    );
                    return dealers.find(
                      (dealer) => dealer.id === project[0]?.dealerId
                    )?.name;
                  },
                  projectId: (val) =>
                    projects.find((project) => project.id === val)?.name,
                  status: (val) => {
                    switch (val) {
                      case OrderStatus.QuoteInReview:
                        return 'Quoted - Review Pending';
                      case OrderStatus.ReadyForOrder:
                        return 'Quote - Approved';
                      default:
                        return val;
                    }
                  },
                }}
                filter={orderFilter}
                onRowClick={onOrderRowClick}
                empty={<Empty />}
                exportData={exportData}
                setExportData={setExportData}
              />
            </Segment>
          ) : (
            <>
              {loaded && projects.length === 0 ? (
                <Loader text={USER_MSG.ORDERS_LOADING} />
              ) : (
                <Loader text={USER_MSG.ORDER_DATA_NOT_FOUND} />
              )}
            </>
          )}
        </div>
      </TransitionGroup>
    </div>
  );
}
