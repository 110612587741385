import { APIService, tryRes } from 'services';

class LinerMaterialService extends APIService<any> {
  async getLinerMaterials(dealerId: string) {
    const response = await fetch(
      `${this._endpoint}/${dealerId}/linerMaterials`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
    );
    const json = await response.json();
    return json;
  }

  async getStepFasteners() {
    const response = await fetch(`${this._endpoint}/linerStepFasteners`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    const json = await response.json();
    return json;
  }
}

const service = new LinerMaterialService('swatch');

export default service;
