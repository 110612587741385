export const enum DealerMsg {
  DEALER_INFO_TITLE = 'Company Information',
  CONTACT_LATHAM = 'Contact Latham for Corrections',
  SUB_PATH = 'mine/',
  REFRESH = 'Refresh Details',
  REFRESH_IN_PROGRESS = 'Refreshing Company Details...',
  ERROR_MSG_IMPORT = 'Error Importing Company',
  ERROR_MSG_UPDATE = 'Error Updating Company',
  SUCCESS_MSG_IMPORT = 'Company import successful',
  SUCCESS_MSG_UPDATE = 'Company refresh successful',
  SUCCESS_TITLE = 'Success!',
  ERROR_TITLE = 'Something went wrong!',
}
