import React, { ChangeEvent, useRef } from 'react';
import {
  DEFAULT_FIELD_VALUE,
  FlattenModelField,
  ModelInstance,
} from '../../models/Model';

interface numberInputDataProps {
  name: string;
  value: string;
}

type NumberInputProps<T> = {
  model: ModelInstance<T>;
  field: FlattenModelField;
  onChange: (
    e:
      | ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: numberInputDataProps
  ) => void;
  className?: string;
};

export function NumberInput<T>({
  model,
  field,
  onChange,
  className = '',
}: NumberInputProps<T>) {
  const { prop, label, defaultValue, placeholder, min, max, step } = field;
  const qty = useRef({} as HTMLInputElement);

  return (
    <div className={'number-input ' + className}>
      <button
        className="minus"
        type="button"
        onClick={(e) => {
          qty.current.stepDown();
          onChange(e, { name: prop, value: qty.current.value });
        }}
      />
      <input
        ref={qty}
        type="number"
        name={prop}
        min={min || DEFAULT_FIELD_VALUE.number}
        max={max || Infinity}
        step={step || 1}
        value={model.get(prop) || defaultValue || DEFAULT_FIELD_VALUE.number}
        placeholder={placeholder || `Enter ${label.toLowerCase()}...`}
        onChange={(e) => onChange(e, { name: prop, value: e.target.value })}
      />
      <button
        className="plus"
        type="button"
        onClick={(e) => {
          qty.current.stepUp();
          onChange(e, { name: prop, value: qty.current.value });
        }}
      />
    </div>
  );
}
