import React from 'react';
import './FeaturesPlaceholder.scss';

const FeaturesPlaceholder = () => (
  <div className="features-placeholder">
    <div className="features-placeholder__copy">
      No features were tagged during measuring. Click 'Next' to continue.
    </div>
  </div>
);

export default FeaturesPlaceholder;
