import { FieldGroup, Modal, SmartTable, TransitionGroup } from 'components';
import { DealerModel, Dealer } from 'models';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CompanyService, EmployeeService } from 'sections/employee';
import { ReactComponent as Empty } from 'res/img/empty.svg';
import { Button, Form, Header, Input, Segment } from 'semantic-ui-react';
import { USER_MSG } from 'strings';
import useSmartTableSortOrder, { TableType } from 'context/SmartTableContext';
import { useHistory } from 'react-router-dom';
import { Role, useAuth, useUI } from 'services';
import { DealerMsg } from './Constants';
import { Toggle } from 'components/forms/Toggle';
import Loader from 'components/layout/loader/Loader';
import './Admin.scss';
import { UserService } from 'sections/user';

const IMPORT_CONTENT = 'Import a Dealer';
const GET_EMAILS = 'Get all user emails';
const DEALER_USER_CONTENT = 'One to Many Profile Setup';
const tableType: TableType = 'dealers';

interface AdminDealerListProps {
  dealers: Dealer[];
  fetchDealers: () => Promise<void>;
  loaded: boolean;
  setLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AdminDealerList(
  props: AdminDealerListProps
): React.ReactElement {
  const { dealers, fetchDealers, loaded, setLoaded } = props;
  const [company, setCompany] = useState(DealerModel({} as Dealer));
  const [loading, setLoading] = useState<boolean>(false);
  const [importState, setImportState] = useState(false);
  const [isUserDealer, setIsUserDealer] = useState<boolean>(false);
  const [exportData, setExportData] = useState(false);
  const [emails, setEmails] = useState<string[] | undefined>();
  const history = useHistory();
  const { user } = useAuth();
  const hasSuperAccess =
    user?.role === Role.LATHAM_ADMIN || user?.role === Role.BAI_ADMIN;

  const { dealersTableState, updateTableFilteringState } =
    useSmartTableSortOrder();
  const query = dealersTableState.filters?.get('query') ?? '';
  const status = dealersTableState.filters?.get('status') ?? '';
  const createdDate = dealersTableState.filters?.get('createdDate') ?? '';

  const { showError, showSuccess } = useUI();

  const onRowClick = async (dealer: Dealer) => {
    history.push(`/admin/company/${dealer.id}`);
  };

  const setQuery = (value: string) => {
    updateTableFilteringState('query', value, tableType);
  };

  const downloadBlob = (
    content: string,
    filename: string,
    contentType: string
  ) => {
    // Create a blob
    const blob = new Blob([content], { type: contentType });
    const url = URL.createObjectURL(blob);

    // Create a link to download it
    const pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', filename);
    pom.click();
  };

  useEffect(() => {
    if (exportData) {
      const dataToExport = emails ? ['Emails', ...emails] : ['Emails'];
      const csvContent = dataToExport.join('\n');
      const fileName = 'userEmails';

      // Export content to csv file
      downloadBlob(csvContent, fileName, 'text/csv;charset=utf-8;');

      // Reset export state so user can download file again
      if (setExportData) setExportData(false);
    }
  }, [exportData]);

  const filter = useCallback(
    (dealer: Dealer) => {
      const normalize = (str: string) => str?.trim()?.toLowerCase();
      const normedIncludes = (str1: string, str2: string) =>
        normalize(str1)?.includes(normalize(str2));
      return (
        (normedIncludes(dealer?.name ?? '', query) ||
          normedIncludes(dealer?.address.city ?? '', query) ||
          normedIncludes(dealer?.rewardsId ?? '', query)) &&
        (!createdDate || createdDate === dealer.createdAt.split('T')[0])
      );
    },
    [query, status, createdDate]
  );

  const handleOpenImportModal = (): void => {
    setImportState(true);
  };

  const importCompany = async (): Promise<void> => {
    try {
      setLoading(true);
      if (isUserDealer) {
        await CompanyService.addOrRefreshUserDealer(company.data).then(
          (dealer) => {
            fetchDealers();
            handleCloseImportModal();
            showSuccess({
              title: DealerMsg.SUCCESS_TITLE,
              msg: DealerMsg.SUCCESS_MSG_IMPORT,
            });
            history.push(`/admin/company/${dealer.id}`);
          }
        );
      } else {
        await CompanyService.addOrRefreshDealer(company.data.rewardsId).then(
          (dealer) => {
            fetchDealers();
            handleCloseImportModal();
            showSuccess({
              title: DealerMsg.SUCCESS_TITLE,
              msg: DealerMsg.SUCCESS_MSG_IMPORT,
            });
            history.push(`/admin/company/${dealer.dealerId}`);
          }
        );
      }
    } catch (error: any) {
      handleCloseImportModal();
      showError({ title: DealerMsg.ERROR_MSG_IMPORT, msg: error.message });
    }
  };

  const handleCloseImportModal = (): void => {
    setLoading(false);
    setImportState(false);
    setCompany(DealerModel({} as Dealer));
  };

  const renderModelControls: React.ReactElement = useMemo(() => {
    return (
      <div className="controls">
        <Button
          basic
          onClick={handleCloseImportModal}
          content="Cancel"
          disabled={loading}
        />
        <Button
          className="control-right"
          primary
          onClick={importCompany}
          content="Import"
          disabled={loading}
        />
      </div>
    );
  }, [setImportState, importState, company, loading, isUserDealer]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <div className="admin">
      <TransitionGroup isVisible={loaded}>
        <div className="admin-tab">
          <Modal
            isOpen={importState}
            size="small"
            title={IMPORT_CONTENT}
            actions={renderModelControls}
            className="dealer-modal"
          >
            <Form loading={loading}>
              {hasSuperAccess && (
                <Toggle
                  name={DEALER_USER_CONTENT}
                  label={DEALER_USER_CONTENT}
                  isValid
                  checked={isUserDealer ?? false}
                  onChange={(e) => setIsUserDealer(e.target.checked)}
                />
              )}

              {!isUserDealer && (
                <>
                  <Header size="large">Import Dealer by RewardsID</Header>
                  <div className="row">
                    <FieldGroup
                      model={company}
                      fields="rewardsId"
                      className="col"
                    />
                  </div>
                </>
              )}
              {isUserDealer && hasSuperAccess && (
                <>
                  <Header size="large">Create One To Many Profile</Header>
                  <div className="row">
                    <FieldGroup
                      model={company}
                      fields="name address.address1 address.address2 address.city"
                      className="col"
                    />
                    <FieldGroup
                      model={company}
                      fields="email address.state address.postalcode phone"
                      className="col"
                    />
                  </div>
                </>
              )}
            </Form>
          </Modal>
          {dealers.length > 0 ? (
            <Segment className="admin-table">
              <Header size="large">Dealers</Header>
              <div className="main-action">
                <Button
                  primary
                  data-cy="get-user-emails"
                  content={GET_EMAILS}
                  onClick={() =>
                    void EmployeeService.getEmails().then((res) => {
                      setEmails(res);
                      setExportData(true);
                    })
                  }
                />
                <Button
                  primary
                  icon="plus"
                  data-cy="add-a-new-company"
                  content={IMPORT_CONTENT}
                  onClick={handleOpenImportModal}
                />
              </div>
              <div className="controls mbottom">
                <Input
                  icon="search"
                  placeholder="Name, RewardsId or City..."
                  onChange={(_, { value }) => setQuery(value)}
                  value={query}
                />
              </div>

              <SmartTable
                tableType="dealers"
                data={dealers}
                model={DealerModel}
                excludeProps=" url email phone "
                transformProps={{
                  isUserDealer: (val) =>
                    val ? 'One to Many Profile' : 'Builder',
                }}
                filter={filter}
                onRowClick={onRowClick}
                empty={<Empty />}
                className="dealer-scroll"
              />
            </Segment>
          ) : (
            <>
              {loaded && dealers.length === 0 ? (
                <Loader text={USER_MSG.DEALERS_LOADING}>
                  <Button
                    primary
                    icon="plus"
                    data-cy="add-a-new-company"
                    style={{ margin: 'auto' }}
                    content={IMPORT_CONTENT}
                    onClick={handleOpenImportModal}
                  />
                </Loader>
              ) : (
                <Loader text={USER_MSG.DEALER_DATA_NOT_FOUND}>
                  <Button
                    primary
                    icon="plus"
                    data-cy="add-a-new-company"
                    style={{ margin: 'auto' }}
                    content={IMPORT_CONTENT}
                    onClick={handleOpenImportModal}
                  />
                </Loader>
              )}
            </>
          )}
        </div>
      </TransitionGroup>
    </div>
  );
}
