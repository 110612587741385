import { ImageType } from 'react-images-uploading';
import { Button, Loader } from 'semantic-ui-react';
import React from 'react';
import './ProjectImageGallery.scss';

export const ProjectImageList = (props: {
  image: ImageType;
  index: number;
  onOpen: () => void;
  canDelete: boolean | undefined;
  onClose: () => void;
}) => {
  return (
    <div className="thumb show-children">
      <img
        src={props.image.url ?? props.image.thumbnailUrl}
        alt={props.image.file?.name || `image-${props.index}`}
        height="100%"
        onClick={props.onOpen}
        className={props.image.error ? 'error' : ''}
      />
      {props.image.uploading ? (
        <Loader size="large" />
      ) : props.canDelete ? (
        <Button
          icon="trash"
          size="mini"
          className="hidden delete"
          onClick={props.onClose}
        />
      ) : null}
    </div>
  );
};
