import React, { useEffect, useState } from 'react';
import { ManagedSelect } from 'components/orders/Fields/Select/ManagedSelect';
import OrderForm, { GenericFieldValidationType } from '../../OrderForm';
import {
  companyAddress,
  dealerDirect,
  distributorAddress,
  otherAddress,
  pickUp,
  poolAddress,
  stateOptions,
  whereToShipOptions,
} from './ShippingFormConstants';
import {
  ShippingFormFieldsErrorMessageType,
  ShippingFormFieldsValidationType,
  ShippingFormPropTypes,
  ShippingFormSchema,
} from './ShippingFormSchema';
import './ShippingForm.scss';
import { ManagedInput } from 'components/orders/Fields/Input/ManagedInput';
import { ProjectService } from 'sections/projects';
import { useRouteMatch } from 'react-router-dom';
import { Dealer, DealerModel, Project, ProjectModel } from 'models';
import { ModelInstance } from 'models/Model';
import { CompanyService } from 'sections/company';
import { useAuth } from 'services';
import { OrderContext } from '../../../../../context/OrderContext';
import { usePrevious } from 'utils';
import { SpecFormPropTypes } from '../Spec/SpecFormSchema';
import { getLathamSpec } from 'components/orders/helpers/getLathamSpec';

interface ShippingFormProps {
  data: ShippingFormPropTypes;
  hasEditAccess: boolean;
}

/**
 * All fields are "valid", until we get an error!
 */
const InitialShippingFormValidationStatus =
  (): ShippingFormFieldsValidationType => {
    return {
      whereToShip: true,
      name: true,
      addressLine1: true,
      addressLine2: true,
      city: true,
      shippingState: true,
      zip: true,
    };
  };

const isAddressComplete = (
  model?: ModelInstance<Project> | ModelInstance<Dealer>
) => {
  return (
    model &&
    model.data.name &&
    model.data.address.address1 &&
    model.data.address.city &&
    model.data.address.state &&
    model.data.address.postalcode
  );
};

const ShippingForm = (props: ShippingFormProps) => {
  const {
    //@ts-ignore
    params: { id },
  } = useRouteMatch();
  const { user } = useAuth();

  // Global state
  const { state, dispatch } = React.useContext(OrderContext);

  const disabled = state?.order?.archived || !props?.hasEditAccess;

  const isDealerDirect = state.product?.partner === dealerDirect;

  const [pool, setPool] = useState<ModelInstance<Project> | undefined>(
    undefined
  );
  const [company, setCompany] = useState<ModelInstance<Dealer> | undefined>(
    undefined
  );

  const [whereToShip, setWhereToShip] = useState<string | undefined>(
    isDealerDirect
      ? state.shipping.whereToShip ?? props.data.whereToShip
      : distributorAddress
  );
  const prevWhereToShip = usePrevious(whereToShip);
  const [name, setName] = useState<string | undefined>(
    state.shipping.name ?? props.data.name
  );
  const [addressLine1, setAddressLine1] = useState<string | undefined>(
    state.shipping.addressLine1 ?? props.data.addressLine1
  );
  const [addressLine2, setAddressLine2] = useState<string | undefined>(
    state.shipping.addressLine2 ?? props.data.addressLine2
  );
  const [city, setCity] = useState<string | undefined>(
    state.shipping.city ?? props.data.city
  );
  const [shippingState, setShippingState] = useState<string | undefined>(
    state.shipping.shippingState ?? props.data.shippingState
  );
  const [zip, setZip] = useState<string | undefined>(
    state.shipping.zip ?? props.data.zip
  );

  // Validation state
  const [validFields, setValidFields] =
    useState<ShippingFormFieldsValidationType>(
      InitialShippingFormValidationStatus()
    );
  const [errorMessages, setErrorMessages] =
    useState<ShippingFormFieldsErrorMessageType>({ zip: null });
  const currentValues = {
    whereToShip,
    name,
    addressLine1,
    addressLine2,
    city,
    shippingState,
    zip,
  };

  const filteredWhereToShipOptions = whereToShipOptions.filter(
    (option) =>
      option.label === otherAddress ||
      option.label === pickUp ||
      (option.label === poolAddress &&
        (whereToShip === poolAddress || isAddressComplete(pool))) ||
      (option.label === companyAddress &&
        (whereToShip === companyAddress || isAddressComplete(company)))
  );
  const specVals = {
    projectAttributes: {
      ...getLathamSpec(state)?.projectAttributes,
      shipToCity: city,
      shipToState: shippingState,
      shipToStreet: `${addressLine1} ${addressLine2}`,
      shipToZip: zip,
    },
  } as SpecFormPropTypes;

  const updateOrderState = () => {
    dispatch({
      type: 'updateShipping',
      payload: currentValues,
    });
    dispatch({
      type: 'updateSpec',
      payload: specVals,
    });
  };

  useEffect(() => {
    ProjectService.get(id).then((proj) => {
      setPool(ProjectModel({ ...proj }));
    });
    CompanyService.get(`${state.order?.dealerId}`).then((dealer) => {
      setCompany(DealerModel({ ...dealer }));
    });
  }, []);

  useEffect(() => {
    if (!isDealerDirect) {
      setWhereToShip(distributorAddress);
    }
  }, [isDealerDirect]);

  useEffect(() => {
    if (
      (whereToShip === poolAddress && pool) ||
      (whereToShip === companyAddress && company)
    ) {
      const model = whereToShip === poolAddress ? pool : company;
      setName(company?.data.name); // Always use company name, even for pool
      setAddressLine1(model?.data.address.address1);
      setAddressLine2(model?.data.address.address2 ?? undefined);
      setCity(model?.data.address.city);
      setShippingState(model?.data.address.state);
      setZip(model?.data.address.postalcode);
    } else if (
      whereToShip === otherAddress &&
      (prevWhereToShip === poolAddress || prevWhereToShip === companyAddress)
    ) {
      // Need to clear these fields with empty strings instead of undefined or null
      // Otherwise situations can arise where the wrong values are prepopulated on mount
      setName('');
      setAddressLine1('');
      setAddressLine2('');
      setCity('');
      setShippingState('');
      setZip('');
    } else if (whereToShip === pickUp || whereToShip === distributorAddress) {
      setName('');
      setAddressLine1('');
      setAddressLine2('');
      setCity('');
      setShippingState('');
      setZip('');
    }
  }, [whereToShip]);

  return (
    <div className="shipping-form">
      <div className="shipping-form__title">Ship To</div>
      <OrderForm
        values={currentValues}
        initialValidationState={InitialShippingFormValidationStatus()}
        schema={ShippingFormSchema}
        onFieldsValid={updateOrderState}
        onFieldsInvalid={updateOrderState}
        setValidFields={
          setValidFields as React.Dispatch<
            React.SetStateAction<GenericFieldValidationType>
          >
        }
        setFieldErrorMessages={setErrorMessages}
        fieldErrorMessages={errorMessages}
      >
        <>
          <ManagedSelect
            label="Where would you like this order shipped?"
            isValid={validFields.whereToShip}
            value={whereToShip}
            onChange={setWhereToShip}
            options={
              isDealerDirect ? filteredWhereToShipOptions : whereToShipOptions
            }
            placeholder="Select shipping location"
            name="where_to_ship"
            disabled={disabled || !isDealerDirect}
          />
          {whereToShip === otherAddress && (
            <>
              <ManagedInput
                isValid={validFields.name}
                label="Shipping Address"
                type="text"
                placeholder="Name"
                onChange={setName}
                value={name}
                disabled={disabled}
              />
              <ManagedInput
                isValid={validFields.addressLine1}
                type="text"
                placeholder="Address"
                onChange={setAddressLine1}
                value={addressLine1}
                disabled={disabled}
              />
              <ManagedInput
                isValid={validFields.addressLine2}
                type="text"
                placeholder="Address (cont.)"
                onChange={setAddressLine2}
                value={addressLine2}
                disabled={disabled}
              />
              <ManagedInput
                isValid={validFields.city}
                type="text"
                placeholder="City"
                onChange={setCity}
                value={city}
                disabled={disabled}
              />
              <div className="shipping-form__two-column">
                <ManagedSelect
                  isValid={validFields.shippingState}
                  options={stateOptions}
                  name="state"
                  placeholder="State"
                  value={shippingState}
                  onChange={setShippingState}
                  disabled={disabled}
                />
                <ManagedInput
                  errorMessage={
                    errorMessages.zip !== 'zip is a required field'
                      ? errorMessages.zip
                      : ''
                  }
                  isValid={validFields.zip}
                  type="zip"
                  placeholder="ZIP"
                  onChange={setZip}
                  value={zip}
                  disabled={disabled}
                />
              </div>
            </>
          )}
          {!!whereToShip &&
            whereToShip !== otherAddress &&
            whereToShip !== pickUp &&
            whereToShip !== distributorAddress && (
              <div>
                <div className="shipping-form__address--title">
                  Shipping Address
                </div>
                <div>{name}</div>
                <div>{addressLine1}</div>
                {addressLine2 && <div>{addressLine2}</div>}
                <div>
                  {city}, {shippingState}, {zip}
                </div>
              </div>
            )}
        </>
      </OrderForm>
    </div>
  );
};

export default ShippingForm;
