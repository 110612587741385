import React from 'react';
import './Footer.scss';

export default function Footer(): React.ReactElement {
  return (
    <div className="footer">
      <div className="footer__copy">
        © Latham Pool Products, Inc. | All rights reserved.
      </div>
    </div>
  );
}
