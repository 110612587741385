import React, { SetStateAction, useEffect, useState } from 'react';
import OrderForm, { GenericFieldValidationType } from '../../OrderForm';
import {
  AccessoriesFormFieldsErrorMessageType,
  AccessoriesFormPropTypes,
  AccessoriesFormSchema,
} from './AccessoriesFormSchema';
import {
  accessoriesOrderState,
  OrderContext,
} from '../../../../../context/OrderContext';
import { ManagedToggle } from '../../../Fields/Toggle/ManagedToggle';
import './AccessoriesForm.scss';
import { ManagedInput } from 'components/orders/Fields/Input/ManagedInput';
import { OrderType } from 'models';
import { ManagedSelect } from 'components/orders/Fields/Select/ManagedSelect';
import {
  RacingStripeQuanityOptions,
  SafetyStripesOptions,
  SolidShallowEndOptions,
} from './AccessoriesFormConstants';
import { SpecFormPropTypes } from '../Spec/SpecFormSchema';
import { getLathamSpec } from 'components/orders/helpers/getLathamSpec';

interface AccessoriesFormProps {
  data: AccessoriesFormPropTypes;
  hasEditAccess: boolean;
}

export interface AccessoriesFormFields {
  doubleD_Rings: boolean;
  topAndBottomWebbing: boolean;
  buckles: boolean;
  kemkap: boolean;
  yStraps: boolean;
  yStrapQty: boolean;
  strapLength: boolean;
  safetyStripes: boolean;
  safetyStripesColor: boolean;
  safetyStripeWidth: boolean;
  solidShallowEnd: boolean;
  solidShallowEndColor: boolean;
  divingTarget: boolean;
  divingTargetComments: boolean;
  racingStripes: boolean;
  racingStripesCount: boolean;
  racingStripesComments: boolean;
}

/**
 * All fields are "valid", until we get an error!
 * We use yup to check for errors to drive the UI state, every time we run the validation we only update the records in the state that have an error, until no errors are returned, and every field is marked true (valid)
 */
const InitialAccessoriesFormValidationStatus = (): AccessoriesFormFields => {
  return {
    doubleD_Rings: true,
    topAndBottomWebbing: true,
    buckles: true,
    kemkap: true,
    yStraps: true,
    yStrapQty: true,
    strapLength: true,
    safetyStripes: true,
    safetyStripesColor: true,
    safetyStripeWidth: true,
    solidShallowEnd: true,
    solidShallowEndColor: true,
    divingTarget: true,
    divingTargetComments: true,
    racingStripes: true,
    racingStripesCount: true,
    racingStripesComments: true,
  };
};

const AccessoriesForm = (props: AccessoriesFormProps) => {
  const { state, dispatch } = React.useContext(OrderContext);
  const disabled = state?.order?.archived || !props?.hasEditAccess;

  // Field states
  const type = state.project?.type;

  // Cover accessories
  const [doubleD_Rings, setDoubleD_Rings] = useState<boolean>(
    state.accessories.doubleD_Rings ?? props.data.doubleD_Rings ?? false
  );
  const [topAndBottomWebbing, setTopAndBottomWebbing] = useState<boolean>(
    state.accessories.topAndBottomWebbing ??
      props.data.topAndBottomWebbing ??
      false
  );
  const [buckles, setBuckles] = useState<boolean>(
    state.accessories.buckles ?? props.data.buckles ?? false
  );
  const [kemkap, setKemkap] = useState<boolean>(
    state.accessories.kemkap ?? props.data.kemkap ?? false
  );
  const [yStraps, setYStraps] = useState<boolean>(
    state.accessories.yStraps ?? props.data.yStraps ?? false
  );
  const [yStrapQty, setYStrapQty] = useState<string | undefined>(
    state.accessories.yStrapQty ?? props.data.yStrapQty
  );

  // Liner accessories
  const [safetyStripes, setSafetyStripes] = useState<boolean>(
    state.accessories.safetyStripes ?? props.data.safetyStripes ?? false
  );
  const [safetyStripesColor, setSafetyStripesColor] = useState<
    string | undefined
  >(state.accessories.safetyStripesColor ?? props.data.safetyStripesColor);
  const [solidShallowEnd, setSolidShallowEnd] = useState<boolean>(
    state.accessories.solidShallowEnd ?? props.data.solidShallowEnd ?? false
  );
  const [solidShallowEndColor, setSolidShallowEndColor] = useState<
    string | undefined
  >(state.accessories.solidShallowEndColor ?? props.data.solidShallowEndColor);
  const [divingTarget, setDivingTarget] = useState<boolean>(
    state.accessories.divingTarget ?? props.data.divingTarget ?? false
  );
  const [divingTargetComments, setDivingTargetComments] = useState<
    string | undefined
  >(state.accessories.divingTargetComments ?? props.data.divingTargetComments);
  const [racingStripes, setRacingStripes] = useState<boolean>(
    state.accessories.racingStripes ?? props.data.racingStripes ?? false
  );
  const [racingStripesCount, setRacingStripesCount] = useState<
    string | undefined
  >(state.accessories.racingStripesCount ?? props.data.racingStripesCount);
  const [racingStripesComments, setRacingStripesComments] = useState<
    string | undefined
  >(
    state.accessories.racingStripesComments ?? props.data.racingStripesComments
  );
  const [spec, setSpec] = useState(getLathamSpec(state));

  const [errorMessages, setErrorMessages] =
    useState<AccessoriesFormFieldsErrorMessageType>({
      yStrapQty: null,
      safetyStripesColor: null,
      safetyStripeWidth: null,
      solidShallowEndColor: null,
      divingTargetComments: null,
      racingStripesCount: null,
      racingStripesComments: null,
    });

  useEffect(() => {
    // since all values in this view are boolean the yup validation will always be successfull
    // to prevent this from happening we require that isViewed is true and update that value once this component mounts
    updateOrderState();
  }, []);

  // Validation state
  const [validFields, setValidFields] = useState<AccessoriesFormFields>(
    InitialAccessoriesFormValidationStatus()
  );
  const updateYStraps = (straps: SetStateAction<boolean>) => {
    setYStraps(straps);
    if (!straps) {
      setYStrapQty(undefined);
    }
  };
  useEffect(() => {
    if (safetyStripes == false) {
      setSafetyStripesColor(undefined);
    }
  }, [safetyStripes]);

  useEffect(() => {
    if (solidShallowEnd == false) {
      setSolidShallowEndColor(undefined);
    }
  }, [solidShallowEnd]);

  useEffect(() => {
    if (racingStripes == false) {
      setRacingStripesCount(undefined);
      setRacingStripesComments(undefined);
    }
  }, [racingStripes]);

  useEffect(() => {
    if (divingTarget == false) {
      setDivingTargetComments(undefined);
    }
  }, [divingTarget]);

  const formValues = {
    doubleD_Rings: doubleD_Rings,
    topAndBottomWebbing: topAndBottomWebbing,
    buckles: buckles,
    kemkap: kemkap,
    yStraps: yStraps,
    yStrapQty: yStrapQty,
    safetyStripes: safetyStripes,
    safetyStripesColor: safetyStripesColor,
    safetyStripeWidth: safetyStripes ? '12' : '0',
    solidShallowEnd: solidShallowEnd,
    solidShallowEndColor: solidShallowEndColor,
    divingTarget: divingTarget,
    divingTargetComments: divingTargetComments,
    racingStripes: racingStripes,
    racingStripesCount: racingStripesCount,
    racingStripesComments: racingStripesComments,
    isViewed: true,
  } as accessoriesOrderState;

  const getLinerAccessoryAttributesSpec = (lathamSpec: SpecFormPropTypes) => {
    lathamSpec.linerAccessoryAttributes = {
      ...lathamSpec.linerAccessoryAttributes,
      safetyStripeWidth: Number(formValues.safetyStripeWidth),
      safetyStripeColor: formValues.safetyStripesColor ?? '',
      materialSolidShallowColor: formValues.solidShallowEndColor ?? '',
      racingLanesComments: formValues.racingStripesComments ?? '',
      targetComments: formValues.divingTargetComments ?? '',
      safetyStripFeatureSId: formValues.safetyStripes ? 'F750_1' : '',
      targetFeatureSId: formValues['divingTarget'] ? 'F750_3' : '',
      materialSolidShallowFeatureSId: formValues['solidShallowEnd']
        ? 'F750_2'
        : '',
      racingLanesFeatureSId: getRacingStripesFeatureId(
        formValues['racingStripesCount']
      ),
    };
    return lathamSpec.linerAccessoryAttributes;
  };

  const getFullPerimeterCoverAccessoryAttributesSpec = (
    lathamSpec: SpecFormPropTypes
  ) => {
    lathamSpec.fullPerimeterCoverAccessoryAttributes = {
      ...lathamSpec.fullPerimeterCoverAccessoryAttributes,
      doubleDRingsFeatureSId: formValues.doubleD_Rings ? 'F780_15' : '',
      topBottomWebbingFeatureSId: formValues.topAndBottomWebbing
        ? 'F780_46'
        : '',
      bucklesFeatureSId: formValues.buckles ? 'F780_4' : '',
      yStrapAssembly: formValues.yStraps
        ? {
            yStrapFeatureId: 'F780_45',
            yStrapQty: Number(formValues.yStrapQty) ?? 0,
          }
        : { yStrapFeatureId: '', yStrapQty: 0 },
    };
    return lathamSpec.fullPerimeterCoverAccessoryAttributes;
  };

  const getRacingStripesFeatureId = (qty: string | undefined) => {
    switch (qty) {
      case '1':
        return 'F755_1';
      case '2':
        return 'F755_2';
      case '3':
        return 'F755_3';
      case '4':
        return 'F755_4';
      case '5':
        return 'F755_5';
      default:
        return '';
    }
  };

  const updateOrderState = () => {
    dispatch({
      type: 'updateAccessories',
      payload: formValues,
    });

    if (spec && spec.linerAccessoryAttributes) {
      setSpec({
        ...spec,
        linerAccessoryAttributes: getLinerAccessoryAttributesSpec(spec),
      });
      dispatch({
        type: 'updateSpec',
        payload: spec as SpecFormPropTypes,
      });
    }

    if (spec && spec.fullPerimeterCoverAccessoryAttributes) {
      setSpec({
        ...spec,
        fullPerimeterCoverAccessoryAttributes:
          getFullPerimeterCoverAccessoryAttributesSpec(spec),
      });
      dispatch({
        type: 'updateSpec',
        payload: spec as SpecFormPropTypes,
      });
    }
  };

  return (
    <div className="accessories-form">
      <div className="accessories-form__title">Accessories</div>
      <OrderForm
        onFieldsInvalid={updateOrderState}
        onFieldsValid={updateOrderState}
        values={formValues}
        schema={AccessoriesFormSchema}
        fieldErrorMessages={errorMessages}
        setFieldErrorMessages={setErrorMessages}
        setValidFields={
          setValidFields as React.Dispatch<
            React.SetStateAction<GenericFieldValidationType>
          >
        }
        initialValidationState={
          InitialAccessoriesFormValidationStatus() as GenericFieldValidationType
        }
      >
        <>
          {type === OrderType.Cover && (
            <>
              <ManagedToggle
                label="Double D-Rings (Non-buckle) Option/not upgrade"
                checked={doubleD_Rings}
                name="doubleD_Rings"
                onChange={setDoubleD_Rings}
                isValid={validFields.doubleD_Rings}
                disabled={disabled}
              />

              <hr className="accessories-form__hr" />

              <ManagedToggle
                label="Top and Bottom Webbing"
                checked={topAndBottomWebbing}
                name="topAndBottomWebbing"
                onChange={setTopAndBottomWebbing}
                isValid={validFields.topAndBottomWebbing}
                disabled={disabled}
              />

              <hr className="accessories-form__hr" />

              <ManagedToggle
                label="Buckles"
                checked={buckles}
                name="buckles"
                onChange={setBuckles}
                isValid={validFields.buckles}
                disabled={disabled}
              />

              <hr className="accessories-form__hr" />

              <ManagedToggle
                label="Y Strap"
                checked={yStraps}
                name="yStraps"
                onChange={updateYStraps}
                isValid={validFields.yStraps}
                disabled={disabled}
              />

              <>
                {yStraps && (
                  <ManagedInput
                    label="How Many?"
                    isValid={validFields.yStrapQty}
                    type="number"
                    min={0}
                    placeholder="Strap Quantity"
                    onChange={setYStrapQty}
                    errorMessage={errorMessages?.yStrapQty}
                    value={yStrapQty}
                  />
                )}
              </>
            </>
          )}

          {type === OrderType.Liner && (
            <>
              <ManagedToggle
                label="Safety Stripes"
                checked={safetyStripes}
                name="safetyStripes"
                onChange={setSafetyStripes}
                isValid={validFields.safetyStripes}
                disabled={disabled}
              />
              {safetyStripes && (
                <>
                  <ManagedSelect
                    label="Safety Stripes Color"
                    isValid={validFields.safetyStripesColor}
                    value={safetyStripesColor}
                    onChange={setSafetyStripesColor}
                    options={SafetyStripesOptions}
                    name="safetyStripesColor"
                    disabled={disabled}
                  />
                  <span className="featureContainer">
                    <i className="featureIcon exclamation triangle icon"></i>
                    <div className="featureWarning">
                      {
                        'Note: Safety stripe width is 12" and will be made in the color selected'
                      }
                    </div>
                  </span>
                </>
              )}

              <hr className="accessories-form__hr" />

              <ManagedToggle
                label="Clarity \ Diving Target"
                checked={divingTarget}
                name="divingTarget"
                onChange={setDivingTarget}
                isValid={validFields.divingTarget}
                disabled={disabled}
              />
              <>
                {divingTarget && (
                  <ManagedInput
                    label="Describe Location"
                    isValid={validFields.divingTargetComments}
                    type="text"
                    placeholder="Clarity \ Diving Target Location"
                    onChange={setDivingTargetComments}
                    errorMessage="Clarity \ Diving Target location must be specified."
                    value={divingTargetComments}
                  />
                )}
              </>

              <hr className="accessories-form__hr" />

              <ManagedToggle
                label="Racing Stripes"
                checked={racingStripes}
                name="racingStripes"
                onChange={setRacingStripes}
                isValid={validFields.racingStripes}
                disabled={disabled}
              />
              <>
                {racingStripes && (
                  <>
                    <ManagedSelect
                      label="How Many?"
                      isValid={validFields.racingStripesCount}
                      value={racingStripesCount}
                      onChange={setRacingStripesCount}
                      options={RacingStripeQuanityOptions}
                      name="racingStripesQuantity"
                    />
                    <ManagedInput
                      label="Describe Location"
                      isValid={validFields.racingStripesComments}
                      type="text"
                      placeholder="Racing Stripe Location"
                      onChange={setRacingStripesComments}
                      errorMessage="Racing Stripe location must be specified."
                      value={racingStripesComments}
                    />
                  </>
                )}
              </>
              <hr className="accessories-form__hr" />
              <ManagedToggle
                label="Solid Color Shallow End"
                checked={solidShallowEnd}
                name="solidShallowEnd"
                onChange={setSolidShallowEnd}
                isValid={validFields.solidShallowEnd}
                disabled={disabled}
              />
              <>
                {solidShallowEnd && (
                  <ManagedSelect
                    label="Solid Color Shallow End Color"
                    isValid={validFields.solidShallowEndColor}
                    value={solidShallowEndColor}
                    onChange={setSolidShallowEndColor}
                    options={SolidShallowEndOptions}
                    name="safetyStripesColor"
                    disabled={disabled}
                  />
                )}
              </>
            </>
          )}
        </>

        <hr className="accessories-form__hr" />
      </OrderForm>
    </div>
  );
};

export default AccessoriesForm;
