import './styles/semantic-ui/semantic.min.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import './styles/index.scss'; // must be imported last to be able to override!

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import registerServiceWroker from './registerServiceWorker'

const rootElement = document.getElementById('root');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  rootElement
);

//registerServiceWroker() no offline capabilites are required for now
