import * as PoolShapeSvg from './PoolShapeImages';
import { PoolShapeOptionsType } from './Project';

export const PoolShapeOptions: PoolShapeOptionsType[] = [
  {
    text: 'Rectangle',
    value: 'Rectangle',
    code: '01',
    orientation: '',
    type: 'geometric',
    image: {
      avatar: true,
      src: PoolShapeSvg.Rectangle,
    },
  },
  {
    text: 'Fiberglass Freeform',
    value: 'Fiberglass Freeform',
    code: '76',
    orientation: '',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.FiberglassFreeform,
    },
  },
  {
    text: 'Fiberglass Geometric',
    value: 'Fiberglass Geometric',
    code: '76',
    orientation: '',
    type: 'geometric',
    image: {
      avatar: true,
      src: PoolShapeSvg.FiberglassGeometric,
    },
  },
  {
    text: 'Oval',
    value: 'Oval',
    code: '02',
    orientation: '',
    type: 'geometric',
    image: {
      avatar: true,
      src: PoolShapeSvg.Oval,
    },
  },
  {
    text: 'Freeform',
    value: 'Freeform (Custom)',
    code: '88',
    orientation: '',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.Freeform,
    },
  },
  {
    text: 'Grecian',
    value: 'Grecian',
    code: '07',
    orientation: '',
    type: 'geometric',
    image: {
      avatar: true,
      src: PoolShapeSvg.Grecian,
    },
  },
  {
    text: 'Kidney Left',
    value: 'Kidney',
    code: '06',
    orientation: 'left',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.KidneyLeft,
    },
  },
  {
    text: 'Roman End',
    value: 'Roman End',
    code: '09',
    orientation: '',
    type: 'geometric',
    image: {
      avatar: true,
      src: PoolShapeSvg.RomanEnd,
    },
  },
  {
    text: 'Patrician',
    value: 'Patrician',
    code: '21',
    orientation: '',
    type: 'geometric',
    image: {
      avatar: true,
      src: PoolShapeSvg.Patrician,
    },
  },
  {
    text: 'Round',
    value: 'Round',
    code: '03',
    orientation: '',
    type: 'geometric',
    image: {
      avatar: true,
      src: PoolShapeSvg.Round,
    },
  },
  {
    text: 'Figure 8',
    value: 'Figure 8',
    code: '20',
    orientation: '',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.Figure8,
    },
  },
  {
    text: 'Octagon',
    value: 'Octagon',
    code: '08',
    orientation: '',
    type: 'geometric',
    image: {
      avatar: true,
      src: PoolShapeSvg.Octagon,
    },
  },
  {
    text: 'Kite',
    value: 'Kite',
    code: '59',
    orientation: '',
    type: 'geometric',
    image: {
      avatar: true,
      src: PoolShapeSvg.Kite,
    },
  },
  {
    text: 'Modified Oval',
    value: 'Modified Oval',
    code: '12',
    orientation: '',
    type: 'geometric',
    image: {
      avatar: true,
      src: PoolShapeSvg.ModifiedOval,
    },
  },
  {
    text: 'Keyhole',
    value: 'Keyhole',
    code: '45',
    orientation: '',
    type: 'geometric',
    image: {
      avatar: true,
      src: PoolShapeSvg.Keyhole,
    },
  },
  {
    text: 'Cancun/Blues Left',
    value: 'Cancun/Blues',
    code: '52',
    orientation: 'left',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.CancunBluesLeft,
    },
  },
  {
    text: 'Cancun/Blues Right',
    value: 'Cancun/Blues',
    code: '52',
    orientation: 'right',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.CancunBluesRight,
    },
  },
  {
    text: 'Diamond Left',
    value: 'Diamond',
    code: '54',
    orientation: 'left',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.DiamondLeft,
    },
  },
  {
    text: 'Diamond Right',
    value: 'Diamond',
    code: '54',
    orientation: 'right',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.DiamondRight,
    },
  },
  {
    text: 'Escale Left',
    value: 'Escale',
    code: '55',
    orientation: 'left',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.EscaleLeft,
    },
  },
  {
    text: 'Escale Right',
    value: 'Escale',
    code: '55',
    orientation: 'right',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.EscaleRight,
    },
  },
  {
    text: 'Gemini/Omni/Oasis/Cypress Left',
    value: 'Gemini/Omni/Oasis/Cypress',
    code: '32',
    orientation: 'left',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.GeminiOmniOasisCypressLeft,
    },
  },
  {
    text: 'Gemini/Omni/Oasis/Cypress Right',
    value: 'Gemini/Omni/Oasis/Cypress',
    code: '32',
    orientation: 'right',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.GeminiOmniOasisCypressRight,
    },
  },
  {
    text: 'Grecian Lazy L Left',
    value: 'Grecian Lazy L',
    code: '18',
    orientation: 'left',
    type: 'geometric',
    image: {
      avatar: true,
      src: PoolShapeSvg.GrecianLazyLLeft,
    },
  },
  {
    text: 'Grecian Lazy L Right',
    value: 'Grecian Lazy L',
    code: '18',
    orientation: 'right',
    type: 'geometric',
    image: {
      avatar: true,
      src: PoolShapeSvg.GrecianLazyLRight,
    },
  },
  {
    text: 'Grecian True L Left',
    value: 'Grecian True L',
    code: '16',
    orientation: 'left',
    type: 'geometric',
    image: {
      avatar: true,
      src: PoolShapeSvg.GrecianTrueLLeft,
    },
  },
  {
    text: 'Grecian True L Right',
    value: 'Grecian True L',
    code: '16',
    orientation: 'right',
    type: 'geometric',
    image: {
      avatar: true,
      src: PoolShapeSvg.GrecianTrueLRight,
    },
  },
  {
    text: 'Highlander Left',
    value: 'Highlander',
    code: '58',
    orientation: 'left',
    type: 'geometric',
    image: {
      avatar: true,
      src: PoolShapeSvg.HighlanderLeft,
    },
  },
  {
    text: 'Highlander Right',
    value: 'Highlander',
    code: '58',
    orientation: 'right',
    type: 'geometric',
    image: {
      avatar: true,
      src: PoolShapeSvg.HighlanderRight,
    },
  },
  {
    text: 'Kidney Right',
    value: 'Kidney',
    code: '06',
    orientation: 'right',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.KidneyRight,
    },
  },
  {
    text: 'Lazy L Left',
    value: 'Lazy L',
    code: '05',
    orientation: 'left',
    type: 'geometric',
    image: {
      avatar: true,
      src: PoolShapeSvg.LazyLLeft,
    },
  },
  {
    text: 'Lazy L Right',
    value: 'Lazy L',
    code: '05',
    orientation: 'right',
    type: 'geometric',
    image: {
      avatar: true,
      src: PoolShapeSvg.LazyLRight,
    },
  },
  {
    text: 'Liberty Left',
    value: 'Liberty',
    code: '61',
    orientation: 'left',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.LibertyLeft,
    },
  },
  {
    text: 'Liberty Right',
    value: 'Liberty',
    code: '61',
    orientation: 'right',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.LibertyRight,
    },
  },
  {
    text: 'Michigan/Sentra Left',
    value: 'Michigan/Sentra',
    code: '38',
    orientation: 'left',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.MichiganSentraLeft,
    },
  },
  {
    text: 'Michigan/Sentra Right',
    value: 'Michigan/Sentra',
    code: '38',
    orientation: 'right',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.MichiganSentraRight,
    },
  },
  {
    text: 'Monte Carlo / Jazz',
    value: 'Monte Carlo / Jazz',
    code: '63',
    orientation: '',
    type: 'geometric',
    image: {
      avatar: true,
      src: PoolShapeSvg.MonteCarloJazz,
    },
  },
  {
    text: 'Monterey/Niagra/Vermont Left',
    value: 'Monterey/Niagra/Vermont',
    code: '48',
    orientation: 'left',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.MontereyNiagraVermontLeft,
    },
  },
  {
    text: 'Monterey/Niagra/Vermont Right',
    value: 'Monterey/Niagra/Vermont',
    code: '48',
    orientation: 'right',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.MontereyNiagraVermontRight,
    },
  },
  {
    text: 'Mountain/Crystal Lake Left',
    value: 'Mountain/Crystal Lake',
    code: '23',
    orientation: 'left',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.MountainCrystalLakeLeft,
    },
  },
  {
    text: 'Mountain/Crystal Lake Right',
    value: 'Mountain/Crystal Lake',
    code: '23',
    orientation: 'right',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.MountainCrystalLakeRight,
    },
  },
  {
    text: 'Mountain Pond Left',
    value: 'Mountain Pond',
    code: '36',
    orientation: 'left',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.MountainPondLeft,
    },
  },
  {
    text: 'Mountain Pond Right',
    value: 'Mountain Pond',
    code: '36',
    orientation: 'right',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.MountainPondRight,
    },
  },
  {
    text: 'Norlander / Cape May Left',
    value: 'Norlander / Cape May',
    code: '65',
    orientation: 'left',
    type: 'geometric',
    image: {
      avatar: true,
      src: PoolShapeSvg.NorlanderCapeMayLeft,
    },
  },
  {
    text: 'Norlander / Cape May Right',
    value: 'Norlander / Cape May',
    code: '65',
    orientation: 'right',
    type: 'geometric',
    image: {
      avatar: true,
      src: PoolShapeSvg.NorlanderCapeMayRight,
    },
  },
  {
    text: 'Oblique/Celebrity Left',
    value: 'Oblique/Celebrity',
    code: '25',
    orientation: 'left',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.ObliqueCelebrityLeft,
    },
  },
  {
    text: 'Oblique/Celebrity Right',
    value: 'Oblique/Celebrity',
    code: '25',
    orientation: 'right',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.ObliqueCelebrityRight,
    },
  },
  {
    text: 'Odyssey Left',
    value: 'Odyssey',
    code: '50',
    orientation: 'left',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.OdysseyLeft,
    },
  },
  {
    text: 'Odyssey Right',
    value: 'Odyssey',
    code: '50',
    orientation: 'right',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.OdysseyRight,
    },
  },
  {
    text: 'Other(Point to point)',
    value: 'Other(Point to point)',
    code: '10',
    orientation: '',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.Other,
    },
  },
  {
    text: 'Pacific Lagoon Left',
    value: 'Pacific Lagoon',
    code: '34',
    orientation: 'left',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.PacificLagoonLeft,
    },
  },
  {
    text: 'Pacific Lagoon Right',
    value: 'Pacific Lagoon',
    code: '34',
    orientation: 'right',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.PacificLagoonRight,
    },
  },
  {
    text: 'Palette/Nautilus Left',
    value: 'Palette/Nautilus',
    code: '42',
    orientation: 'left',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.PaletteNautilusLeft,
    },
  },
  {
    text: 'Palette/Nautilus Right',
    value: 'Palette/Nautilus',
    code: '42',
    orientation: 'right',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.PaletteNautilusRight,
    },
  },
  {
    text: 'Rio Left',
    value: 'Rio',
    code: '44',
    orientation: 'left',
    type: 'geometric',
    image: {
      avatar: true,
      src: PoolShapeSvg.RioLeft,
    },
  },
  {
    text: 'Rio Right',
    value: 'Rio',
    code: '44',
    orientation: 'right',
    type: 'geometric',
    image: {
      avatar: true,
      src: PoolShapeSvg.RioRight,
    },
  },
  {
    text: 'Taormina Left',
    value: 'Taormina',
    code: '40',
    orientation: 'left',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.TaorminaLeft,
    },
  },
  {
    text: 'Taormina Right',
    value: 'Taormina',
    code: '40',
    orientation: 'right',
    type: 'freeform',
    image: {
      avatar: true,
      src: PoolShapeSvg.TaorminaRight,
    },
  },
  {
    text: 'True L Left',
    value: 'True L',
    code: '04',
    orientation: 'left',
    type: 'geometric',
    image: {
      avatar: true,
      src: PoolShapeSvg.TrueLLeft,
    },
  },
  {
    text: 'True L Right',
    value: 'True L',
    code: '04',
    orientation: 'right',
    type: 'geometric',
    image: {
      avatar: true,
      src: PoolShapeSvg.TrueLRight,
    },
  },
];
