import React, { useEffect, useState } from 'react';
import './SpecForm.scss';
import { ManagedInput } from 'components/orders/Fields/Input/ManagedInput';
import { PartialDecking } from './SpecFormSchema';

interface PartialDeckingProps {
  setPartialDecking: (partialDecking: PartialDecking) => void;
  partialDecking: PartialDecking;
  archived?: boolean;
}

export interface partialDeckingValuesType {
  id: string;
  anchorTypeOptions?: string;
  cableAssembly?: boolean;
  isRemovable?: boolean;
  stepFastener?: string;
  isCovered?: boolean;
  isOverWater?: boolean;
  notes?: string;
}

const PartialDeckingFeat = ({
  partialDecking,
  setPartialDecking,
  archived,
}: PartialDeckingProps) => {
  // Field states
  const [partialDeckingLength, setPartialDeckingLength] = useState<
    string | undefined
  >(partialDecking?.partialDeckingLength?.toString());
  const [partialDeckingType, setPartialDeckingType] = useState<
    string | undefined
  >(partialDecking?.partialDeckingType);
  const [partialDeckingFeatureId, setPartialDeckingFeatureId] = useState<
    string | undefined
  >(partialDecking?.partialDeckingFeatureId);
  const [partialDeckingPaddingLength, setPartialDeckingPaddingLength] =
    useState<string | undefined>(
      partialDecking?.partialDeckingPaddingLength?.toString()
    );
  const [partialDeckingPaddingFeatureId, setPartialDeckingPaddingFeatureId] =
    useState<string | undefined>(
      partialDecking?.partialDeckingPaddingFeatureId
    );

  useEffect(() => {
    setPartialDecking({
      partialDeckingLength: parseInt(partialDeckingLength ?? '0'),
      partialDeckingType: partialDeckingType ?? '',
      partialDeckingFeatureId: partialDeckingFeatureId ?? '',
      partialDeckingPaddingLength: parseInt(partialDeckingPaddingLength ?? '0'),
      partialDeckingPaddingFeatureId: partialDeckingPaddingFeatureId ?? '',
    });
  }, [
    partialDeckingLength,
    partialDeckingType,
    partialDeckingFeatureId,
    partialDeckingPaddingLength,
    partialDeckingPaddingFeatureId,
  ]);

  return (
    <>
      <div className="spec-form__second-title">
        {`Decking: ${partialDeckingType}`}
      </div>
      <ManagedInput
        isValid={true}
        label="Partial Decking Length"
        type="text"
        placeholder="Partial Decking Length"
        onChange={setPartialDeckingLength}
        value={partialDeckingLength}
        disabled={archived}
      />
      <ManagedInput
        isValid={true}
        label="Partial Decking Type"
        type="text"
        placeholder="Partial Decking Type"
        onChange={setPartialDeckingType}
        value={partialDeckingType}
        disabled={archived}
      />
      <ManagedInput
        isValid={true}
        label="Partial Decking FeatureId"
        type="text"
        placeholder="Partial Decking FeatureId"
        onChange={setPartialDeckingFeatureId}
        value={partialDeckingFeatureId}
        disabled={archived}
      />
      <ManagedInput
        isValid={true}
        label="Partial Decking Padding Length"
        type="text"
        placeholder="Partial Decking Padding Length"
        onChange={setPartialDeckingPaddingLength}
        value={partialDeckingPaddingLength}
        disabled={archived}
      />
      <ManagedInput
        isValid={true}
        label="Partial Decking Padding FeatureId"
        type="text"
        placeholder="Partial Decking Padding FeatureId"
        onChange={setPartialDeckingPaddingFeatureId}
        value={partialDeckingPaddingFeatureId}
        disabled={archived}
      />
    </>
  );
};

export default PartialDeckingFeat;
