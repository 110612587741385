import { Stepper, TransitionGroup } from 'components';
import { _bemHelper } from 'components/orders/Fields/FieldHelpers';
import { BaseDeckFormType } from 'components/orders/Forms/Partials/Features/Feature/FeatureSchema';
import SaveButton from 'components/orders/SaveButton/SaveButton';
import { OrderStatus, OrderType, Resource } from 'models';
import { Feature, FeatureType, labelFeatures } from 'models/Feature';
import { ModelInstance } from 'models/Model';
import { FeatureMeasurements, Project, ProjectModel } from 'models/Project';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ReactComponent as Cross } from 'res/img/cross.svg';
import UserService from 'sections/user/UserService';
import {
  Card,
  Confirm,
  Form,
  Header,
  Modal,
  Ref,
  Segment,
  Tab,
} from 'semantic-ui-react';
import { Role, useAuth, useUI } from 'services';
import { ORDER_MSG, USER_MSG } from 'strings';
import { catchAndThrowErrors } from 'utils';
import { OrdersService } from '.';
import {
  OrderModal,
  OrderModalTitle,
} from '../../components/orders/OrderModal/OrderModal';
import { FIRST_STEP } from '../../components/orders/Stepper/Stepper';
import {
  accessoriesOrderState,
  anchorsOrderState,
  featureOrderState,
  OrderContext,
  OrderState,
  projectOrderState,
  shippingOrderState,
} from '../../context/OrderContext';
import {
  OrderBody,
  OrderQuote,
  ServerOrder,
  ServerProduct,
  OrderQuoteStatus,
} from '../../models/Order';
import { ProjectService } from '../projects';
import './Orders.scss';
import {
  checkFeatureOrderType,
  isLinerFeature,
} from 'components/orders/Forms/Partials/Features/Feature/FeatureConstants';
import { useFeatureFlags } from 'services';
import '../projects/ProjectMeasurement.scss';

const QUOTES_AND_ORDERS_TITLE = 'Quotes and Orders';
const SUBMITTED_BY_TITLE = 'Submitted By';
const ORDER_STATUS_TITLE = 'Status';
const QUOTE_DETAILS_TITLE = 'View Quote Details';
const ORDER_DETAILS_TITLE = 'View Order Details';
const INITIAL_STEP = 0;
type template = (vars: { [key: string]: any }) => string;
const EMAIL = {
  to: 'jasonkeith@lathampool.com, billgregware@lathampool.com',
  subject: (({ dealer, project }) =>
    encodeURIComponent(
      `Latham Measure Order - ${dealer} - ${project}`
    )) as template,
  body: (({ url, msg }) =>
    encodeURIComponent(`-------------[ PROJECT DATA ]-------------

${url}
-------------[ DEALER MESSAGE ]-------------
${msg}
------------------[ END ]--------------------
`)) as template,
};

type WithRequestedBy<T> = T & { requestedBy: string };

type OrdersProps = {
  project: Project;
  isProjectValid: boolean;
  updateProject: React.Dispatch<React.SetStateAction<ModelInstance<Project>>>;
  fetchProject: () => Promise<void>;
};

export type LabeledResources = {
  id?: string;
  label: string;
  type: FeatureType;
  featureName: string;
  notes?: string;
  images: Resource[];
  length?: number;
  isRemovable?: boolean;
  isCovered?: boolean;
  measurement?: FeatureMeasurements;
  stepMaterialOption?: string;
  stepMaterial?: string;
  stepStripe?: string;
  stripeWidth?: number;
  differentTreads?: string;
  riserMaterial?: string;
  stepFastener?: string;
  stepComment?: string;
};

type Error = {
  statusCode: number;
  message: string;
  error: string;
};

export default function Orders({
  project,
  isProjectValid,
  updateProject,
  fetchProject,
}: OrdersProps): React.ReactElement {
  const emailMsg = useRef({} as HTMLTextAreaElement);
  const { state, dispatch } = React.useContext(OrderContext);
  const flags = useFeatureFlags();

  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [orders, setOrders] = useState([] as ServerOrder[]);
  const [currentStep, setCurrentStep] = useState(INITIAL_STEP);
  const [isLastStep, setIsLastStep] = useState(false);
  const [submitUrl, setSubmitUrl] = useState(null as string | null);
  const [confirmButtonText, setConfirmButtonText] = useState<string>('');
  const [modalTitle, setModalTitle] = useState<OrderModalTitle>('New Quote');
  const [showArchived, setShowArchived] = useState<boolean>(false);
  const [waitingForLathamResponse, setWaitingForLathamResponse] =
    useState(false);
  const { showError, showSuccess } = useUI();
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false);
  const [isArchiveConfirmModalOpen, setIsArchiveConfirmModalOpen] =
    useState(false);
  const [orderToDelete, setOrderToDelete] = useState<ServerOrder | undefined>();
  const [confirmHeaderText, setConfirmHeaderText] = useState('');
  const [confirmBodyText, setConfirmBodyText] = useState('');
  const { user } = useAuth();
  const hasAccess = user?.role !== Role.DESIGN && user?.role !== Role.SALES;
  const hasReviewAccess = user?.role !== Role.ADMIN && user?.role !== Role.USER;

  const LAST_STEP = state.steps.length - 1;

  useEffect(() => {
    setModalTitle(getTitle());
  }, [currentStep]);

  const fetchOrders = async () => {
    try {
      const freshOrders = await ProjectService.getOrders(project.id);
      const ordersWithRequestedBy = await appendUserInfoToOrder(freshOrders);
      setOrders(
        ordersWithRequestedBy.sort((a, b) => {
          return a.createdAt > b.createdAt ? 1 : -1;
        })
      );

      //@todo This needs to be handled in react state instead of mutating an object
      project.orders = freshOrders;
    } catch (e) {
      console.log(e);
    }
  };

  const appendUserInfoToOrder = (orders: ServerOrder[]) => {
    return Promise.all(
      orders.map(async (order) => {
        if (order?.requestedByUserId) {
          const user = await UserService.getById(order?.requestedByUserId);
          (order as WithRequestedBy<ServerOrder>).requestedBy =
            user?.name ?? 'Unavailable';
        } else if (order?.quoteId && order.quotes?.length) {
          const id = order.quotes.find((quote) => quote.id === order.quoteId)
            ?.requestedBy as string;
          const user = await UserService.getById(id);
          (order as WithRequestedBy<ServerOrder>).requestedBy =
            user?.name ?? 'Unavailable';
        }
        return order;
      })
    );
  };

  useEffect(() => {
    fetchOrders();
  }, [JSON.stringify(state.order)]);

  // TODO move this out of this component?
  const fetchFeatures = async () => {
    try {
      const allFeatures = await ProjectService.getFeatures(project.id);
      const { features, decks } = labelFeatures(allFeatures);

      dispatch({
        type: 'updateState',
        payload: {
          featuresData: features.filter((feat) =>
            checkFeatureOrderType(feat.type, state.project?.type ?? '')
          ),
          decksData: decks,
        } as Partial<OrderState>,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const openModal = (order?: ServerOrder): void => {
    if (project?.poolDetails?.poolShape?.baseName) {
      if (order && order?.features && order?.accessories) {
        dispatch({
          type: 'updateState',
          payload: {
            order,
            features: order.features as featureOrderState,
            anchors: order.anchors as anchorsOrderState,
            accessories: order.accessories as accessoriesOrderState,
            shipping: order.shipping as shippingOrderState,
            project: state.project as projectOrderState,
            //@ts-ignore @todo fix
            product: order.product,
          },
        });
      } else {
        dispatch({
          type: 'updateState',
          payload: {
            ...state,
            features: { ...state.features, isViewed: false },
            accessories: { ...state.accessories, isViewed: false },
          },
        });
      }
      setCurrentStep(FIRST_STEP);
      setIsOrderModalOpen(true);
    } else {
      showError(USER_MSG.ERROR_NEW_QUOTE);
    }
  };

  const goToStep = (step: number) => {
    // Save project when navigating away from project step only
    if (currentStep === FIRST_STEP) {
      saveProject();
    }
    setCurrentStep(step);
  };

  const openConfirmationModal = async () => {
    submitToLatham();
  };

  const openEmailClient = useCallback(() => {
    const { to, subject, body } = EMAIL;
    const mailto = `mailto:${to}?subject=${subject({
      project: project.name,
      dealer: project.dealerId,
    })}&body=${body({ url: submitUrl, msg: emailMsg.current?.value })}`;
    window.open(mailto, '_blank');
    setIsConfirmModalOpen(false);
    dispatch({ type: 'resetOrder' });
    setSubmitUrl(null);
  }, [project.name, project.dealerId, emailMsg, submitUrl]);

  const cancelOrder = useCallback(() => {
    setIsOrderModalOpen(false);
    dispatch({ type: 'resetOrder' });
  }, [project.dealerId, project.id]);

  const createOrder = async (submitOrder?: boolean) => {
    const proj = state.project;
    delete proj.poNumber;

    const newOrder: OrderBody = {
      product: state.product as ServerProduct,
      project: state.project,
      features: state.features,
      anchors: state.anchors,
      accessories: state.accessories,
      shipping: state.shipping,
      lathamSpec: state.spec,
      projectId: project.id,
      dealerId: project.dealerId,
      poNumber: state.project.poNumber,
      type: state.project.type,
      status: OrderStatus.Saved,
      requestedByUserId: user?.id,
      requestedByEmail: user?.email,
    };
    return await OrdersService.add(newOrder)
      .then(async (order) => {
        dispatch({
          type: 'updateState',
          payload: {
            order: order as ServerOrder,
          },
        });
        fetchOrders();
        setIsOrderModalOpen(false);

        // saved -> quote in review -> ready for order -> submitted
        const needsReview = quoteNeedsReview();
        if (submitOrder) {
          if (!isProjectValid) {
            showError(USER_MSG.ERROR_INCOMPLETE_PROJECT);
          } else if (order?.id && !waitingForLathamResponse) {
            let request: Function;
            let successMessage: { title: string; msg: string };
            if (order.status === OrderStatus.ReadyForOrder) {
              request = ProjectService.submitQuotedOrder;
              successMessage = USER_MSG.SUCCESS_ORDER_SUBMIT;
            } else if (order.status === OrderStatus.Saved && needsReview) {
              request = ProjectService.submitQuoteForReview;
              successMessage = USER_MSG.SUCCESS_QUOTE_SUBMIT;
            } else {
              request = ProjectService.submitOrderForQuote;
              successMessage = USER_MSG.SUCCESS_QUOTE_SUBMIT;
            }

            setWaitingForLathamResponse(true);
            await request(project.id, order.id)
              .then(async () => {
                showSuccess(successMessage);
                await fetchProject();
                await fetchOrders();
              })
              .catch((error: Error) => {
                catchAndThrowErrors(
                  error,
                  showError,
                  USER_MSG.ERROR_MEASUREMENT_DATA
                );
              })
              .finally(() => {
                setWaitingForLathamResponse(false);
                setIsConfirmModalOpen(false);
                dispatch({ type: 'resetOrder' });
              });
          }
        }
      })
      .catch((error) => {
        catchAndThrowErrors(error, showError, USER_MSG.ERROR_CREATING_ORDER);
      });
  };

  const saveProject = async () => {
    try {
      // features are saved in the project object so we get the feature from there, then update it with the
      // updated feature information from the order
      const newFeatures: Feature[] = [];
      if (project?.features) {
        project?.features.forEach((el: Feature, i: number) => {
          const stateFeat = state.features.features
            ? state.features.features.filter(
                (f: BaseDeckFormType) => f.id === el.id
              )[0]
            : undefined;
          if (project?.features) {
            const feat = {
              ...project?.features[i],
              notes: stateFeat?.notes ?? el?.notes,
              isRemovable: stateFeat?.isRemovable ?? false,
              isCovered: stateFeat?.isCovered ?? false,
            };
            // @ts-ignore
            delete feat.isOverWater;
            newFeatures.push(feat);
          }
        });
      }

      // Update the project's address
      const newProject = {
        ...project,
        id: project.id,
        address: {
          address1: state.project.address1,
          address2: state.project.address2,
          city: state.project.city,
          state: state.project.shippingState,
          postalcode: state.project.postalCode,
        },
        customer: {
          name: state.project.primaryContactName,
          email: project.customer?.email,
          phone: project.customer?.phone,
        },
        features: newFeatures,
      };

      if (JSON.stringify(project) !== JSON.stringify(newProject)) {
        // trim data to transfer
        const data = { ...newProject };
        delete data.resources;
        delete data.scans;
        delete data.orders;

        // update the project and refresh the form data
        await ProjectService.set(data, 'PATCH', false).catch((error) => {
          catchAndThrowErrors(error, showError, USER_MSG.ERROR_SAVING_PROJECT);
        });
        updateProject(ProjectModel({ ...newProject }));
      }
    } catch (err) {
      const error = err as Error;
      catchAndThrowErrors(error, showError, USER_MSG.ERROR_SAVING_PROJECT);
    }
  };

  const saveOrder = async (submitOrder?: boolean) => {
    try {
      await saveProject();

      // Order ID is created on initial POST, we'll use its status to differentiate between create and update actions
      if (state?.order?.id) {
        // requested by and project are fields used only by the client, it should not be included in the order payload
        delete state.order?.requestedBy;
        delete state.order?.project;

        const newOrder: ServerOrder = {
          ...state.order,
          poNumber: state.project.poNumber,
          type: state.project.type,
          product: { ...state.product } as ServerProduct,
          features: state.features,
          anchors: state.anchors,
          shipping: state.shipping,
          lathamSpec: state.spec,
          status: OrderStatus.Saved,
          accessories: state.accessories,
        };

        await OrdersService.set(newOrder, 'PATCH', false)
          .then(() => {
            dispatch({
              type: 'updateOrder',
              payload: { status: OrderStatus.Saved },
            });
            showSuccess(
              state.order?.quoteId
                ? USER_MSG.SUCCESS_ORDER_SAVE
                : USER_MSG.SUCCESS_QUOTE_SAVE
            );
          })
          .catch((error) => {
            catchAndThrowErrors(error, showError, USER_MSG.ERROR_SAVING_ORDER);
          });
      } else {
        createOrder(submitOrder);
        showSuccess(USER_MSG.SUCCESS_QUOTE_CREATE);
      }
    } catch (err) {
      const error = err as Error;
      catchAndThrowErrors(error, showError, USER_MSG.ERROR_SAVING_ORDER);
    }
    setIsOrderModalOpen(false);
    dispatch({ type: 'resetOrder' });
    await fetchOrders();
  };

  const deleteOrder = (order?: ServerOrder) => {
    try {
      const showOrderMsg =
        !order ||
        order?.status === OrderStatus.QuotedOptions ||
        order?.status === OrderStatus.ReadyForOrder;
      const orderId = state.order?.id ?? order?.id;
      if (project.id && orderId) {
        ProjectService.deleteOrder(project.id, orderId)
          .then(async () => {
            setIsOrderModalOpen(false);
            showSuccess(
              showOrderMsg
                ? USER_MSG.SUCCESS_ORDER_DELETE
                : USER_MSG.SUCCESS_QUOTE_CANCEL
            );
            dispatch({ type: 'resetOrder' });
            await fetchOrders();
            await fetchProject();
          })
          .catch((error) => {
            catchAndThrowErrors(
              error,
              showError,
              showOrderMsg
                ? USER_MSG.ERROR_DELETING_ORDER
                : USER_MSG.ERROR_DELETING_QUOTE
            );
          });
      }
    } catch (err) {
      const error = err as Error;
      catchAndThrowErrors(error, showError, USER_MSG.ERROR_DELETING_ORDER);
    }
  };

  const confirmDeleteOrder = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    order: ServerOrder
  ) => {
    const isAfterQuote = order.status === OrderStatus.Ordered;
    setOrderToDelete(order);
    setConfirmHeaderText(
      isAfterQuote ? ORDER_MSG.DELETE_ORDER : ORDER_MSG.CANCEL_QUOTE
    );
    setConfirmBodyText(
      isAfterQuote ? ORDER_MSG.DELETE : ORDER_MSG.CANCEL_QUOTE
    );
    e.stopPropagation();
    setIsDeleteConfirmModalOpen(true);
  };

  const archiveOrder = async (order?: ServerOrder) => {
    if (order?.id) {
      // requested by is a field used only by the client, it should not be included in the order payload
      delete order?.requestedBy;

      const newOrder: ServerOrder = {
        ...order,
        archived: !order.archived,
      };

      await OrdersService.set(newOrder, 'PATCH', false)
        .then(() => {
          dispatch({
            type: 'updateOrder',
            payload: { status: newOrder.status },
          });
          if (newOrder.archived) {
            showSuccess(
              newOrder.status === OrderStatus.Ordered
                ? USER_MSG.SUCCESS_ORDER_ARCHIVE
                : USER_MSG.SUCCESS_QUOTE_ARCHIVE
            );
          } else {
            showSuccess(
              newOrder.status === OrderStatus.Ordered
                ? USER_MSG.SUCCESS_ORDER_UNARCHIVE
                : USER_MSG.SUCCESS_QUOTE_UNARCHIVE
            );
          }
        })
        .catch((error) => {
          console.log('failed updating order', error, newOrder);
        });

      await fetchOrders();
    }
  };

  const confirmArchiveOrder = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    order: ServerOrder
  ) => {
    const isAfterQuote = order.status === OrderStatus.Ordered;
    setOrderToDelete(order);
    if (isAfterQuote) {
      setConfirmHeaderText(
        order?.archived ? ORDER_MSG.ORDER_UNARCHIVE : ORDER_MSG.ORDER_ARCHIVE
      );
    } else {
      setConfirmHeaderText(
        order?.archived ? ORDER_MSG.QUOTE_UNARCHIVE : ORDER_MSG.QUOTE_ARCHIVE
      );
    }
    setConfirmBodyText(
      order?.archived ? ORDER_MSG.UNARCHIVE : ORDER_MSG.ARCHIVE
    );
    e.stopPropagation();
    setIsArchiveConfirmModalOpen(true);
  };

  const cancelQuote = () => {
    try {
      if (project.id && state.order?.id) {
        ProjectService.cancelQuote(project.id, state.order.id)
          .then(() => {
            setIsOrderModalOpen(false);
            showSuccess(USER_MSG.SUCCESS_QUOTE_CANCEL);
            dispatch({ type: 'resetOrder' });
            fetchOrders();
          })
          .catch((error) => {
            catchAndThrowErrors(
              error,
              showError,
              USER_MSG.ERROR_DELETING_QUOTE
            );
          });
      }
    } catch (err) {
      const error = err as Error;
      catchAndThrowErrors(error, showError, USER_MSG.ERROR_DELETING_QUOTE);
    }
  };

  const quoteNeedsReview = () => {
    const hasFeatures =
      state.features?.features &&
      state.features.features.filter(
        (feat) => !isLinerFeature(feat.featureType)
      ).length > 0;
    const hasStepFeatureOnly =
      state.features?.features?.filter(
        (feat) => feat.featureType === FeatureType.Steps
      ).length === state.features?.features?.length;
    const hardware: string[] = [];
    if (state.anchors && state.anchors.decks) {
      state.anchors.decks?.forEach((deck) =>
        hardware.includes(deck.hardware as string)
          ? null
          : hardware.push(deck.hardware as string)
      );
    }
    if (state.project && state.project.type === OrderType.Cover) {
      // if there are no features and has only 1 anchor type
      if (!hasFeatures && hardware.length <= 1) {
        return false;
      }
      // if the only feature type is outside step and has only 1 anchor type
      if (hasStepFeatureOnly && hardware.length <= 1) {
        return false;
      }
      return true;
    }
    return true;
  };

  const submitToLatham = async () => {
    await saveOrder(true);
    const needsReview = quoteNeedsReview();
    if (!isProjectValid) {
      showError(USER_MSG.ERROR_INCOMPLETE_PROJECT);
    } else if (state.order?.id && !waitingForLathamResponse) {
      let request: Function;
      let successMessage: { title: string; msg: string };
      if (state.order.status === OrderStatus.ReadyForOrder) {
        request = ProjectService.submitQuotedOrder;
        successMessage = USER_MSG.SUCCESS_ORDER_SUBMIT;
      } else if (state.order.status === OrderStatus.Saved && needsReview) {
        request = ProjectService.submitQuoteForReview;
        successMessage = USER_MSG.SUCCESS_QUOTE_SUBMIT;
      } else {
        request = ProjectService.submitOrderForQuote;
        successMessage = USER_MSG.SUCCESS_QUOTE_SUBMIT;
      }

      setWaitingForLathamResponse(true);
      await request(project.id, state.order.id)
        .then(async () => {
          showSuccess(successMessage);
          await fetchProject();
          await fetchOrders();
        })
        .catch((error: Error) => {
          catchAndThrowErrors(
            error,
            showError,
            USER_MSG.ERROR_MEASUREMENT_DATA
          );
        })
        .finally(() => {
          setWaitingForLathamResponse(false);
          setIsOrderModalOpen(false);
          setIsConfirmModalOpen(false);
        });
    }
  };

  useEffect(() => {
    dispatch({ type: 'updateStep', payload: currentStep });
    setIsLastStep(currentStep === LAST_STEP);
  }, [currentStep]);

  useEffect(() => {
    if (state.order?.status === OrderStatus.Ordered) {
      setCurrentStep(LAST_STEP);
    }
  }, [state.order?.status]);

  useEffect(() => {
    if (!submitUrl) return;
    if (isProjectValid) {
      openEmailClient();
    } else {
      showError(USER_MSG.ERROR_INCOMPLETE_PROJECT);
    }
  }, [submitUrl]);

  useEffect(() => {
    if (project.id) {
      fetchOrders();
      fetchFeatures();
    } else {
      console.warn('fetching no has id');
      console.warn(project);
    }
  }, [project.id]);

  useEffect(() => {
    if (project.id && state.project?.type) {
      fetchFeatures();
    } else {
      console.warn('fetching no has id');
      console.warn(project);
    }
  }, [state.project?.type]);

  useEffect(() => {
    const text = waitingForLathamResponse
      ? 'Processing...'
      : state.order?.status === OrderStatus.Saved
      ? 'Submit for Quote'
      : 'Submit Order to Latham';

    setConfirmButtonText(text);
  }, [state.order?.status, waitingForLathamResponse]);

  useEffect(() => {
    const title = getTitle();
    setModalTitle(title);
  }, [state.order?.status]);

  const getTitle = (): OrderModalTitle => {
    if (state.order?.id) {
      switch (state.order.status) {
        case OrderStatus.Ordered:
          return 'Review Order';
        case OrderStatus.QuotedOptions:
        case OrderStatus.ReadyForOrder: {
          const productType =
            state.order.type === OrderType.Liner ? 'Liner' : 'Cover';
          return `Finalize Order - ${productType}` as OrderModalTitle;
        }
        default:
          return 'Resume Quote';
      }
    }
    return 'New Quote';
  };

  const getLatestQuote = (order: ServerOrder): OrderQuote | undefined => {
    return order.quotes?.find(
      (quote) =>
        quote.id === order.quoteId && quote.status === OrderQuoteStatus.Valid
    );
  };

  const getQuoteDateText = (order: ServerOrder): string => {
    let subject: string;
    let dateObject: string;
    switch (order?.status) {
      case OrderStatus.Saved: {
        subject = 'Last Saved';
        dateObject = order.updatedAt;
        break;
      }
      case OrderStatus.QuotedOptions:
      case OrderStatus.ReadyForOrder: {
        subject = 'Submitted Quote';
        dateObject =
          (getLatestQuote(order)?.quotedAt as string) ?? order.updatedAt;
        break;
      }
      case OrderStatus.Ordered: {
        subject = 'Ordered';
        dateObject = order.updatedAt;
        break;
      }
      case OrderStatus.QuoteInReview: {
        subject = 'Quote In Review';
        dateObject = order.updatedAt;
        break;
      }
      default:
        return '';
    }

    const d = new Date(dateObject);
    const date = d.toLocaleDateString();
    const time = d.toLocaleTimeString();

    return `${subject} on ${date} at ${time}`;
  };

  const toggleArchivedFilter = () => {
    setShowArchived(!showArchived);
  };

  const getArchiveText = () => {
    return `${showArchived ? 'Hide' : 'Show'} archived Quotes and Orders`;
  };

  const disableQuoteAndOrderForVinylLiner =
    (state.project?.poolShape?.baseName ?? state.project?.poolShapeType) ===
      'Fiberglass Geometric' ||
    (state.project?.poolShape?.baseName ?? state.project?.poolShapeType) ===
      'Fiberglass Freeform';

  const OrderCard = (order: ServerOrder, i: number) => {
    return (
      <>
        <Card
          className="ui card"
          key={i}
          onClick={() => openModal(order)}
          style={{
            background: order.archived ? '#e0e1e2' : '#fff',
          }}
        >
          <Card.Header>
            {order?.type === OrderType.Liner
              ? OrderType.Liner
              : OrderType.Cover}
            <div className="archive-icon">
              <span
                className={`tooltiptext archive-tooltiptext ${
                  order?.archived ? 'unarchive' : 'archive'
                }`}
              >{`Click to ${
                order?.archived ? ORDER_MSG.UNARCHIVE : ORDER_MSG.ARCHIVE
              }`}</span>
              <i
                onClick={(e) => confirmArchiveOrder(e, order)}
                className="archive icon icon-btn"
              />
            </div>
            {(order?.status === OrderStatus.Saved ||
              order?.status === OrderStatus.QuotedOptions ||
              order?.status === OrderStatus.QuoteInReview ||
              order?.status === OrderStatus.ReadyForOrder) && (
              <div className="delete-icon">
                <span className="tooltiptext delete-tooltiptext">
                  Click to Delete
                </span>
                <i
                  onClick={(e) => confirmDeleteOrder(e, order)}
                  className="trash alternate icon icon-btn"
                />
              </div>
            )}
          </Card.Header>
          {!!order.quoteId && (
            <Card.Meta>
              {SUBMITTED_BY_TITLE}
              <Card.Description>
                {(order as WithRequestedBy<ServerOrder>).requestedBy}
              </Card.Description>
            </Card.Meta>
          )}
          <Card.Meta>
            {ORDER_STATUS_TITLE}
            <Card.Description>{getQuoteDateText(order)}</Card.Description>
            {order.quotes &&
              order.quotes?.filter(
                (quote) => quote.status === OrderQuoteStatus.Valid
              ).length > 0 && (
                <Card.Description>
                  <div onMouseDown={(e) => e.stopPropagation()}>
                    <a
                      href={
                        order.quoteId
                          ? getLatestQuote(order)?.lathamLinkResponse
                              .quoteOrderUrl
                          : ''
                      }
                      rel="noreferrer"
                      target="_blank"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {order.status === OrderStatus.Ordered
                        ? ORDER_DETAILS_TITLE
                        : QUOTE_DETAILS_TITLE}
                    </a>
                  </div>
                </Card.Description>
              )}
          </Card.Meta>
        </Card>
      </>
    );
  };

  const panes = [
    {
      menuItem: 'Quotes',
      render: () => (
        <Tab.Pane attached={false}>
          {
            <div className="order-card-group">
              {hasAccess && (
                <Card
                  onClick={() => openModal(state.order)}
                  data-cy="create-order-button"
                >
                  <Cross />
                </Card>
              )}
              {flags.showLinerUi && !disableQuoteAndOrderForVinylLiner ? (
                <div className="order-feature-card-group">
                  <div className={'cover-card-group'}>
                    <Header size="medium" className="feature-header">
                      {OrderType.Cover}
                    </Header>
                    {orders
                      ?.filter((o) => {
                        if (
                          o.status !== OrderStatus.Ordered &&
                          o.type !== OrderType.Liner
                        ) {
                          if (!showArchived) {
                            return !o.archived;
                          }
                          return o;
                        }
                      })
                      .map((order, i) => OrderCard(order, i))}
                  </div>
                  <div className="vertical-line"></div>
                  <div className={'liner-card-group'}>
                    <Header size="medium" className="feature-header">
                      {OrderType.Liner}
                    </Header>
                    {orders
                      ?.filter((o) => {
                        if (
                          o.status !== OrderStatus.Ordered &&
                          o.type === OrderType.Liner
                        ) {
                          if (!showArchived) {
                            return !o.archived;
                          }
                          return o;
                        }
                      })
                      .map((order, i) => OrderCard(order, i))}
                  </div>
                </div>
              ) : (
                <div className="card-group">
                  {orders
                    ?.filter((o) => {
                      if (
                        o.status !== OrderStatus.Ordered &&
                        o.type !== OrderType.Liner
                      ) {
                        if (!showArchived) {
                          return !o.archived;
                        }
                        return o;
                      }
                    })
                    .map((order, i) => OrderCard(order, i))}
                </div>
              )}
            </div>
          }
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Orders',
      render: () => (
        <Tab.Pane attached={false}>
          {
            <div className="order-card-group">
              {flags.showLinerUi ? (
                <div className="order-feature-card-group">
                  <div className={'cover-card-group'}>
                    <Header size="medium" className="feature-header">
                      {OrderType.Cover}
                    </Header>
                    {orders
                      ?.filter((o) => {
                        if (
                          o.status === OrderStatus.Ordered &&
                          o.type !== OrderType.Liner
                        ) {
                          if (!showArchived) {
                            return !o.archived;
                          }
                          return o;
                        }
                      })
                      .map((order, i) => OrderCard(order, i))}
                  </div>
                  <div className="vertical-line"></div>
                  <div className={'liner-card-group'}>
                    <Header size="medium" className="feature-header">
                      {OrderType.Liner}
                    </Header>
                    {orders
                      ?.filter((o) => {
                        if (
                          o.status === OrderStatus.Ordered &&
                          o.type === OrderType.Liner
                        ) {
                          if (!showArchived) {
                            return !o.archived;
                          }
                          return o;
                        }
                      })
                      .map((order, i) => OrderCard(order, i))}
                  </div>
                </div>
              ) : (
                <div className="card-group">
                  {orders
                    ?.filter((o) => {
                      if (
                        o.status === OrderStatus.Ordered &&
                        o.type !== OrderType.Liner
                      ) {
                        if (!showArchived) {
                          return !o.archived;
                        }
                        return o;
                      }
                    })
                    .map((order, i) => OrderCard(order, i))}
                </div>
              )}
            </div>
          }
        </Tab.Pane>
      ),
    },
  ];

  return (
    <TransitionGroup isVisible={!!orders}>
      <Segment className="pad">
        <Header size="large">{QUOTES_AND_ORDERS_TITLE}</Header>
        <button
          className="archive-button"
          onClick={() => toggleArchivedFilter()}
        >
          {getArchiveText()}
        </button>
        <Tab menu={{ pointing: true }} className="orders-tab" panes={panes} />
      </Segment>
      <Confirm
        size="mini"
        open={isDeleteConfirmModalOpen}
        header={confirmHeaderText}
        content={'Are you sure?'}
        onConfirm={() => {
          deleteOrder(orderToDelete);
          setIsDeleteConfirmModalOpen(false);
        }}
        confirmButton={confirmBodyText}
        onCancel={() => setIsDeleteConfirmModalOpen(false)}
      />
      <Confirm
        size="mini"
        open={isArchiveConfirmModalOpen}
        header={confirmHeaderText}
        content={'Are you sure?'}
        onConfirm={() => {
          archiveOrder(orderToDelete);
          setIsArchiveConfirmModalOpen(false);
        }}
        confirmButton={confirmBodyText}
        onCancel={() => setIsArchiveConfirmModalOpen(false)}
      />
      <OrderModal
        title={modalTitle}
        cancelOrder={cancelOrder}
        submitOrder={openConfirmationModal}
        currentOrder={state.order}
        currentStep={currentStep}
        previousStep={() => setCurrentStep(currentStep - 1)}
        nextStep={() => goToStep(currentStep + 1)}
        isLastStep={isLastStep}
        finalStep={LAST_STEP}
        initialStep={INITIAL_STEP}
        goToStep={goToStep}
        isOpen={isOrderModalOpen}
        hasAccess={hasReviewAccess}
      >
        <>
          {state.order?.status !== OrderStatus.Ordered &&
            !state.order?.archived && <SaveButton onSave={saveOrder} />}
        </>
        <Stepper
          currentStep={currentStep}
          project={project}
          goToStep={goToStep}
          deleteOrder={deleteOrder}
          cancelQuote={cancelQuote}
        />
      </OrderModal>
      <Confirm
        size="mini"
        open={isConfirmModalOpen}
        header={confirmButtonText}
        content={
          <Form>
            <Form.Field>
              <Ref innerRef={emailMsg}>
                <textarea
                  id="msg"
                  cols={30}
                  rows={10}
                  placeholder="You can enter a message here"
                />
              </Ref>
            </Form.Field>
          </Form>
        }
        onConfirm={submitToLatham}
        confirmButton={confirmButtonText}
        onCancel={() => setIsConfirmModalOpen(false)}
      />
      <Modal open={waitingForLathamResponse}>
        <div className="ui large text loader">Processing...</div>
      </Modal>
    </TransitionGroup>
  );
}
