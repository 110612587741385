import { APIService, tryRes } from 'services';
import { OrderBody, ServerOrder } from '../../models/Order';

class OrdersService extends APIService<ServerOrder | OrderBody> {
  async getOrders() {
    const response = await fetch(`${this._endpoint}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    return tryRes<ServerOrder[]>(response);
  }
}

const service = new OrdersService('orders');

export default service;
