import * as yup from 'yup';
import { stepStripeOptions } from '../Features/Feature/FeatureConstants';

export interface SpecFormPropTypes {
  coverFeatureAttributes: CoverFeatureAttributes;
  coverMaterialAttributes: CoverMaterialAttributes;
  customerAttributes: CustomerAttributes;
  deckingAttributes: DeckingAttributes;
  fullPerimeterCoverAccessoryAttributes: FullPerimeterCoverAccessoryAttributes;
  geoShapeAttributes: GeoShapeAttributes;
  linerMaterialAttributes: LinerMaterialAttributes;
  measurementAttributes: MeasurementAttributes;
  projectAttributes: ProjectAttributes;
  shapeAttributes: ShapeAttributes;
  vinylCoveredSteps: VinylCoveredSteps;
  spec: any;
  linerAccessoryAttributes: LinerAccessoryAttributes;
}

export interface VinylCoveredSteps {
  vos?: VinylCoveredStepsInfo[];
}

export interface VinylCoveredStepsInfo {
  stepNumber: number;
  stepType: string;
  stepFastener: string;
  stepDimensionWidth: string;
  treadQty: string;
  stepComment: string;
  whiteStripeTread: boolean;
  colorStripeWidth: number;
  diffColorRiser: boolean;
  riserMaterial: string;
  texturedStepFeatureSId: string;
  stepMaterial?: string;
  stepMaterialOption?: string;
}

export interface ObstructionTypeArray {
  obstructionComments: string;
  obstructionFeatureId: string;
  obstructionHeight: number;
  obstructionLabel: string;
  obstructionLength: number;
  obstructionSpaCoveredFeatureSId: string;
  obstructionType: string;
  obstructionUpOverFeatureSId: string;
}

export interface OutsideStepArray {
  outsideStep: boolean;
  outsideStepFeatureId: string;
  outsideStepWidthSize: number;
  oustideStepComments: string;
}

export interface CoverFeatureAttributes {
  obstructionTypeArray?: ObstructionTypeArray[] | undefined;
  outsideStepArray?: OutsideStepArray[] | undefined;
}

export interface CoverMaterialAttributes {
  generalSpecific: string;
  materialColor: string;
  materialDrainTypeFeatureSId: string;
  materialFabric: string;
  materialGrid: number;
  materialSpringUpgradeFeatureSId: string;
}

export interface LinerAccessoryAttributes {
  safetyStripFeatureSId: string;
  safetyStripeWidth: number;
  safetyStripeColor: string;
  materialOverlapFeatureId: string;
  materialOverlapSize: number;
  materialSolidShallowFeatureSId: string;
  materialSolidShallowColor: string;
  racingLanesFeatureSId: string;
  racingLanesComments: string;
  targetFeatureSId: string;
  targetComments: string;
}

export interface LinerMaterialAttributes {
  generalSpecific: string;
  materialOption: string;
  materialWall: string;
  materialWallMil: number;
  materialFloor: string;
  materialFloorMil: number;
  materialBeadType: string;
  materialBeadColor: string;
}

export interface CustomerAttributes {
  SugarId: string;
  customerName: string;
  gl_company_new_c: string;
  indirect_customer_number_c: string;
  purchasing_channel_c: string;
  rewards_id_c: string;
  selectedBranchNumber: string;
  selectedCustomerNumber: string;
}

export interface PartialDecking {
  partialDeckingLength: number;
  partialDeckingType: string;
  partialDeckingFeatureId: string;
  partialDeckingPaddingLength: number;
  partialDeckingPaddingFeatureId: string;
}

export interface DeckingAttributes {
  copingTexture: string;
  deckAnchorFeatureSId: string;
  deckConsistency: boolean;
  deckSize: number;
  deckType: string;
  extraPaddingFeatureId: string;
  extraPaddingLength: number;
  partialDeckingArray: PartialDecking[];
}

export interface CableArea1 {
  cableArea1FeatureId: string;
  cableArea1Length: number;
}

export interface CableArea2 {
  cableArea2FeatureId: string;
  cableArea2Length: number;
}

export interface CableArea3 {
  cableArea3FeatureId: string;
  cableArea3Length: number;
}

export interface CableArea4 {
  cableArea4FeatureId: string;
  cableArea4Length: number;
}

export interface CableAssembly {
  cableArea1: CableArea1;
  cableArea2: CableArea2;
  cableArea3: CableArea3;
  cableArea4: CableArea4;
}

export interface YStrapAssembly {
  yStrapFeatureId: string;
  yStrapQty: number;
}

export interface SinglePad {
  singlePadFeatureId: string;
  singlePadQty: number;
}

export interface FullPerimeterCoverAccessoryAttributes {
  brassAnchorCollarsFeatureSId: string;
  bucklesFeatureSId: string;
  cableAssembly: CableAssembly;
  doubleDRingsFeatureSId: string;
  extraLongAnchorsFeatureSId: string;
  kemKapFeatureSId: string;
  noAnchorsFeatureSId: string;
  noHardwareFeatureSId: string;
  topBottomWebbingFeatureSId: string;
  yStrapAssembly: YStrapAssembly;
  singlePad: SinglePad;
}

export interface GeoShapeAttributes {
  cornerLC: { cornerType: string; cornerSize: number };
  cornerLD: { cornerType: string; cornerSize: number };
  cornerLS: { cornerType: string; cornerSize: number };
  cornerRD: { cornerType: string; cornerSize: number };
  cornerRS: { cornerType: string; cornerSize: number };
  cornerReverse: { cornerType: string; cornerSize: number };
  cutCorner: { cutCornerQty: number; cutCornerFeatureId: string };
  ovalEnd: { ovalEndQty: number; ovalEndFeatureId: string };
  romanEnd: { romanEndQty: number; romanEndFeatureId: string };
}

export interface MeasurementAttributes {
  coverLnFt: number;
  coverSqFt: number;
  dimensionA: number;
  dimensionA1: number;
  dimensionB: number;
  dimensionB3: number;
  dimensionB7: number;
  dimensionC: number;
  dimensionD: number;
  dimensionE: number;
  dimensionJ2: number;
  dimensionJ3: number;
  dimensionJ4: number;
  dimensionL: number;
  dimensionRd: number;
  dimensionS: number;
  dimensionS1: number;
  dimensionT: number;
  dimensionV3: number;
  dimensionW1: number;
  dimensionX1: number;
  linerSqFt: number;
  overlapCalc: number;
  overlapOverride: number;
}

export interface ProjectAttributes {
  brand: string;
  dropShip: boolean;
  enteredBy: string;
  enteredDate: string;
  externalComments: string;
  freightInstructions: string;
  geolocationMatch: boolean;
  lathamQuoteNumber: string;
  orderChanges: boolean;
  pickup: boolean;
  poNumber: string;
  previousJobNumber: string;
  priorityRush: boolean;
  productType: string;
  projectId: string;
  projectLat: string;
  projectLong: string;
  projectName: string;
  projectSiteCity: string;
  projectSiteState: string;
  projectSiteStreet: string;
  projectSiteStreet2: string;
  projectSiteZip: string;
  projectUrl: string;
  quoteOrder: string;
  quoteStatus: string;
  reorderRemakeReason: string;
  reorderSerialNumber: string;
  shipToCity: string;
  shipToCountry: string;
  shipToState: string;
  shipToStreet: string;
  shipToZip: string;
}

export interface ShapeAttributes {
  bottomType: string;
  freeformIdentification: boolean;
  shapeId: string;
  shapeName: string;
  shapeOrientaion: string;
}

/**
 * The yup schema of the shipping (fourth) step in the order form
 */
export const SpecFormSchema = yup.object().shape({
  spec: yup.object(),
});

/**
 * The hand-made description of props of the possible validation states
 */
export interface SpecFormFieldsValidationType {
  spec: boolean;
}
