import { createModel, DataFromSchema } from 'models/Model';
import { Role } from 'services/Auth';

const required = true;

const UserSchema = {
  name: { type: 'text', label: 'Name', required },
  email: { type: 'email', label: 'Email', required },
  phone: { type: 'tel', label: 'Phone' },
} as const;
// ☝️ this const assertion is needed for DataFromSchema generic to do its magic

type UserMetaData = {
  id: string;
  dealerId: string;
  role: Role;
  isActive: boolean;
  signedTOS: boolean;
};

export type User = DataFromSchema<typeof UserSchema> & UserMetaData;

export const UserModel = createModel<User>(UserSchema);
