import { OrderState } from 'context/OrderContext';

export function getLathamSpec(state: OrderState) {
  if (state?.order?.lathamSpec == undefined) {
    return state.spec;
  }
  if (state?.spec?.projectAttributes?.projectId) {
    return state.spec;
  }
  return state?.order?.lathamSpec;
}
