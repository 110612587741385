import * as yup from 'yup';
import {
  otherAddress,
  stateOptions,
  whereToShipOptions,
} from './ShippingFormConstants';

export interface ShippingFormPropTypes {
  whereToShip: string;
  name: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  shippingState: string;
  zip: string;
}

/**
 * The yup schema of the shipping (fourth) step in the order form
 */
export const ShippingFormSchema = yup.object().shape({
  whereToShip: yup
    .string()
    .oneOf(whereToShipOptions.map((el) => el.value))
    .required(),
  name: yup.string().when('whereToShip', {
    is: (whereToShip: string) => !!whereToShip && whereToShip === otherAddress,
    then: yup.string().required(),
  }),
  addressLine1: yup.string().when('whereToShip', {
    is: (whereToShip: string) => !!whereToShip && whereToShip === otherAddress,
    then: yup.string().required(),
  }),
  addressLine2: yup.string().optional(),
  city: yup.string().when('whereToShip', {
    is: (whereToShip: string) => !!whereToShip && whereToShip === otherAddress,
    then: yup.string().required(),
  }),
  shippingState: yup.string().when('whereToShip', {
    is: (whereToShip: string) => !!whereToShip && whereToShip === otherAddress,
    then: yup
      .string()
      .oneOf(stateOptions.map((el) => el.value))
      .required(),
  }),
  zip: yup.string().when('whereToShip', {
    is: (whereToShip: string) => !!whereToShip && whereToShip === otherAddress,
    then: yup
      .string()
      .min(5, 'Must be exactly 5 digits')
      .max(5, 'Must be exactly 5 digits')
      .required(),
  }),
});

/**
 * The hand-made description of props of the possible validation states
 */
export interface ShippingFormFieldsValidationType {
  whereToShip: boolean;
  name: boolean;
  addressLine1: boolean;
  addressLine2: boolean;
  city: boolean;
  shippingState: boolean;
  zip: boolean;
}

export interface ShippingFormFieldsErrorMessageType {
  zip: string | null;

  // Allowing any index to instead of having to add ts-ignore to usage in OrderForm
  [index: string]: string | null;
}
