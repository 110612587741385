import { OrderState } from '../../../context/OrderContext';
import { ProductFormPropTypes } from '../Forms/Partials/Product/ProductFormSchema';
import { AccessoriesFormPropTypes } from '../Forms/Partials/Accessories/AccessoriesFormSchema';
import { AnchorsFormType } from '../Forms/Partials/Anchors/AnchorsForm/AnchorsFormSchema';
import { FeaturesFormType } from '../Forms/Partials/Features/FeaturesForm/FeaturesFormSchema';
import { ProjectFormPropTypes } from '../Forms/Partials/Project/ProjectFormSchema';
import { OrderType, Project } from 'models';
import { chooseLaterString } from '../Forms/Partials/Product/ProductFormConstants';

export function getProductFormData(project: Project, state: OrderState) {
  const isGeneralSelection = (selection: string) => {
    if (
      selection === chooseLaterString
      // &&
      // state?.project?.type !== OrderType.Liner
    ) {
      return '';
    }
    return selection;
  };
  // First check the current local state ?? check the last saved order ?? use undefined
  const productFormData = {
    type: state.order?.type ?? state?.project?.type ?? undefined,
    newOrReplacement:
      state?.product?.newOrReplacement ??
      state.order?.product?.newOrReplacement ??
      undefined,
    partner:
      state?.product?.partner ?? state.order?.product?.partner ?? undefined,
    brand: state?.product?.brand ?? state.order?.product?.brand ?? undefined,
    gridSpacing: isGeneralSelection(
      state?.product?.gridSpacing ??
        state.order?.product?.gridSpacing ??
        undefined
    ),
    material: isGeneralSelection(
      state?.product?.material ?? state.order?.product?.material ?? undefined
    ),
    materialOptions:
      state?.product?.materialOptions ??
      state.order?.product?.materialOptions ??
      undefined,
    bottomMaterialOptions:
      state?.product?.bottomMaterialOptions ??
      state.order?.product?.bottomMaterialOptions ??
      undefined,
    linerPattern:
      state?.product?.linerPattern ??
      state.order?.product?.linerPattern ??
      undefined,
    linerBottomPattern:
      state?.product?.linerBottomPattern ??
      state.order?.product?.linerBottomPattern ??
      undefined,
    millGauge:
      state?.product?.millGauge ?? state.order?.product?.millGauge ?? undefined,
    bottomMillGauge:
      state?.product?.bottomMillGauge ??
      state.order?.product?.bottomMillGauge ??
      undefined,
    bead: state?.product?.bead ?? state.order?.product?.bead ?? undefined,
    fabric: state?.product?.fabric ?? state.order?.product?.fabric ?? undefined,
    color: state?.product?.color ?? state.order?.product?.color ?? undefined,
    drainOrPump:
      state?.product?.drainOrPump ??
      state.order?.product?.drainOrPump ??
      undefined,
    commercialSprings:
      state?.product?.commercialSprings ??
      state.order?.product?.commercialSprings ??
      undefined,
    pumpQty:
      state?.product?.pumpQty ?? state.order?.product?.pumpQty ?? undefined,
    noDrainOrPumpAck:
      state?.product?.noDrainOrPumpAck ??
      state.order?.product?.noDrainOrPumpAck ??
      undefined,
    dealerId: project.dealerId,
  } as ProductFormPropTypes;
  return productFormData;
}

export function getProjectFormData(project: Project, state: OrderState) {
  return {
    address1: project?.address?.address1 ?? undefined,
    address2: project?.address?.address2 ?? undefined,
    city: project?.address?.city ?? undefined,
    shippingState: project?.address?.state ?? undefined,
    postalCode: project?.address?.postalcode ?? undefined,
    primaryContactName: project?.customer?.name ?? undefined,
    poNumber: state.order?.poNumber ?? state?.project?.poNumber ?? undefined,
    type: state?.project?.type ?? undefined,
  } as ProjectFormPropTypes;
}

export function getAccessoriesFormData(state: OrderState) {
  return {
    doubleD_Rings:
      state?.accessories.doubleD_Rings ??
      state.order?.accessories?.doubleD_Rings ??
      undefined,
    topAndBottomWebbing:
      state?.accessories.topAndBottomWebbing ??
      state.order?.accessories?.topAndBottomWebbing ??
      undefined,
    buckles:
      state?.accessories.buckles ??
      state.order?.accessories?.buckles ??
      undefined,
    kemkap:
      state?.accessories.kemkap ??
      state.order?.accessories?.kemkap ??
      undefined,
  } as AccessoriesFormPropTypes;
}

export function getAnchorsFormData(state: OrderState) {
  return {
    hardwareNeeded: state?.anchors?.hardwareNeeded ?? undefined,
    decks: state?.anchors?.decks ?? [],
  } as AnchorsFormType;
}

export function getFeaturesFormData(state: OrderState) {
  return {
    features: state?.features.features ?? [],
    isViewed: state?.features.isViewed,
  } as FeaturesFormType;
}

export function getShippingFormData(state: OrderState) {
  return {
    whereToShip:
      state?.shipping?.whereToShip ??
      state.order?.shipping?.whereToShip ??
      undefined,
    name: state?.shipping?.name ?? state.order?.shipping?.name ?? undefined,
    addressLine1:
      state?.shipping?.addressLine1 ??
      state.order?.shipping?.addressLine1 ??
      undefined,
    addressLine2:
      state?.shipping?.addressLine2 ??
      state.order?.shipping?.addressLine2 ??
      undefined,
    city: state?.shipping?.city ?? state.order?.shipping?.city ?? undefined,
    shippingState:
      state?.shipping?.shippingState ??
      state.order?.shipping?.shippingState ??
      undefined,
    zip: state?.shipping?.zip ?? state.order?.shipping?.zip ?? undefined,
  };
}

export function getSpecFormData(state: OrderState) {
  return state?.order?.lathamSpec;
}
