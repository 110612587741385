import * as yup from 'yup';
import { InferType } from 'yup';
import {
  chooseLaterString,
  coverTypeSelectOptions,
  drainOrPumpSelectOptions,
  fabric5000MStandardMesh,
  fabricSelectOptions,
  gridSpacingSelectOptionsOrder,
  gridSpacingSelectOptions,
  MaterialOptionSolid,
  noPumpString,
  pumpString,
  linerMaterialOptions,
  allCoverSelectOptions,
  MaterialOptionMismatched,
  MaterialOptionMatch,
  MaterialOptionBottom,
} from './ProductFormConstants';

const ProductFormBaseShape = {
  newOrReplacement: yup
    .string()
    .oneOf(coverTypeSelectOptions.map((el) => el.value))
    .required(),
  partner: yup.string().required(),
  brand: yup.object().required(),
  gridSpacing: yup
    .string()
    .when(['material'], {
      is: (material: string) =>
        material !== MaterialOptionMismatched &&
        material !== MaterialOptionMatch &&
        material !== MaterialOptionBottom,
      then: yup.string().oneOf(gridSpacingSelectOptions.map((el) => el.value)),
    })
    .when(['material'], {
      is: (material: string) =>
        material === MaterialOptionMismatched ||
        material === MaterialOptionMatch ||
        material === MaterialOptionBottom,
      then: yup.string(),
    }),
  material: yup.string().oneOf(allCoverSelectOptions.map((el) => el.value)),
  fabric: yup.string().when('material', {
    is: (material: string) =>
      !!material &&
      material !== chooseLaterString &&
      material !== MaterialOptionMismatched &&
      material !== MaterialOptionMatch &&
      material !== MaterialOptionBottom,
    then: yup
      .string()
      .oneOf(fabricSelectOptions.map((el) => el.value))
      .required(),
  }),
  color: yup.string().when('fabric', {
    is: (fabric: string) => !!fabric && fabric !== chooseLaterString,
    then: yup.string().required('Color option must be selected'),
  }),
  drainOrPump: yup.string().when('material', {
    is: (material: string) => material === MaterialOptionSolid,
    then: yup
      .string()
      .oneOf(drainOrPumpSelectOptions.map((el) => el.value))
      .required(),
  }),
  commercialSprings: yup.boolean().when('fabric', {
    is: (fabric: string) => fabric === fabric5000MStandardMesh,
    then: yup.bool().oneOf([true, false], 'Field must be checked!').required(),
  }),
  pumpQty: yup.number().when('drainOrPump', {
    is: (drainOrPump: string) => drainOrPump === pumpString,
    then: yup
      .number()
      .min(1, 'At least one pump is required')
      .max(3, 'Max number of pumps is 3')
      .integer('Pump value must be an integer')
      .required(''),
  }),
  noDrainOrPumpAck: yup.boolean().when(['drainOrPump', 'material'], {
    is: (drainOrPump: string, material: string) =>
      drainOrPump === noPumpString && material === MaterialOptionSolid,
    then: yup.boolean().oneOf([true], 'Field must be checked!').required(),
  }),
  overlap: yup.boolean().nullable(),
  overlapSize: yup
    .number()
    .nullable()
    .when('overlap', {
      is: (overlap: boolean) => overlap,
      then: yup
        .number()
        .min(1, 'At least one Overlap Inch is required')
        .integer('Quantity value must be an integer')
        .required(),
    }),
};

const ProductSafetyFormBaseShape = {
  newOrReplacement: yup
    .string()
    .oneOf(coverTypeSelectOptions.map((el) => el.value))
    .required(),
  partner: yup.string().required(),
  brand: yup.object().required(),
  gridSpacing: yup
    .string()
    .when(['material'], {
      is: (material: string) =>
        material !== MaterialOptionMismatched &&
        material !== MaterialOptionMatch &&
        material !== MaterialOptionBottom,
      then: yup.string().oneOf(gridSpacingSelectOptions.map((el) => el.value)),
    })
    .when(['material'], {
      is: (material: string) =>
        material === MaterialOptionMismatched ||
        material === MaterialOptionMatch ||
        material === MaterialOptionBottom,
      then: yup.string(),
    }),
  material: yup.string().oneOf(allCoverSelectOptions.map((el) => el.value)),
  fabric: yup.string().when('material', {
    is: (material: string) =>
      !!material &&
      material !== chooseLaterString &&
      material !== MaterialOptionMismatched &&
      material !== MaterialOptionMatch &&
      material !== MaterialOptionBottom,
    then: yup
      .string()
      .oneOf(fabricSelectOptions.map((el) => el.value))
      .required(),
  }),
  color: yup.string().when('fabric', {
    is: (fabric: string) => !!fabric && fabric !== chooseLaterString,
    then: yup.string().required('Color option must be selected'),
  }),
  drainOrPump: yup.string().when('material', {
    is: (material: string) => material === MaterialOptionSolid,
    then: yup
      .string()
      .oneOf(drainOrPumpSelectOptions.map((el) => el.value))
      .required(),
  }),
  commercialSprings: yup.boolean().when('fabric', {
    is: (fabric: string) => fabric === fabric5000MStandardMesh,
    then: yup.bool().oneOf([true, false], 'Field must be checked!').required(),
  }),
  pumpQty: yup.number().when('drainOrPump', {
    is: (drainOrPump: string) => drainOrPump === pumpString,
    then: yup
      .number()
      .min(1, 'At least one pump is required')
      .max(3, 'Max number of pumps is 3')
      .integer('Pump value must be an integer')
      .required(''),
  }),
  noDrainOrPumpAck: yup.boolean().when(['drainOrPump', 'material'], {
    is: (drainOrPump: string, material: string) =>
      drainOrPump === noPumpString && material === MaterialOptionSolid,
    then: yup.boolean().oneOf([true], 'Field must be checked!').required(),
  }),
};

/**
 * The yup schema of the product step in the order form, before quote is submitted
 */
export const ProductFormSchemaForQuote = yup
  .object()
  .shape(ProductFormBaseShape);

export const ProductSofetyFormSchemaForQuote = yup
  .object()
  .shape(ProductSafetyFormBaseShape);

/**
 * The yup schema of the product step in the order form, after quote is submitted
 * Grid spacing and Material are required at this step
 */
export const ProductFormSchemaForOrder = yup.object().shape({
  ...ProductFormBaseShape,
  gridSpacing: ProductFormBaseShape.gridSpacing
    .when(['material'], {
      is: (material: string) =>
        material !== MaterialOptionMismatched &&
        material !== MaterialOptionMatch &&
        material !== MaterialOptionBottom,
      then: yup
        .string()
        .oneOf(gridSpacingSelectOptionsOrder.map((el) => el.value))
        .required(),
    })
    .when(['material'], {
      is: (material: string) =>
        material === MaterialOptionMismatched ||
        material === MaterialOptionMatch ||
        material === MaterialOptionBottom,
      then: yup.string(),
    }),
  material: ProductFormBaseShape.material
    .oneOf(gridSpacingSelectOptionsOrder.map((el) => el.value))
    .required(),
  materialOptions: yup.string().when(['material'], {
    is: (material: string) =>
      material === MaterialOptionMismatched ||
      material === MaterialOptionMatch ||
      material === MaterialOptionBottom,
    then: yup.string().required(),
  }),
  millGauge: yup.string().required('Mill gauge must be selected'),
  bottomMillGauge: yup.string().when(['material'], {
    is: (material: string) => material === MaterialOptionMismatched,
    then: yup.string().required('Bottom mill gauge must be selected'),
  }),
  linerPattern: yup.string().when(['material'], {
    is: (material: string) =>
      material === MaterialOptionMismatched ||
      material === MaterialOptionMatch ||
      material === MaterialOptionBottom,
    then: yup.string().required('Pattern must be selected'),
  }),
  linerBottomPattern: yup.string().when(['material'], {
    is: (material: string) => material === MaterialOptionMismatched,
    then: yup.string().required('Bottom pattern must be selected'),
  }),
  bottomMaterialOptions: yup.string().when(['materialOptions'], {
    is: (materialOptions: string) =>
      materialOptions === MaterialOptionMismatched,
    then: yup.string().required(),
  }),
  bead: yup.string().when(['material'], {
    is: (material: string) =>
      material === MaterialOptionMismatched ||
      material === MaterialOptionMatch ||
      material === MaterialOptionBottom,
    then: yup.string().when('overlap', {
      is: (overlap: boolean) => !overlap,
      then: yup.string().required(),
    }),
  }),
  overlap: yup.boolean().nullable(),
  overlapSize: yup
    .number()
    .nullable()
    .when('overlap', {
      is: (overlap: boolean) => overlap,
      then: yup
        .number()
        .min(1, 'At least one Overlap Inch is required')
        .integer('Quantity value must be an integer')
        .required(),
    }),
});

/**
 * The yup schema of the product step in the order form, after quote is submitted
 * Grid spacing and Material are required at this step
 */
export const ProductSafetyFormSchemaForOrder = yup.object().shape({
  ...ProductFormBaseShape,
  gridSpacing: ProductFormBaseShape.gridSpacing
    .when(['material'], {
      is: (material: string) =>
        material !== MaterialOptionMismatched &&
        material !== MaterialOptionMatch &&
        material !== MaterialOptionBottom,
      then: yup
        .string()
        .oneOf(gridSpacingSelectOptionsOrder.map((el) => el.value))
        .required(),
    })
    .when(['material'], {
      is: (material: string) =>
        material === MaterialOptionMismatched ||
        material === MaterialOptionMatch ||
        material === MaterialOptionBottom,
      then: yup.string(),
    }),
  material: ProductFormBaseShape.material
    .oneOf(gridSpacingSelectOptionsOrder.map((el) => el.value))
    .required(),
  materialOptions: yup.string().when(['material'], {
    is: (material: string) =>
      material === MaterialOptionMismatched ||
      material === MaterialOptionMatch ||
      material === MaterialOptionBottom,
    then: yup.string().required(),
  }),
  linerPattern: yup.string().when(['material'], {
    is: (material: string) =>
      material === MaterialOptionMismatched ||
      material === MaterialOptionMatch ||
      material === MaterialOptionBottom,
    then: yup.string().required('Pattern must be selected'),
  }),
  linerBottomPattern: yup.string().when(['material'], {
    is: (material: string) => material === MaterialOptionMismatched,
    then: yup.string().required('Bottom pattern must be selected'),
  }),
  bottomMaterialOptions: yup.string().when(['materialOptions'], {
    is: (materialOptions: string) =>
      materialOptions === MaterialOptionMismatched,
    then: yup.string().required(),
  }),
  bead: yup.string().when(['material'], {
    is: (material: string) =>
      material === MaterialOptionMismatched ||
      material === MaterialOptionMatch ||
      material === MaterialOptionBottom,
    then: yup.string().required(),
  }),
});

/**
 * The yup generated type from the product schema
 *
 * A general note on types: the yup schema type is powerful, but very strict.
 * This gets us into trouble with how we're handling numeric inputs, since every input's value is stored as a string (even input[type=number])
 * Yup handles this fine, and will validate strings as numbers, but if we use the generated yup type, then it types our input type as number, and then we have to muddy up the code trying to deal with this
 * The solution so far is schemas that need additional flexibility from beyond what the generated type provides, is we're responsible for maintaining our own type.
 */
export type ProductFormType = InferType<typeof ProductFormSchemaForQuote>;

/**
 * The hand-made description of props of the possible form values
 */
export interface Brand {
  name: string;
  product: string;
  id: string;
}
export interface ProductFormPropTypes {
  newOrReplacement: string;
  partner: string | undefined;
  brand: Brand | undefined;
  gridSpacing: string;
  material: string;
  materialOptions: string;
  bottomMaterialOptions: string;
  linerPattern: string;
  linerBottomPattern: string;
  millGauge: string;
  bottomMillGauge: string;
  bead: string;
  fabric: string;
  color: string | undefined;
  drainOrPump: string | undefined;
  commercialSprings: boolean | undefined;
  pumpQty: string | undefined;
  noDrainOrPumpAck: boolean | undefined;
  dealerId: string | undefined;
  overlap?: boolean;
  overlapSize?: string;
}

/**
 * The hand-made description of props of the possible validation states
 */
export interface ProductFormFieldsValidationType {
  newOrReplacement: boolean;
  partner: boolean;
  brand: boolean;
  gridSpacing: boolean;
  material: boolean;
  materialOptions: boolean;
  bottomMaterialOptions: boolean;
  linerPattern: boolean;
  linerBottomPattern: boolean;
  millGauge: boolean;
  bottomMillGauge: boolean;
  bead: boolean;
  fabric: boolean;
  color: boolean;
  drainOrPump: boolean;
  commercialSprings: boolean;
  pumpQty: boolean;
  noDrainOrPumpAck: boolean;
  overlapSize: boolean;
  overlap: boolean;
}

export interface ProductFormFieldsErrorMessageType {
  pumpQty: string | null;
  color: string | null;
  linerPattern: string | null;
  linerBottomPattern: string | null;
  overlapSize: string | null;
  // Allowing any index to instead of having to add ts-ignore to usage in OrderForm
  [index: string]: string | null;
}
