import React from 'react';
import { Modal } from 'semantic-ui-react';
import { TransitionGroup } from '.';

export interface ModalProps {
  title?: string;
  children?: React.ReactElement | React.ReactElement[];
  actions?: React.ReactElement | React.ReactElement[];
  isOpen?: boolean;
  className?: string;
  size?: 'small' | 'mini' | 'tiny' | 'large' | 'fullscreen' | undefined;
  onClose?: () => void;
  onOpen?: () => void;
}

export default function ModalComponent({
  title,
  children,
  className = '',
  isOpen = false,
  size = 'small',
  actions,
  onClose,
  onOpen,
}: ModalProps): React.ReactElement {
  return (
    <TransitionGroup transition="scale" isVisible={isOpen} isPortal={true}>
      <Modal
        size={size}
        open
        onClose={onClose}
        onOpen={onOpen}
        className={className}
        closeOnEscape={false}
      >
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content className="scroll">{children}</Modal.Content>
        <Modal.Actions>{actions}</Modal.Actions>
      </Modal>
    </TransitionGroup>
  );
}
