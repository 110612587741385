import React, { useEffect } from 'react';
import { ObjectSchema, ValidationError } from 'yup';
import {
  ProductFormFieldsErrorMessageType,
  ProductFormFieldsValidationType,
  ProductFormPropTypes,
  ProductFormType,
} from './Partials/Product/ProductFormSchema';
import { FeaturesFormType } from './Partials/Features/FeaturesForm/FeaturesFormSchema';
import { AnchorsFieldValidity } from './Partials/Anchors/AnchorsForm/AnchorsForm';
import { FeaturesFormFieldsValidationStatus } from './Partials/Features/FeaturesForm/FeaturesForm';
import { logger } from '../../../utils';
import { AccessoriesFormFields } from './Partials/Accessories/AccessoriesForm';
import {
  AccessoriesFormFieldsErrorMessageType,
  AccessoriesFormPropTypes,
} from './Partials/Accessories/AccessoriesFormSchema';
import {
  ShippingFormFieldsErrorMessageType,
  ShippingFormFieldsValidationType,
  ShippingFormPropTypes,
} from './Partials/Shipping/ShippingFormSchema';
import {
  ProjectFormFieldsErrorMessageType,
  ProjectFormFieldsValidationType,
  ProjectFormPropTypes,
} from './Partials/Project/ProjectFormSchema';
import {
  SpecFormFieldsValidationType,
  SpecFormPropTypes,
} from './Partials/Spec/SpecFormSchema';

/**
 * abortEarly will prevent Yup from reporting all errors, and will instead break execution after finding a single validation error
 * Since we're using the validation errors to update the UI state, we want to explicitly turn this Yup feature off.
 */
const YupValidationOptions = { abortEarly: false };

type FieldValidationType = Record<string, string>;

export type GenericFieldValidationType =
  | ProductFormFieldsValidationType
  | FeaturesFormFieldsValidationStatus
  | AccessoriesFormFields
  | AnchorsFieldValidity
  | ShippingFormFieldsValidationType
  | ProjectFormFieldsValidationType
  | SpecFormFieldsValidationType
  | genericFieldValueType;

interface genericFieldValueType {
  [id: string]: boolean | string | number | null | undefined;
}

export interface OrderFormProps {
  values:
    | genericFieldValueType
    | FeaturesFormType
    | AccessoriesFormPropTypes
    | ProductFormPropTypes
    | ShippingFormPropTypes
    | ProjectFormPropTypes
    | SpecFormPropTypes;
  initialValidationState: GenericFieldValidationType;
  schema: ObjectSchema<any>;
  children: JSX.Element | JSX.Element[];
  setValidFields: React.Dispatch<
    React.SetStateAction<GenericFieldValidationType>
  >;
  onFieldsInvalid?: () => void;
  onFieldsValid?: () => void;
  setFieldErrorMessages?:
    | React.Dispatch<React.SetStateAction<ProductFormFieldsErrorMessageType>>
    | React.Dispatch<React.SetStateAction<ProjectFormFieldsErrorMessageType>>
    | React.Dispatch<React.SetStateAction<ShippingFormFieldsErrorMessageType>>
    | React.Dispatch<
        React.SetStateAction<AccessoriesFormFieldsErrorMessageType>
      >;
  fieldErrorMessages?:
    | ProductFormFieldsErrorMessageType
    | ShippingFormFieldsErrorMessageType
    | ProjectFormFieldsErrorMessageType
    | AccessoriesFormFieldsErrorMessageType;
}

export type PotentialFieldNames =
  | 'coverType'
  | 'purchasePartner'
  | 'brand'
  | 'gridSpacing'
  | 'material'
  | 'fabric'
  | 'color'
  | 'drainOrPump'
  | 'commercialSprings'
  | 'pumpQty'
  | 'noDrainOrPumpAck';

const OrderForm = (props: OrderFormProps) => {
  const getDynamicYupValidationOptions = () => {
    // Casting the values as a schema will let us access other variables in the top-level schema via the context handler "$"
    return {
      ...YupValidationOptions,
      context: props.schema.cast(props.values),
    };
  };
  /**
   * Handle field validation on change
   */
  useEffect(() => {
    let ignore = false;
    const handleValidation = (result: FieldValidationType) => {
      if (!ignore) {
        onValidateSuccess(result);
      }
    };

    const handleError = (error: ValidationError) => {
      if (!ignore) {
        onValidateFail(error);
      }
    };
    validateSchema(handleValidation, handleError);
    return () => {
      ignore = true;
    };
  }, [JSON.stringify(props.values)]);

  /**
   * Helper function that validates the current form fields against the schema
   */
  const validateSchema = (
    onSuccess: (fieldsAndValues: FieldValidationType) => void,
    onFailure: (fieldsAndValues: ValidationError) => void
  ) => {
    return props.schema
      .validate(
        JSON.parse(JSON.stringify(props.values)),
        getDynamicYupValidationOptions()
      )
      .then(onSuccess)
      .catch(onFailure);
  };

  /**
   * Receives ValidationError from yup, which contains an array of all errors, and the "path" (i.e. the index of the Yup ProductFormSchema schema object)
   * We iterate through that here, and mark any received errors as invalid
   * @param error
   */
  const onValidateFail = (error: ValidationError) => {
    // Reset everything to "valid"
    const newValidFieldsState = { ...props.initialValidationState };
    const newFieldErrorMessages = props.fieldErrorMessages;

    // Mark each failed result as invalid
    error.inner.forEach((error) => {
      const fieldWithError = error?.params?.path as string;

      // Does the field have nested properties? i.e. decks[2].paddingType
      const squareOpen = fieldWithError.indexOf('[');
      const squareClose = fieldWithError.indexOf(']');
      const period = fieldWithError.indexOf('.');

      if (squareOpen !== -1 && squareClose !== -1 && period !== -1) {
        const firstPart = fieldWithError.substr(0, squareOpen);
        const index = fieldWithError.substr(
          squareOpen + 1,
          squareClose - squareOpen - 1
        );
        const lastPart = fieldWithError.substr(period + 1);

        if (
          //@ts-ignore
          newValidFieldsState[firstPart] &&
          //@ts-ignore
          newValidFieldsState[firstPart][index] &&
          //@ts-ignore
          typeof newValidFieldsState[firstPart][index][lastPart] !== 'undefined'
        ) {
          //@ts-ignore
          newValidFieldsState[firstPart][index][lastPart] = false;
        }
      } else {
        //@ts-ignore
        newValidFieldsState[fieldWithError] = false;

        // Note: we only have error messages in non-nested schemas right now!
        if (
          newFieldErrorMessages &&
          props.setFieldErrorMessages &&
          fieldWithError in newFieldErrorMessages
        ) {
          newFieldErrorMessages[fieldWithError] = error.message;
        }
      }
    });
    props.setValidFields(newValidFieldsState);

    // Tell parent component that something failed
    if (props.onFieldsInvalid) {
      props.onFieldsInvalid();
    }
  };

  /**
   * We only hit this when everything is correct, so let's go-ahead and reset any UI elements stuck in error states
   */
  const onValidateSuccess = (fieldsAndValues: FieldValidationType) => {
    props.setValidFields(props.initialValidationState);

    if (props.onFieldsValid) {
      props.onFieldsValid();
    }

    logger.trace('onValidateSuccess', fieldsAndValues);
  };

  // Please don't style this component
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
      className="order-form"
    >
      {props.children}
    </form>
  );
};

export default OrderForm;

export type OrderFormType = ProductFormType & FeaturesFormType;
