import React, { useState } from 'react';
import { Transition, TransitionablePortal } from 'semantic-ui-react';

interface TransitionGroupProps {
  children?: React.ReactElement | React.ReactElement[] | any;
  isVisible?: boolean | false;
  transition?: string | 'scale';
  isPortal?: boolean | false;
}
export default function TransitionGroup({
  children,
  transition = 'scale',
  isVisible,
  isPortal,
}: TransitionGroupProps): React.ReactElement {
  const [animationType] = useState({ animation: transition, duration: 170 });

  return isPortal ? (
    <TransitionablePortal open={isVisible} transition={animationType}>
      {children}
    </TransitionablePortal>
  ) : (
    <Transition.Group
      animation={animationType.animation}
      duration={animationType.duration}
    >
      {isVisible ? children : null}
    </Transition.Group>
  );
}
