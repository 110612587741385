import { useCallback } from 'react';
import { ImageType } from 'react-images-uploading';
import { ProjectService } from '../../sections/projects';
import { USER_MSG } from '../../strings';
import { Project } from '../../models';
import { UIMessageProps } from '../../services/UI';
import { sanitizeFilename } from 'utils';

export const uploadProjectImage = (
  project: Project,
  projId: string,
  showSuccess: (msg?: UIMessageProps) => void,
  showError: (msg?: UIMessageProps) => void
) => {
  return useCallback(
    async (image: ImageType): Promise<any> => {
      if (image.uploading !== true) {
        image.uploading = true;
        return ProjectService.upload(
          project.id ?? projId,
          sanitizeFilename(image.file!.name),
          image.url,
          image.file?.type
        ).then(
          (resourceId) => {
            image.id = resourceId;
            image.uploading = false;
          },
          (err) => {
            console.error(err);
            image.uploading = false;
            image.error = true;
            showError(USER_MSG.ERROR_IMAGE);
          }
        );
      }
    },
    [project.id, projId, showSuccess, showError]
  );
};
