export const MOCK_SERVICE = {
  dealer: false,
  partner: false,
  employee: false,
  orders: false,
  resources: false,
  user: false,
};

export enum LogLevel {
  ERROR = 'error',
  WARN = 'warn',
  LOG = 'log',
}

export const LOG_LEVEL = LogLevel.LOG;
