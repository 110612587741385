import React, { useEffect, useState } from 'react';
import { ManagedSelect } from '../../../../Fields/Select/ManagedSelect';
import OrderForm, { GenericFieldValidationType } from '../../../OrderForm';
import { AnchorsFormSchema, AnchorsFormType } from './AnchorsFormSchema';
import { OrderContext } from '../../../../../../context/OrderContext';
import Deck from '../Deck/Deck';
import './AnchorsForm.scss';
import { DeckFormType } from '../Deck/DeckSchema';
import {
  HARDWARE_NEEDED_CHOOSE,
  HARDWARE_NEEDED_NO_ANCHORS,
  HARDWARE_NEEDED_NOTHING,
  hardwareNeededOptions,
} from './AnchorsFormConstants';
import { SpecFormPropTypes } from '../../Spec/SpecFormSchema';

interface AnchorsFormProps {
  data: AnchorsFormType;
  hasEditAccess: boolean;
}

export interface AnchorsDeckValidity {
  decking: boolean;
  padding: boolean;
  hardware: boolean;
  collars: boolean;
  paddingType: boolean;
}

export interface AnchorsFieldValidity {
  hardwareNeeded: boolean;
  decks: AnchorsDeckValidity[];
}

/**
 * Anchors form partial, manages state of decks
 * @param props
 * @constructor
 */
const AnchorsForm = (props: AnchorsFormProps) => {
  const {
    state: { anchors, decksData: deckFeatures, order, spec: lathamSpec },
    dispatch,
  } = React.useContext(OrderContext);

  const disabled = order?.archived || !props?.hasEditAccess;

  const [spec, setSpec] = useState(lathamSpec);

  const getEmptyDeckState = (): DeckFormType => {
    const decks = anchors.decks as DeckFormType[];
    //Make sure to check current state to see if there's data from an existing order, remember that we always define a single anchor even if there's no data for one
    if (decks && decks[0]) {
      return {
        decking: decks[0].decking,
        padding: decks[0].padding,
        hardware: decks[0].hardware,
        collars: decks[0].collars,
        paddingType: decks[0].paddingType,
        length: decks[0].length,
      } as DeckFormType;
    } else {
      return {
        decking: '',
        padding: false,
        hardware: undefined,
        collars: undefined,
        paddingType: undefined,
        length: undefined,
      } as DeckFormType;
    }
  };

  const getDefaultValidationOptions = () => {
    return {
      decking: true,
      padding: true,
      hardware: true,
      collars: true,
      paddingType: true,
      length: true,
    };
  };
  /**
   * All fields are "valid", until we get an error!
   */
  const InitialAnchorsFormValidationStatus = (): AnchorsFieldValidity => {
    const decks = deckFeatures.map((feature) => {
      return getDefaultValidationOptions();
    });

    return {
      hardwareNeeded: true,
      decks: decks.length ? decks : [getDefaultValidationOptions()],
    };
  };

  // Try to get the initial state from whatever is currently saved on the context, otherwise grab an empty one
  const getInitialDeckState = (): DeckFormType[] => {
    const mappedFeatures = deckFeatures.map((deck, index) => {
      if (anchors?.decks && anchors.decks[index]) {
        return {
          decking: anchors?.decks[index]?.decking,
          padding: anchors?.decks[index]?.padding,
          hardware: anchors?.decks[index]?.hardware,
          collars: anchors?.decks[index]?.collars,
          paddingType: anchors?.decks[index]?.paddingType,
          featureName: deck.featureName,
          length: deck.length,
        } as DeckFormType;
      } else {
        return getEmptyDeckState();
      }
    });
    return mappedFeatures.length ? mappedFeatures : [getEmptyDeckState()];
  };

  // Field states
  const [hardwareNeeded, setHardwareNeeded] = useState<string>(
    props.data.hardwareNeeded
  );

  // Validation state
  const [validFields, setValidFields] = useState<AnchorsFieldValidity>(
    InitialAnchorsFormValidationStatus()
  );
  const [decks, setDecks] = useState<DeckFormType[]>(getInitialDeckState());

  const getFullPerimeterCoverAccessoryAttributes = (
    lathamSpec: SpecFormPropTypes
  ) => {
    lathamSpec.fullPerimeterCoverAccessoryAttributes = {
      ...lathamSpec.fullPerimeterCoverAccessoryAttributes,
      brassAnchorCollarsFeatureSId: decks && decks[0].collars ? 'F780_2' : '',
      noHardwareFeatureSId:
        currentValues &&
        currentValues.hardwareNeeded === HARDWARE_NEEDED_NOTHING
          ? 'F780_49'
          : '',
      noAnchorsFeatureSId:
        currentValues &&
        currentValues.hardwareNeeded === HARDWARE_NEEDED_NO_ANCHORS
          ? 'F780_52'
          : '',
    };
    return lathamSpec.fullPerimeterCoverAccessoryAttributes;
  };

  const getPaddingType = (deckPaddingType: String) => {
    switch (deckPaddingType) {
      case 'Sewn On':
        return 'F780_27';
      case 'Replaceable':
        return 'F780_32';
      case 'Loose (2” X 20”)':
        return 'F780_26';
      default:
        return '';
    }
  };

  const getDeckAnchorFeatureSId = (deckHardware: String) => {
    switch (deckHardware) {
      case 'Standard Brass Anchors':
        return '';
      case '2” Long Brass Anchors':
        return 'F780_47';
      case 'Deck Tubes':
        return 'F780_14';
      case 'Lawn Tubes':
        return 'F780_19';
      case 'Wood Deck Anchors':
        return 'F780_44';
      default:
        return '';
    }
  };

  const getDeckingAttributes = (lathamSpec: SpecFormPropTypes) => {
    lathamSpec.deckingAttributes = {
      ...lathamSpec.deckingAttributes,
      deckType: (decks && decks[0].decking) ?? '',
      extraPaddingFeatureId: getPaddingType(
        (decks && decks[0].paddingType) ?? ''
      ),
      copingTexture: decks && decks[0].padding ? 'Rough' : '',
      deckAnchorFeatureSId: getDeckAnchorFeatureSId(
        (decks && decks[0].hardware) ?? ''
      ),
    };
    return lathamSpec.deckingAttributes;
  };

  const updateOrderState = () => {
    dispatch({
      type: 'updateAnchors',
      payload: currentValues,
    });

    if (spec && spec.fullPerimeterCoverAccessoryAttributes) {
      setSpec({
        ...spec,
        fullPerimeterCoverAccessoryAttributes:
          getFullPerimeterCoverAccessoryAttributes(spec),
      });
      dispatch({
        type: 'updateSpec',
        payload: spec as SpecFormPropTypes,
      });
    }

    if (spec && spec.deckingAttributes) {
      setSpec({
        ...spec,
        deckingAttributes: getDeckingAttributes(spec),
      });
      dispatch({
        type: 'updateSpec',
        payload: spec as SpecFormPropTypes,
      });
    }
  };

  const currentValues = {
    hardwareNeeded: hardwareNeeded,
    decks: decks,
  } as AnchorsFormType;

  const shouldShowHardwareDropdown = hardwareNeeded === HARDWARE_NEEDED_CHOOSE;

  const setDeck = (deck: DeckFormType, index: number) => {
    const decksToSet = [...decks];
    decksToSet[index] = deck;
    setDecks(decksToSet);
  };

  return (
    <div className="anchors-form">
      <div className="anchors-form__title">Anchors</div>
      <OrderForm
        values={currentValues}
        schema={AnchorsFormSchema}
        setValidFields={
          setValidFields as React.Dispatch<
            React.SetStateAction<GenericFieldValidationType>
          >
        }
        initialValidationState={InitialAnchorsFormValidationStatus()}
        onFieldsInvalid={updateOrderState}
        onFieldsValid={updateOrderState}
      >
        <>
          <ManagedSelect
            label="What hardware is needed?"
            isValid={validFields.hardwareNeeded}
            value={hardwareNeeded}
            onChange={setHardwareNeeded}
            options={hardwareNeededOptions}
            name="hardware-needed"
            disabled={disabled}
          />
          {deckFeatures.length ? (
            deckFeatures.map((deck, i) => (
              <>
                <Deck
                  validFields={validFields.decks[i]}
                  deck={decks[i]}
                  setDeck={(deck: DeckFormType) => setDeck(deck, i)}
                  key={`deck-form-${i}`}
                  title={deck.label}
                  showHardwareDropdown={shouldShowHardwareDropdown}
                  image={deck.images[0]?.url}
                  notes={deck.notes}
                  length={deck?.length ?? 0}
                  archived={disabled}
                  featureName={deck.featureName}
                  measurement={deck.measurement}
                />
                {i < deckFeatures.length - 1 && (
                  <hr className="anchors-form__hr" />
                )}
              </>
            ))
          ) : (
            <Deck
              deck={decks[0]}
              setDeck={(deck: DeckFormType) => setDeck(deck, 0)}
              validFields={validFields.decks[0]}
              title="Decking"
              showHardwareDropdown={shouldShowHardwareDropdown}
              length={decks[0].length ?? 0}
              archived={disabled}
              featureName={decks[0].featureName}
              measurement={decks[0].measurement}
            />
          )}
        </>
      </OrderForm>
    </div>
  );
};

export default AnchorsForm;
