import * as yup from 'yup';
import { InferType } from 'yup';
import {
  BaseAnchorFormSchema,
  BaseDeckFormType,
  VinlyCoverLinerFeatureBaseAnchorFormSchema,
} from '../Feature/FeatureSchema';

/**
 * The schema for the features (2nd) step of the order form
 * Whoa, is this only one line? Yup: it's just an array of the Deck schema
 */
export const FeaturesFormSchema = yup.object().shape({
  features: yup.array().of(BaseAnchorFormSchema),
  isViewed: yup.boolean().oneOf([true], 'Field must be checked!').required(),
});

export const VinlyCoverLinerFeaturesFormSchema = yup.object().shape({
  features: yup.array().of(VinlyCoverLinerFeatureBaseAnchorFormSchema),
  isViewed: yup.boolean().oneOf([true], 'Field must be checked!').required(),
});

export type FeaturesFormType = InferType<typeof FeaturesFormSchema>;

export interface FeaturesFormPropTypes {
  features?: BaseDeckFormType[];
  isViewed?: boolean;
}
