import React from 'react';
import '../Select/ManagedSelect.scss';
import { _bemHelper, bemErrorHelper } from '../FieldHelpers';
import './ManagedImagePicker.scss';
import { ErrorField } from '../ErrorField/ErrorField';

interface MangedImagePickerProps<T> {
  isValid: boolean;
  label?: string;
  value?: any | null;
  displayText: (value: T) => string | undefined;
  onChange: (value: T | undefined) => void;
  namespace?: string;
  options: T[];
  imageUrl: (option: T) => string;
  errorMessage?: string | null;
  disabled?: boolean;
  isItemSelected: (value: any | undefined, option: T) => boolean;
  itemDataCy: (value: T) => string;
}

export const textureImageBaseURL =
  'https://bai-latham-cloud-api-dev.s3.us-west-2.amazonaws.com/texture_image/';

/**
 * This might still need some work
 * @param props
 * @constructor
 */
export const ManagedImagePicker = (props: MangedImagePickerProps<any>) => {
  // BEM css convention namespace
  const BEM_NS = props.namespace ?? 'image-picker';

  const selectedClassName = _bemHelper(`${BEM_NS}__img`, 'selected');
  const defaultClassName = bemErrorHelper(`${BEM_NS}__img`);

  const defaultImageWrapperClassName = bemErrorHelper(
    `${BEM_NS}__img-label-wrap`
  );
  const selectedImageWrapperClassName = _bemHelper(
    `${BEM_NS}__img-label-wrap`,
    'selected'
  );

  return (
    <div className={bemErrorHelper(`${BEM_NS}__wrap`, props.isValid)}>
      <label className={bemErrorHelper(BEM_NS, props.isValid)}>
        <span className={bemErrorHelper(`${BEM_NS}__copy`, props.isValid)}>
          {props.label}
        </span>
        <ErrorField
          className="margin-bottom"
          errorMessage={props.errorMessage}
          valid={props.isValid}
        />
        <div
          className={`selection__wrap ${bemErrorHelper(
            `${BEM_NS}__selection-wrap`,
            props.isValid
          )}`}
        >
          {props.options &&
            props.options.map((option, key) => (
              <div
                key={key}
                className={bemErrorHelper(`${BEM_NS}__img-wrap`)}
                onClick={(e) => {
                  if (!props?.disabled) props.onChange(option);
                }}
              >
                <div
                  className={
                    props.isItemSelected(props.value, option)
                      ? selectedImageWrapperClassName
                      : defaultImageWrapperClassName
                  }
                  data-cy={`${props.itemDataCy(option)}`}
                >
                  <img
                    className={
                      props.isItemSelected(props.value, option)
                        ? selectedClassName
                        : defaultClassName
                    }
                    alt={props.displayText(option)}
                    src={props.imageUrl(option)}
                  />
                </div>
                <span className={bemErrorHelper(`${BEM_NS}__label`)}>
                  {props.displayText(option)}
                </span>
              </div>
            ))}
        </div>
      </label>
    </div>
  );
};
