import React from 'react';
import './Toggle.scss';
import { bemErrorHelper } from '../orders/Fields/FieldHelpers';

interface ToggleProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  checked: boolean;
  label?: string;
  isValid: boolean;
  disabled?: boolean;
}

export const Toggle = (props: ToggleProps) => {
  return (
    <div className={bemErrorHelper('toggle', props.isValid)}>
      <div className={bemErrorHelper('toggle__wrap', props.isValid)}>
        <label className={bemErrorHelper('toggle__label', props.isValid)}>
          {props.label && (
            <span
              className={bemErrorHelper('toggle__label-wrap', props.isValid)}
            >
              {props.label}
            </span>
          )}
          <span
            className={bemErrorHelper('toggle__checkbox-wrap', props.isValid)}
          >
            <input
              type="checkbox"
              name={props.name}
              className={bemErrorHelper('toggle__checkbox', props.isValid)}
              checked={props.checked}
              onChange={props.onChange}
              disabled={props?.disabled}
            />
            <span className={bemErrorHelper('toggle__slider', props.isValid)} />
          </span>
        </label>
      </div>
    </div>
  );
};

Toggle.displayName = 'Toggle';
