import React from 'react';
import './SaveButton.scss';

interface SaveButtonProps {
  onSave: (submitOrder: boolean) => void;
}

const SaveButton = ({ onSave }: SaveButtonProps) => {
  return (
    <button className="save-button" onClick={() => onSave(false)}>
      Save and Exit
    </button>
  );
};

export default SaveButton;
