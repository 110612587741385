import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { withErrorBoundary } from 'react-error-boundary';
import { AuthProvider, AuthRoute, UIProvider, UIMessage } from 'services';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import Layout from 'components/layout/Layout';
import { routes } from 'routes';
import { USER_MSG } from 'strings';
import { SmartTableProvider } from 'context/SmartTableContext';
import TermsAndConditions from 'components/TermsAndConditions';

function App(): React.ReactElement {
  return (
    <Router>
      <AuthProvider>
        <UIProvider>
          <SmartTableProvider>
            <Layout>
              <TermsAndConditions />
              <Switch>
                {routes.map(({ path, component, exact, role }, i) => {
                  //@ts-ignore
                  component = withErrorBoundary(component, {
                    fallback: <UIMessage {...USER_MSG.ERROR_GENERIC} />,
                  });
                  const RouteType = role ? AuthRoute : Route;
                  return (
                    // @ts-ignore
                    <RouteType
                      key={i}
                      {...{ path, component, exact, role }}
                    ></RouteType>
                  );
                })}
              </Switch>
            </Layout>
          </SmartTableProvider>
        </UIProvider>
      </AuthProvider>
    </Router>
  );
}

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_KEY ?? '',
})(App);
