import { ReviewField as NoOptionMessage } from 'components/orders/ReviewOrder/ReviewOrder';
import React, { SetStateAction, useCallback, useEffect, useState } from 'react';
import { CompanyService } from 'sections/company';
import { ProductInfo } from 'sections/company/CompanyService';
import {
  ClientSwatch,
  LinerBottomMaterial,
  LinerMatchMaterial,
  LinerWallMaterial,
  OrderContext,
  productOrderState,
} from '../../../../../context/OrderContext';
import {
  LEGAL_TERMS_URL,
  OrderStatus,
  OrderType,
  ReplacementType,
} from '../../../../../models/Order';
import { ManagedCheckbox } from '../../../Fields/Checkbox/ManagedCheckbox';
import { ManagedImagePicker } from '../../../Fields/ImagePicker/ManagedImagePicker';
import { ManagedSelect } from '../../../Fields/Select/ManagedSelect';
import OrderForm, { GenericFieldValidationType } from '../../OrderForm';
import { SpecFormPropTypes } from '../Spec/SpecFormSchema';
import './ProductForm.scss';
import {
  chooseLaterGridOption,
  chooseLaterString,
  chooseLaterStringOld,
  coverMaterialSelectOptions,
  coverTypeSelectOptions,
  dealerDirectString,
  drainOrPumpSelectOptions,
  fabric1000HSHyperLiteSolid,
  fabric5000MStandardMesh,
  fabric500PLiteSolid,
  fabricSelectOptions,
  fabricSelectOptionsMesh,
  fabricSelectOptionsSolid,
  fiveByFiveGridOption,
  lathamCanadaBrand,
  linerMaterialSelectOptions,
  linerTypeSelectOptions,
  MaterialOptionBottom,
  MaterialOptionMatch,
  MaterialOptionMesh,
  MaterialOptionMismatched,
  MaterialOptionSolid,
  None,
  noPumpString,
  pumpSelectOptions,
  pumpString,
  purchasePartnerSelectOptions,
  threeByThreeGridOption,
} from './ProductFormConstants';
import {
  Brand,
  ProductFormFieldsErrorMessageType,
  ProductFormFieldsValidationType,
  ProductFormPropTypes,
  ProductFormSchemaForOrder,
  ProductFormSchemaForQuote,
} from './ProductFormSchema';
import { ManagedCarouselImagePicker } from 'components/orders/Fields/ImagePicker/ManagedCarouselImagePicker';
import { getLathamSpec } from 'components/orders/helpers/getLathamSpec';
import { ManagedToggle } from 'components/orders/Fields/Toggle/ManagedToggle';
import { ManagedInput } from 'components/orders/Fields/Input/ManagedInput';
import { SelectOptions } from 'models/SelectOptions';

interface ProductFormProps {
  data: ProductFormPropTypes;
  hasEditAccess: boolean;
}

type BrandMap = Map<string, Brand[]>;

/**
 * All fields are "valid", until we get an error!
 */
const InitialProductFormValidationStatus =
  (): ProductFormFieldsValidationType => {
    return {
      newOrReplacement: true,
      partner: true,
      brand: true,
      gridSpacing: true,
      material: true,
      materialOptions: true,
      bottomMaterialOptions: true,
      linerPattern: true,
      linerBottomPattern: true,
      millGauge: true,
      bottomMillGauge: true,
      bead: true,
      fabric: true,
      color: true,
      drainOrPump: true,
      commercialSprings: true,
      pumpQty: true,
      noDrainOrPumpAck: true,
      overlapSize: true,
      overlap: true,
    };
  };

const ProductForm = (props: ProductFormProps) => {
  // Global state
  const { state, dispatch } = React.useContext(OrderContext);
  const poolArea = state.project?.poolArea;
  // Old projects might not have updated poolShape data so first check if the basename is populated and if it isn't then get the poolShapeType
  const poolShape =
    state.project?.poolShape?.baseName ?? state.project?.poolShapeType;
  const poolShapeType = state.project?.poolShapeType;
  const isAfterQuote =
    state.order?.status === OrderStatus.QuotedOptions ||
    state.order?.status === OrderStatus.ReadyForOrder;
  const hideMaterial =
    state.order?.status === undefined ||
    state.order?.status === OrderStatus.Saved ||
    state.order?.status === OrderStatus.QuoteInReview;
  const disabled = state?.order?.archived || !props?.hasEditAccess;
  const type = state.project?.type;

  // Field states
  const [materialSelectOptions, setMaterialSelectOptions] = useState<
    { label: string; value: string }[]
  >(
    type === OrderType.Liner
      ? linerMaterialSelectOptions
      : coverMaterialSelectOptions
  );
  const [newOrReplacement, setNewOrReplacement] = useState<
    ReplacementType | undefined
  >(
    (state.product.newOrReplacement ??
      props.data.newOrReplacement) as ReplacementType
  );
  const [purchasePartner, setPurchasePartner] = useState<string | undefined>(
    state.product.partner ?? props.data.partner
  );

  const [brand, setBrand] = useState<Brand | undefined>(
    state.product.brand ?? props.data.brand
  );
  const isCanadian = brand && brand.name === lathamCanadaBrand;

  const [brandMap, setBrandMap] = useState<BrandMap>(new Map());
  const [currentBrandOptions, setCurrentBrandOptions] = useState<ProductInfo[]>(
    []
  );
  const [gridSpacing, setGridSpacing] = useState<string>(
    state.product.gridSpacing ?? props.data.gridSpacing
  );
  const [material, setMaterial] = useState<string>(
    state.product.material ?? props.data.material
  );
  const [fabric, setFabric] = useState<string>(
    state.product.fabric ?? props.data.fabric
  );
  const [materialOptions, setMaterialOptions] = useState<string>(
    type === OrderType.Liner
      ? state.product.materialOptions ?? props.data.materialOptions
      : ''
  );
  const [bottomMaterialOptions, setBottomMaterialOptions] = useState<string>(
    type === OrderType.Liner
      ? state.product.bottomMaterialOptions ?? props.data.bottomMaterialOptions
      : ''
  );
  const [linerPattern, setLinerPattern] = useState<string>(
    state.product.linerPattern ?? props.data.linerPattern
  );

  const [linerBottomPattern, setLinerBottomPattern] = useState<string>(
    state.product.linerBottomPattern ?? props.data.linerBottomPattern
  );

  const [millGauge, setMillGauge] = useState<string>(
    type === OrderType.Liner
      ? state.product.millGauge ?? props.data.millGauge
      : ''
  );

  const [bottomMillGauge, setBottomMillGauge] = useState<string>(
    type === OrderType.Liner
      ? state.product.bottomMillGauge ?? props.data.bottomMillGauge
      : ''
  );

  const [bead, setBead] = useState<string | undefined>(
    type === OrderType.Liner ? state.product.bead ?? props.data.bead : ''
  );

  // The ID of the swatch object
  const [color, setColor] = useState<string | undefined>(
    state.product.color ?? props.data.color
  );
  const [drainOrPump, setDrainOrPump] = useState<string | undefined>(
    state.product.drainOrPump ?? props.data.drainOrPump
  );
  const [commercialSprings, setCommercialSprings] = useState<boolean>(
    state.product.commercialSprings ?? props.data.commercialSprings ?? false
  );
  const [pumpQty, setPumpQty] = useState<string | undefined>(
    state.product.pumpQty ?? props.data.pumpQty
  );
  const [noDrainOrPumpAck, setNoDrainOrPumpAck] = useState<boolean>(
    state.product.noDrainOrPumpAck ?? props.data.noDrainOrPumpAck ?? false
  );
  const isOverlap = state.product.overlap ?? false;

  const matchMaterials =
    state.linerMaterials && brand && Object.keys(state.linerMaterials)[0]
      ? state?.linerMaterials[Object.keys(state.linerMaterials)[0]][brand.id]
          ?.MatchMaterials?.Material ?? []
      : [];
  const wallMaterials =
    state.linerMaterials && brand && Object.keys(state.linerMaterials)[0]
      ? state?.linerMaterials[Object.keys(state.linerMaterials)[0]][brand.id]
          ?.WallMaterials?.WallMaterial ?? []
      : [];
  const bottomMaterials =
    state.linerMaterials && brand && Object.keys(state.linerMaterials)[0]
      ? state?.linerMaterials[Object.keys(state.linerMaterials)[0]][brand.id]
          ?.BottomMaterials?.BottomMaterial ?? []
      : [];
  const millGaugeOptions =
    material === MaterialOptionMatch
      ? [
          { label: '27/27 mil', value: '27/27 mil' },
          { label: '27/20 mil', value: '27/20 mil' },
          { label: '20/20 mil', value: '20/20 mil' },
        ]
      : material === MaterialOptionBottom
      ? [
          { label: '27 mil', value: '27 mil' },
          { label: '20 mil', value: '20 mil' },
        ]
      : material === MaterialOptionMismatched
      ? [
          { label: '27 mil', value: '27 mil' },
          { label: '20 mil', value: '20 mil' },
        ]
      : [];

  const beadOptions: { label: string; value: string }[] | undefined =
    state.linerBeads?.getBeadsOut?.BeadsList['Beads']['bead']?.map((bead) => ({
      label: bead['@_BeadDescription'],
      value: bead['@_BeadDescription'],
    }));
  const [errorMessages, setErrorMessages] =
    useState<ProductFormFieldsErrorMessageType>({
      pumpQty: null,
      color: null,
      overlapSize: null,
      linerPattern: null,
      linerBottomPattern: null,
    });
  const [overlap, setOverlap] = useState<boolean>(
    state.product.overlap ?? props.data.overlap ?? false
  );
  const [overlapSize, setOverlapSize] = useState<string | undefined>(
    state.product.overlapSize ?? props.data.overlapSize ?? undefined
  );
  const updateOverlap = (overlapValue: SetStateAction<boolean>) => {
    setOverlap(overlapValue);
    if (overlapValue === false) {
      setOverlapSize(undefined);
    }
    if (overlapValue === true) {
      setBead(undefined);
    }
  };
  useEffect(() => {
    if (!isAfterQuote) {
      setGridSpacing(chooseLaterGridOption.value);
      setMaterial(chooseLaterGridOption.value);
    }
    if (isAfterQuote && type === OrderType.Liner) {
      setGridSpacing(chooseLaterGridOption.value);
    }
  }, [state?.order]);

  const setMaterialForComponent = (material: string) => {
    setColor('');
    setFabric('');
    setMaterial(material);
    setMillGaugeForComponent('');
    setBottomMillGaugeForComponent('');
  };

  const setMillGaugeForComponent = (millGauge: string) => {
    updatelinerPattern('');
    updateLinerBottomPattern('');
    setMillGauge(millGauge);
  };

  const setBottomMillGaugeForComponent = (bottomMillGauge: string) => {
    updatelinerPattern('');
    updateLinerBottomPattern('');
    setBottomMillGauge(bottomMillGauge);
  };

  // Validation state
  const [validFields, setValidFields] =
    useState<ProductFormFieldsValidationType>(
      InitialProductFormValidationStatus()
    );

  const getFabricColorOptions = (): ClientSwatch[] => {
    const applicableSwatches = state.swatches.filter(
      (swatch) => swatch.pattern === fabric && swatch.fabric === material
    );
    if (applicableSwatches.length) {
      return applicableSwatches;
    }

    return [];
  };

  const getBrandSelectOptions = (): SelectOptions => {
    return currentBrandOptions.map((option) => ({
      label: option.name,
      value: option.id,
    }));
  };

  const getMaterialSelectOptions = () => {
    const materialOptions = isAfterQuote
      ? materialSelectOptions.filter(
          (option) => option.value !== chooseLaterString
        )
      : materialSelectOptions;
    if (poolArea && poolArea > 2200) {
      // All solid fabric options are incompatible with pools over 2200 sq in
      // So don't present solid as an option in that scenario
      return materialOptions.filter(
        (option) => option.value !== MaterialOptionSolid
      );
    }
    return materialOptions;
  };

  const getFabricSelectOptions = () => {
    if (material === MaterialOptionSolid) {
      // Do not show 100V option if area is over 1600 sq ft
      // (If area is over 2200, solid should not be an option to begin with)
      return fabricSelectOptionsSolid.filter(
        (option) =>
          !(
            poolArea &&
            poolArea > 1600 &&
            option.value === fabric1000HSHyperLiteSolid
          )
      );
    }
    if (material === MaterialOptionMesh) {
      return fabricSelectOptionsMesh;
    }
    return fabricSelectOptions;
  };

  const getGridOptions = () => {
    // TODO: Any pool with existing anchors <= 4ft max spacing - 3x3 Only
    // TODO: Any pool with existing anchors > 4ft max spacing - 5x5 Only
    if (
      poolShape === 'Fiberglass Freeform' ||
      poolShape === 'Fiberglass Geometric'
    ) {
      // if a pool is fiberglass then 3x3 and 5x5 are options
      return isAfterQuote
        ? [threeByThreeGridOption, fiveByFiveGridOption]
        : [threeByThreeGridOption, fiveByFiveGridOption, chooseLaterGridOption];
    } else if (poolShapeType === 'freeform') {
      // if a pool is free form then 3x3 is the only option
      return isAfterQuote
        ? [threeByThreeGridOption]
        : [threeByThreeGridOption, chooseLaterGridOption];
    } else {
      // if a pool is geometric (meaning not free form or fiberglass)
      if (material === MaterialOptionSolid) {
        // if fabric is any and poolArea =< 1200
        if (poolArea && poolArea <= 1200) {
          // 3x3 and 5x5
          return isAfterQuote
            ? [threeByThreeGridOption, fiveByFiveGridOption]
            : [
                threeByThreeGridOption,
                fiveByFiveGridOption,
                chooseLaterGridOption,
              ];
        } else if (
          fabric === fabric500PLiteSolid &&
          poolArea &&
          poolArea <= 2200
        ) {
          // if fabric is 500p and poolArea =< 2200 and poolArea > 1200
          // 3x3 only
          return isAfterQuote
            ? [threeByThreeGridOption]
            : [threeByThreeGridOption, chooseLaterGridOption];
        } else if (
          fabric === fabric1000HSHyperLiteSolid &&
          poolArea &&
          poolArea <= 1600
        ) {
          // if fabric is 1000HS and poolArea =< 1600 and poolArea > 1200
          // 3x3 only
          return isAfterQuote
            ? [threeByThreeGridOption]
            : [threeByThreeGridOption, chooseLaterGridOption];
        } else {
          // if fabric is 500p and poolArea > 2200 or 1000HS and poolArea > 1600
          // no option
          return isAfterQuote ? [] : [chooseLaterGridOption];
        }
      } else {
        if (poolArea && poolArea < 3500) {
          // if a pool is mesh and area is less than 3500 sq ft then 3x3 and 5x5 are options
          return isAfterQuote
            ? [threeByThreeGridOption, fiveByFiveGridOption]
            : [
                threeByThreeGridOption,
                fiveByFiveGridOption,
                chooseLaterGridOption,
              ];
        } else {
          // if a pool is mesh and area is greater than 3500 sq ft then 3x3 is the only option
          return isAfterQuote
            ? [threeByThreeGridOption]
            : [threeByThreeGridOption, chooseLaterGridOption];
        }
      }
    }
  };

  const [selectedMaterialOption, setSelectedMaterialOption] = useState(
    type === OrderType.Liner
      ? material === MaterialOptionMismatched && wallMaterials
        ? wallMaterials.filter(
            (el: any) => el['@_Description'] === materialOptions
          )[0]
        : material === MaterialOptionMatch && matchMaterials
        ? matchMaterials.filter(
            (el: any) => el['@_Description'] === materialOptions
          )[0]
        : material === MaterialOptionBottom && bottomMaterials
        ? bottomMaterials.filter(
            (el: any) => el['@_Description'] === materialOptions
          )[0]
        : undefined
      : undefined
  );

  const [selectedBottomMaterialOption, setSelectedBottomMaterialOption] =
    useState(
      material === MaterialOptionMismatched && bottomMaterials
        ? bottomMaterials.filter(
            (el: LinerBottomMaterial) =>
              el['@_Description'] === bottomMaterialOptions
          )[0]
        : ''
    );

  const [selectedBeadOption, setSelectedBeadOption] = useState(
    type === OrderType.Liner
      ? material === MaterialOptionMismatched && wallMaterials
        ? wallMaterials.filter((el: any) => el['@_BeadDesc'] === bead)[0]
        : material === MaterialOptionMatch && matchMaterials
        ? matchMaterials.filter((el: any) => el['@_WallBeadDesc'] === bead)[0]
        : material === MaterialOptionBottom && bottomMaterials
        ? bottomMaterials.filter((el: any) => el['@_BeadDesc'] === bead)[0]
        : undefined
      : undefined
  );

  const [purchasePartnerOptions, setPurchasePartnerOptions] = useState(
    purchasePartnerSelectOptions
  );
  const [colorOptions, setColorOptions] = useState(getFabricColorOptions());
  const fabricOptions = getFabricSelectOptions();
  const [linerFabricOptions, setLinerFabricOptions] =
    useState<Array<{ value: string; label: string; code: string }>>();

  const [linerBottomFabricOptions, setLinerBottomFabricOptions] =
    useState<Array<{ value: string; label: string; code: string }>>();

  useEffect(() => {
    if (purchasePartner && brandMap.has(purchasePartner)) {
      setCurrentBrandOptions(brandMap.get(purchasePartner)!);
    }
  }, [purchasePartner, brandMap, type]);

  useEffect(() => {
    const currentColorOptions = getFabricColorOptions();
    // When fabric changes: check if current color is valid for fabric selection, if not: clear it out.
    if (!currentColorOptions.find((option) => option.color === color)) {
      setColor('');
    }
    if (fabric !== fabric5000MStandardMesh) {
      setCommercialSprings(false);
    }
    setColorOptions(currentColorOptions);
  }, [fabric]);

  useEffect(() => {
    if (material === MaterialOptionMesh) {
      setDrainOrPump('');
      setPumpQty(undefined);
    }
  }, [material]);

  useEffect(() => {
    if (fabric === chooseLaterString) {
      setColor('');
    }
  }, [color]);

  const currentValues = {
    newOrReplacement: newOrReplacement,
    partner: purchasePartner,
    brand: brand,
    gridSpacing: gridSpacing,
    material: material,
    materialOptions: materialOptions,
    bottomMaterialOptions: bottomMaterialOptions,
    linerPattern: linerPattern,
    linerBottomPattern: linerBottomPattern,
    millGauge: millGauge,
    bottomMillGauge: bottomMillGauge,
    bead: !isOverlap ? bead : undefined,
    fabric: fabric,
    color: color,
    drainOrPump: drainOrPump,
    commercialSprings: commercialSprings,
    pumpQty: pumpQty,
    noDrainOrPumpAck: noDrainOrPumpAck,
    overlap: overlap,
    overlapSize: overlap ? overlapSize : undefined,
  } as productOrderState;

  const getFabricCode = (fabric: string) => {
    const AvailCodes = ['1000HS', '500P', '5000M', '7000MS', '9000MX'];
    if (!AvailCodes.includes(fabric)) return '';
    switch (fabric) {
      case '1000HS':
        return 'S';
      case '500P':
        return 'L';
      case '5000M':
        return 'M';
      case '7000MS':
        return 'U';
      case '9000MX':
        return 'X;';
      default:
        return '';
    }
  };
  const getColorCode = (color: string) => {
    const AvailColors = ['Blue', 'Green', 'Tan', 'Grey'];
    if (!AvailColors.includes(color)) return '';
    return color === 'Grey' ? 'Y' : color[0].toUpperCase();
  };
  const getGridCode = (grid: string) => {
    const AvailCodes = ['5x5', '3x3', 'Non Standard'];
    if (!AvailCodes.includes(grid)) return null;
    return grid === 'Non Standard' ? 0 : parseInt(grid[0], 10);
  };
  const getDrainPumpCode = () => {
    if (drainOrPump === 'Invis-a-Drain') {
      return 'F910_2';
    }
    if (drainOrPump === 'Pump') {
      switch (pumpQty) {
        case '1':
          return 'F913_1';
        case '2':
          return 'F913_2';
        case '3':
          return 'F913_3';
        default:
          return '';
      }
    }
    // 'No, thanks' i.e. no drain nor pump
    return 'F910_3';
  };
  function findBeadCode(beadDesc: string): string | undefined {
    return state.linerBeads?.getBeadsOut.BeadsList['Beads']['bead'].find(
      (bead) => {
        return bead['@_BeadDescription'] == beadDesc;
      }
    )?.['@_BeadCode'];
  }
  const getLinerMaterialSpec = () => {
    if (material === MaterialOptionMatch) {
      return {
        materialWall: selectedMaterialOption
          ? (selectedMaterialOption as LinerMatchMaterial)['@_WallCode']
          : '',
        materialFloor: selectedMaterialOption
          ? (selectedMaterialOption as LinerMatchMaterial)['@_BottomCode']
          : '',
        materialBeadType: !isOverlap
          ? selectedBeadOption
            ? (selectedBeadOption as LinerMatchMaterial)['@_WallBeadCode']
            : (bead && findBeadCode(bead)) ?? ''
          : undefined,
      };
    } else if (material === MaterialOptionBottom) {
      return {
        materialWall: '',
        materialFloor: selectedMaterialOption
          ? (selectedMaterialOption as LinerBottomMaterial)['@_BottomCode']
          : '',
        materialBeadType: !isOverlap
          ? selectedBeadOption
            ? (selectedBeadOption as LinerBottomMaterial)['@_BeadCode']
            : (bead && findBeadCode(bead)) ?? ''
          : undefined,
      };
    } else if (material === MaterialOptionMismatched) {
      return {
        materialWall: selectedMaterialOption
          ? (selectedMaterialOption as LinerWallMaterial)['@_WallCode']
          : '',
        materialFloor: selectedBottomMaterialOption
          ? (selectedBottomMaterialOption as LinerBottomMaterial)[
              '@_BottomCode'
            ]
          : '',
        materialBeadType: !isOverlap
          ? selectedBeadOption
            ? (selectedBeadOption as LinerWallMaterial)['@_BeadCode']
            : (bead && findBeadCode(bead)) ?? ''
          : undefined,
      };
    }
    return '';
  };
  const lathamSpec = getLathamSpec(state);
  const specVals = {
    ...lathamSpec,
    coverMaterialAttributes: {
      ...lathamSpec?.coverMaterialAttributes,
      generalSpecific: getFabricCode(fabric) ? 'S' : 'G',
      materialFabric: getFabricCode(fabric),
      materialColor: getColorCode(color ?? ''),
      materialGrid: getGridCode(gridSpacing),
      materialDrainTypeFeatureSId: getDrainPumpCode(),
      materialSpringUpgradeFeatureSId: commercialSprings ? 'F780_7' : '',
    },
    linerMaterialAttributes: {
      ...state?.order?.lathamSpec?.linerMaterialAttributes,
      generalSpecific: material !== chooseLaterString ? 'S' : 'G',
      ...getLinerMaterialSpec(),
      materialOption: material,
    },
  } as SpecFormPropTypes;
  const updateOrderState = () => {
    dispatch({
      type: 'updateProduct',
      payload: currentValues,
    });
    const newSpec = {
      ...specVals,
      linerAccessoryAttributes: {
        ...specVals.linerAccessoryAttributes,
        materialOverlapSize: Number(currentValues.overlapSize),
        materialOverlapFeatureId: currentValues['overlap'] ? 'F601_5' : '',
      },
    };
    dispatch({
      type: 'updateSpec',
      payload: newSpec,
    });
  };
  const isMaterialSolid = material === MaterialOptionSolid;
  const isNoPumpSelected = drainOrPump === noPumpString;
  const isPumpSelected = drainOrPump === pumpString && isMaterialSolid;

  const shouldShowPumpPrompt = isMaterialSolid && isNoPumpSelected;
  const shouldShowSpringPrompt =
    fabric === fabric5000MStandardMesh &&
    (poolArea === undefined || poolArea === null || poolArea <= 2000) &&
    !isCanadian;

  const getProductBrands = (brands: Brand[]): Brand[] =>
    brands.filter((brand) => brand.product.includes(type ?? None));

  useEffect(() => {
    if (props.data?.dealerId) {
      CompanyService.get(props.data.dealerId).then((dealerInfo) => {
        CompanyService.getBrandsById(dealerInfo.rewardsId).then(
          (purchasePartners) => {
            const { direct, throughBuyingGroup, throughDistribution } =
              purchasePartners;
            let formattedPartners = [];
            const allBrands: BrandMap = new Map();

            if (direct) {
              formattedPartners.push({
                value: dealerDirectString,
                label: dealerDirectString,
              });
              allBrands.set(
                dealerDirectString,
                getProductBrands(direct.brands)
              );
              if (!purchasePartner && direct.isDefault) {
                setPurchasePartner(dealerDirectString);
              }
            }

            if (throughBuyingGroup?.name) {
              formattedPartners.push({
                value: throughBuyingGroup.name,
                label: throughBuyingGroup.name,
              });
              allBrands.set(
                throughBuyingGroup.name,
                getProductBrands(throughBuyingGroup.brands)
              );
              if (!purchasePartner && throughBuyingGroup.isDefault) {
                setPurchasePartner(throughBuyingGroup.name);
              }
            }

            if (throughDistribution?.distributorCustomerAccount?.length) {
              throughDistribution.distributorCustomerAccount.forEach(
                (distributor) => {
                  formattedPartners.push({
                    value: distributor.name,
                    label: distributor.name,
                  });
                  allBrands.set(
                    distributor.name,
                    getProductBrands(distributor.brands)
                  );
                  if (!purchasePartner && distributor.isDefault) {
                    setPurchasePartner(distributor.name);
                  }
                }
              );
            }

            for (const [partnerName, brands] of allBrands.entries()) {
              if (brands.length < 1) {
                formattedPartners = formattedPartners.filter(
                  (partner) => partner.value !== partnerName
                );
              }
            }

            setBrandMap(allBrands);
            setPurchasePartnerOptions(formattedPartners);
          }
        );
      });
    }
  }, [type]);

  const updateMaterialOptions = (materialOptions: string) => {
    setMaterialOptions(materialOptions);
    if (materialOptions) {
      const option =
        material === MaterialOptionMismatched && wallMaterials
          ? wallMaterials.filter(
              (el: any) => el['@_Description'] === materialOptions
            )[0]
          : material === MaterialOptionMatch && matchMaterials
          ? matchMaterials.filter(
              (el: any) => el['@_Description'] === materialOptions
            )[0]
          : material === MaterialOptionBottom && bottomMaterials
          ? bottomMaterials.filter(
              (el: any) => el['@_Description'] === materialOptions
            )[0]
          : undefined;

      const bottomOption =
        material === MaterialOptionMismatched && bottomMaterials
          ? bottomMaterials.filter(
              (el: any) => el['@_Description'] === bottomMaterialOptions
            )[0]
          : '';
      setSelectedMaterialOption(option);
      setSelectedBottomMaterialOption(bottomOption);
      if (option) {
        if (material === MaterialOptionMatch) {
          setBead((option as LinerMatchMaterial)['@_WallBeadDesc']);
        } else if (material === MaterialOptionBottom) {
          setBead((option as LinerBottomMaterial)['@_BeadDesc']);
        } else if (material === MaterialOptionMismatched) {
          setBead((option as LinerBottomMaterial)['@_BeadDesc']);
        }
      }
    }
  };

  useEffect(() => {
    updateOrderState();
  }, [JSON.stringify(specVals)]);

  const updateLinerBottomPattern = (linerBottomPattern: string) => {
    setLinerBottomPattern(linerBottomPattern);
    updateBottomMaterialOptions(linerBottomPattern);
  };

  const updateBottomMaterialOptions = (linerBottomPattern: string) => {
    if (isAfterQuote) {
      if (bottomMaterials) {
        const match = bottomMaterials.filter(
          (el: any) =>
            el['@_Description'].includes(linerBottomPattern) &&
            el['@_Description'].includes(bottomMillGauge)
        )[0];
        setBottomMaterialOptions(match ? match['@_Description'] : '');
        setSelectedBottomMaterialOption(match);
      }
    }
  };

  const updatelinerPattern = (linerPattern: string) => {
    setLinerPattern(linerPattern);
    updateMaterialOptionsfunction(linerPattern);
  };

  const updateMaterialOptionsfunction = (linerPattern: string) => {
    if (isAfterQuote) {
      switch (material) {
        case MaterialOptionMatch:
          if (matchMaterials) {
            const match = matchMaterials.filter(
              (el: any) =>
                el['@_Description'].includes(linerPattern) &&
                el['@_Description'].includes(millGauge)
            )[0];
            updateMaterialOptions(match ? match['@_Description'] : '');
            setSelectedMaterialOption(match);
          }
          break;
        case MaterialOptionBottom:
          if (bottomMaterials) {
            const match = bottomMaterials.filter(
              (el: any) =>
                el['@_Description'].includes(linerPattern) &&
                el['@_Description'].includes(millGauge)
            )[0];
            updateMaterialOptions(match ? match['@_Description'] : '');
            setSelectedMaterialOption(match);
          }
          break;
        case MaterialOptionMismatched:
          if (wallMaterials) {
            const match = wallMaterials.filter(
              (el: any) =>
                el['@_Description'].includes(linerPattern) &&
                el['@_Description'].includes(millGauge)
            )[0];
            updateMaterialOptions(match ? match['@_Description'] : '');
            setSelectedMaterialOption(match);
          }
          break;
        default:
          break;
      }
    }
  };

  function updateBead(bead: string) {
    setBead(bead);
    if (isAfterQuote) {
      switch (material) {
        case MaterialOptionMatch:
          if (matchMaterials) {
            const match = matchMaterials.filter((el: any) =>
              el['@_WallBeadDesc'].includes(bead)
            )[0];
            setSelectedBeadOption(match);
          }
          break;
        case MaterialOptionBottom:
          if (bottomMaterials) {
            const match = bottomMaterials.filter((el: any) =>
              el['@_BeadDesc'].includes(bead)
            )[0];
            setSelectedBeadOption(match);
          }
          break;
        case MaterialOptionMismatched:
          if (wallMaterials) {
            const match = wallMaterials.filter((el: any) =>
              el['@_BeadDesc'].includes(bead)
            )[0];
            setSelectedBeadOption(match);
          }
          break;
        default:
          break;
      }
    }
  }

  useEffect(() => {
    if (matchMaterials && wallMaterials && bottomMaterials) {
      switch (material) {
        case MaterialOptionMatch:
          setLinerFabricOptions(
            Array.from(
              new Set(
                matchMaterials.map((el: LinerMatchMaterial) => ({
                  label: el['@_Description'].split(') ')[1],
                  value: el['@_Description'],
                  code: el['@_WallCode'],
                }))
              )
            ).filter((el: any) => el.value.includes(millGauge)) as Array<{
              value: string;
              label: string;
              code: string;
            }>
          );
          setLinerBottomFabricOptions(undefined);
          break;
        case MaterialOptionBottom:
          setLinerFabricOptions(
            Array.from(
              new Set(
                bottomMaterials.map((el: LinerBottomMaterial) => ({
                  label: el['@_Description'].split(') ')[1],
                  value: el['@_Description'],
                  code: el['@_BottomCode'],
                }))
              )
            ).filter((el: any) => el.value.includes(millGauge)) as Array<{
              value: string;
              label: string;
              code: string;
            }>
          );
          setLinerBottomFabricOptions(undefined);
          break;
        case MaterialOptionMismatched:
          setLinerFabricOptions(
            Array.from(
              new Set(
                wallMaterials.map((el: LinerWallMaterial) => ({
                  label: el['@_Description'].split(') ')[1],
                  value: el['@_Description'],
                  code: el['@_WallCode'],
                }))
              )
            ).filter((el: any) => el.value.includes(millGauge))
          );
          setLinerBottomFabricOptions(
            Array.from(
              new Set(
                bottomMaterials.map((el: LinerBottomMaterial) => ({
                  label: el['@_Description'].split(') ')[1],
                  value: el['@_Description'],
                  code: el['@_BottomCode'],
                }))
              )
            ).filter((el: any) => el.value.includes(bottomMillGauge))
          );
          break;
        default:
          break;
      }
    }
  }, [
    material,
    matchMaterials,
    bottomMaterials,
    wallMaterials,
    millGauge,
    bottomMillGauge,
  ]);

  useEffect(() => {
    if (
      matchMaterials &&
      wallMaterials &&
      bottomMaterials &&
      millGauge &&
      type === OrderType.Liner
    ) {
      const linerMaterialOptions: string[] = [];
      linerMaterialOptions.push(MaterialOptionMatch);
      linerMaterialOptions.push(MaterialOptionBottom);
      linerMaterialOptions.push(MaterialOptionMismatched);
      setMaterialSelectOptions(
        linerMaterialOptions.map((value) => ({
          label: value,
          value: value,
        }))
      );
    } else {
      let materialSelections =
        type === OrderType.Liner
          ? linerMaterialSelectOptions
          : coverMaterialSelectOptions;
      materialSelections = isAfterQuote
        ? materialSelections.filter(
            (option) => option.value !== chooseLaterString
          )
        : materialSelections;
      if (poolArea && poolArea > 2200) {
        // All solid fabric options are incompatible with pools over 2200 sq in
        // So don't present solid as an option in that scenario
        materialSelections = materialSelections.filter(
          (option) => option.value !== MaterialOptionSolid
        );
      }
      setMaterialSelectOptions(materialSelections);
    }
  }, [
    JSON.stringify(matchMaterials),
    JSON.stringify(wallMaterials),
    JSON.stringify(bottomMaterials),
  ]);

  useEffect(() => {
    if (!isPumpSelected) {
      setPumpQty(undefined);
    }
  }, [isPumpSelected]);

  return (
    <div className="product-form">
      <div className="product-form__title">Product</div>
      <OrderForm
        fieldErrorMessages={errorMessages}
        setFieldErrorMessages={setErrorMessages}
        onFieldsInvalid={updateOrderState}
        onFieldsValid={updateOrderState}
        values={currentValues}
        schema={
          isAfterQuote ? ProductFormSchemaForOrder : ProductFormSchemaForQuote
        }
        setValidFields={
          setValidFields as React.Dispatch<
            React.SetStateAction<GenericFieldValidationType>
          >
        }
        initialValidationState={InitialProductFormValidationStatus()}
      >
        <>
          {type && (
            <ManagedSelect
              label={`Is this a New or Replacement ${
                type === OrderType.Liner ? 'Liner' : 'Cover'
              }?`}
              isValid={validFields.newOrReplacement}
              value={newOrReplacement}
              onChange={setNewOrReplacement}
              options={
                type === OrderType.Cover
                  ? coverTypeSelectOptions
                  : linerTypeSelectOptions
              }
              name="new_or_replacement"
              disabled={
                disabled || state.order?.status === OrderStatus.ReadyForOrder
              }
            />
          )}
          <ManagedSelect
            label="Purchase Partner"
            isValid={validFields.partner}
            value={purchasePartner}
            onChange={(purchasePartner: string) => {
              setBrand(undefined);
              setPurchasePartner(purchasePartner);
            }}
            options={purchasePartnerOptions}
            name="purchase_partner"
            disabled={
              disabled || state.order?.status === OrderStatus.ReadyForOrder
            }
          />
          {brand || !purchasePartner || currentBrandOptions?.length > 0 ? (
            <ManagedSelect
              label="Brand"
              isValid={validFields.brand}
              value={brand?.id ?? ''}
              onChange={(brand: string) =>
                setBrand(
                  currentBrandOptions.find(
                    (option: Brand) => option.id === brand
                  )!
                )
              }
              options={getBrandSelectOptions()}
              name="brand"
              disabled={
                disabled || state.order?.status === OrderStatus.ReadyForOrder
              }
            />
          ) : (
            <NoOptionMessage label="Brand" value="No Brand is Available" />
          )}
          {type === OrderType.Liner && (
            <>
              <hr className="accessories-form__hr" />
              <ManagedToggle
                label="Overlap"
                checked={overlap}
                name="overlap"
                onChange={updateOverlap}
                isValid={validFields.overlap}
                disabled={disabled || isAfterQuote}
              />
              {overlap && (
                <ManagedInput
                  label="How Many Inches?"
                  isValid={validFields.overlapSize}
                  type="number"
                  min={1}
                  placeholder="Overlap Size"
                  onChange={setOverlapSize}
                  errorMessage="Overlap Size must be specified and a whole number."
                  value={overlapSize}
                  disabled={disabled || isAfterQuote}
                />
              )}
            </>
          )}
          {!hideMaterial && (
            <ManagedSelect
              label="Material"
              isValid={validFields.material}
              value={material}
              onChange={setMaterialForComponent}
              options={getMaterialSelectOptions()}
              name="material"
              disabled={disabled || !isAfterQuote}
            />
          )}
          {type === OrderType.Liner &&
            material !== chooseLaterString &&
            material !== chooseLaterStringOld &&
            millGaugeOptions && (
              <ManagedSelect
                label="Material Thickness"
                isValid={validFields.millGauge}
                value={millGauge}
                onChange={setMillGaugeForComponent}
                options={millGaugeOptions}
                name="millGauge"
                disabled={disabled}
              />
            )}
          {type === OrderType.Liner &&
            material === MaterialOptionMismatched &&
            millGaugeOptions && (
              <ManagedSelect
                label="Bottom Material Thickness"
                isValid={validFields.bottomMillGauge}
                value={bottomMillGauge}
                onChange={setBottomMillGaugeForComponent}
                options={millGaugeOptions}
                name="bottomMillGauge"
                disabled={disabled}
              />
            )}
          {type === OrderType.Cover &&
            material !== chooseLaterString &&
            material !== chooseLaterStringOld &&
            material && (
              <ManagedSelect
                label="Fabric"
                isValid={validFields.fabric}
                value={fabric}
                onChange={setFabric}
                options={fabricOptions}
                name="fabric"
                disabled={disabled}
              />
            )}

          {type === OrderType.Liner &&
            millGauge &&
            material &&
            linerFabricOptions && (
              <>
                <ManagedCarouselImagePicker
                  label={`${linerBottomFabricOptions ? 'Wall ' : ''}Pattern`}
                  isValid={validFields.linerPattern}
                  value={linerPattern}
                  onChange={(value) => {
                    updatelinerPattern(value.value);
                  }}
                  options={linerFabricOptions}
                  data-cy="linerPatternrSelector"
                  isItemSelected={(value, option) => value === option.value}
                  itemDataCy={(value) => `linerPattern_${value.value}`}
                  imageUrl={(option) =>
                    `https://reports.lathamlink.com/birt/report/images/Static_Images/CPC/liner-materials/${option.code}.png`
                  }
                  errorMessage={errorMessages?.linerPattern}
                  displayText={(option) => option.label}
                  disabled={disabled}
                />
                {linerBottomFabricOptions &&
                  material === MaterialOptionMismatched && (
                    <ManagedCarouselImagePicker
                      label="Bottom Pattern"
                      isValid={validFields.linerBottomPattern}
                      value={linerBottomPattern}
                      onChange={(value) => {
                        updateLinerBottomPattern(value.value);
                      }}
                      options={linerBottomFabricOptions}
                      data-cy="linerBottomPatternrSelector"
                      isItemSelected={(value, option) => value === option.value}
                      itemDataCy={(value) =>
                        `linerBottomPattern_${value.value}`
                      }
                      imageUrl={(option) =>
                        `https://reports.lathamlink.com/birt/report/images/Static_Images/CPC/liner-materials/${option.code}.png`
                      }
                      errorMessage={errorMessages?.linerBottomPattern}
                      displayText={(option) => option.label}
                      disabled={disabled}
                    />
                  )}
              </>
            )}

          {materialOptions && beadOptions && !isOverlap && (
            <ManagedSelect
              label="Bead Options"
              isValid={validFields.bead}
              value={bead}
              onChange={(bead: string) => {
                updateBead(bead);
              }}
              options={beadOptions}
              name="beadOptions"
              disabled={disabled}
            />
          )}
          {brand && fabric !== chooseLaterString && fabric && (
            <ManagedImagePicker
              label="Color"
              isValid={validFields.color}
              value={color}
              onChange={(value) => {
                setColor(value.color);
              }}
              options={colorOptions}
              data-cy="fabricColorSelector"
              isItemSelected={(value, option) => value === option.color}
              itemDataCy={(value) =>
                `colorSwatch_${value.pattern}_${value.fabric}_${value.color}`
              }
              imageUrl={(option) => option.url}
              displayText={(option) => option.color.replaceAll('_', ' ')}
              errorMessage={errorMessages?.color}
              disabled={disabled}
            />
          )}
          {material === MaterialOptionSolid && (
            <ManagedSelect
              label="Do you want a Drain or Pump?"
              isValid={validFields.drainOrPump}
              value={drainOrPump}
              onChange={setDrainOrPump}
              options={drainOrPumpSelectOptions}
              name="drain_or_pump"
              disabled={disabled}
            />
          )}
          {shouldShowSpringPrompt && (
            <ManagedCheckbox
              label="Do you want to upgrade to commercial springs?"
              isValid={validFields.commercialSprings}
              checked={commercialSprings ?? false}
              onChange={setCommercialSprings}
              disabled={disabled}
            />
          )}
          {isPumpSelected && (
            <ManagedSelect
              label="Quantity"
              isValid={validFields.pumpQty}
              value={pumpQty}
              onChange={setPumpQty}
              options={pumpSelectOptions}
              name="quantity"
              disabled={disabled}
            />
          )}
          {shouldShowPumpPrompt && (
            <ManagedCheckbox
              labelElement={
                <span>
                  I understand that by selecting "No, thanks" I've acknowledged
                  the following:{' '}
                  <a href={LEGAL_TERMS_URL} target="_blank" rel="noreferrer">
                    Read Acknowledgement
                  </a>
                </span>
              }
              isValid={validFields.noDrainOrPumpAck}
              checked={noDrainOrPumpAck ?? false}
              onChange={setNoDrainOrPumpAck}
              disabled={disabled}
            />
          )}
          {type === OrderType.Cover && (
            <ManagedSelect
              label="Grid Spacing"
              isValid={validFields.gridSpacing}
              value={gridSpacing}
              onChange={setGridSpacing}
              options={getGridOptions()}
              name="grid_spacing"
              disabled={disabled || !isAfterQuote}
            />
          )}
        </>
      </OrderForm>
    </div>
  );
};

export default ProductForm;
