import * as yup from 'yup';
import { InferType } from 'yup';
import { BaseDeckFormSchema } from '../Deck/DeckSchema';
import { getHardwareNeededValues } from './AnchorsFormConstants';

/**
 * Anchors form schema (step 3)
 * There's only a single field on the top-level schema, and then the Deck schema is repeated for each deck feature
 */
export const AnchorsFormSchema = yup.object().shape({
  hardwareNeeded: yup.string().required().oneOf(getHardwareNeededValues()),
  decks: yup.array().of(BaseDeckFormSchema),
});

export type AnchorsFormType = InferType<typeof AnchorsFormSchema>;
