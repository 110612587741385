import * as yup from 'yup';
import { InferType } from 'yup';

export const AccessoriesFormSchema = yup.object().shape({
  doubleD_Rings: yup.boolean().nullable(),
  yStraps: yup.boolean().nullable(),
  yStrapQty: yup
    .number()
    .nullable()
    .when('yStraps', {
      is: (yStraps: boolean) => yStraps,
      then: yup
        .number()
        .min(1, 'At least one yStrap is required')
        .integer('Quantity value must be an integer')
        .required(),
    }),
  strapLength: yup.number().nullable(),
  topAndBottomWebbing: yup.boolean().nullable(),
  buckles: yup.boolean().nullable(),
  kemkap: yup.boolean().nullable(),
  safetyStripes: yup.boolean().nullable(),
  safetyStripeWidth: yup.string().nullable(),
  safetyStripesColor: yup
    .string()
    .nullable()
    .when('safetyStripes', {
      is: (safetyStripes: boolean) => safetyStripes,
      then: yup.string().required(),
    }),
  solidShallowEnd: yup.boolean().nullable(),
  solidShallowEndColor: yup
    .string()
    .nullable()
    .when('solidShallowEnd', {
      is: (solidShallowEnd: boolean) => solidShallowEnd,
      then: yup.string().required(),
    }),
  divingTarget: yup.boolean().nullable(),
  divingTargetComments: yup
    .string()
    .nullable()
    .when('divingTarget', {
      is: (divingTarget: boolean) => divingTarget,
      then: yup.string().required(),
    }),
  racingStripes: yup.boolean().nullable(),
  racingStripesCount: yup
    .number()
    .nullable()
    .when('racingStripes', {
      is: (racingStripes: boolean) => racingStripes,
      then: yup
        .number()
        .min(1, 'At least one Racing Stripe is required')
        .integer('Quantity value must be an integer')
        .required(),
    }),
  racingStripesComments: yup
    .string()
    .nullable()
    .when('racingStripes', {
      is: (racingStripes: boolean) => racingStripes,
      then: yup.string().required(),
    }),
  isViewed: yup.boolean().oneOf([true], 'Field must be checked!').required(),
});

export type AccessoriesFormType = InferType<typeof AccessoriesFormSchema>;

export interface AccessoriesFormPropTypes {
  doubleD_Rings?: boolean;
  topAndBottomWebbing?: boolean;
  buckles?: boolean;
  kemkap?: boolean;
  isViewed?: boolean;
  yStraps?: boolean;
  yStrapQty?: string;
  safetyStripes?: boolean;
  safetyStripesColor?: string;
  safetyStripeWidth?: string;
  solidShallowEnd?: boolean;
  solidShallowEndColor?: string;
  divingTarget?: boolean;
  divingTargetComments?: string;
  racingStripes?: boolean;
  racingStripesCount?: string;
  racingStripesComments?: string;
}

export interface AccessoriesFormFieldsErrorMessageType {
  yStrapQty: string | null;
  safetyStripesColor: string | null;
  safetyStripeWidth: string | null;
  solidShallowEndColor: string | null;
  divingTargetComments: string | null;
  racingStripesCount: string | null;
  racingStripesComments: string | null;
  // Allowing any index to instead of having to add ts-ignore to usage in OrderForm
  [index: string]: string | null;
}
